@font-face {
    font-family: "Poppins-Regular";  src: url('../fonts/Poppins-Regular.woff') format('woff'), url('../fonts/Poppins-Regular.otf') format('otf'), 	url('../fonts/Poppins-Regular.ttf') format('ttf'), url('../fonts/Poppins-Regular.svg') format('svg'); 
  }
  
  @font-face {
    font-family: "Poppins-Medium";  src: url('../fonts/Poppins-Medium.woff') format('woff'), url('../fonts/Poppins-Medium.otf') format('otf'), 	url('../fonts/Poppins-Medium.ttf') format('ttf'), url('../fonts/Poppins-Light.svg') format('svg'); 
  }
  
  @font-face {
    font-family: "Poppins-SemiBold";  src: url('../fonts/Poppins-SemiBold.woff') format('woff'), url('../fonts/Poppins-SemiBold.otf') format('otf'), 	url('../fonts/Poppins-SemiBold.ttf') format('ttf'), url('../fonts/Poppins-SemiBold.svg') format('svg'); 
  } 
  
  @font-face {
    font-family: "Poppins-Bold";  src: url('../fonts/Poppins-Bold.woff') format('woff'), url('../fonts/Poppins-Bold.otf') format('otf'), 	url('../fonts/Poppins-Bold.ttf') format('ttf'), url('../fonts/Poppins-Bold.svg') format('svg'); 
  } 
  
  @font-face {
    font-family: "Poppins-Black";  src: url('../fonts/Poppins-Black.woff') format('woff'), url('../fonts/Poppins-Black.otf') format('otf'), 	url('../fonts/Poppins-Black.ttf') format('ttf'), url('../fonts/Poppins-Black.svg') format('svg'); 
  }
  
  @font-face {
    font-family: "Poppins-ExtraBold";  src: url('../fonts/Poppins-ExtraBold.woff') format('woff'), url('../fonts/Poppins-ExtraBold.otf') format('otf'), 	url('../fonts/Poppins-ExtraBold.ttf') format('ttf'), url('../fonts/Poppins-ExtraBold.svg') format('svg'); 
  } 
  
  b, strong{font-family: "Poppins-SemiBold";}
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { -webkit-appearance: none;} 
  *{	padding:0;	margin:0; font-family: "Poppins-Regular"; color:#333; font-size:14px; line-height:24px;}
  p{ padding:0; margin:0px 0px 15px; font-family: "Poppins-Regular"; color:#333; font-size:14px; line-height:24px;}
  .container{ max-width: 90% !important; margin: 0 auto; width: 100% !important;}
  img{ max-width:100%;}
  a, button{ transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
  input:focus, select:focus{ outline:none;}
  a, a:hover{ text-decoration:none !important;}
  .flex-class{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
  .container_new {max-width: 1366px; width: 100%; padding: 0 15px; margin: 0 auto;}

  /********************************************************** Header CSS ****************************************************/
  .breadcrumb > .breadcrumb-item + .breadcrumb-item::before {
    content: ' > ';
  }
  header { background-color: #202020; padding: 15px 0px; position: relative;}
  .header-row { align-items: center;}
  .header-row .co-logo { width: 25%;}
  .header-row .co-logo img { width: 165px; }
  .header-row .searchbox { width: 50%; padding: 0px 15px; align-items: center;}
  .header-row .header-right-col{ width:25%; text-align:right;}
  .header-row .header-right-col .top-bar-menu { padding-bottom: 15px;}
  .searchbox .search-col { width: calc(100% - 60px);}
  .searchbox .search-col .form-group .search-input { width: 100%; background-color: #fff; border: none; border-radius: 4px; height: 40px; padding: 0px 15px;}
  .searchbox .search-col .form-group .search-input::placeholder {color: rgb(0, 0, 0);}
  .searchbox .search-col .form-group .search-input::-ms-input-placeholder {color: rgb(0, 0, 0);  }

  .searchbox .search-col .form-group .sear-btn { width: 40px; height: 40px; background-color: transparent; border: none; position: absolute; top: 0px; right: 0px;}
  .searchbox .cameraUpload { width: 60px; text-align:right;}
  .searchbox .cameraUpload button{ background-color:transparent; border:none;}
  .searchbox .cameraUpload img{ max-width: 30px;}
  .searchbox .search-col .form-group { margin: 0px; position: relative;}
  .header-right-col .top-bar-menu ul { list-style: none; align-items: center; justify-content: right; margin: 0px; padding: 0px;}
  .header-right-col .top-bar-menu ul li { padding-left: 15px;}
  .header-right-col .top-bar-menu ul li a{ color: #ffffff;}
  .header-right-col .top-bar-menu ul li.help-call a { border: solid 1px transparent; padding: 4px 12px; border-radius: 30px;}
  .header-right-col .top-bar-menu ul li.help-call a:hover { border: solid 1px #ffcb08; background-color: #ffcb08; color:#333;}
  .header-right-col .top-bar-menu ul li.help-call a:hover img{ filter: brightness(0.2); -webkit-filter: brightness(0.2);}
  .header-right-col .h-main-menu ul { list-style: none; align-items: center; justify-content: right; margin: 0px; padding:0px;}
  .header-right-col .h-main-menu ul li { padding-left: 30px;}
  .h-main-menu .cart-bx { position: relative; }
  .h-main-menu .cart-bx span { background: #ffcb37; width: 20px; height: 20px; display: inline-block; text-align: center; border-radius: 50%; line-height: 20px; font-size: 12px; position: absolute; right: -10px; top: -8px; z-index: 5; }
  .h-main-menu .cart-bx span:hover{cursor: pointer;}
  .header-right-col .h-main-menu ul li:first-child{ padding-left:0px;}
  .header-right-col .h-main-menu ul li a{ color: #ffffff;}
  .header-right-col .h-main-menu ul li.cart-bx p img{  height:26px; -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);}
  .custom-head-mobile{ display:none;}
  .header-section.fixed .obl-nav-section {
      position: fixed;
      width: 100%;
      top: 0px;
      z-index: 9;
  }
  #mobile_search_div { display:none;}
  .mini_cart { width: 370px; z-index: 2; position: absolute; background: #fff; top: 90px; right: 80px; padding: 15px; box-shadow: 0 0 5px #0000008c; display: block;}
  .mini_cart.close_min_cart{display: none;}
  .mini_cart .btn.btn-primary { background: #008758; border-color: #008758; width: 100%; border-radius: 30px; padding: 8px 0;}
  .mini_cart .btn.btn-primary:hover{background: transparent;color: #008758;} 
  .mini_flex { display: flex; justify-content: space-between; margin-bottom: 15px;}
  .mini_flex small { color: #000; }
  .mini_cart_data { display: flex; align-items: center; padding-bottom: 15px; } 
  .min-cart_row { width: calc(100% - 20px); display: flex; align-items: center; }
  .mini_cart_data_img { margin-right: 0; width: 80px; }
  .mini_cart_data_cont { width: calc(100% - 80px); padding-left: 10px; }
  .mini_cart_itemremove { width: 20px; text-align: right; }
  .mini_cart_data_img img { height: 80px; width: 80px; object-fit: cover; }
  .mini_cart_data_cont p { margin: 0; }
  .mini_cart_productlist { border-top: 1px solid #dddddd; border-bottom: 1px solid #dddddd; margin: 15px 0; padding: 15px 0; padding-bottom: 0; max-height: 285px; overflow-y: scroll; }
  .mini_cart:before { content: ''; width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; border-bottom: 5px solid white; position: absolute; right: 10px; top: -5px; }
  .close_icon { position: absolute; right: 5px; top: 0px; }
  .close_icon svg {font-size: 22px;}
  .close_icon:hover {cursor: pointer;}
  .close_icon:hover svg path {color: red;}
  .whiteTitleHead{color: white;}
  .cataloguesearch_page_tile_filter button.ais-InfiniteHits-loadMore.ais-InfiniteHits-loadMore--disabled{display: none;}
  /********************************************************** Header CSS ****************************************************/
  
  
  
  /********************************************************** NAV CSS ****************************************************/
  .obl-nav-section { background-color: #343434; position: relative;}
  .nav-row ul.product-menu{ justify-content: space-between; list-style:none; margin:0px; padding-left:0px;}
  .nav-row ul.product-menu>li .menu-arrow{ display:none;}
  .nav-row ul.product-menu>li>a{ padding: 13px 5px; color: #ACACAC; display: block; text-decoration:none;}
  .nav-row ul.product-menu>li>a:hover{ background-color: #202020; color:#fc0;}
  .obl-submenu-row { display: none;}
  .nav-row ul.product-menu>li:hover .obl-submenu-row { display: block; width: 100%; position: absolute; top: 50px; left: 0px; background-color: #202020; z-index: 1;}
  .obl-submenu-row .obl-submenu-nav { height: 500px; overflow: hidden; overflow-y: auto !important; margin: 30px 0px;}
  .obl-submenu-nav::-webkit-scrollbar { width: 10px;}
  .obl-submenu-nav::-webkit-scrollbar-track { background: #888; border-radius:5px;}
  .obl-submenu-nav::-webkit-scrollbar-thumb { background: #f1f1f1; border-radius:5px;}
  .obl-submenu-row .obl-submenu-nav .titleBox { width: 20%; padding-right: 15px;}
  .obl-submenu-row .obl-submenu-nav .titleBox:last-child { padding-right: 0px;}
  .obl-submenu-row .obl-submenu-nav .titleHead a { color: #fff; padding-bottom: 10px; display: inline-block;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul{ list-style:none; margin:0px; padding:0px;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul li a{ color:#acacac; font-size:14px; position:relative; transition:all 0.5s; overflow:hidden; display: inline-block; line-height:24px;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul li a:hover{ color:#fc0; text-decoration:none;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul li a:after{content:"";position:absolute;bottom:0px;width:100%;height:1px;left:-100%; background-color:#fff;transition:all 0.5s; -webkit-transition:all 0.5s; -moz-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul li a:hover:after{display:inline-block;left:0px; background-color:#fc0;transition:all 0.5s; -webkit-transition:all 0.5s; -moz-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul.titlepattern li .pattern { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; padding-bottom:15px;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul.titlepattern li .pattern img { width: 40px; height: 40px;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul.titlepattern li .pattern .patternName { width: calc(100% - 40px); padding-left: 10px;}
  .obl-submenu-row .obl-submenu-nav .titleBox ul.titlepattern li .pattern .patternName span{ color: #a8a8a8; font-size: 12px; width: 100%; display: block; line-height: normal;}
  .obl-submenu-row .obl-submenu-nav .tilecolor { width: 100%;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul{ list-style:none; margin:0px; padding:0px;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li{ margin: 0.5em 0.5em 0; text-align: center; padding: 0 0.4em;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch { width: 35px; height: 35px; display: block; padding: 0; border-radius: 50%; margin: 0 auto; background: #fff;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li span { color: #a8a8a8; padding-top: 5px; display: inline-block;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch2 { background: #040404;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch4 { background: #865340;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch5 { background: #c9b194;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch6 { background: #eadeba;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch7 { background: #ede3c4;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch8 { background: #feca2d;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch9 { background: #2b70f1;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch10 { background: #3dc72c;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch11 { background: #b4b4b4;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch12 { background: #f6a9e8;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch14 { background: #fc431a;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch15 { background: #cf4e46;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch15 { background: #cf4e46;}
  .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch16 { background: linear-gradient(to right bottom, #cc0076, #a02f87, #629834);}
  .obl-submenu-row .obl-submenu-nav .titleBox .header-view-all-tiles a{ font-size: 18px; color: #ffcb08;}
  .obl-submenu-row .obl-submenu-nav .titleBox .titleBox { width: 100%;}
  /********************************************************** NAV CSS ****************************************************/
  .foot-col.foot-connect .f-heading:before, .foot-col.cpaddress .f-heading:before {
    display: none;
}
.digi-content { max-width: 80%; padding: 50px 20px; width: 100%; margin: 0px auto; }
.digi-wrap { border: 2px solid #39b549; border-radius: 50px; clear: both; padding: 2em 0 0; margin: 2em 0; }
.digi-wrap .digi-head { border-radius: 90px; background: #39b549; color: #fff; max-width: 80%; width: 100%; margin: 0 auto; padding: 0.5em 0.8em; font-size: 2em; text-align: center; line-height: 120%; }
.digi-wrap .digi-row { width: 100%; text-align: center; border-bottom: 2px solid #39b549; padding: 1em 0; }
.digi-wrap .digi-row:last-child{ border-bottom: none;}
.digi-wrap .digi-row .digi-txt { font-size: 1.4em; line-height: 120%; color: #000; padding: 10px 0.5em 0px; }
.digi-wrap .digi-row ul.digi-social-icon { list-style: none; padding: 0; max-width: 320px; width: 100%; margin: 2em auto 0; }
.digi-wrap .digi-row ul.digi-social-icon li { display: inline-block; padding: 0 0.5em; width: 17%; margin: 0.5em 0; }




/**********************************************Header********************************************/
.language_trans_modal .modal-header { flex-flow: wrap; border: none; padding: 30px 15px !important;}
.language_trans_modal .modal-header label { width: 100%;}
.language_trans_modal .modal-header label { text-align: center; font-size: 20px; line-height: normal; margin-bottom: 20px;}
.language_trans_modal .modal-header .mobilelanguga { width: 100%; border: solid 1px #ccc; border-radius: 5px; height: 45px; padding: 0px 10px;}
.language_trans_modal .modal-header .btn-close { position: absolute; top: 15px; right: 15px;}
.language_trans_modal .modal-body{ display: none !important;}


/******* **********/

.category_accordion_data .accordion>li .inner>li>.toggle { width: 100%; background: #e9e9e9; padding: 10px;}
.category_accordion_data .accordion>li .inner>li .inner { border: 1px solid #e9e9e9;}







@media only screen and (max-width: 767px)
{ 

    /******** Expansion on category content *******/
.category_accordion_data.mobile p:last-child {
    margin-bottom: 0px;
}
/******** Expansion on category content *******/
	.category_accordion_data .accordion>li .inner>li>.toggle { padding: 10px 20px 10px 10px;}
	.category_accordion_data .accordion>li .inner>li>.toggle:after{ content: ">"; float: right; font-size: 20px; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); position: absolute; top: 10px; right: 10px;}
}





/*******************************************/
@media screen and (max-width: 980px)
{
	.digi-content { max-width: 100%; padding: 30px 0px;}
	
}

@media screen and (max-width: 768px)
{
	.lauch-row img { width: 150px;}
	.digi-wrap .digi-row .digi-txt{ font-size:16px;}
	
}
  
  
  /********************************************************** Menu Category Slider CSS ****************************************************/
  .menu-slider { padding-top: 5px; height: 210px;}
  .menu-slider .slick-list{ padding:0px !important;}
  .menu-slider .slick-list .slick-slide{ padding:0px 4px;}
  .menu-slider .slick-list .slick-slide .menu-item{ text-align:center;}
  .menu-slider .slick-list .slick-slide .menu-item p{ margin:10px 0px;line-height: normal; color: #333; font-size: 14px;}
  .menu-slider .slick-list .slick-slide img{ width:100%; border-radius: 8px;}
  .menu-slider .slick-arrow { top: 42%;}
  .menu-slider .slick-prev { width: 30px; height: 30px;}
  .menu-slider .slick-prev:before { content: ''; display: inline-block; width: 20px; height: 20px; border-right: 2px solid #ccc; border-top: 2px solid #ccc; transform: rotate(-135deg); -webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); -ms-transform: rotate(-135deg); -o-transform: rotate(-135deg); }
  .menu-slider .slick-next { width: 30px; height: 30px;}
  .menu-slider .slick-next:before { content:''; display: inline-block; width: 20px; height: 20px; border-right: 2px solid #ccc; border-top: 2px solid #ccc; transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg);}
  .product_mobile_view_slider{display: none;}
  .product_mobile_view_slider .slick-dots{bottom: 15px !important;}
  .blog_menu_new:hover .obl-submenu-row{width: auto !important; left: auto !important;}
 .blog_menu_new .obl-submenu-row .obl-submenu-nav {height: auto; margin: 15px 0px;}

  
  /********************************************************** Menu Category Slider CSS ****************************************************/
  
  
  
  /**************************** Product Page ***********************/
  .review_pro_custom_file {
    flex-wrap: wrap;
    margin-top: 30px;
}
.review_pro_custom_file>div{width: 49%;}
  .review_pro_custom_file label {
    cursor: pointer;
    font-weight: 100;
    margin: 0;
    border: 1px solid #c9c9c9;
    padding: 10px;
    border-radius: 2px;
    width: 100%;
    background: #eee;
    text-align: center;
}
.your_rating_css_div>div {
    width: 48%;
}
.your_rating_css {
    width: 48%;
}
.your_rating_css span{font-size: 28px !important;}
.your_rating_css p{margin-bottom: 5px;font-weight: bold; font-size: 14px;}
.your_rating_css_div { flex-wrap: wrap; flex-direction: row-reverse;}
  .review_pro_custom_file  #upload-photo {opacity: 0;position: absolute;z-index: -1;display: none;}
  .productdetail .breadcumb { display: flex; justify-content: space-between; align-items: center; margin: 15px 0; }
  .productdetail .breadcrumb { margin-bottom: 0; }
  .prolocation .text-danger { font-size: 14px; padding-left: 5px;}
  .productdetail .breadcumb p {margin-bottom: 0;font-size: 12px;}
  .productdetail .breadcumb p:hover {cursor: pointer;}
  .productdetail .breadcrumb-item a { color: #747474; }
  .productdetail .breadcrumb-item.active{color: #333;}
  .productslider { width: 60%; padding-right: 25px; }
  .productpara { width: 40%; padding-left: 25px;}
  .productpara h3 { font-size: 35px; }
  .prorating { display: flex; justify-content: space-between; align-items: center;}
  .prorating p{ margin: 0; }
  .prorating { display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; border-bottom: 1px solid #e5e5e5; padding-bottom: 3px; }
  .prorating p { font-size: 12px; color: #333; display: inherit; align-self: center; }
  .prorating small { font-size: 12px; color: #747474; padding-right: 20px; position: relative; }
  .prorating small:after { content: ''; width: 12px; height: 12px; border-radius: 50%;  display: block; position: absolute; right: 0; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); }
  .prorating small.new_avialabily:after{background: #747474;}
  .rrr { margin-right: 5px; font-size: 16px; }
  .rrr1 { margin-right: 5px; font-size: 16px; }
  .rrr1 path { color: #8f8f8f; font-size: 16px; }
  .rrr path { color: #ff5e00; font-size: 16px; }
  .prolocation { border: 1px solid #d1d1d1; margin-bottom: 20px;}
  .productpara input.form-control { height: 40px; }
  .deliv_date { margin: 0; font-size: 13px; text-align: center; background: #ffca4d; color: #333; }
  .deliv_date strong { font-size: 13px; }
  .pro_counterdata { display: flex; justify-content: space-between; border: 1px solid #cdd4da; align-items: center; border-radius: 5px; }
  .pro_counterdata button { box-shadow: none; background: transparent; border: 1px solid #ccd4da; height: 40px; width: 40px; border-top: 0; border-bottom: 0; }
  .pro_counterdata p{margin: 0;}
  .procounter { width: 180px; padding-left: 10px; }
  .pro_forminline .form-label {font-weight: 100;}
  .pro_forminline { display: flex; justify-content: space-between; flex-wrap: wrap; }
  .pro_forminline div { width: 49%; }
  .procounter div { width: 100%; }
  .pr_loc_total .disclaimer-price-txt {
    display: block;
    margin: 0px auto;
}
.pr_loc_total .disclaimer-price-txt:after {
    display: none;
}
  .pr_loc_total { margin: 15px 0; text-align: center; color: #525252;}
  p.pr_loc_total span:after {content: '';width: 100%;height: 1px;background: #7d7686;position: absolute;left: 0;top: 50%;
  }
  p.pr_loc_total span {margin: 0 7px;color: #7d7686;position: relative;}
  .pr_loc_total storng { font-size: 20px; font-weight: 700; color: #525252; padding-left: 5px }
  .prolocation .btn-outline-success { font-size: 16px; font-weight: bold; border: 2px solid; padding: 8px 0; width: 49%; }
  .prolocation .btn-primary,.prolocation .btn-primary:hover { float: right; font-weight: bold; padding: 9px 0; width: 49%; background: #008758; border: 2px solid; font-size: 16px; }
  .prolooksgoodcard{display: flex;flex-wrap: wrap;margin: 0 0 20px;}
  .prolookdata img { height: 70px; width: 100%; border-radius: 5px; object-fit: cover;}
  .prolookdata small { font-size: 10px; }
  .prolookdata { width: 22%; text-align: center; margin-right: 10px; }
  .prolookdata:nth-last-child(1) { margin-right: 0; }
  .prodeatilcard .accordion-button,.protoolscard .accordion-button { background: transparent !important; box-shadow: none !important; color: #333 !important; font-size: 18px; }
  h5.looksgoodwith_head { margin-top: 20px; margin-bottom: 15px; }
  .prodeatilcard .accordion-button:not(.collapsed)::after, .protoolscard .accordion-button:not(.collapsed)::after{background-image: var(--bs-accordion-btn-icon);}
  .prodeatilcard p { padding-left: 90px; position: relative; }
  .prodeatilcard p span { position: absolute; left: 0; top: 2px; }
  .faTagIcon { font-size: 18px; margin-right: 20px; }
  .faTagIcon path { color: #fff; }
  .prodeatilcard .btn.btn-outline-success{font-weight: bold; font-size: 20px; width: 100%; padding: 12px; border: 2px solid;}
  .pro_testimonials{clear: both;}
  .proToolIcon { font-size: 38px; display: block; margin: 0 auto; }
  .proToolIcon path{color: #828282;}
  .protoolsdata { display: flex; flex-wrap: wrap; justify-content: space-between; }
  .toolscontent { text-align: center; width: 31%; box-shadow: 0px 3px 5px #0000001f; margin: 10px 0; padding: 10px; cursor: pointer;}
  .toolscontent small{font-size: 10px;}
  .productpage_title { text-align: left !important; margin: 20px 0; }
  .productpage_title span{ text-align: left; font-size: 30px; color: #333; }
  .pro_testimonials .slick-next, .pro_testimonials .slick-prev{display: none !important;}
  .productdetail .pro_testimonials .slick-next, .productdetail .pro_testimonials .slick-prev{display: block !important;}
  .prosimilartiles_item { padding: 0 10px; }
  .productpage_title1 { margin: 40px 0 30px; border-bottom: 1px solid #82828238; padding-bottom: 15px; font-weight: bold; }
  .prd-heading.addIcon { margin: 40px 0 20px; border-bottom: 1px solid #82828238; padding-bottom: 20px; font-size: 22px; color: #828282; font-weight: bold; }
  #syte-similar-items-container .syte-slider-title, #syte-personalization-container .syte-slider-title{display: none;}
  #syte-personalization-container .syte-slider-layout-container{margin: 0 auto;}
  .pro_similartiles { background: #f3f3f4; padding: 15px; margin-bottom: 60px;}
  .pro_similartiles .slick-slide{padding: 0 10px;}
  .pro_youmayalso { background: #f3f3f4; padding: 15px; margin-top: 60px; }
  .prd-heading.addIcon span{font-size: 22px; color: #333;font-weight: bold !important;} 
  .productpage_title1 strong { font-size: 22px; color: #333; font-weight: bold !important;}
  .pro_about.Pro_Other p { position: relative; width: 60%; padding-left: 270px;}
  .pro_about.Pro_Other p span { left: 0; color: #747474; font-size: 16px; position: absolute; }
  .pro_writereview .form-control {height: 50px;}
  .pro_writereview .form-control::placeholder, .pro_writereview textarea#floatingTextarea2::placeholder{font-size: 16px; color: #828282 !important;}
  .pro_writereview .btn.btn-primary {
    background: #6c6c6c;
    color: #fff;
    border-color: #6c6c6c;
    padding: 12px;
    border-radius: 2px;
    width: 49%;
    text-align: center;
}
  .review_iden img { width: 30px; height: 30px; border-radius: 50%; margin-right: 10px; }
  .pro_writereview_data { padding: 20px 0; }
  .review_rat small { color: #008975 !important; padding: 0 9px; line-height: 24px !important; font-size: 14px !important; }
  .review_rat { font-size: 14px; color: #000; margin: 0; display: inline-flex; }
  .pro_writereview_data small{font-size: 12px;display: block; color: #828282;}
  .pro_writereview_data small { font-size: 12px; display: block; color: #828282; line-height: 20px; }
  .review_com { font-size: 14px; margin: 15px 0; color: #000; }
  .review_images img { width: 180px; height: 130px; object-fit: cover; margin-right: 15px; }
  .pro_writereview_content { margin: 50px 0; }
  .pro_writereview { padding-top: 40px; }
  .pro_writereview .productpage_title, .pro_youmayalso .productpage_title{ margin-bottom: 50px; }
  .pro_youmayalso .slick-slide{padding: 0 10px;}
  .pro_similartiles .productpage_title{margin-bottom: 40px;margin-top: 0;}
  .pro_youmayalso .productpage_title{margin-top: 0;}
  .review_iden { display: flex; align-items: center; }
  .productslider .thumbnail-bx { max-width: 100px; float: left; max-height: 525px; height: 100%; overflow: hidden; }
  .product-img-view { width: calc(100% - 125px); float: right; position: relative; height: 525px; overflow: hidden; border: 1px solid #ccc; display: flex; flex-flow: wrap; justify-content: center; align-items: center; }
  .product-img-view .my-room-btn { display: block; text-align: center; margin-top: 20px; height: 54px; line-height: 54px; font-size: 20px; border-radius: 4px; color: #333; position: absolute; bottom: 20px; left: 10%; right: 10%; background: #fff; font-weight: bold; box-shadow: 0 0 3px #000; border: none; }
  .product-img-view .my-room-btn:hover { background-color:#0B9444;  color: #fff;}
  .product-img-view img { width: auto !important; height: auto !important; max-height: 100%; transition: ease all .5s; -webkit-transition: ease all .5s; -moz-transition: ease all .5s; -ms-transition: ease all .5s; -o-transition: ease all .5s;}
  .product-img-view:hover img { transform: scale(1.5); }
  .thumbnail-bx img { height: 80px; width: 100%; object-fit: cover; }
  .pro_similartiles #syte-similar-items-container .syte-slider-arrow.syte-slider-arrow-left,.pro_youmayalso   #syte-personalization-container .syte-slider-arrow.syte-slider-arrow-left { left: -60px !important; }
  .pro_similartiles #syte-similar-items-container .syte-slider-arrow.syte-slider-arrow-right,.pro_youmayalso #syte-personalization-container .syte-slider-arrow.syte-slider-arrow-right { right: -60px; }
  
  .product-wrap .product-right{ width:50%; padding-left:25px;}
  .product-wrap .product-right .product-name { border-bottom: solid 1px #E0E0E0; padding-bottom: 15px;}
  .product-wrap .product-right .product-name p{ color: #828282; font-size: 14px; font-family: "Roboto-Bold"; margin: 0px; line-height: normal; text-align: right; padding-right: 25px; position:relative;}
  .product-wrap .product-right .product-name p:before { content: ''; width: 15px; height: 15px;background-color: #0F9243; display: block; border-radius: 50%; position: absolute;
      top: 0px; right: 0px;}
      .product-left { position: -webkit-sticky; position: sticky; top: 60px; width: 100%; float: left; }
   
  .exclamation_icon { display: inline-block; height: 25px; width: 25px; border-radius: 50%; text-align: center; line-height: 25px;position: relative;z-index: 3;position: unset !important;}
  .exclamation_icon:hover{cursor: pointer;}
  .exclamation_icon svg{font-size: 10px;}   
 .exclamation_size_modal .modal-dialog{position: relative;}
 .exclamation_size_modal {border-radius: 0;}
 .exclamation_size_modal .btn-close { position: absolute; right: 10px; top: 10px; z-index: 5; }

 .product_defaultModal .modal-content {border-radius: 0;padding: 20px;}
 .product_defaultModal .modal-header {padding-bottom: 10px !important;}
 .product_defaultModal .btn.btn-primary{width: 120px;margin-top: 20px;border-radius: 30px; border-color:#dde2e6; color: #333; background-color: transparent; height: 40px;}
 .product_defaultModal .btn.btn-primary:hover{border-color:#ffcb08; color: #333; background-color: #ffcb08;}
 .productslider-row-asas { display: flex; flex-flow: wrap; width: 100%; }
 .productpara .accordion-button {padding-bottom: 0;}
 .productpara .accordion-button h2{margin-bottom: 0;}
 .productdetail .productpage_title span, .productdetail .prd-heading.addIcon span{font-weight: bold;font-size: 28px;}
 .pro_similartiles h2, .pro_about.Pro_Other h2, .pro_youmayalso h2, .pro_writereview h2{ padding-bottom: 20px; }
 .productdetail .prd-heading.addIcon{font-size: 28px; font-weight: bold;}
 .productdetail .productpage_title{margin: 0;}
 .pro_about.Pro_Other { margin-top: 50px; }
 .productslider .thumbnail-bx .slick-prev{ left: 50% !important; top: 0px; transform: translate(-50%, -0%) !important; -o-transform: translate(-50%, -0%) !important; -ms-transform: translate(-50%, -0%) !important; -moz-transform: translate(-50%, -0%) !important; z-index: 1; width: 26px; height: 22px; background-color: #f1f1f1f0 !important; border-radius: 0px 0px 5px 5px;}
.productslider .thumbnail-bx .slick-prev:before { content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #0b9444 !important; border-top: 2px solid #0b9444 !important; opacity: 1 !important; transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg);}
.productslider .thumbnail-bx .slick-next { right: unset; top: unset; left: 50%; bottom: 9px; z-index: 1; width: 26px; height: 22px; background-color: #f1f1f1f0 !important; border-radius: 5px 5px 0px 0px; transform: translate(-50%, -0%) !important; -o-transform: translate(-50%, -0%) !important; -ms-transform: translate(-50%, -0%) !important; -moz-transform: translate(-50%, -0%) !important; }
.productslider .thumbnail-bx .slick-next:before { content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #0b9444 !important; border-bottom: 2px solid #0b9444 !important;  opacity: 1 !important; margin-bottom: 7px; transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg);}
.category_detail_page .productpage_title{margin: 20px 0;}

  
  /************************************ 3d Virtual CSS *************************/
  
  .breadcrumb { margin: 0; padding: 15px 0; }
  .breadcrumb-item { color: #828282; font-size: 13px; }
  .breadcrumb-item a{ color: #828282; font-size: 13px; }
  .title_h3 { text-align: center; margin: 45px 0 90px; position: relative; color: #000;}
  .title_h3 h3{ position: relative;}
  .title_h3 h3:after { content: ''; width: 50px; height: 2px; position: absolute; background-color: #000; top: 50%; right: 260px; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}
  .title_h3 h3:before { content: ''; width: 50px; height: 2px; position: absolute; background-color: #000; top: 50%; left: 260px; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); }
  
  .title_h3:after { content: ''; width: 50px; height: 2px; position: absolute; background-color: #d4b068; bottom: -25px; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%);}
  .three_d_frame { display: flex; justify-content: space-between; flex-wrap: wrap; }
  .three_d_frame div span { display: block; font-size: 22px; color: #848484; text-transform: capitalize; margin-bottom: 15px; }
  .three_d_frame div { text-align: center; width: 49%; }
  .three_d_frame { margin: 60px 0; }
  .three_d_frame div img { width: 100%; }
  .\33 d_virtual_page ol.breadcrumb { margin-top: 30px; }
  .three_d-virtual-city { padding: 20px 0 40px; }
  .three_d-virtual-city iframe { margin-top: 10px; }
  .three_d-virtual-city a { color: #333; }
  
  
  
  /********************************************************** Footer CSS ****************************************************/
  .review-Catalouge { background-color: #e6e6e6; text-align: center; padding: 20px 0px;}
  .d-catalog-row a{ color: #000; display: inline-block; font-size: 16px; text-decoration: none;}
  .fromUs-section { background-color: #494949; padding: 20px 0px;}
  .ft-fromUs-row { max-width: 1000px; width: 100%; margin: 0px auto; align-items:center;}
  .ft-fromUs-row .fr-Title { font-size: 20px; color: #fff; width: 220px;}
  .ft-fromUs-row .us-form-col { width: calc(100% - 220px);}
  .ft-fromUs-row .us-form-col form .form-group { margin: 0px; width: 27%; padding-right: 5px;}
  .ft-fromUs-row .us-form-col form .form-group .input-bx { padding: 0px 15px; height: 40px; border-radius: 30px; border: none; width:100%;}
  .ft-fromUs-row .us-form-col form .form-group.button { width: 16%; padding: 0px;}
  .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn { background-color: #0f9243; border: solid 1px #0f9243; color: #fff; padding: 0px 20px; font-size: 16px; height: 40px; border-radius: 30px;}
  .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn:hover { background-color: #fc0; border: solid 1px #fc0; color: #000;}
  footer{ background-color:#202020; padding:50px 0px;}
  .footer-top .foot-col { width: 14.28%; padding-right: 15px;}
  .footer-top .foot-col:last-child{ padding-right:0px;}
  .footer-top .foot-col .f-heading h6 { font-size: 18px; color: #fff; margin: 0px 0px 20px;}
  .footer-top .foot-col ul{ list-style:none; margin:0px; padding:0px;}
  .footer-top .foot-col ul li { line-height: 12px; padding: 0px 0px 5px; margin: 0px;}
  .footer-top .foot-col ul li a{ color:#acacac; font-size:14px; position: relative; transition: all 0.5s; overflow: hidden; display: inline-block; line-height:normal;}
  .footer-top .foot-col ul li a:hover, .footer-top .foot-col .telno a:hover{ color:#fc0; text-decoration:none;}
  .footer-top .foot-col ul li a:after{content:"";position:absolute;bottom:0px;width:100%;height:1px;left:-100%; background-color:#fff;transition:all 0.5s; -webkit-transition:all 0.5s; -moz-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s;}
  .footer-top .foot-col ul li a:hover:after{display:inline-block;left:0px; background-color:#fc0;transition:all 0.5s; -webkit-transition:all 0.5s; -moz-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s;}
  .footer-top .foot-col .foot-box { padding-top: 50px; width: 100%; border: none; padding-left: 0px; padding-right: 0px;    min-height: unset;}
  .footer-top.ft-bottom { border-top: 1px solid #333;  padding: 3em 0 0; margin-top: 2em;}
  .footer-top.ft-bottom .foot-col:nth-child(5) { width: 23%;}
  .footer-top.ft-bottom .foot-col:nth-child(6) { width: 19%;}
  .footer-top .foot-col .Social-Icons li { padding: 0px 15px 0px 0px;}
  .footer-top .foot-col .Social-Icons li:last-child{ padding-right:0px;}
  .footer-top .foot-col .Social-Icons li a:before, .footer-top .foot-col .Social-Icons li a:hover:before{ display:none;}
  .footer-top .foot-col .select-lang{ padding-top:30px;}
  .footer-top .foot-col .select-lang select{ color: #5d5d5d; width: 90%; background-color: #fafafa; padding: 5px; border: none; font-family: "Poppins-SemiBold"; border-radius: 3px;}
  .footer-top .foot-col p, .footer-top .foot-col .telno, .footer-top .foot-col .telno a { color: #acacac; text-decoration:none;}
  .copy-right { background-color: #0b9444; text-align: center; color: #fff; padding: 6px 15px;line-height: 18px;}

  .right_sidebar_form { width: 320px; background: #ededed; padding: 35px; position: fixed; right: -320px; top: 300px; z-index: 5; padding-top: 50px; transition: ease all .5s;}
  .right_sidebar_form.active{right: 0;}
  .getintouch_siadebar { position: absolute; top: 0; left: -50px; background: #ffcb08; width: 50px; height: 50px; line-height: 50px; text-align: center; border-radius: 50%; }
  .right_sidebar_form.active .getintouch_siadebar {left: -25px;}
  .getintouch_siadebar svg {font-size: 22px; position: absolute; left: 56px; top: 15px;}
  .getintouch_siadebar p { margin: 0; font-size: 12px; line-height: 13px; color: #333; padding-top: 12px; }
  .right_sidebar_form .btn-primary { background: #0f9243; border-color: #0f9243; }
  .right_sidebar_form .btn-primary:hover { background: transparent; border-color: #0f9243; color: #0f9243;}
  .getintouch_siadebar:hover { cursor: pointer; }
  .mobile_view_footericon svg{ font-size: 25px; display: block; }
  .mobile_view_footericon svg path{ color: #0f9243; }
  .mobile_view_footericon { position: fixed; bottom: 50px; right: 0; background: #ededed; padding: 12px; display: none; z-index: 5;}
  .mobile_view_footericon a svg {font-size: 25px;display: block;}
  .mobile_view_footericon a:nth-child(1) svg {font-size: 20px; margin-bottom: 20px;}


  .footer-badge-row{ padding-top: 15px;}
.footer-badge-row ul { display: flex !important; justify-content: space-between;}
.footer-badge-row ul li { width: 23%; background-color: #f1f1f1; padding: 10px !important; display: flex; justify-content: space-around; align-items: center;}

@media screen and (max-width: 1366px)
{
    .footer-badge-row ul li{ padding: 5px 10px !important;}
}


@media screen and (max-width: 980px)
{
	.footer-badge-row ul{ max-width: 400px;}
    .footer-badge-row ul li{ padding: 10px !important;}
	.footer-badge-row ul li img { height: 70px;}
}

@media screen and (max-width: 767px)
{
	.footer-badge-row ul { max-width: 295px;}
	.footer-badge-row ul li img { height: 45px;}
}
    /********************************************************** Checkout Cart CSS ****************************************************/

   .checkout_cart_page {background-color: #f1f1f1; padding: 60px 0;}
   .checkout_cart_page h2 span { font-size: 25px; }
   .checkout_cart_page h2 { margin-bottom: 20px; }
   .shopping_cart { display: flex; justify-content: space-between; flex-wrap: wrap;margin-bottom: 30px;flex-direction: row-reverse;}
   .shopping_cart_data_left { width: calc(100% - 430px); background: #fff; padding: 15px; }
   .shopping_cart_data_right { width: 400px; background: #fff; padding: 15px; }
   .item_data_cart { display: flex; align-items: center; padding-top: 10px;}
   .item_data_cart img { height: 80px; margin-right: 15px; width: 80px; object-fit: cover;}
   .item_data_cart a { color: #333; }
   .item_data_cart a:hover { color: #007f1d; }
   .shopping_cart_data_left td { vertical-align: middle; }
   .checkout_cart_page .btn-outline-primary { padding: 10px 20px; border-radius: 30px; margin: 10px 0 15px; border-color: #0b9444; color: #0b9444; background: transparent;}
   .checkout_cart_page .btn-outline-primary:hover {  border-color: #0b9444; background-color: #0b9444; color: #fff;}
   .checkout_cart_page tr, .checkout_cart_page td{border: none;}
   .checkout_cart_page tbody, .checkout_cart_page th { border-bottom: 1px solid #ddd; font-size: 16px; color: #333 !important;}
   .cont_shopping_btn { display: flex; justify-content: space-between; align-items: center; }
   .shopping_cart_data_right h4 span { font-size: 16px; font-weight: bold; }
   .shopping_cart_data_right p{font-size: 14px;position: relative; padding-right: 60px;}
   .shopping_cart_data_right p { font-size: 14px; position: relative; padding-right: 90px; margin: 0;}
   .shopping_cart_data_right p span { position: absolute; right: 0; top: 0;}
   .protoolscard { margin-top: 5px; }
   .shopping_cart_data_right h4 { border-bottom: 1px solid #d1d1d1; padding-bottom: 10px;}
   .shopping_cart_data_right h2{margin: 0;}
   .shopping_cart_data_right .accordion-button { padding: 0; color: #333 !important; font-size: 16px;}
   .shopping_cart_data_right .accordion-item{border: none;}
   .shopping_cart_data_right .accordion-button:focus, .shopping_cart_data_right .accordion-button:not(.collapsed){outline: none !important; box-shadow: none !important;background-color: transparent !important; border-color: transparent !important; color: #333 !important;} 
   .summary_data_cart { border-bottom: 1px solid #d1d1d1; padding: 12px 0; }
   .shopping_cart_data_right .accordion { margin: 15px 0; }
   .shopping_cart_data_right .accordion-body{padding-left: 0;padding-right: 0;}
   .shopping_cart_data_right .form-label { margin-bottom: 0.5rem; font-weight: 100; color: #747474; }
   .shopping_cart_data_right .accordion .btn-outline-primary {margin: 0;}
   .checkout_cart_page .btn.btn-primary, .shipping_modal_box .btn.btn-primary{padding: 10px 20px; border-radius: 30px; border-color: #0b9444; background: #0b9444;}
   .checkout_cart_page .btn.btn-primary:hover, .shipping_modal_box .btn.btn-primary:hover{padding: 10px 20px; border-radius: 30px; border-color: #0b9444; color: #0b9444; background-color: transparent;}
   .shopping_cart_data_right .btn.btn-primary{width: 100%; margin: 10px 0 30px;}
   .shopping_cart_data_right h3 { margin-top: 20px; font-weight: bold; }
   .shopping_cart_data_right h3 span { font-size: 16px; color: #333; }
   .shopping_cart_data_right ul li {color: #747474;line-height: 26px;}
   .shopping_cart_noitem p { margin: 0 0 5px; }
   .shopping_cart_noitem p:nth-child(2) { margin-bottom: 20px; }
   .shopping_cart_noitem a { color: #333; font-size: 18px; }
   .shopping_cart_noitem a:hover { color: #088df4;}
   .shopping_cart_data_left .table > thead{border-bottom: 1px solid #ddd;}
   .deleteICon path{color: #F34541;}
   .deleteICon svg{margin: 0 auto;}
   .deleteICon:hover path{color: #333 !important; cursor: pointer;}


   /********************************************************** Shipping CSS ****************************************************/
   
   .shipping_summary_data { position: relative; margin-bottom: 10px; margin-top: 15px; }
   .shipping_page .summary_data_cart img { height: 50px; margin-right: 10px; width: 50px; object-fit: cover; }
   .shipping_page_con { position: absolute; left: 60px; top: -3px; right: 0; }
   .shipping_page .shopping_cart_data_right p { color: #333; line-height: 20px; }
   .shipping_page .shopping_cart_data_right p span{color: #747474;}    
   .shipping_page .shopping_cart_data_right h4 small { display: block; color: #333; padding-top: 4px; }
   .shipping_page .shopping_cart_data_left h4{border-bottom: 1px solid #d1d1d1; padding-bottom: 10px; }
   .shipping_page .shopping_cart_data_left h4 span{font-size: 16px; font-weight: bold;}
   .shipping_page .shopping_cart_data_left h4 { margin-bottom: 30px; }
   .shipping_page .shopping_cart_data_left h4:nth-child(3) { margin-bottom: 30px; margin-top: 40px;}
   .shipping_page .shopping_cart_data_left td { vertical-align: baseline; }
   .shipping_bottom_btn { display: flex; justify-content: space-between; margin-top: 30px; }
   .shipping_page .nav-tabs .nav-link.active, .shipping_page .nav-tabs .nav-item.show .nav-link { color: var(--bs-nav-tabs-link-active-color); background-color: transparent; border-color: transparent; }
   .shipping_page .nav-tabs{border: none;margin-bottom: 90px !important;}
   .shipping_page .nav-tabs .nav-item .nav-link.active:before{ content: ''; height: 7px; position: absolute; background: #1c9444; left: 0; right: 0;top: 0;}
   .shipping_page .nav-tabs .nav-item .nav-link:before{ content: ''; height: 7px;  position: absolute; background: #747474; left: 0; right: 0; top: 0;}
   .shipping_page .nav-tabs .nav-item .nav-link:after { content: ''; width: 30px; height: 30px; position: absolute; background: white; border-radius: 50%; left: 50%;  top: -12px; border: 5px solid #747474; transform: translateX(-50%); -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%);}
   .shipping_page .nav-tabs .nav-item .nav-link.active:after { border: 5px solid #1c9444; }
   .shipping_page .nav-tabs .nav-item .nav-link{ position: relative; padding: 0;border: none;}
   .shipping_page .nav-tabs .nav-link:hover, .shipping_page .nav-tabs .nav-link:focus{border: none;padding: 0;}
   .shipping_page .shopping_cart_data_left .form-label{font-weight: 100;}
   .shipping_page .shopping_cart_data_left .form-select{color: #747474;}
   .shipping_page.checkout_cart_page tbody, .shipping_page.checkout_cart_page th{color: #333 !important;font-weight: 100;}
   .shipping_page.checkout_cart_page td {color: #747474 !important;}
   .shipping_page .nav-tabs .nav-item .nav-link{font-size: 20px; color: #747474;padding-top: 25px;}
   .shipping_page .nav-tabs .nav-item .nav-link.active{font-size: 20px; color: #333;}
   .shipping_page .fade.tab-pane.avtive.show{position: relative;}
   .shipping_page .fade.tab-pane {position: relative;}
   .shipping_discount { margin-top: 30px; }
   .shipping_discount h2{ margin: 0; }
   .shipping_discount .accordion-button.collapsed, .shipping_discount .accordion-body{padding-left: 0; padding-right: 0;}
   .shipping_discount .accordion-item { border: none; border-top: 1px solid #dde2e6; border-radius: 0 !important; }
   .shipping_discount .accordion-button:not(.collapsed) { color: #333; background-color: transparent; box-shadow: none !important; outline: none !important; border: none !important; padding-left: 0; padding-right: 0; }
   .shipping_discount .accordion-button:focus{box-shadow: none; border: none;}
   .shipping_btn_checkbox .btn.btn-primary, .shipping_btn_checkbox .btn.btn-primary:hover {padding: 5px 20px; margin-bottom: 20px;}
   .shipping_btn_checkbox p{font-size: 14px; margin-bottom: 5px;}
   .shipping_page .summary_data_cart p { margin-bottom: 10px; }
   .shipping_page .shipping_page_con p { margin: 0; }
   .shipping_meth { margin-top: 30px; }
   .shipping_modal { text-align: right; margin-bottom: 30px; }
   .shipping_page.checkout_cart_page{padding: 30px 0;}
   .shipping_modal_box a { float: right; color: #747474; }  
   .shipping_modal_box a:hover {color: #1c9444; }  
   .shipping_modal_box .form-label{font-weight: 100;}       
   .shipping_modal_box .modal-dialog .modal-content .modal-header { padding: 5px 15px; }
   .react_formicon { margin-top: 33px; position: relative; }
   .react_formicon svg{ font-size: 22px; margin-top: 35px;}
   .react_formicon svg path{ color: #747474; }
   .input_icon { position: relative; }
   .input_icon .form-control{ width: 85%; }
   .react_formicon.col { position: relative; }
   .react_formicon.col p { position: absolute; left: 50px; border: 1px solid #c1c1c1; padding: 5px; top: 50%;  font-size: 14px; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}
   .react_formicon.col p:before { content: ""; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-right: 10px solid #c1c1c1; position: absolute; left: -10px; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}
   .react_formicon.phone_tooltip.col p{top: 70%;}
   .shipping_page .cart_images { margin-top: 30px; }
   .canvas-cart-bx.cart-bx img { width: 22px; } 
   .canvas-cart-bx.cart-bx { position: relative; }
   .canvas-cart-bx.cart-bx span { position: absolute; top: -5px; right: -9px; color: #00934b; }
   .canvas-cart-bx.cart-bx:hover { cursor: pointer; }
   .offcanvas-body .shopping_cart_data_right{padding: 0;}
   .estimate_mobile_view .navbar-brand{font-size: 16px; color: #333;}
   .estimate_mobile_view{display: none;}
   .site_map_page.shipping_policy_page, .site_map_page.return_refund_policy_page{padding: 0;}
   .site_map_page.shipping_policy_page .obl-policy-section .abt-content .corAdd h1, .site_map_page.return_refund_policy_page  .obl-policy-section .abt-content .corAdd h1{font-size: 28px;font-family: 'Poppins-SemiBold';}
   .site_map_page.shipping_policy_page .obl-policy-section .abt-content .corAdd h3, .site_map_page.return_refund_policy_page .obl-policy-section .abt-content .corAdd h3{font-size: 18px;}
   .site_map_page.return_refund_policy_page .obl-policy-section .abt-content .corAdd ul{margin: 10px 0px 30px !important;}
   
   

   /***************************************** store locator ***************************************/

   .store_locator_cont p { color: #747474; font-size: 16px;}
   .store_locator_cont h2 strong{ color: #747474; font-size: 16px;}
   .store_locator_top_cont { font-size: 12px; line-height: 18px; border-bottom: 1px solid #dddddd; padding-bottom: 5px;}
   .store_locator_flex { display: flex; align-items: center; justify-content: space-between;flex-wrap: wrap; margin: 50px 0;flex-direction: row-reverse;}
   .store_locator_flex .store_locator_data{width: 48%;}
   .store_locator_data img { width: 25px; margin-right: 10px; }
   .store_locator_data .btn-outline-secondary{padding: 8px 15px; border-radius: 30px;}
   .store_locator_data form { margin-top: 30px; }
   .store_locator_data form .btn.btn-primary{ padding: 8px 15px; width: 100%; background: #0B9444; border-color: #0B9444; border-radius: 30px; margin-top: 20px; font-size: 16px;}
   .store_locator_data form .btn.btn-primary:hover{background-color: transparent;color: #0B9444;}
   .store_locator_flex_distributor { display: flex; justify-content: space-between; flex-wrap: wrap; }
   .store_locator_flex_distributor .distributor_card{width: 32%; border: 1px solid #cdcdcd; padding: 15px;margin-bottom: 20px;}
   .distributor_card p strong { color: #262626; font-size: 20px; font-weight: 100; }
   .distributor_card p { color: #333; font-size: 14px; margin-bottom: 5px; line-height: 20px;}
   .distributor_card_flex { margin-top: 20px; display: flex; justify-content: space-between; align-items: center; }
   .distributor_card_flex a{ color: #686868; text-transform: uppercase;}
   .distributor_card_flex a img{ width: 20px; }
   .distributor_card_flex div{ color: #686868; text-transform: uppercase; cursor: pointer;}
   .distributor_card_flex div img{ width: 20px; }
   .store_locator_cont { margin: 50px 0 0; }
   .store_locator_page { padding: 50px 0; }
   .store_locator_cont p a { font-size: 16px; }
   .store_locator_page .productpage_title{margin: 20px 0;}



   /********************************** faq_page *************************************/

   .faq_page .faqWrapper {padding: 60px 0;}
   .faq_page .faqWrapper h1 {text-align: center; text-transform: uppercase}
   .faq_page .faqWrapper .faqPara { text-align: center; font-size: 23px; padding: 30px 0 40px; color: #898989; line-height: 34px; }
   .faq_page .bread-crumb { margin-top: 10px; }
   .faq_page .faqHead { font-size: 16px; color: #333; margin: 25px 0; padding-right: 35px;}
   .faq_page .faqHead:hover{cursor: pointer;}
   .faq_page .faqHead:before { content: '+'; background: #747474; height: 23px; width: 23px; position: absolute; border-radius: 50%; color: #fff; line-height: 25px; text-align: center; right: 0; }
   .faq_page .faqContant { font-size: 16px; color: #747474;}
   .faq_page .faqContant:after { content: '-'; background: green; height: 23px; width: 23px; position: absolute; border-radius: 50%; color: #fff; line-height: 25px; text-align: center; right: 0; top: 0; }
   .faq_page .faqContant a{ font-size: 16px; }
   .faq_page .faqWrap .faqContant { display: none !important; }
   .faq_page .faqWrap .faqContant.show { display: block !important; }
   .faq_page .faqWrap{margin: 0 100px; position: relative;}
   .faq_page audio{margin-left: 100px;}


  /******************* tile-buying css *********************/
   
  .tile_buying_page .spotlight-image img { width: 100%; height: 340px; object-fit: cover; }
  .tile_buying_page .sp-head { font-size: 32px; color: #ffcb08; font-weight: bold; margin: 30px 0; }
  .tile_buying_page .main-head { display: inline-block; font-size: 32px; color: #ffcb08; }
  .tile_buying_page .bread-crumb {display: none;}
  .tile_buying_page h1 { font-size: 28px; border-left: 5px solid #ffcb08; padding-left: 15px; margin: 50px 0; line-height: 25px;}
  .tile_buying_page .resp-tabs-list { padding: 0; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 80px 0 50px; }
  .tile_buying_page .resp-tabs-list li { list-style: none; border: #ccc solid 1px; text-align: center; width: 16%; height: 80px; position: relative; display: flex; align-items: end; justify-content: center; padding: 5px 0; }
  .tile_buying_page .resp-tabs-list li span { display: block; border: #cccc solid 1px; width: 70px; height: 70px; line-height: 70px; border-radius: 50%; background: #fff; position: absolute; top: -40px; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); -moz-transform: translateX(-50%); -ms-transform: translateX(-50%); -o-transform: translateX(-50%); }
  .tile_buying_page .resp-tabs-list li span img { width: 35px; }
  .tile_buying_page .resp-tabs-list li label { display: inline-block; font-weight: 100; font-size: 16px; }
  .tile_buying_page .resp-tabs-list li:hover{cursor: pointer;}
  .tile_buying_page .tile-head, .tile_buying_page .wall-tile-heading { font-size: 22px; margin-bottom: 20px; font-weight: bold;}
  .tile_buying_page .tile-buying-highlight { background-color: #f1f1f1; padding: 25px; }
  .tile_buying_page .tile-buying-highlight ul { list-style-type: decimal; }
  .tile_buying_page .tile-buying-highlight ul li{ font-size: 16px; color: #747474; margin-bottom: 7px;}
  .tile_buying_page .tile-buying-sub { display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; }
  .tile_buying_page .tile-buying-left { width: 48%; }
  .tile_buying_page .tile-buying-right { width: 48%; padding-right: 40px;}
  .tile_buying_page .tile-buying-guide-content { margin: 180px 0; }
  .tile_buying_page .tile-buying-sub { display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; position: relative; }
  .tile_buying_page .tile-buying-sub:before { content: ''; border: 20px solid #e5e5e5; left: 150px; top: -80px; bottom: -80px; right: 0; position: absolute; z-index: -1; }
  .tile_buying_page .resp-tabs-list li.active, .tile_buying_page .resp-tabs-list li.active span { border-color: #008000; }
  .tile_buying_page .resp-tabs-list li.active label { color: #008000; }
  .tile_buying_page .productpage_title{margin: 20px 0; color: #ffcb08;}
  .tile_buying_page .site_map_page h1{margin-top: 30px;}
  .tile-buying-sub .tile-buying-img img{width: 100%;}


  /******************* trulook_page *********************/

  .trulook_page{padding: 50px 0;}
  .trulook_page .productpage_title span, .trulook_page p{color: #333;}
  .trulook_logo { margin: 30px 0; }
  .trulook_tab .nav-tabs{justify-content: space-between; border: none; margin-bottom: 50px;}
  .trulook_tab .nav-tabs .nav-link.active, .trulook_tab .nav-tabs .nav-item.show .nav-link{border: none; background: transparent;}
  .trulook_tab .nav-tabs .nav-link:hover, .trulook_tab .nav-tabs .nav-link:focus, .trulook_tab .nav-link:focus-visible{border: none; box-shadow: none;}
  .trulook_tab .nav-item{width: 16%;}
  .trulook_tab .nav-tabs .nav-link { border: none; font-size: 15px; color: #747474; width: 100%; text-align: left; position: relative; background: url(https://server.orientbell.com/static/version1687428043/frontend/colora/colora_home2/en_US/images/step-bg.png) no-repeat; background-size: 100% 100%; height: 100%; padding: 10px 10px 10px 25px; line-height: 20px; }
  .trulook_tab .nav-tabs .nav-link.active{border: none; font-size: 15px; color: #747474; width: 100%; text-align: left; position: relative; background: url(https://server.orientbell.com/static/version1687428043/frontend/colora/colora_home2/en_US/images/active-step-bg.png) no-repeat; background-size: 100% 100%; height: 100%; padding: 10px 10px 10px 25px; line-height: 20px; color: #fff;} 
  .get_started_tab h4 span { font-size: 22px; }
  .get_started_tab h4 { margin-bottom: 20px; }
  .get_started_tab small { display: block; }
  .multiple_input .form-control { width: 60px; margin-right: 5px; text-align: center; }
  .multiple_input div {display: flex;}
  .multiple_input .btn.btn-secondary { border-radius: 30px; padding: 8px 30px; margin: 20px 0 15px; font-size: 16px; }
  .get_started_data_img { display: flex; border: 1px dotted; margin-top: 30px; justify-content: space-between; flex-wrap: wrap; padding: 30px; }
  .get_started_data_img_left, .get_started_data_img_right{width: 48%;}
  .get_started_data_img p{color: #747474;}
  .get_started_data_img_right p{margin-bottom: 5px;}
  .get_started_tab .form-label{margin-bottom: 0;}
  .get_started_tab small{margin: 0; margin-bottom: 10px;}
  .get_started_tab .form-control, .get_started_tab .form-select{height: 60px; border-radius: 0;}
  .get_started_tab a {color: #40c477}


  /************************ About us ********************/
  
  .aboutus_page{padding: 50px 0;}
  .aboutus_page p, .aboutus_page a{font-size: 16px;}
  .aboutus_page_data { display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; background: #f1f1f1;}
  .aboutus_page_data h4{padding-right: 30px;}
  .aboutus_page_data h4 span{color: #ffcb08 !important; font-size: 45px !important; line-height: 55px;}
  .aboutus_page_data div{ width: 48%; }
  .aboutus_page .productpage_title{margin: 50px 0 15px;}
  .aboutus_page .bread-crumb { margin-top: 15px; font-size: 13px; }
  .aboutus_page .bread-crumb img { display: none; }
  .aboutus_page .bread-crumb span:after{display: none;}


   /************************ Press Release ********************/

   .Pressrelease_page{padding: 50px 0;}
   .Pressrelease_data { display: flex; justify-content: space-between; flex-wrap: wrap; }
   .Pressrelease_card { width: 32%; border: 1px solid #e5e5e5; margin-top: 20px; padding: 15px; }
   .Pressrelease_image { text-align: center; margin-bottom: 15px;}
   .pressrelease_announcement .Pressrelease_card{width: 100%;}
   .Pressrelease_image img { width: 190px; }
   .Pressrelease_page h4{font-size: 32px; color: #333; margin-bottom: 20px;}
   .Pressrelease_page h5{font-size: 20px; font-weight: bold; color: #333;}
   .Pressrelease_card small { color: #747474; }
   .Pressrelease_card p { color: #333; margin-top: 10px; }

   .press_release_detail_page{padding: 60px 0;}
   .Pressrelease_page .productpage_title, .press_release_detail_page .productpage_title{margin: 20px 0;}
   .press_release_detail_page img {padding: 15px 0;}




   /************************ Investor ********************/

   .investor_page {padding: 50px 0;}
   .investor_important_area { text-align: center; background: #ffcb08; padding: 20px; }
   .investor_important_area h4{ margin-bottom: 10px; color: #000;}
   .investor_important_area a { color: #434343; font-weight: bold; font-size: 16px; }
   .investor_update_area.investor_important_area { background: transparent; border: 1px solid #0b9444; margin: 15px 0; }
   .investor_accordion .accordion-item{margin-bottom: 10px}
   .investor_accordion .accordion-item:first-of-type .accordion-button, .investor_accordion .accordion-item:last-of-type .accordion-button.collapsed, .investor_accordion .accordion-item:first-of-type, .investor_accordion .accordion-item:last-of-type{border-radius: 0;}
   .investor_accordion .accordion-button{background-color: #e6e6e6;}
   .investor_accordion .accordion-button:not(.collapsed) { background-color: #e6e6e6; box-shadow: none !important; }
   .investor_accordion .accordion-button:focus{border-color: transparent; box-shadow: none}
   .investor_accordion .accordion-body { padding: 25px 0; }
   .sub_accordion_investor .accordion-item{border: none; border-bottom: 1px solid #dde2e6;}
   .sub_accordion_investor .accordion-item:nth-last-child(1){border: none;}
   .sub_accordion_investor table tr th { background: #e6e6e6 !important; text-align: center;vertical-align: middle;}
   .sub_accordion_investor table tr td { text-align: center;vertical-align: middle;}
   .sub_accordion_investor table tr td img{ width: 25px;}
   /* .productpage_title{margin: 20px 0 60px;} */
   .productpage_title{font-size: 28px;}


   /*********************** Career Page *************************/

   .career_page { padding: 60px 0; }
   .career_page .masonry { display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px; margin-top: 30px; }
   .career_page .brick img { width: 100%; height: 100%;}
   .career_page .brick img:hover { opacity: .8; }
   .career_page p{font-size: 16px;}
   .career_page h4{margin-top: 15px;}
   .career_page iframe{width: 100%;}



   /*********************** Contact Page *************************/
    
   .contactus_page{padding: 50px 0;}
   .contactus_page .productpage_title { margin: 0 0 20px;}
   .contactus_page p { font-size: 16px; }
   .contactus_page .contactus_data_con h3{margin-bottom: 20px;}
   .contactus_page .contactus_data { display: flex; justify-content: space-between; flex-wrap: wrap; margin-top: 50px; flex-direction: row-reverse;}
   .contactus_page .contactus_data_con { width: 48%; }
   .contactus_page .contactus_data_con h3 span { font-size: 22px; }
   .contactus_page p strong{font-weight: 100; font-size: 16px;}
   .contactus_bottom_form { display: flex; align-items: center; justify-content: space-between;}
   .contactus_bottom_form label { font-weight: 100; margin: 0; }
   .contactus_bottom_form input { margin-top: 5px; }
   .contactus_page .contactus_data_con .form-control, .contactus_page .contactus_data_con .form-select{height: 40px; border-radius: 0;}
   .contactus_page .btn.btn-primary { padding: 10px 20px; border-radius: 5px; border-color: #0b9444; background: #0b9444; }
   .contactus_page .btn.btn-primary:hover{padding: 10px 20px; border-color: #0b9444; color: #0b9444; background-color: transparent;}



   /******************************** Site map **************************/

   .site_map_page{padding: 60px 0;}
   .site_map_page h1{font-size: 28px;}
   .sitemap-wrapper { display: grid; grid-template-columns: repeat(7, 1fr); gap: 20px; }
   .sitemap-wrapper .sitemap-box ul { padding: 0; margin: 0; list-style-type: none; }
   .site_map_page_new .abt-content{display: none;}
   .site_map_page h1{font-size: 28px; margin-bottom: 30px;}
   .sitemap-wrapper .f-heading{font-size: 16px; color: #333; margin-bottom: 15px; font-family: "Poppins-SemiBold";}
   .sitemap-main .sitemap-box ul li a { color: #686868; font-size: 14px; position: relative; transition: all .5s; overflow: hidden; display: inline-block; line-height: normal;}
   .sitemap-main .sitemap-box ul li a:after { content: ''; position: absolute; bottom: 0; width: 100%; height: 2px; left: -100%; background-color: #fff;}
   .sitemap-main .sitemap-box ul li a:after, .sitemap-main .sitemap-box ul li a:hover:after { transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
   .sitemap-main .sitemap-box ul li a:hover:after { display: inline-block; left: 0; background-color: #0f9243;}
   .sitemap-main .sitemap-box ul li a:hover{ color: #0f9243;}
   .sitemap-main .sitemap-box p { margin: 0px;}
   
   /********************************* Signature Company Showrooms ***************************/   

    .signature-company-showrooms {padding: 50px 0;}
    .signature-company-showrooms p, .signature-company-showrooms a{font-size: 16px;}
    .signature-company-showrooms .productpage_title{margin: 20px 0;}
    .flex-css{display: flex;justify-content: space-between;align-items: center;}
    .Our_stores { flex-wrap: wrap; margin-bottom: 70px}
    .our_stores_data, .our_stores_img { width: 49%; text-align: center; }
    .our_stores_data h3 { margin-top: 0px !important;}
    .why_signature_data p:last-child{ margin-bottom:0px;}
    .our_stores_data h3 span { font-size: 24px; color: #747474; }
    .signature-company-showrooms .btn.btn-primary { background: #0f9243; border-color: #0f9243; border-radius: 30px; padding: 10px 25px;  margin: 10px 0;}
    .signature-company-showrooms .btn.btn-primary:hover{background-color: #ffcb08; border-color: #ffcb08; color: #333;}
    .signature-company-showrooms h3 { margin: 30px 0;  } 
    .why_signature { border: 5px solid #f1f1f1; flex-wrap: wrap; margin-top: 20px;}
    .why_signature_img, .why_signature_data{width: 50%;}
    .why_signature_img img{width: 100%;}
    .why_signature_data{padding: 15px;}
    .why_signature_trulook { flex-direction: row-reverse; }
    .appointment_book { padding: 30px; border: 1px solid #c1c1c1; margin: 50px 0; }
    .appointment_book h5 { text-align: center; font-weight: bold; color: #747474; }
    .appointment_book .form-control, .appointment_book .form-select{height: 45px; border-radius: 0;}
    .appointment_book p, .appointment_book a{font-size: 14px; color: #747474;margin: 0;}
    .appointment_book .form-select, .appointment_book .form-control {color: #666666;}
    .appointment_book label{font-weight: 100; color: #747474;}
    .signature_company_tabs .nav-tabs{display: initial; border: none;width: 300px;margin-right: 50px;}
    .signature_company_tabs .nav-item { margin: 0; margin-bottom: 10px; }
    .signature_company_tabs .nav-link {color: #1979c3; font-size: 18px;}
    .signature_company_tabs .nav-tabs .nav-link.active {color: #23527c; border: none;}
    .signature_company_tabs .nav-tabs .nav-link:hover, .signature_company_tabs .nav-tabs .nav-link:focus{border-color: transparent; color: #23527c}
    .signature_company_tabs { justify-content: flex-start; flex-wrap: wrap; align-items: baseline; }
    .signature_company_tabs .tab-content{width: calc(100% - 350px);}
    .signature_company_tabs .nav-tabs .nav-link, .signature_company_tabs .nav-tabs .nav-link.active{padding-left: 0;}
    .signature-company-showrooms .signature_gallery{line-height:0; -webkit-column-count:6;  -webkit-column-gap:6px; -moz-column-count:6; -moz-column-gap:6px; column-count:6; column-gap:5px;}
    .signature-company-showrooms .signature_gallery img{width: 100% !important; height: auto !important; margin-bottom:5px;transition: ease all 1s;}
    .signature-company-showrooms .signature_gallery img:hover{opacity: .5;}
    .signature-company-showrooms .tab_data_address { display: flex; justify-content: space-between; flex-wrap: wrap; margin-bottom: 20px;}
    .signature-company-showrooms .tab_data_address .address_data, .signature-company-showrooms .tab_data_address .address_data_map{width: 49%;}
    .address_data p { font-size: 14px; margin-bottom: 5px; color: #747474;}
    .address_data a {color: #333;}

    
/************************************** store-locator-page CSS ****************************************/
.store_locator_tab_section { background-color: #F6F6F6;}
.store_locator_tab_section .tab_head{ margin: 0px; padding: 0px; list-style: none; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center;}
.store_locator_tab_section .tab_head li{ width:33.333%; padding: 15px; border: solid 1px #ECECEC; color: #555555; font-size: 20px; cursor: pointer; text-align: center; background-color: #FFFFFF;}
.store_locator_tab_section .tab_head.tab-four-columns li { width: 25%;}
.store_locator_tab_section .tab_head li.active{ background-color: #F6F6F6; box-shadow: 0px 4px 5px #e5e5e5 inset; border-bottom-color: transparent;}
.store_locator_tab_data { padding: 30px;}
.store_locator_row{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap;}
.store_locator_row .obl_store_locator_col { background-color: #fff; border: solid 1px #CECECE; border-radius: 5px; position: relative; padding: 20px 20px 20px 20px; width: 32%; margin-bottom: 25px; box-shadow: 0px 2px 6px #c5c5c5;}
.store_locator_row .obl_store_locator_col:nth-child(3n+2) { margin-left: 2%; margin-right: 2%;}
.store_locator_row .obl_store_locator_col h3 { font-size: 20px !important; margin-bottom: 10px; padding-right: 100px;}
.store_locator_row .obl_store_locator_col .store_obl_logo { position: absolute; top: -1px; right: -1px;}
.store_locator_row .obl_store_locator_col .store_obl_logo img{ height:35px;}
.store_locator_row .obl_store_locator_col .store_add_row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; min-height: 70px;}
.store_locator_row .obl_store_locator_col .store_add_row .store_add_text { width: calc(100% - 35px); padding-right: 10px;}
.store_locator_row .obl_store_locator_col .store_add_row .store_add_text h6 { font-size: 16px; line-height: normal; color: #202020; margin-bottom: 3px;}
.store_locator_row .obl_store_locator_col .store_add_row .store_add_text p { font-size: 14px; line-height: normal; color: #202020; margin-bottom: 0px;}
.store_locator_row .obl_store_locator_col .store_add_row .store_add_icon { width: 35px; text-align: right;}
.store_locator_row .obl_store_locator_col .store_timing_row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center; padding: 0px 20px; position: absolute; left: 0px; bottom: 20px; width: 100%;}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon { width: 130px; text-align: right;}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a { font-size: 16px; color: #4B4B4B;}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a img { height: 18px; padding-right: 5px;}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_text { width: calc(100% - 130px);}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p { font-size: 14px; margin: 0px; color: #8B8B8B;}
.store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p span { font-size: 14px; color: #14AE5C; padding-right: 10px;}

.obl_store_locator_detail_section{ padding: 50px 0px;}
.obl_store_locator_detail_section h1 { font-size: 40px !important; margin-bottom: 50px; color: #2E363F !important;}
.obl_store_locator_detail{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; border: solid 1px #BEBEBE;  border-radius: 4px; padding: 5px;}
.obl_store_locator_detail .obl_store_detail_left { width:350px;}
.obl_store_locator_detail .obl_store_detail_left>div ~ div { height: 100% !important;}
.obl_store_locator_detail .obl_store_detail_right { width:calc(100% - 350px); padding: 15px 30px; position: relative;}
.obl_store_detail_right .store_obl_logo { position: absolute; top: -6px; right: -6px;}
.obl_store_detail_right .store_obl_logo img{ height:59px;}
.obl_store_locator_detail .obl_store_detail_right h3 { font-size: 34px !important; margin-bottom: 15px; padding-right: 170px;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text{ width: calc(100% - 60px); padding-right: 15px;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text h6 { font-size: 28px; line-height: normal; color: #202020; margin-bottom: 3px;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text p { font-size: 24px; line-height: normal; color: #202020; margin-bottom: 0px;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_icon { width: 60px; text-align: right;}
.obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_icon img{ height:60px;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center; padding-top: 30px;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon { width: 200px; text-align: right;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon a { font-size: 24px; color: #4B4B4B;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon a img { height: 26px; padding-right: 5px;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text { width: calc(100% - 200px);}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p { font-size: 24px; margin: 0px; color: #8B8B8B;}
.obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p span { font-size: 24px; color: #14AE5C; padding-right: 10px;}
.store_locator_about{ padding-top: 50px;}
.store_locator_heading { font-size: 24px !important; color: #828282 !important; margin-bottom: 15px;}
.store_locator_products_section { padding: 50px 0px; position: relative;}
.store_locator_products_section .products_all-bx { position: absolute; top: 50px; right: 0px;}
.store_locator_products_section .products_all-bx a{ font-size: 16px; color: #1E1E1E;}
.store_locator_products_section .products_all-bx a:hover{ color: #0f9243;}
.store_locator_products_section .pdt-slider .slick-list{ margin: 0px -10px;}
.store_locator_products_section .pdt-slider .slick-list .slick-slide{ padding: 0px 10px;}
.store_locator_products_section .pdt-slider .locator_products_figure img{ width:100%;}
.store_locator_products_section .pdt-slider .locator_products_text{ padding-top: 15px;}
.store_locator_products_section .pdt-slider .locator_products_text h6{ font-size: 16px; color: #9A9A9A; margin-bottom: 5px;}
.store_locator_products_section .pdt-slider .locator_products_text p{ font-size: 20px; color: #9A9A9A; margin-bottom: 0px; font-weight: 600; display: flex; flex-flow: wrap; align-items: center;}
.store_locator_products_section .pdt-slider .locator_products_text span{ width: 20px; height: 10px; display: inline-block; background-color: #9A9A9A; margin-right: 10px;}
.store_locator_products_section .pdt-slider .slick-prev{ left: -10px; width: 30px; height: 30px; z-index: 1; background-color: #717171;}
.store_locator_products_section .pdt-slider .slick-prev:before{ display: none;}
.store_locator_products_section .pdt-slider .slick-prev:after{ content: ''; display: inline-block; width: 10px; height: 10px; border-right: 3px solid #fff; border-top: 3px solid #fff;  margin-left: 3px; transform: rotate(-135deg); -webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); -ms-transform: rotate(-135deg); -o-transform: rotate(-135deg);}
.store_locator_products_section .pdt-slider .slick-next{ right: -10px; width: 30px; height: 30px; z-index: 1; background-color: #717171;}
.store_locator_products_section .pdt-slider .slick-next:before{ display: none;}
.store_locator_products_section .pdt-slider .slick-next:after{ content: ''; display: inline-block; width: 10px; height: 10px; border-right: 3px solid #fff; border-top: 3px solid #fff;  margin-right: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg);}
.store_locator_tag_section ul{ list-style: none; padding: 0px; margin: 0px; display: flex; flex-flow: wrap;}
.store_locator_tag_section ul li { margin: 0px 15px 10px 0px;}
.store_locator_tag_section ul li a{ background-color: #F2F2F2; padding: 8px 12px; font-size: 16px; color: #202020; border-radius: 5px; display: inline-block;}
.store_locator_tag_section ul li a:hover{ background-color: #0f9243;  color: #fff;}
.store_locator_reviews_section { padding-bottom: 70px;}
.store_locator_reviews_wrap { max-width: 1024px; width: 100%; border-top: solid 1px #E0E0E0; padding: 50px 0px 0px 50px; }
.store_locator_reviews_col { padding-bottom: 70px; max-width: 750px; width: 100%;}
.store_locator_reviews_col:last-child { padding-bottom: 0px;}
.store_locator_reviews_col .store_locator_reviews_head { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center; padding-bottom: 20px; }
.store_locator_reviews_head .reviews_head_left { width: calc(100% - 150px); display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center;}
.store_locator_reviews_head .reviews_head_left .reviews_head_fig { width: 70px;}
.store_locator_reviews_head .reviews_head_left .reviews_head_figcap { width: calc(100% - 70px); padding-left: 15px;}
.store_locator_reviews_head .reviews_head_left .reviews_head_figcap p{ color: #000; font-size: 20px; margin: 0px;}
.store_locator_reviews_head .reviews_head_right { width: 150px; text-align: right;}
.store_locator_reviews_text p { font-size: 20px; color: #000; font-weight: bold;}
.store_locator_reviews_text span{ color:#bdbdbd;}
.store_locator_blog_section{ padding-bottom: 50px;}
.store_locator_blog_row{ display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; }
.store_locator_blog_row .store_locator_blog_col {background-color: #EDEDED; width: 24%; margin: 0px 1% 20px 0px;}
.store_locator_blog_row .store_locator_blog_col .store_locator_blog_figure img{ width:100%}
.store_locator_blog_row .store_locator_blog_col .store_locator_blog_text { padding: 15px;}
.store_locator_blog_row .store_locator_blog_col .store_locator_blog_text h5{ margin-bottom: 10px; color: #000; font-size: 16px; line-height: normal;}
.store_locator_blog_row .store_locator_blog_col .store_locator_blog_text a{ font-size: 16px; color: #00722D;}
.tab_head_select{ display: none;}
.tab_head_select select { width: 100%; padding: 10px; border: solid 1px #ECECEC; color: #555555; font-size: 18px; cursor: pointer; text-align: center; background-color: #FFFFFF;}
.store_add_text img { height: 18px; margin-right: 5px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col{ padding: 20px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row { position: unset; padding: 15px 0px 0px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row .store_add_text{ width:100%; padding: 0px 0px 10px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row .store_add_icon{ width:100%; text-align: left; position: relative; padding-left: 25px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row .store_add_icon .store_phone_icon { position: absolute; left: 0px; width: 20px; height: 20px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row .store_add_icon .store_phone_icon img{ height: 16px;}
.store_locator_row .obl_store_locator_col.sshowrooms-col .store_timing_row .store_add_icon a{ font-size: 14px !important;}



@media screen and (max-width: 1199px)
{
    .store_locator_tab_section .tab_head li{ font-size: 18px;}
    .store_locator_row .obl_store_locator_col h3{ font-size: 18px !important;}
    .store_locator_row .obl_store_locator_col{ padding: 15px 15px 15px 15px;}
    .store_locator_row .obl_store_locator_col .store_timing_row { padding: 0px 15px; bottom: 15px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p span { padding-right: 0px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a { font-size: 14px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a img { height: 16px; padding-right: 0px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text { width: calc(100% - 110px);}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon { width: 110px;}

    .obl_store_locator_detail_section h1 { font-size: 34px !important; margin-bottom: 30px;}
    .obl_store_locator_detail .obl_store_detail_left { width: 300px;}
    .obl_store_locator_detail .obl_store_detail_right { width: calc(100% - 300px); padding: 15px;}
    .obl_store_detail_right .store_obl_logo img { height: 50px;}
    .obl_store_locator_detail .obl_store_detail_right h3 { font-size: 28px !important; padding-right: 150px;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text h6 { font-size: 20px;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text p { font-size: 18px;}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p, .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p span { font-size: 18px;}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon a { font-size: 18px;}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon a img { height: 18px; padding-right: 0px;}

}

@media screen and (max-width: 991px)
{
    .store_locator_tab_section .tab_head li{ font-size: 15px; padding: 15px 5px;}
    .store_locator_row .obl_store_locator_col{ width:49%;}
    .store_locator_row .obl_store_locator_col:nth-child(3n+2) { margin-left: 0%; margin-right: 0%;}
    .store_locator_row .obl_store_locator_col:nth-child(odd){ margin-right: 1%;}
    .store_locator_row .obl_store_locator_col:nth-child(even){ margin-left: 1%;}
    .store_locator_row .obl_store_locator_col h3{ font-size: 16px !important;}
    .store_locator_row .obl_store_locator_col .store_add_row .store_add_text h6{ font-size: 14px;}
    .store_locator_row .obl_store_locator_col .store_add_row .store_add_text p{ font-size: 12px !important;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p span { padding-right: 5px;}

    .obl_store_locator_detail .obl_store_detail_left { width: 250px;}
    .obl_store_locator_detail .obl_store_detail_right { width: calc(100% - 250px);}
    .obl_store_locator_detail .obl_store_detail_right h3 { font-size: 24px !important; padding-right: 120px;}
    .obl_store_detail_right .store_obl_logo img { height: 40px;}
    
    .store_locator_products_section .pdt-slider .locator_products_text p{ font-size: 16px;}
    .store_locator_reviews_wrap { padding: 30px 0px 0px 0px;}
    .store_locator_reviews_text p{ font-size: 16px;}
    .store_locator_blog_row .store_locator_blog_col { width: 49%; margin: 0px 0% 20px 0px;}
    .store_locator_blog_row .store_locator_blog_col:nth-child(odd){ margin-right: 1%;}
    .store_locator_blog_row .store_locator_blog_col:nth-child(even){ margin-left: 1%;}

}

@media screen and (max-width: 767px)
{
    .store_locator_tab_data { padding: 15px;}
    .store_locator_tab_section .tab_head{ display: none;}
    .tab_head_select { display: block;}
    .store_locator_tab_section .tab_head li { font-size: 14px; line-height: normal; padding: 10px 5px;}
    .store_locator_row .obl_store_locator_col{ width:100%; margin-bottom: 15px; padding: 15px;}
    .store_locator_row .obl_store_locator_col .store_timing_row { padding: 15px 0px 0px; position: unset;}
    .store_locator_row .obl_store_locator_col:nth-child(odd){ margin-right: 0%;}
    .store_locator_row .obl_store_locator_col:nth-child(even){ margin-left: 0%;}
    .store_locator_row .obl_store_locator_col:last-child{ margin-bottom: 0px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p, .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text p span { font-size: 12px !important;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a { font-size: 12px !important;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon a img { height: 14px; padding-right: 2px;}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_text { width: calc(100% - 100px);}
    .store_locator_row .obl_store_locator_col .store_timing_row .store_add_icon { width: 100px;}

    .obl_store_locator_detail_section h1 { font-size: 24px !important; margin-bottom: 20px;}
    .obl_store_locator_detail .obl_store_detail_left { width: 200px;}
    .obl_store_locator_detail .obl_store_detail_right { width: calc(100% - 200px);}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text { width: calc(100% - 120px);}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_icon { width: 120px;}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p, .obl_store_locator_detail .obl_store_detail_right .store_timing_row .store_add_text p span { font-size: 14px;}
    .obl_store_locator_detail .obl_store_detail_right h3 { font-size: 20px !important;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text h6 { font-size: 16px; }
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_icon img { height: 40px;}
    .store_locator_row .obl_store_locator_col.sshowrooms-col { padding: 15px;}
    .store_locator_row .obl_store_locator_col .store_add_row .store_add_icon { right: 15px; bottom: 15px;}

}

@media screen and (max-width: 639px)
{
    .store_locator_tab_section .tab_head li { height: 70px; display: flex; flex-flow: wrap; justify-content: center; align-items: center; overflow: hidden; padding: 5px;}
    .store_locator_row .obl_store_locator_col .store_obl_logo img { height: 26px;}
    .store_locator_row .obl_store_locator_col h3 { padding-right: 75px;}
    
    .store_locator_heading { font-size: 20px !important;}
    .obl_store_locator_detail .obl_store_detail_left { width: 100%;}
    .obl_store_locator_detail .obl_store_detail_left img{ width:100%;}
    .obl_store_locator_detail .obl_store_detail_left>div ~ div { height: 250px !important;}
    .obl_store_locator_detail .obl_store_detail_right { width: calc(100% - 0px); position: unset;}
    .obl_store_locator_detail{ position: relative;}
    .obl_store_detail_right .store_obl_logo { top: -1px; right: -1px;}
    .obl_store_locator_detail .obl_store_detail_right .store_timing_row { padding-top: 15px;}
    .obl_store_locator_detail .obl_store_detail_right h3 { padding-right: 0px;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text { width: calc(100% - 40px); padding-right: 10px;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_icon{ width:40px;}
    .obl_store_locator_detail .obl_store_detail_right .store_add_row .store_add_text h6 { font-size: 14px;}
    .store_locator_reviews_head .reviews_head_left { width: calc(100% - 0px);}
    .store_locator_reviews_head .reviews_head_right { width: 100%; text-align: left; padding-top: 10px;}
    .store_locator_reviews_col .store_locator_reviews_head{ padding-bottom: 0px;}

}

@media screen and (max-width: 479px)
{
    .store_locator_tag_section ul li a{ padding: 5px 8px;}
    .store_locator_blog_row .store_locator_blog_col { width: 100%; margin: 0px 0% 15px 0px;}
    .store_locator_blog_row .store_locator_blog_col:nth-child(odd){ margin-right: 0%;}
    .store_locator_blog_row .store_locator_blog_col:nth-child(even){ margin-left: 0%;}
    .store_locator_blog_row .store_locator_blog_col:last-child{ margin-bottom: 0px;}

}
/************************************** store-locator-page CSS ****************************************/
   /************************************* Download catalog *********************************/ 

   .download_catalog_page{padding: 60px 0; position: relative;}
   .download_content_brifdata { display: grid; grid-template-columns: repeat(4, 1fr); gap: 20px;}
   .catalog_data img {width: 100%;border: 1px solid #e3e3e3;}
   .catalog_data .flex-css img{border: none; width: 15px; margin-right: 9px;}
   .catalog_data .flex-css a{color: #333; font-weight: bold;}   
   .catalog_data .flex-css {margin-top: 5px; margin-bottom: 30px;}
   .download_content_data .btn.btn-outline-secondary{border-radius: 30px; margin: 0 3px;}
   .download_content_data .btn.btn-outline-secondary:nth-child(1){margin-left: 0;}
   .download_catalog_page .nav { width: 80px; background: #ededed;}
   .download_catalog_page .nav-tabs { display: inherit; border: 0;}
   .catalog_download_btn p { font-size: 14px; line-height: 16px; color: #333; margin: 0; margin-top: 3px; }
   .catalog_download_btn img { width: 30px; position: relative;}
   .catalog_download_btn .img { content: ''; width: 50px; line-height: 50px; background: #a5a5a5; border-radius: 50%; margin: 0 auto; }
   .download_catalog_page .nav-tabs .nav-link { padding: 0; margin: 0 auto 0px; border-radius: 0px;}
   .download_catalog_page .nav-tabs .nav-link.active { background: #343434; border-radius: 0; width: 100%;}
   .download_catalog_page .nav-tabs .nav-link:hover, .download_catalog_page .nav-tabs .nav-link:focus{border-color: transparent; outline: none; box-shadow: none;}
   .download_catalog_page .nav-tabs .nav-link.active p { color: #fff;}
   .download_catalog_page .nav-tabs .nav-link.active .catalog_download_btn .img { background: #ffcb08;}
   .dr_download_data .tab-content .download_content_data button:hover, .dr_download_data .tab-content .download_content_data button.active { background-color: #343434; border-color: #343434;}
   .download_catalog_page.flex-css { align-items: flex-start; flex-wrap: wrap; }
   .dr_download_data { width: calc(100% - 100px);}
   .catalog_download_btn{padding: 8px 0;}
   .exclamation_size_modal .modal-content{border-radius: 0;}
   .bread-crumb span:after { content: ">";  color: #828282; }

   .download_catalog_page nav { position: sticky; top: 40px; z-index: 1; padding-top: 10px;}
   .dr_download_data .tab-content { position: sticky; top: 50px; z-index: 1; background-color: #fff; padding: 10px 10px 10px 0px;}




   /**************************************** Thankyou CSS *************************************/

   .thankyou_page { padding: 30px 0; }
   .thankyou_page_data { text-align: center; padding: 50px 250px; background: #ededed; margin-top: 20px;}
   .thankyou_page_data img { max-width: 100px; margin-bottom: 15px; }
   .thankyou_page h2 { margin-bottom: 0; padding-left: 15px; border-left: 5px solid #ffca4d; line-height: 22px; }
   .thankyou_page h2 span {font-size: 22px;margin: 0;}
   .thankyou_page_data h2 { margin-bottom: 10px; padding-left: 0 !important; border-left: initial; line-height: 28px; }
   .thankyou_page_data p {margin-top: 25px; font-size: 14px; line-height: 24px;}
   .thankyou_page_data a {font-size: 14px; color: #333;}


   /**************************************** PincodeBasedQuotation CSS *************************************/

   .PincodeBasedQuotation {padding: 60px 0;}
   .PincodeBasedQuotation .form-control, .PincodeBasedQuotation .form-select{height: 40px;} 
   .PincodeBasedQuotation .productpage_title{margin: 20px 0;}
   .PincodeBasedQuotation p{margin-bottom: 5px;}
   .PincodeBasedQuotation form {margin-top: 30px; padding-right: 300px;}
   .PincodeBasedQuotation .btn-primary { background: #008758; border-color: #008758; border-radius: 30px; padding: 10px 30px; font-size: 16px; }
   .PincodeBasedQuotation .btn-primary:hover{background: transparent; color: #008758; border-color: #008758;} 
  

   /****************************************** ProductCompare CSS ******************************************/

   .product_compare_page tr { display: grid; grid-template-columns: repeat(4, 1fr);}
   .product_compare_page tr td .prod_imageItem{ position: relative; }
   .product_compare_page tr td .prod_imageItem .prod_imageItem_icon { position: absolute; top: 5px; left: 0; border: 1px solid #a7a7a7; border-radius: 50%; padding: 10px; font-size: 35px; }
   .product_compare_page tr td .prod_imageItem .prod_imageItem_icon path{ color: #a7a7a7; }
   .product_compare_page tr td .prod_imageItem .prod_imageItem_icon2{position: absolute; top: 0; right: 0;}
   .product_compare_page tr td .prod_imageItem .prod_imageItem_icon2 path{color: #a7a7a7;}
   .product_compare_page .prod_imageItem p { margin: 10px 0; color: #333; font-size: 14px; }
   .product_compare_page .prod_imageItem h4 { font-size: 20px; color: #9d4d4a; margin-bottom: 15px;}
   .product_compare_page .table tbody:nth-child(2) tr td, .product_compare_page .table tbody:nth-child(2) tr th { border: none; }
   .product_compare_page .table tbody:nth-child(2) tr td{padding: 15px !important;  color: #747474;}
   .product_compare_page .table tbody:nth-child(2) tr th{padding: 15px 0 !important;  color: #333;}
   .product_compare_page .table tbody:nth-child(1) tr td{padding: 0 15px !important;}


   /******************************* Privacy-policy-page ********************************************/

   .privacy_policy_page .bread-crumb {font-size: 13px;}
   .privacy_policy_page .site_map_page {padding: 0 0 60px;}
   .privacy_policy_page .paraHead {font-size: 18px; color: #333; font-weight: bold; margin: 30px 0 10px; text-transform: capitalize;}
   .privacy_policy_page .paraText { margin-bottom: 15px; }
   .privacy_policy_page .bread-crumb img { width: 15px; margin-right: 3px; }
   .privacy_policy_page .site_map_page h1{margin: 0;font-size: 28px !important;}
   .privacy_policy_page ul{margin: 20px 0; padding: 0 0 0 10px; list-style-type: none;}
   .privacy_policy_page ul li{padding: 5px 0;}
   .privacy_policy_page .paraItalic { margin: 20px 0 10px; }
   .privacy_policy_page .paraItalic strong { font-size: 18px; }


   /******************************* Board directors css ********************************************/

   .board_directors_page p{font-size: 14px; line-height: inherit;}
   .board_directors { display: flex; justify-content: space-between; text-align: center; flex-wrap: wrap;     padding: 20px 0 40px;}
   .board_directors_data { padding: 30px; border: 1px solid #e1e1e1; margin: 15px 0; width: 32%; }
   .board_director_profile { border: 2px solid #ffcb08; width: 60%; margin: 0 auto; padding: 15px 0; border-radius: 15px; margin-bottom: 5px; background: #fff;}
   .board_directors_data .btn-primary{ border-radius: 30px; color: #333; border-color: #ffcb08; background-color: #ffcb08;}
   .board_director_name div {font-size: 20px;}
   .board_director_name p { line-height: 0px; }
   .board_director_name p span { color: #747474; }
   .board_directors_data:hover{background-color: #ffcb08;}
   .board_directors_data:hover .btn-primary{background-color: #fff;}
   .board_directors_data:hover .btn-primary:hover{background-color: #fff; color: #333; border-color: #ffcb08;}
   .board_directors_page .row.leader-ship { margin-top: 30px; }
   .board_directors_page h1{  margin: 0; padding-left: 20px; }
   .board_directors_page .abt-content .g-heading span:before{width: 5px; height: 35px; position: absolute; left: 0; content: ""; background: #ffcb08; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}
   .board_directors_page .abt-content .g-heading span:before{width: 5px; height: 35px; position: absolute; left: 0; content: ""; background: #ffcb08; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%);}


   /******************************* Our milestones css ********************************************/

   .our_milestones_page .mileWay{padding: 60px 0;width: 50%; margin: 0 auto;}
   .our_milestones_page .Milebox { width: 200px; background: #ededed; padding: 20px; position: relative;}
   .our_milestones_page .Milebox::after { content: ''; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left: 15px solid #ededed; position: absolute; right: -15px; top: 10px; }
   .our_milestones_page .mile-stone:nth-child(even) .Milebox::after { content: ''; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-right: 15px solid #ededed; position: absolute; right: unset; top: 10px; left: -15px; border-left: unset; }
   .our_milestones_page .mile-stone:nth-child(even) .Milebox{float: right; margin-bottom: 40px;} 
   .our_milestones_page .Milebox h4{ color: #747474; }
   .our_milestones_page .Milebox p{ font-size: 14px !important; margin: 0 !important; }
   .our_milestones_page .mile-stone { clear: both; position: relative; margin-bottom: 40px;}
   .our_milestones_page .mile-stone::before{background: #ededed; content: ""; width: 3em; height: 3em; border-radius: 50%; position: absolute; left: 0; right: 0; margin: 0 auto; top: -5px;}

   .our_milestones_page .mile-stone:hover::before, .mile-stone:hover .Milebox{background-color: #ffcb08;} 
   .our_milestones_page .mile-stone:hover .Milebox::after{border-left: 15px solid #ffcb08} 
   .our_milestones_page .mile-stone:nth-child(even):hover .Milebox::after{border-left:unset; border-right: 15px solid #ffcb08;}
   .our_milestones_page .mile-stone:hover .Milebox h4{color: #333;} 
   .our_milestones_page .g-heading {margin-top: 40px !important;}


   /******************************* Awards accolades css ********************************************/

   .about_awards_accolades_years { padding: 15px 25px; background: #e3e3e3; color: #818181; font-size: 20px; display: inline-block; margin: 30px 1px; width: 11.111%; text-align: center; }
   .about_awards_accolades_years:hover{cursor: pointer;}
   .about_awards_accolades_page .abt-content .g-heading{margin-top: 40px !important;}
   .about_awards_accolades_data { width: 18%; text-align: center; margin: 0px 1% 25px;}
   .about_awards_accolades_data h4{ border-bottom: 1px solid; padding-bottom: 10px; margin: 10px 0; }
   .about_awards_accolades_data h4 span{ line-height: 20px; color: #000; font-size: 16px !important;}
   .about_awards_accolades_data h6{ color: #747474; line-height: 22px; }
   .about_awards_accolades_data img{ object-fit: contain; }
   .board_directors_page.about_awards_accolades_page { margin-bottom: 60px;}
   .obl-awards_accolades_years { display: flex; flex-flow: wrap; align-items: center; justify-content: space-between; background: #f1f1f1; border: solid 1px #e3e3e3; margin-top: 30px; border-bottom: 0px;}
   .obl-awards_accolades_years .about_awards_accolades_years { margin: 0px; background: #f1f1f1; border-right: solid 1px #e3e3e3;}
   .obl-awards_accolades_years .about_awards_accolades_years:last-child{ border-right: none;}
   .about_awards_accolades_con { border: solid 1px #e3e3e3; display: flex; flex-flow: wrap; width: 100%; border-top: none; padding: 30px;}
   .obl-awards_accolades_years .about_awards_accolades_years.activeCls { background-color: #fff;}


   
    @media screen and (max-width: 1366px)
    {
        .about_awards_accolades_data { width: 23%;}

    }

   @media screen and (max-width: 1199px)
   {
    .about_awards_accolades_data h4 span { font-size: 16px !important;}
   }

    @media screen and (max-width: 991px)
    {
        .obl-awards_accolades_years .about_awards_accolades_years { font-size: 16px; padding: 15px 15px;}
        .about_awards_accolades_con{ padding: 15px;}
        .about_awards_accolades_data { width: 31%;}


    }

    @media screen and (max-width: 767px)
    {
        .about_awards_accolades_years{ }
        .obl-awards_accolades_years .about_awards_accolades_years { width:11%; padding: 15px 10px;}

    }

    @media screen and (max-width: 639px)
    {
        .obl-awards_accolades_years .about_awards_accolades_years { width: 33.333%; border: solid 1px #dfdfdf !important; padding:10px;}
        .obl-awards_accolades_years{ border: none;}
        .about_awards_accolades_data { width: 48%;}

    }

   /******************************* Residential projects css ********************************************/

   .residential_project_page .projectHead { text-align: center; padding-top: 60px; }
   .residential_project_page h1 { font-size: 35px !important; } 
   .residential_project_page .projectHead p { padding: 10px 210px; }
   .residential_project_page .row { flex-flow: wrap; justify-content: space-between; padding: 30px 0; align-items: center;}
   .residential_project_page .row:nth-child(2n) { flex-direction: row-reverse; }
   .residential_project_page .col { max-width: 50%;}
   .residential_project_page .col img{ width: 100%; }
   .residential_project_page .text{ padding: 0 50px; }
   .residential_project_page .row:nth-child(2n) .text{ padding-left: 15px; }
   .residential_project_page .desContant a { font-size: 16px; } 
   .residential_project_page .desContant { font-size: 16px; color: #333; }
   .residential_project_page .desHead { margin-bottom: 20px; font-size: 22px; font-weight: bold; text-transform: uppercase; color: #000; border-bottom: 5px solid #ffcb08; width: fit-content; padding-bottom: 10px; }
   .residential_project_page .rowSection { padding-bottom: 40px; }


   /******************************* Projects at orientbell css ********************************************/
   
    
   .projects_at_orientbell_page {padding: 60px 0;}
   .projects_at_orientbell_page .slick-slide{display: block !important;}
   .projects_at_orientbell_page .thumb-image{width: 250px;}
   .projects_at_orientbell_page .slick-slide:nth-child(2n){margin: 0 30px;}
   .projects_at_orientbell_page .abt-content{display: flex; flex-wrap: wrap; justify-content: center;}
   .projects_at_orientbell_page .p-content { margin: 30px 0; background: #ededed; padding: 15px;}
   .projects_at_orientbell_page .project-name { font-size: 20px; margin-bottom: 15px;}
   .projects_at_orientbell_page .overviewHead { text-align: center; margin-bottom: 40px;}
   .projects_at_orientbell_page .overviewHead h1{ font-size: 35px; }
   .projects_at_orientbell_page .overviewHead p{ padding: 15px 350px 0;}
   .projects_at_orientbell_page .projectRow { display: flex; text-align: center; justify-content: center; }
   .projects_at_orientbell_page .slick-slide img{width: 100%; margin: 0 auto;}
   .projects_at_orientbell_page .large-whyUs { padding: 60px 0 20px; }
   .projects_at_orientbell_page .l-head { font-size: 28px; text-align: center; background: url(../public/headingSep.png) no-repeat center bottom; padding-bottom: 40px;}
   .projects_at_orientbell_page .large-whyUs p, .projects_at_orientbell_page .large-testi p{ padding: 15px 350px 30px; text-align: center; }
   .projects_at_orientbell_page .whyMain ul { display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; list-style-type: none; padding: 0 180px; }
   .projects_at_orientbell_page .whyMain ul li { width: 48%; margin-bottom: 30px; }
   .projects_at_orientbell_page .whyMain ul li:hover .whyList span{ background-color: #ffcb08; }
   .projects_at_orientbell_page .whyList { display: flex; align-items: center; justify-content: space-around; }
   .projects_at_orientbell_page .whyList span { border: 1px solid #ffcb08; border-radius: 50%; width: 100px; height: 100px; line-height: 100px; text-align: center; background: #fff; }
   .projects_at_orientbell_page .whyTag { font-size: 20px; width: calc(100% - 140px); }
   .projects_at_orientbell_page .large-testimonial .t-profile { display: flex; align-items: center; justify-content: center; margin: 30px 0 60px; }
   .projects_at_orientbell_page .large-testimonial .t-profile { display: flex; align-items: center; justify-content: center; margin: 30px 0 60px; }
   .projects_at_orientbell_page .large-testimonial .tRight { margin-left: 20px; }
   .projects_at_orientbell_page .large-testimonial .t-detail { font-size: 18px; }
   .projects_at_orientbell_page .large-testimonial .t-detail span { display: block; color: #747474; }
   .projects_at_orientbell_page .contactMain { display: flex; justify-content: center; flex-wrap: wrap; margin-top: 40px; display: none;}
   .projects_at_orientbell_page .contact-right { order: 2; width: 48%; padding-left: 20px; }
   .projects_at_orientbell_page .contact-left { order: 1; width: 48%; padding-right: 20px; }
   .projects_at_orientbell_page .salesOffice { margin-top: 80px; padding-top: 30px; }
   .projects_at_orientbell_page .Large-contact .abt-content{justify-content: center;}
   .projects_at_orientbell_page  .abt-content .g-heading{font-size: 28px !important;}
   .projects_at_orientbell_page .regionDetails { list-style-type: none; padding: 0; }
   .projects_at_orientbell_page .regionDetails li { display: flex; justify-content: space-between; font-size: 18px; border-bottom: 1px solid #d7d7d7; padding: 20px 0; }
   .projects_at_orientbell_page .regionDetails li:nth-last-child(1){border-bottom: none;}
   .projects_at_orientbell_page .regionDetails li span { font-size: 18px; }
   .projects_at_orientbell_page .contactMain h3 { color: #747474; font-size: 20px; }
   .projects_at_orientbell_page .Tollfree { background: #e6e6e6; padding: 0.5em; text-align: center; font-size: 1.3em; line-height: 120%; margin: 0.5em 0; font-family: 'Museo-500'; }
   .projects_at_orientbell_page .p-content:hover{background-color: #ffcb08;}
   .projects_at_orientbell_page .tabber.regio-Tab.largeRegion ul {list-style-type: none;padding: 0;}
   .projects_at_orientbell_page .tabber.regio-Tab.largeRegion ul .actv {font-size: 16px;}
   .projects_at_orientbell_page .tabber.regio-Tab.largeRegion ul .actv span{font-size: 16px; margin-left: 5px;}
   .projects_at_orientbell_page .contactus_data { display: flex; justify-content: space-between; margin-top: 60px; flex-wrap: wrap; border-bottom: 2px solid #bdbdbd; padding-bottom: 60px; }
   .projects_at_orientbell_page .custom_div_in_projectatobl .g-heading { font-size: 28px; margin-bottom: 20px; line-height: 36px;}
   .projects_at_orientbell_page .custom_div_in_projectatobl p strong { font-size: 20px; }
   .projects_at_orientbell_page .contactus_data_con p span { font-size: 18px; color: #333; text-align: center; display: block; background: #d5d5d5; padding: 8px 0; margin: 10px 0; }
   .projects_at_orientbell_page .contactus_data_con.custom_div_in_projectatobl { width: 45%; }
   .projects_at_orientbell_page .form-control,.projects_at_orientbell_page .form-select{border: none;border-bottom: 1px solid #dbdbdb; border-radius: 0; padding: 10px 0;}
   .projects_at_orientbell_page .contactus_data_con.custom_div_in_projectatobl .btn { background: #0b9444; border-color: #0b9444; width: 100%; border-radius: 30px; margin-top: 20px; padding: 8px 0; font-size: 16px; }
   .projects_at_orientbell_page .contactus_data_con.custom_div_in_projectatobl .btn:hover { background: transparent; border-color: #0b9444; width: 100%; border-radius: 30px; margin-top: 20px; padding: 8px 0; font-size: 16px; color: #0b9444;}
   .projects_at_orientbell_page .salesOffice { padding-top: 50px; width: 60%; margin: 0 auto 0; }
   .projects_at_orientbell_page .salesOffice .g-heading{text-align: center; margin-bottom: 50px;}

   

   /******************************* CSR css ********************************************/
   
   .CSR_page .csr-initiate { align-items: center; padding-top: 60px; padding-bottom: 60px; border-bottom: 1px solid #d1d1d1; }
   .CSR_page{padding-bottom: 60px;}
   .CSR_page .g-heading{margin-bottom: 40px !important;} 
   .CSR_page .abt-content p{line-height: normal;} 
   .CSR_page .csr-initiate iframe{width: 100%; height: 350px;}
   .CSR_page .gallerySec{padding-top: 60px;}
   .CSR_page .gallerySec h3{margin-bottom: 30px;}
   .CSR_slider iframe { border: 0; width: 100%; height: 350px; }
   .CSR_slider .slick-track .slick-slide { padding: 0px 15px; }
   .CSR_slider .slick-next:before, .CSR_slider .slick-prev:before{color: #333;}

   /******************************* Our capabilities css ********************************************/
   
   .projects_at_orientbell_page.our_capabilities_page .overviewHead p{padding: 15px 250px 0;}
   .projects_at_orientbell_page.our_capabilities_page .abt-content{justify-content: center;}
   .our_capabilities_page .productionMain h3{ color: #747474; }
   .our_capabilities_page .productionMain h3 span{ color: #333; font-size: 22px; font-weight: bold;}
   .our_capabilities_page .productionMain{text-align: center;}
   .our_capabilities_page .sevenTag ul { list-style-type: none; padding: 0 200px; margin: 0; }
   .our_capabilities_page .sevenTag ul li { display: inline-block; border-right: 2px solid; margin: 8px 0; }
   .our_capabilities_page .sevenTag ul li a { font-size: 20px; text-transform: uppercase; color: #333; padding: 0 25px; }
   .our_capabilities_page .designRow{display: flex; justify-content: space-between; flex-wrap: wrap;}
   .our_capabilities_page .designLeft,.our_capabilities_page .designRight{width: 48%;}
   .our_capabilities_page .designRow.odd{flex-direction: row-reverse;}
   .our_capabilities_page .typeRow ul{list-style-type: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap;}
   .our_capabilities_page .typeRow ul li{width: 31%; text-align: center; margin-bottom: 15px;}
   .our_capabilities_page .typeRow ul li img{width: 100%;}
   .our_capabilities_page .typeRow ul li span{display: block;}
   .our_capabilities_page .designRow { display: flex; justify-content: space-between; flex-wrap: wrap; margin-top: 60px; }
   .our_capabilities_page .designLeft, .our_capabilities_page .designRight { width: 48%; }
   .our_capabilities_page .typeRow ul { list-style-type: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap; }
   .our_capabilities_page .typeRow ul li { width: 30%; text-align: center; margin: 0 5px 15px; }
   .our_capabilities_page .typeRow ul li img { width: 100%; border: 1px solid #e5e5e5; }
   .our_capabilities_page .typeRow ul li span { display: block; font-size: 16px; padding-top: 5px; color: #333; }
   .our_capabilities_page .designLeft .desHead { font-size: 28px; color: #333; text-transform: uppercase; font-weight: bold; padding-bottom: 5px;}
   .our_capabilities_page .designLeft .desBorder { width: 25%; height: 5px; background-color: #ffcb08; margin: 1em 0 0; }
   .our_capabilities_page .desContant, .our_capabilities_page .desContant strong, .our_capabilities_page .desContant a {font-size: 16px; margin-top: 25px;}


   /************************** Core values **************************/

   .core_value_page .thumbIcon { border: 2px solid #ffcb08; width: 20%; text-align: center; float: left; border-radius: 15px; padding: 15px; }
   .core_value_page .thumbIcon img { width: 90px; }
   .core_value_page .thumbContent { width: 80%; float: left; padding-left: 20px; }
   .core_value_page .thumbContent h3{margin-bottom: 15px;}
   .core_value_page .inno-main{margin-bottom: 60px;}
   .core_value_page h1{position: relative;}
   .core_value_page h1:before { content: ''; width: 5px; height: 35px; position: absolute; left: 0; background: #ffcb08; top: 5px; }
   .core_value_page .g-heading{margin-top: 40px !important;}
   .core_value_page .g-heading+p{margin-bottom: 60px !important;}


   /************************** Product Quality Css **************************/

   .residential_project_page.product_quality_page  .desHead{border: none; margin: 0; padding-bottom: 3px;}
   .product_quality_page .desSubhead { font-size: 16px;}
   .product_quality_page .desBorder { width: 25%; height: 5px; background-color: #ffcb08; margin: 1em 0 0; }
   .product_quality_page .desContant {margin: 1em 0 0;}
   .product_quality_page .mobile {position: relative;}
   .product_quality_page .row:nth-child(odd) .mobile {position: unset;}
   .product_quality_page .col.mobile span { position: absolute; right: 100%; bottom: 0; font-size: 13em; font-family: "arial"; font-weight: 700; color: #e6e6e6; line-height: .7; z-index: -1;}
   .product_quality_page .row:nth-child(odd) { position: relative; }
   .product_quality_page .row:nth-child(odd) .mobile span { right: 0px; bottom: 0px; }


   /************************** Government Projects Css **************************/

   .government_project_page .clientHead { font-size: 28px; font-weight: bold; text-align: center; }
   .government_project_page .ourClientWrap { padding: 60px 0; border-top: 1px solid #cfcfcf;}
   .government_project_page .clientLogo { margin-top: 40px; }
   .government_project_page .clientLogoSlider { display: flex; flex-flow: wrap; justify-content: space-around; align-items: center; }
   .government_project_page .clientLogoSlides { width: 20%; margin: 10px auto; text-align: center; }
   .government_project_page .clientLogoSlides img { width: 120px; }

   .dr-new-one .titleBox{width: 95px !important;}
   .dr-new-one .titleHead a{padding-bottom: 0 !important;}

   .expert_assistance_modal input { background-color: transparent !important; border-radius: 0 !important; padding: 0px 15px !important; font-size: 16px; border: 1px solid #d9d9d9 !important; }
   .expert_assistance_modal .btn.btn-primary{background: #0f9243; border-color: #0f9243;}
   .expert_assistance_modal .btn.btn-primary { background: #0f9243; border-color: #0f9243; border-radius: 0; font-size: 16px; padding: 10px 0; margin-top: 20px; margin-bottom: 20px}
   .expert_assistance_modal .modal-body {padding-bottom: 0 !important;}
   .expert_assistance_modal .btn.btn-primary:hover{background: #037e34; border-color: #037e34;}
   .category_accordion_data img{height: auto !important;}
   .category_accordion_data .accordion li ul li a { background: transparent; display: inline-block; padding: 0; }
   .category_listing_page h1{margin: 10px 0;}

   .tile-related-blog-section .listing-Wrap .listing-col{display: flex;flex-flow: wrap;padding: 15px;}
   .tile-related-blog-section .listing-Wrap .listing-col .listingImg { width: 160px; order: 1; height: 160px; }{}
   .tile-related-blog-section .listing-Wrap .listing-col .listingImg img{height: 160px; width: 100%;}
   .tile-related-blog-section .listing_blog_data { order: 2; width: calc(100% - 200px); padding-left: 20px; }{}
   .tile-related-blog-section .listing-Wrap .listing-col .listingText { width: 100%; padding: 0; padding-top: 10px; order: 3; }
   .tile-related-blog-section .listing-Wrap .listing-col .listingText p{color: #747474;}
   .tile-related-blog-section a div{font-size: 20px;color: #333;}
   .tile-related-blog-section .listingDate { font-size: 16px; color: #747474; margin-bottom: 10px; }
   .tile-related-blog-section .listingHead  a div{ color: #333; }
   .tile-related-blog-section .listingButton  a{ font-size: 14px; }
   .tru_btn_new { width: 100%; text-align: center; }
   .tru_btn_new .btn.btn-primary { background: #0f9243; border-color: #0f9243; border-radius: 4px; color: #fff; padding: 10px 30px; }
  
   
   /*************************************** exports-project *******************************/

   .exports_project_page h2 {margin: 60px 0 30px;}
   .exports_project_page h2 strong span, .exports_project_page h2 strong{font-size: 28px !important; color: #333; font-weight: bold !important; text-transform: capitalize; line-height: normal;}
   .exports_project_page .rowSection .row .col.text .desBorder { float: left; width: 25%; height: 5px; background-color: #ffcb08; margin: 1em 0 0; margin-left: 150px; }
   .exports_project_page .rowSection .row { width: 100%; display: flex; padding: 2em 0; margin: 0;}
   .exports_project_page .rowSection .row .col { width: 50%; position: relative; padding: 0;}
   .exports_project_page .rowSection .row .col img { width: 100%; }
   .exports_project_page .row:nth-child(even) .col.text { order: -1; padding-left: 0; }
   .exports_project_page .rowSection .row .col.text .desHead { /* font-family: "Poppins-SemiBold"; */ font-size: 20px; font-weight: bold; padding-left: 150px; margin-top: 15px; }
   .exports_project_page .rowSection .row .col.text .desContant{float: left; width: 100%; margin: 1em 0 0;padding: 0 150px;}
   .exports_project_page .row:nth-child(even) .col.text .desContant, .exports_project_page .rowSection .row:nth-child(even) .col.text .desBorder, .exports_project_page .rowSection .row:nth-child(even)  .col.text .desHead  {padding-left: 0 !important;margin-left: 0 !important;}
   .exports_project_page .client-slider { display: flex; justify-content: space-between; flex-wrap: wrap; }
   .exports_project_page .client-slider div { width: 32%; margin-bottom: 15px; box-shadow: -1px 5px 5px 1px #e5e5e5; }
   .exports_project_page .client-logo-bx { width: 100% !important; margin-bottom: 0 !important; }
   .exports_project_page .packaging-section .awards-row .awards-col p { margin: 15px 0px 0px; font-size: 16px; line-height: normal; }
   .exports_project_page .export-enquiry, .exports_project_page .export-enquiry .export-enq-bx { float: unset; }
   .exports_project_page .export-box { border: 1px solid #d1d1d1; padding: 15px; width: fit-content; }
   .exports_project_page .export-enquiry p, .exports_project_page .export-enquiry p strong, .exports_project_page .export-enquiry a { font-size: 14px; margin-bottom: 5px; }
   .exports_project_page .export-enquiry .export-enq-bx{padding: 60px 0;}
   .exports_project_page iframe { border: 0; width: 100%; }
   .exports_project_page .obl-makes-section { padding: 50px 0px 20px; }
   .exports_project_page .awards-section{padding: 0; padding-top: 60px;}
   .exports_project_page .packaging-section p span { font-size: 16px !important; }
   .exports_project_page .export-enquiry h3 { font-size: 18px !important; margin-bottom: 15px; }
   .exports_project_page .awards-section h2{font-size: 28px !important;}


   /******************************** catalogue search *********************************/
   
   .cataloguesearch_page{padding: 60px 0;}
   .cataloguesearch_page_top_strip { display: flex; justify-content: space-between; flex-flow: wrap; }
   .cataloguesearch_page_top_strip button.ais-ClearRefinements-button { font-size: 1.2rem; background-color: #ebebeb; border: 2px solid #ebebeb; color: #333; padding: 6px; width: 300px; border-radius: 30px; }
   .cataloguesearch_page_top_strip button.ais-ClearRefinements-button:hover{background-color: #007f1d;border-color: #007f1d; color: #fff;cursor: pointer;}
   .cataloguesearch_page_top_strip div:nth-child(2) input[type="search"]{width: 100%; height: 40px; border: 1px solid #dddddd; padding: 0 5px;}
   .cataloguesearch_page_top_strip .ais-Stats{padding-left: 320px; margin-top: 10px;}
   .cataloguesearch_page_top_strip div:nth-child(2) { width: calc(100% - 320px); }
   .cataloguesearch_page_top_strip .ais-SearchBox button.ais-SearchBox-submit, .cataloguesearch_page_top_strip .ais-SearchBox button.ais-SearchBox-reset, .ais-SearchBox-submit, .ais-SearchBox-reset{ display: none; }
   .cataloguesearch_page_top_strip p{font-size: 16px; margin-right: 10px;}
   .cataloguesearch_page_top_strip select.ais-SortBy-select{  border: 1px solid #cbcbcb; color: #747474; background: transparent;}
   .cataloguesearch_page_tile_filter{display: flex; justify-content: space-between;}
   .cataloguesearch_page_tile_filter .sidebar{width: 300px;}
   .cataloguesearch_page_main_tile_data{width: calc(100% - 320px);}
   .ais-InfiniteHits-list { padding: 0; margin: 0; display: flex; justify-content: space-between; flex-wrap: wrap;list-style-type: none;}
   .cataloguesearch_page_tile_filter .ais-InfiniteHits-item{width: 31%; text-align: center; margin-bottom: 30px; border: 1px solid #f1f1f1;}
   .cataloguesearch_page_tile_filter .ais-InfiniteHits-item a{ position: relative; overflow: hidden; text-align: center; height: 350px; display: flex; flex-flow: wrap; align-items: center; justify-content: center; padding-bottom: 50px;}
   .cataloguesearch_page_tile_filter .ais-InfiniteHits-item img{ width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset;}
   .cataloguesearch_page_tile_filter .ais-InfiniteHits-item div{background: #f1f1f1; padding: 13px 0; font-size: 16px; font-weight: bold; color: #333; width: 100%; position: absolute; left: 0px; bottom: 0px;}
   .cataloguesearch_page_tile_filter button.ais-InfiniteHits-loadMore { display: flex; margin: 0 auto; padding: 15px 50px; margin-top: 20px; border: 1px solid #ebebeb; background: #ebebeb; border-radius: 30px; font-size: 16px; font-weight: bold; color: #333; }
   .cataloguesearch_page_tile_filter button.ais-InfiniteHits-loadMore:hover {background-color: #007f1d; border-color: #007f1d; color: #fff;}
   .sidebar .price,.sidebar .category,.sidebar .color,.sidebar .tile-area,.sidebar .tile-type,.sidebar .tile-design,.sidebar .tile-collections,.sidebar .location { border: solid 1px #efefef; margin-bottom: 15px; }
   .sidebar .price p,.sidebar .category p,.sidebar .color p,.sidebar .tile-area p,.sidebar .tile-type p,.sidebar .tile-design p,.sidebar .tile-collections p,.sidebar .location p { background-color: #efefef; padding: 7px 10px; font-size: 16px; color: #333; margin: 0; }
   .sidebar .ais-RangeInput { padding: 10px; }
   .sidebar .ais-RefinementList, .sidebar .ais-HierarchicalMenu{padding: 10px}
   .sidebar .ais-SearchBox input.ais-SearchBox-input{width: 100%; border: 1px solid #cbcbcb; padding: 5px; margin-bottom: 10px;}
   .sidebar ul.ais-RefinementList-list { padding: 0; margin: 0; list-style-type: none; }
   .sidebar span.ais-RefinementList-labelText {padding-left: 5px;}
   .sidebar ul.ais-HierarchicalMenu-list { padding: 0; margin: 0; list-style: none; }
   .sidebar .ais-HierarchicalMenu-label, .sidebar .ais-HierarchicalMenu-count { color: #088df4; padding-right: 5px}
   .sidebar .price label{width: 45%;}
   .sidebar .price label input{width: 100%;border: 1px solid #cbcbcb; padding: 5px;}
   .sidebar .price span{padding: 0 5px;}
   .sidebar .price .ais-RangeInput-submit{display: none;}

   
   .cataloguesearch_page .ais-CurrentRefinements { width: 300px; margin: 15px 0; }
   .cataloguesearch_page ul.ais-CurrentRefinements-list { padding: 0; margin: 0; list-style-type: none; }
   .cataloguesearch_page li.ais-CurrentRefinements-item { color: #636363; /* border-radius: 2px; */ border: solid 1px #ddd; background-color: #f4f4f4; display: inline-block; max-width: 100%; /* white-space: nowrap; */ padding: 0.2em 0.5em; /* overflow: hidden; */ margin-right: 0.4em; margin-bottom: 3px; }
   .cataloguesearch_page span.ais-CurrentRefinements-label { font-weight: bold; text-transform: capitalize; }
   .cataloguesearch_page span.ais-CurrentRefinements-category { margin: 0 5px; }
   .cataloguesearch_page button.ais-CurrentRefinements-delete { line-height: initial; box-shadow: none; border: none; margin-left: 5px; }
   .filter_mobileData{display: none;}
   .filter_mobileData.show_filter{display: block;}
   .mobile_view_filter h3{text-align: center; line-height: 40px; border: solid 1px #ddd; margin: 10px 0 20px; cursor: pointer; font-size: 16px;}
   .cataloguesearch_page_mobile_view{display: none;}


/**************** errorpage start  ***************/
.errorpage { padding-top: 50px;}
p.back-to-hp-txt { font-size: 14px; display: flex;cursor: pointer;}
p.back-to-hp-txt:hover, p.back-to-hp-txt:hover span{ color: #009444;}
.error-section .error-content .fr-Title{ display: none;}
.error-section{ padding: 25px 0px 50px; max-width: 1366px; width: 100%; margin: 0px auto;}
.error-section .error-figure img { max-width: 500px; width: 100%;}
.error-content .error-text-bx h2 { margin-bottom: 20px;}
.error-content .us-form-col { max-width: 600px; width: 100%; margin: 0px auto; background-color: #f1f1f1; padding: 30px; border-radius: 20px;}
.error-content .us-form-col .form-group.pdp-form { padding: 0px 0px 20px !important;}
.error-content .us-form-col .form-group.pdp-form .input-bx { height: 46px !important;}
.error-content .us-form-col .form-group.pdp-form .ft-submit-btn { height: 46px !important; font-family: "Poppins-SemiBold";}
.error-content .us-form-col p { margin: 0px 0px 15px; font-size: 16px; color: #333;}
.errorpage .pro_similartiles { background-color: transparent; padding: 0px;}
.errorpage .pro_similartiles .cate-pro-text .simi-name a { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; height: 50px; font-size: 18px;}
.error-content .us-form-col p.error.text-danger{ margin: 0px; font-size: 14px;}
.error-content .us-form-col .form-group.button.pdp-form{ padding-bottom: 10px !important;}
.errorpage .pro_similartiles .slick-slider { margin: 0px -10px;}
.errorpage .pro_similartiles .cate-pro-text .add-button .add-pro-btn:hover { background-color: #ffcb08 !important; color: #333;}
.errorpage .obl-searchresult-col{ margin-bottom: 0px;}

.pro_similartiles .slick-prev:hover, .pro_similartiles .slick-next:hover{ background-color: #ffcb08b8;}
.pro_similartiles .slick-prev { left: -5px; width: 30px; height: 30px; background-color: #ffcb08b8; z-index: 1;}
.pro_similartiles .slick-prev:before{ content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #333; border-top: 2px solid #333; transform: rotate(-135deg); margin-left: 5px;}
.pro_similartiles .slick-next { right: -5px; width: 30px; height: 30px; background-color: #ffcb08b8; z-index: 1;}
.pro_similartiles .slick-next:before{ content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #333; border-top: 2px solid #333; transform: rotate(45deg); margin-right: 5px;}


@media screen and (max-width: 767px)
{
    .errorpage .pro_similartiles .cate-pro-text .simi-name a{ height: auto;}
    .error-content .us-form-col{ padding: 25px 15px;}
    .error-content .us-form-col .form-group.pdp-form { padding: 0px 0px 15px !important; margin-bottom: 0px;}
    .pro_similartiles .slick-prev { left: 5px; width: 25px; height: 25px;}
    .pro_similartiles .slick-next { right: 0px; width: 25px; height: 25px;}

}
/**************** errorpage end  ***************/


   /******************************** Error page *********************************/


   .error-section { padding: 40px 0px; position: relative; display:flex; display:-webkit-box; display:-moz-flex; display:-ms-flex; flex-flow:wrap; background-image: linear-gradient(#f0f0f0, #f6f6f6); align-items:center;}
   .error-figure{width: 50%;    padding-right: 15px;}
   .pagenotfund .error-content {    width: 50%;    padding-left: 15px;    text-align: center;}
   
   .error-content{text-align:center; width:50%;}

   .error-text-bx .home-btn { padding: 20px 50px !important; background-color: #fc0; color: #000000; border-radius: 10px; font-size: 24px!important; display: inline-block; font-weight: 900; margin: 0px 10px; }
   .error-text-bx .home-btn:hover { text-decoration: none; background-color: #0f9243; color: #fff; }
   .error-text-bx p { font-size: 24px;    max-width: 600px;    margin: 30px auto;    line-height: normal;    color: #000;}
   .error-text-bx .gobackhome-btn {    background-color: transparent;    color: #008aef;    margin-top: 10px;    font-size: 20px;}
   .error-text-bx .home-btn:hover svg path {    fill: #fff;}


   /******************************** TileSelector page *********************************/

   .tile_selector_page{padding: 60px 0;}
   .tile_selector_page .tile_selector_content {text-align: center; padding: 15px;}
   .tile_selector_page .tile_selector_content h2{font-size: 40px;line-height: normal;}
   .tile_selector_page .tile_selector_content p{font-size: 20px; margin: 30px 0 0;}
   .tile_selector_page .tile_selector_content img{display: block; margin: 30px auto;}
   .tile_selector_page .tile_selector_content .btn-primary, .tile_selector_page .tile_selector_content .btn-primary:hover{padding: 10px 43px; background: #ffcb08; border: 1px solid #ffcb08; color: #000; font-size: 1.6em; border-radius: 30px;}
   .tile_selector_page .slick-dots li button:before{opacity: .3 !important;color: #333 !important; font-size: 15px !important;}
   .tile_selector_page .slick-dots li.slick-active button:before{opacity: 1 !important; color: #333 !important;}
   .tile_selector_page .slick-dots li{margin: 0;}

   .tile-selector-main { width: 100%; float: left; background: url(../public/tile-selector-bg.jpg) no-repeat top; background-size: cover; background-attachment: fixed; height: 100%; padding: 60px 0; margin-bottom: 30px;}
   .tile-selector-main h2 {color: #fff !important; text-align: center; margin-bottom: 30px;}
   .tile-selector-card { width: 23%; margin: 1%;}
   .tile-selector-card .tile-selector-card-img { text-align: center; overflow: hidden; background-color: #fff;}
   .tile-selector-card .tile-selector-card-img img { height: 310px; object-fit: cover;  width:100%;}
   .tile-selector-flex { display: flex; flex-wrap: wrap; }
   .tile-selector-card-data { background: #e6e6e6; display: flex; justify-content: space-between; padding: 10px; align-items: center;}
   .tile-selector-card-data input[type="checkbox"] { width: 20px; height: 20px; cursor: pointer;}
   .tile-selector-card-data p{ color: #333; font-size: 16px; margin: 0px; }
   .tile-selector-changebtn { text-align: center; margin-top: 60px; }
   .tile-selector-changebtn p { color: #fff; font-size: 14px; }
   .tile-selector-changebtn img { width: 300px; margin: 5px 0; }
   .tile-selector-changebtn img:hover {cursor: pointer;}
   .color-tile-row .tile-selector-card { width: 10%;}
   .color-tile-row .tile-selector-card-data p{ display: flex; flex-flow: wrap; align-items: center; cursor: pointer;}
   .color-tile-row .tile-selector-card-data p .color-tile{ width: 40px; height: 40px; display: inline-block; border-radius: 30px;  border: 2px solid #000;}
   .color-tile-row .tile-selector-card-data p .color-tile ~ span{ padding-left: 10px;}
   .color-tile-row .tile-selector-card-data p .color-tile.Blue{ background-color: #2b70f1;}
   .color-tile-row .tile-selector-card-data p .color-tile.Ivory{ background-color: #eadeba;}
   .color-tile-row .tile-selector-card-data p .color-tile.Yellow{ background-color: #feca2d;}
   .color-tile-row .tile-selector-card-data p .color-tile.White{ background-color: #FFF;}
   .color-tile-row .tile-selector-card-data p .color-tile.Black{ background-color: #040404;}
   .color-tile-row .tile-selector-card-data p .color-tile.Brown{ background-color: #865340;}
   .color-tile-row .tile-selector-card-data p .color-tile.Grey{ background-color: #b4b4b4;}
   .color-tile-row .tile-selector-card-data p .color-tile.Green{ background-color: #3dc72c;}
   .color-tile-row .tile-selector-card-data p .color-tile.Beige{ background-color: #c9b194;}
   .color-tile-row .tile-selector-card-data p .color-tile.Terracotta{ background-color: #cf4e46;}
   .color-tile-row .tile-selector-card-data p .color-tile.Cream{ background-color: #ede3c4;}
   .color-tile-row .tile-selector-card-data p .color-tile.Purple{ background-color: #ad0af6;}
   .color-tile-row .tile-selector-card-data p .color-tile.Red{ background-color: #fc431a;}
   .color-tile-row .tile-selector-card-data p .color-tile.Orange{ background-color: #ffa500;}
   .color-tile-row .tile-selector-card-data p .color-tile.Sandune{ background-color: #d8c5b4;}
   .color-tile-row .tile-selector-card-data p .color-tile.Pink{ background-color: #f6a9e8;}
   .color-tile-row .tile-selector-card-data p .color-tile.Multi-colour{ background: linear-gradient(to right bottom,#cc0076,#a02f87,#629834)!important;}
   .color-tile-row .tile-selector-card-data p .color-tile.Wenge{ background-color: #645452;}
   .tile_selector_data .tile_selector_slider .slick-slider .slick-arrow { display: none !important;}
   .tile_selector_data .tile_selector_slider .slick-slider .slick-dots { bottom: 15px;}
   .tile-selector-main .tile-selector-wrap .sub-tiles-b { width: 23%; margin: 1%;}
   .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb  { text-align: center; overflow: hidden; background-color: #fff;}
   .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height: 310px; object-fit: cover; width: 100%;}
   .tile-selector-main .tile-selector-wrap .sub-tiles-b .tile-name { background: #e6e6e6; padding: 10px;}
   .tile-selector-main .tile-selector-wrap .sub-tiles-b .tile-name .tile-text { font-family: "Poppins-SemiBold";}




   .manufacturing_capabilities_page h2 strong{font-size: 28px; margin: 10px 0;}

   
   .h1,h1,h1 strong span, h1 strong, h1 span{font-size: 35px !important; color: #333 !important;     font-family: 'Poppins-SemiBold';}
   .h2,h2,h2 strong span, h2 strong, h2 span{font-size: 28px !important; color: #333 !important;    font-family: 'Poppins-SemiBold';}
   .h3,h3,h3 strong span, h3 strong, h3 span{font-size: 28px !important; color: #333 !important;    font-family: 'Poppins-SemiBold';}
   .h4,h4,h4 strong span, h4 strong, h4 span{font-size: 20px !important; color: #333 !important;    font-family: 'Poppins-SemiBold';}
   .obl-policy-section h1, .obl-policy-section h2,.obl-policy-section h3{font-family: 'Poppins-SemiBold' !important;}

   .investor_accordion h2 span{font-size: 14px !important;}



   /******************************** Manufacturing Capabilities Page *********************************/

   .manufacturing_capabilities_page{padding: 20px 0 60px;}
   .manufacturing_capabilities_page .abt-main{display: flex; align-items: center; justify-content: space-between;flex-wrap: wrap;}
   .manufacturing_capabilities_page .abt-main .spotlight-image, .manufacturing_capabilities_page .abt-main .spotlight-content{width: 48%;}
   .manufacturing_capabilities_page .abt-main .spotlight-content .main-head{color: #ffcb08; font-size: 47px; line-height: 54px;}
   .manufacturing_capabilities_page .bread-crumb{margin: 10px 0 30px;}
   .manufacturing_capabilities_page h2{margin: 30px 0 15px;}
   .manufacturing_capabilities_page .abt-content a strong{font-size: 16px;}
   .manufacturing_capabilities_page .inno-main{display: flex; justify-content: space-between;margin-top: 50px;align-items: center;}
   .manufacturing_capabilities_page .thumbIcon { border: 2px solid #ffcb08; width: 170px; height: 170px; line-height: 170px; text-align: center; border-radius: 25px; }
   .manufacturing_capabilities_page .thumbContent{width: calc(100% - 200px);}


   
   .style-tiles-section .category-menu .slick-prev { left: 25px; width: 50px; height: 50px; z-index: 1; background-color: #00000054;}
   .style-tiles-section .category-menu .slick-prev:before { content: ''; display: inline-block; width: 20px; height: 20px; border-right: 5px solid #fff; border-top: 5px solid #fff;margin-left: 10px; transform: rotate(-135deg); -webkit-transform: rotate(-135deg); -moz-transform: rotate(-135deg); -ms-transform: rotate(-135deg);  -o-transform: rotate(-135deg); }   
   .style-tiles-section .category-menu .slick-next { right: 25px; width: 50px; height: 50px; z-index: 1; background-color: #00000054;}
   .style-tiles-section .category-menu .slick-next:before { content: ''; display: inline-block; width: 20px; height: 20px; border-right: 5px solid #fff; margin-right: 10px; border-top: 5px solid #fff; transform: rotate(45deg); -o-transform: rotate(45deg); -ms-transform: rotate(45deg); -moz-transform: rotate(45deg); }



  @media screen and (max-width: 1600px)
  {
      .footer-top .foot-col .Social-Icons li { padding: 0px 5px 0px 0px;}
      .tile-selector-card .tile-selector-card-img img{ height:270px}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height:270px}
      .color-tile-row .tile-selector-card { width: 12.222%;}
      .cataloguesearch_page_tile_filter .ais-InfiniteHits-item a { height:260px}

  
  }
  
  @media screen and (max-width: 1440px)
  {
      .title_h3 h3:before{left: 100px;}
      .title_h3 h3:after{right: 100px;}
      .tile-selector-card .tile-selector-card-img img{ height:240px}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height:240px}
  }

    @media screen and (max-width: 1366px)
    {
        .color-tile-row .tile-selector-card {  width: 14.555%;}

    }
  
  @media screen and (max-width: 1199px)
  {
      .nav-row ul.product-menu>li>a{ font-size:12px;}
      .category-menu .slick-prev { width: 15px; height: 24px; left: -10px; z-index: 0;}
      .menu-slider .slick-prev:before { width: 15px; height: 15px;}
      .menu-slider .slick-next { width: 15px; height: 24px; right: -10px; z-index: 0;}
      .menu-slider .slick-next:before { width: 15px; height: 15px;}
      .mini_cart{right: 10px;}
      .tile-selector-card { width: 32%; margin: 0px 0px 15px;}
      .tile-selector-card:nth-child(3n+2){ margin-left: 2%; margin-right: 2%;}
      .color-tile-row .tile-selector-card { width: 23%; margin: 1% !important;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b { width: 32%; margin: 0px 0px 15px;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(3n+2){ margin-left: 2%; margin-right: 2%;}
      .cataloguesearch_page_tile_filter .ais-InfiniteHits-item a { height:240px}
      .cataloguesearch_page_mobile_view .ais-InfiniteHits-item { background: transparent !important; border: 1px solid #f1f1f1;}
      .cataloguesearch_page_mobile_view .ais-InfiniteHits-item img { width: auto !important; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset; }
      .cataloguesearch_page_mobile_view .ais-InfiniteHits-item div { background-color: #f1f1f1; padding: 13px 0 !important; width: 100%; position: absolute; left: 0px; bottom: 0px;}


  }

    @media screen and (max-width: 991px)
    {
        .tile-selector-card .tile-selector-card-img img { height: 200px;}
        .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height:200px}
        .cataloguesearch_page_mobile_view .ais-InfiniteHits-item a { position: relative; overflow: hidden; text-align: center; height: 240px; display: flex; flex-flow: wrap; align-items: center; justify-content: center; padding-bottom: 50px;}

        
    }
  
  @media screen and (max-width: 980px)
  {
    .whiteTitleHead { color: #424242;}
      .ft-fromUs-row .fr-Title { width: 100%; text-align: center; padding-bottom: 15px;}
      .ft-fromUs-row .us-form-col { width: calc(100% - 0px);}
      .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn{ width:100%;}
      .ft-fromUs-row .us-form-col form .form-group { margin: 0px 0px 10px; width: 100%; padding-right: 0px;}
      .ft-fromUs-row .us-form-col form .form-group.button { width: 100%; text-align: center;}
      .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn { width: auto; padding: 0px 50px;}
      .footer-top .foot-col { width: 100%; padding: 15px 0px 0px;}
      .footer-top .foot-col .select-lang { padding-top: 15px;}
      .footer-top .foot-col .f-heading h6 { font-size: 18px; color: #fff; margin: 0px 0px 10px;}
      .footer-top.ft-bottom .foot-col:nth-child(5) { width: 100%;}
      .footer-top.ft-bottom .foot-col:nth-child(6) { width: 100%;}
      .footer-top.ft-bottom .foot-col .maplocate img { height: 40px;}
      .footer-top .foot-col .select-lang select{ width:100%;}
      .footer-top.ft-bottom { border-top: none; padding: 0px; margin-top: 0px;}
      .footer-top .foot-col ul{ display:none; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .footer-top .foot-col .foot-box { padding-top: 15px;}
      .footer-top .foot-col .f-heading{ position:relative;}
      .footer-top .foot-col .f-heading:before{ content: ""; position: absolute; right: 0px; top: 50%;  height: 10px; width: 10px; border-left: 1px solid #d5d5d5; border-bottom: 1px solid #d5d5d5; transform: translateY(-50%) rotate(-45deg); -webkit-transform: translateY(-50%) rotate(-45deg); -moz-transform: translateY(-50%) rotate(-45deg); -ms-transform: translateY(-50%) rotate(-45deg); -o-transform: translateY(-50%) rotate(-45deg);}
      .footer-top .foot-col .f-heading.active-link:before{ content: ""; position: absolute; right: 0px; top: 12px;  border-left: 1px solid #fc0; border-bottom: 1px solid #fc0; transform: translateY(-50%) rotate(135deg); -webkit-transform: translateY(-50%) rotate(135deg); -moz-transform: translateY(-50%) rotate(135deg); -ms-transform: translateY(-50%) rotate(135deg); -o-transform: translateY(-50%) rotate(135deg);}
      .footer-top .foot-col .f-heading.active-link+ul { display: block; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .footer-top .foot-col .f-heading.active-link h6 { color: #fc0;}
  
      .dr-new-one .titleBox{width: unset !important;}
  
      /********************************************************** Mobile Menu CSS ****************************************************/
      .nav-row ul.product-menu>li .menu-arrow{ display:block;}
      .mobile-icon span{height: 2px; margin-bottom:6px; border-radius: 2px; background-color: #fff; display: block; transition: all .25s ease-in-out; -webkit-transition: all .25s ease-in-out; -o-transition: all .25s ease-in-out; -moz-transition: all .25s ease-in-out;}
      .mobile-icon span:nth-child(1){width:25px; }
      .mobile-icon span:nth-child(2){width:25px;}
      .mobile-icon span:nth-child(3){width:25px; margin: 0px;}
      .mobile-icon.mobile-icon-open span:nth-child(1){ transform: rotate(45deg); position: absolute; top: 8px; background-color: #fff;}
      .mobile-icon.mobile-icon-open span:nth-child(2){opacity: 0;}
      .mobile-icon.mobile-icon-open span:nth-child(3){ transform: rotate(-45deg); position: absolute; bottom: -2px; background-color: #fff;}
      .nav-row ul.product-menu>li .menu-arrow{ display:none;}
      .header-row .searchbox, .header-row .header-right-col{ display:none;}
      .obl-nav-section{ position:unset;}
      .header-section { position: sticky; top: 0px; z-index: 1;}
      .header-row .co-logo { max-width: 180px; width: 100%; padding-left:40px;}
      .header-row .co-logo img { height: 30px;}
      .mobile-icon { position: absolute; top: 20px; left: 15px; cursor: pointer;}
      .nav-row ul.product-menu { position: absolute; width: 90%; left: -100%; top: 60px; background-color: #fff; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; height: calc(100vh - 55px); display: unset; overflow: auto; padding-bottom: 100px;}
      .nav-row ul.product-menu::-webkit-scrollbar { width: 10px;}
      .blog_menu_new .titleHead::before{display: none;}
      .nav-row ul.product-menu::-webkit-scrollbar-track { background: #f1f1f1;}
      .nav-row ul.product-menu::-webkit-scrollbar-thumb { background: #888;}
      .mobile-icon.mobile-icon-open+.product-menu{ left:0px; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .nav-row ul.product-menu>li{ display:block; width:100%; border-bottom: 1px solid #ccc; position: relative; display: flex; flex-flow: wrap; align-items: center;}
      .nav-row ul.product-menu>li>a{ color: #434343; font-size: 14px; font-family: "Poppins-Medium"; padding:10px 15px; padding: 10px 15px; width: calc(100% - 50px); order: 1;}
      .nav-row ul.product-menu>li>a:hover { background-color: transparent; color: #acacac;}
      .nav-row ul.product-menu>li:hover i { color: #acacac;}
      .nav-row ul.product-menu>li .menu-arrow { display: block; width: 50px; height:42px; text-align: center; order: 2; line-height: 45px; position: relative;}
      .nav-row ul.product-menu>li .menu-arrow i { font-size: 20px; line-height: 45px;}
      .nav-row ul.product-menu>li:hover .obl-submenu-row{ display:none; position: unset; background-color: #f7f7f7;}
      .nav-row ul.product-menu>li .menu-arrow.sublink-open ~ .obl-submenu-row { display: block; width: 100%; order: 3; background-color: #f7f7f7;}
      .obl-submenu-row .obl-submenu-nav .titleBox ul li a{ color: #a8a8a8; font-size: 14px;  }
      .obl-submenu-row .obl-submenu-nav{ margin:0px; display: unset;}
      .obl-submenu-row .obl-submenu-nav .titleBox { width: 100%; padding-right: 0px;}
      .obl-submenu-row .obl-submenu-nav .titleHead{ padding:10px 0px; position: relative;}
      .obl-submenu-row .obl-submenu-nav .titleHead:before { content: '+'; font-size: 18px; font-weight: bold; position: absolute; top: 50%; right: 4px; transform: translate(-0%, -50%); -o-transform: translate(-0%, -50%); -ms-transform: translate(-0%, -50%); -moz-transform: translate(-0%, -50%);}
      .obl-submenu-row .obl-submenu-nav .titleHead.submenu-open ~ ul { display: block; padding: 0px 15px;}
      .obl-submenu-row .obl-submenu-nav .titleHead.submenu-open ~ ul>li { line-height: 14px;}
      .obl-submenu-row .obl-submenu-nav .titleHead.submenu-open:before{ display:none;}
      .obl-submenu-row .obl-submenu-nav .titleHead.submenu-open:after { content: '-'; font-size: 18px; font-weight: bold; position: absolute; top: 50%; right: 4px; transform: translate(-0%, -50%); -o-transform: translate(-0%, -50%); -ms-transform: translate(-0%, -50%); -moz-transform: translate(-0%, -50%);}
      .obl-submenu-row .obl-submenu-nav .titleHead a { color: #424242; padding-bottom: 0px; display: block; font-size: 14px; font-family: "Poppins-Medium"; text-decoration:none;     padding-right: 15px;}
      .obl-submenu-row .obl-submenu-nav .titleBox ul{ display:none;}
      .obl-submenu-row .obl-submenu-nav .tilecolor ul{ display:none;}
      .nav-row ul.product-menu>li .menu-arrow.sublink-open ~ a { background-color: #151515; color: #fff;}
      .nav-row ul.product-menu>li .menu-arrow.sublink-open { background-color: #151515;}
      .nav-row ul.product-menu>li .menu-arrow.sublink-open i{ color: #fff;}
      .obl-submenu-row .obl-submenu-nav .titleHead.submenu-open a { color: #ffcb08;}
      .obl-submenu-row .obl-submenu-nav .tilecolor ul li { width: 23%; display: inline-block; margin: 0px 0px 10px; vertical-align: top;}
      .obl-submenu-row .obl-submenu-nav .tilecolor ul li .swatch { width: 30px; height: 30px;}
      .obl-submenu-row .obl-submenu-nav .tilecolor ul li span{ font-size:12px;}
      .obl-submenu-row .obl-submenu-nav .titleBox .header-view-all-tiles a { font-size: 14px; color: #424242;}
      /********************************************************** Mobile Menu CSS ****************************************************/
      .custom-head-mobile{ display:block;}
      .custom-head-mobile { display: flex; width: calc(100% - 180px); align-items: center; justify-content: right;}
      .custom-head-mobile .custom-head-col { padding-left: 12px;}
      .custom-head-mobile .custom-head-col.cameraUpload-bx button { border: none; background-color: transparent;}
      .custom-head-mobile .custom-head-col img{ height:22px;}
      .nav-row ul.product-menu>li .menu-arrow.sublink-open:after { border-right: 1px solid #fc0; border-bottom: 1px solid #fc0;}
      .productpara {width: 320px;}
      .productslider {width: calc(100% - 340px);}
      .productslider .thumbnail-bx { max-width: 70px;}
      .product-img-view { width: calc(100% - 90px);}
      .product-img-view .my-room-btn{height: 45px; line-height: 45px; font-size: 16px;}
      .productpara h3 { font-size: 25px; }
      .prodeatilcard .btn.btn-outline-success{font-size: 16px;}
      .productpage_title span{font-size: 25px;}
      .title_h3 h3:before{left: 0;}
      .title_h3 h3:after{right: 0;}
      .pro_about.Pro_Other p{width: 100%;}
      .pro_similartiles .productpage_title,.pro_youmayalso .productpage_title{margin-bottom: 20px;}
      .three_d_frame div { text-align: center; width: 49%; margin-bottom: 20px; }
      .mini_cart{top: 60px;}
      .mini_cart:before{right: 80px}
      .signature-company-showrooms .signature_gallery {column-count: 2; }
      .exports_project_page .rowSection .row{display: unset;}
      .exports_project_page .rowSection .row .col{width: 100%;}
      .exports_project_page .rowSection .row .col.text .desContant{margin-bottom: 30px;}
      .exports_project_page .rowSection .row .col.text .desHead{padding-left: 0 !important;}
      .exports_project_page .rowSection .row .col.text .desBorder{margin-left: 0 !important;}
      .exports_project_page .rowSection .row .col.text .desContant{padding: 0 !important;}
      
      .main-sidebar.open-filter .filter-overlay-bx { width: calc(100% - -30%); height: calc(100% - -100%); background-color: #00000063; position: absolute;  left: 0px;  top: 0px;  z-index: -1; display: none;}
      .main-sidebar.open-filter:before { content: ''; width: 100%; height: 100%; background-color: #000000ad; display: block; position: fixed; top: 0px; left: 0px; z-index: -1; }
      .download_catalog_page { padding: 30px 0;}
      .download_catalog_page nav{ top:50px;}
      .dr_download_data .tab-content{ top:60px;}



  }
  
  
  
  
  
  @media screen and (max-width: 767px)
  {
      .footer-top.ft-bottom .foot-col .maplocate img { height: 30px;}
      .obl-blog-row .blog-article { width: calc(100% - 0px); padding:0px 0px 30px 0px;}
      .obl-blog-row .blog-aside { width: 100%;}
      .productslider { width: 100%; float: inherit; margin: 0; }
      .productpara { width: 100%; float: inherit; margin-top: 40px;}
      .product-left { position: inherit; top: unset; }
      .product-img-view{margin-bottom: 0;padding-left: 0;}
      .product-img-view { width: 100%; }
      .productslider .thumbnail-bx { display: none; }
      .title_h3 h3 { padding: 0 30px; }
      .title_h3 h3:before,.title_h3 h3:after{width: 30px;}
      .product-left img { margin: 0 auto; }
      .product_desktop_view_slider {display: none; }
      .product_mobile_view_slider{display: block;position: relative;}
      .product-img-view img {height: 400px;}
      .product-img-view .my-room-btn{border-radius: 0;bottom: -35px;left: 5px;right: 5px;}
      .product_mobile_view_slider .slick-dots { bottom: 55px !important; }
      .product_mobile_view_slider .my-room-btn { display: block; position: absolute; border: 1px solid #d5d5d5; padding: 5px 0; left: 10px; right: 10px; bottom: 0; }
      .product_mobile_view_slider .my-room-btn:hover{background-color: #0b9444; color: #fff;border-color: #0b9444;} 
      .our_stores_data, .our_stores_img { width: 100%;}
      .our_stores_data h3 {    margin-bottom: 10px !important;    }
      .our_stores_img {  margin-top: 15px;    }
      .why_signature_img {        width: 100%;        order: 2;    }
      .why_signature_data {     width: 100%;        order: 1;    }
      .signature_company_tabs .nav-tabs {       width: 100%;        margin-right: 0px;    }
      .signature_company_tabs .tab-content {        width: calc(100% - 0px);    }
      .signature-company-showrooms .tab_data_address .address_data, .signature-company-showrooms .tab_data_address .address_data_map {        width: 100%;    }
      .address_data_map {        margin-top: 15px;    }
      .appointment_book{ padding: 30px 15px;}
      .exports_project_page h2 {margin: 30px 0 15px;}
      .exports_project_page h2 strong span, .exports_project_page h2 strong, .exports_project_page .awards-section h2, .exports_project_page h2{font-size: 20px !important;}
      .exports_project_page .obl-makes-section { padding: 20px 0 0; }
      .obl-makes-col .makes-text h4{font-size: 16px;}
      .exports_project_page .client-slider div{width: 49%;}
      .exports_project_page iframe{height: 300px;}
      .exports_project_page .export-enquiry .export-enq-bx{padding: 30px 0;}
      .exports_project_page .export-enquiry h3{font-size: 20px !important; margin-bottom: 10px;}
      .bread-crumb-row ul li a{ font-size: 12px !important;}

      .tile-selector-card { width: 48%;}
      .tile-selector-card:nth-child(3n+2){ margin-left: 0%; margin-right: 0%;}
      .tile-selector-card:nth-child(odd){ margin-right: 2%;}
      .tile-selector-card:nth-child(even){ margin-left: 2%;}
      .tile-selector-card .tile-selector-card-img img { height: 240px;}
      .color-tile-row .tile-selector-card { width: 31.2%; margin: 1% !important;}

      .tile-selector-main .tile-selector-wrap .sub-tiles-b { width: 48%;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(3n+2){ margin-left: 0%; margin-right: 0%;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(odd){ margin-right: 2%;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(even){ margin-left: 2%;}
      .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height:240px}

      .style-tiles-section .category-menu .slick-arrow{ display: block !important;}
      .style-tiles-section .category-menu .slick-prev { left: 15px; width: 30px; height: 30px;}
      .style-tiles-section .category-menu .slick-prev:before { width: 12px; height: 12px; border-right: 3px solid #fff; border-top: 3px solid #fff; margin-left: 5px; }
      .style-tiles-section .category-menu .slick-next { right: 15px; width: 30px; height: 30px;}
      .style-tiles-section .category-menu .slick-next:before { width: 12px; height: 12px; border-right: 3px solid #fff; border-top: 3px solid #fff; margin-right: 5px;}
      .cataloguesearch_page_mobile_view .ais-InfiniteHits-item a { height:220px; padding-bottom: 40px;}
      .cataloguesearch_page_mobile_view .ais-InfiniteHits-item div { padding: 8px 0 !important;}
	  
	  .error-text-bx .home-btn {font-size: 18px!important; padding: 12px 25px !important;}
		.error-text-bx .home-btn svg { font-size: 18px !important;          height: auto !important;}
  }

  @media screen and (max-width: 639px)
  {
    .tile-selector-card { width: 100%;}
    .tile-selector-card:nth-child(3n+2){ margin-left: 0%; margin-right: 0%;}
    .tile-selector-card:nth-child(odd){ margin-right: 0%;}
    .tile-selector-card:nth-child(even){ margin-left: 0%;}
    .tile-selector-card .tile-selector-card-img img { height: auto;}
    .color-tile-row .tile-selector-card { width: 48%; margin: 1% !important;}

    .tile-selector-main .tile-selector-wrap .sub-tiles-b { width: 100%;}
    .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(odd){ margin-right: 0%;}
    .tile-selector-main .tile-selector-wrap .sub-tiles-b:nth-child(even){ margin-left: 0%;}
    .tile-selector-main .tile-selector-wrap .sub-tiles-b .tiles-img-thumb img{ height:auto;}

  }
  
  @media screen and (max-width: 479px)
  {
  .custom-head-mobile .custom-head-col { padding-left: 5px;}
  .title_h3 { text-align: center; margin: 25px 0 60px;}
  .\33 d_virtual_page ol.breadcrumb { margin-top: 20px; }
  .product-img-view img { height: 300px; }
  .three_d_frame div { text-align: center; width: 100%; margin-bottom: 15px; }
  .three_d_frame {margin: 60px 0 30px;}
  .mini_cart { width: 300px;}
  .prolookdata { width: 23%; text-align: center; margin-right: 5px; }
  .color-tile-row .tile-selector-card-data p .color-tile ~ span { padding-left: 5px;}
  .color-tile-row .tile-selector-card-data p .color-tile { width: 35px; height: 35px;}
  
  
  }
  /*********************************************************** Category Page CSS ***************************************************/
  .bread-crumb-row { padding: 15px 0px;}
  .bread-crumb-row ul{ list-style:none; padding:0px; margin:0px;}
  .bread-crumb-row ul li{ padding-right:10px; margin-right:10px; position:relative; font-size:12px; color:#828282; font-family: "Poppins-Regular"; display:inline-block;}
  .bread-crumb-row ul li a{ color:#4F4F4F; font-family: "Poppins-Regular"; }
  .bread-crumb-row ul li:before { position: absolute; top: 9px; right: 0px; content: ''; display: inline-block; width: 7px; height: 7px; border-right: 1px solid #8d8d8d; border-top: 1px solid #8d8d8d; transform: rotate(45deg);}
  .bread-crumb-row ul li:last-child:before{ display:none;}
  .breadcrumb-wrap .container{ position:relative;}
  .product-share { position: absolute; top: 50%; right: 15px; transform: translate(-0%, -50%); -o-transform: translate(-0%, -50%); -ms-transform: translate(-0%, -50%); -moz-transform: translate(-0%, -50%);}
  .product-share ul{ list-style:none; padding:0px; margin:0px; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items:center;}
  .product-share ul li{ display:inline-block; padding-left:10px; color:#828282; font-family: "Poppins-Bold";}
  .product-share ul li a { width: 30px; height: 30px; display: inline-block; text-align: center; line-height: 30px; border-radius: 50%; background-color: #828282;}
  .product-share ul li a i { color:#fff;}
  .product-share ul li a:hover{ background-color:#0B9444;}
  .amshopby-filter ul { display: flex; flex-flow: wrap; align-items: center; margin: 0px; padding: 0px 150px 0px 0px; position: relative;}
  .amshopby-filter ul li{ display: inline-block; padding: 0 40px 0 10px; margin: 0 10px 13px 0; background-color: #e9e9e9; border: 1px dashed #929292; border-radius: 20px; line-height: 27px; position: relative;}
  .amshopby-filter ul li button{ right: 10px; left: auto; top: 6px; height: 15px; line-height: 15px; width: 15px; position: absolute; border: none; background-color: transparent;}
  .amshopby-filter button.clear-all-btn { color: #434343; border: none; position: absolute; top: 7px; right: 0px; background-color: transparent;}
  
  
  
  .category-view{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding:0px 0px 50px;}
  .category-view .main-sidebar{ width: 350px;}
  .pro-filter-row { background-color: #f6f6f6; position: sticky; top: 50px;}
  .pro-filter-row>ul {   margin: 0;   height: calc(100vh - 50px);  overflow-y: auto !important;  padding: 0 0 200px;  width: 75px; overflow: hidden;  background-color: #ededed;}

  .pro-filter-row>ul::-webkit-scrollbar { width: 5px;}
  .pro-filter-row>ul::-webkit-scrollbar-track { background: #f1f1f1;}
  .pro-filter-row>ul::-webkit-scrollbar-thumb { background: #888; }
 .pro-filter-row>ul>li { width: 75px; text-align: center; background-color:#ededed; padding:5px; cursor: pointer;}
  .pro-filter-row>ul>li .flt-type-img { width: 40px; height: 40px; line-height:40px; margin: 0px auto; background-color: #a5a5a5; border-radius: 50%;}
  .pro-filter-row>ul>li .flt-type-img img{ height:30px;}
  .pro-filter-row>ul>li h6{ font-size: 12px; margin: 3px 0px 0px;}
  .pro-filter-row ul li .filters-by{ display:none; -webkit-transition: all 1s; -moz-transition: all 1s; -ms-transition: all 1s; -o-transition: all 1s;}
  .pro-filter-row ul li .filters-by.open-filter { display: block; -webkit-transition: all 1s; -moz-transition: all 1s; -ms-transition: all 1s; -o-transition: all 1s; position: absolute; top: 0px; left: 75px; width: 275px; background-color: #f6f6f6; padding-bottom: 50px; height: 100%; overflow: auto;}
  .pro-filter-row ul li .filters-by ul{ list-style:none; margin:0px; padding:0px; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding:10px;}
  .pro-filter-row ul li .filters-by ul li{ margin: 0px 2px 5px; width:auto; background-color:transparent; padding:0px;}
  .pro-filter-row ul li .filters-by ul li{ font-family: Poppins-Regular; padding: 3px 10px; display: inline-block; color: #373737; border: 1px solid #939393; border-radius: 20px; background-color: #fff;}
  .pro-filter-row ul li .filters-by ul li:hover{ background-color: #898989; color:#fff;}
  .pro-filter-row ul li .color-row>li { padding: 0px !important; border: none !important; text-align: center; margin: 0px 4px 5px !important;}
  .pro-filter-row ul li .color-row>li:hover { background-color: transparent !important; color: #373737 !important;}
  .pro-filter-row ul li .color-row>li .color-option { margin: 0 auto !important;  height: 40px; width: 41px; display: block; background-size: cover; background-repeat: no-repeat; float: none; border-radius: 50%; border: 2px solid #000;}
  .pro-filter-row ul li .color-row>li.Grey .color-option { background-color: #b4b4b4;}
  .pro-filter-row ul li .color-row>li.Brown .color-option { background-color: #865340;}
  .pro-filter-row ul li .color-row>li.Beige .color-option { background-color: #c9b194;}
  .pro-filter-row ul li .color-row>li.Cream .color-option { background-color: #ede3c4;}
  .pro-filter-row ul li .color-row>li.Blue .color-option { background-color: #2b70f1;}
  .pro-filter-row ul li .color-row>li.White .color-option { background-color: #FFFFFF;}
  .pro-filter-row ul li .color-row>li.Black .color-option { background-color: #040404;}
  .pro-filter-row ul li .color-row>li.Green .color-option { background-color: #3dc72c;}
  .pro-filter-row ul li .color-row>li.Purple .color-option { background-color: #ad0af6;}
  .pro-filter-row ul li .color-row>li.Ivory .color-option { background-color: #eadeba;}
  .pro-filter-row ul li .color-row>li.Pink .color-option { background-color: #f6a9e8;}
  .pro-filter-row ul li .color-row>li.Red .color-option { background-color: #FF0000;}
  .pro-filter-row ul li .color-row>li.Yellow .color-option { background-color: #feca2d;}
  .pro-filter-row ul li .color-row>li.Sandune .color-option { background-color: #d8c5b4;}
  .pro-filter-row ul li .color-row>li.Orange .color-option { background-color: #ffa500;}
  .pro-filter-row ul li .color-row>li.Wenge .color-option { background-color: #645452;}
  .pro-filter-row ul li .color-row>li.Multi-colour .color-option {     background: linear-gradient(to right bottom,#cc0076,#a02f87,#629834) !important;}
  .pro-filter-row ul li .color-row>li.Chequered .color-option { background-color: #fff;}
  .pro-filter-row ul li .color-row>li.Silver .color-option { background-color: #C0C0C0;}
  .pro-filter-row ul li .color-row>li.Gold .color-option { background-color: #FFD700;}
  .pro-filter-row ul li .color-row>li.Turquoise .color-option { background-color: #40E0D0;}
  .pro-filter-row ul li .color-row>li.Cotto .color-option { background-color: #E2725B;}
  .pro-filter-row ul li .color-row>li.Terracotta .color-option { background-color: #E2725B;}


  
  .category-view .product-listing-wrap{ width: calc(100% - 350px); padding:0px 50px;}
  .category-sorting{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; align-items: center; padding-top:20px; justify-content: space-between;}
  .category-view .product-listing-wrap .category-title+p { font-size: 16px; line-height: 26px;}
  .category-sorting .tilecountis-bx{ width: 35%; display: flex; align-items: center;}
  .category-sorting .tilecountis-bx ul{ list-style:none; padding:0px; margin:0px; max-width: 120px; width: 100%; display: inline-block;}
  .category-sorting .tilecountis-bx ul li { float: left;  text-align: center;}
  .category-sorting .tilecountis-bx ul li button { border: none; background-color: transparent; line-height: normal; width: 38px; height: 38px; line-height: 38px;}
  .category-sorting .tilecountis-bx ul li button i { font-size: 20px; line-height: 40px;}
  .category-sorting .tilecountis-bx ul li a{ color: #7D7D7D; font-size: 18px;}
  .category-sorting .tilecountis-bx ul li:nth-child(1) { border: solid 1px #c1c1c1; border-radius: 3px 0px 0px 3px;}
  .category-sorting .tilecountis-bx ul li:nth-child(2) { border: solid 1px #c1c1c1; margin:0px -1px;}
  .category-sorting .tilecountis-bx ul li:nth-child(3) { border: solid 1px #c1c1c1; border-radius: 0px 3px 3px 0px; margin-left: 0px;}
  .category-sorting .tilecountis-bx ul li.active{ background-color: #f0f0f0;}
  .category-sorting .tilecountis-bx p{ margin:0px; font-size:14px; font-family: "Poppins-Regular"; padding-left:15px;}
  .category-switch { width: 23%; display: flex; align-items: center; justify-content: center;}
  .category-switch .switch { position: relative; display: inline-block; width: 45px; height: 18px; margin: 0px 10px;}
  .category-switch .switch input {  opacity: 0; width: 0; height: 0;}
  .category-switch .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; transition: .4s; -moz-transition: .4s; -ms-transition: .4s; -webkit-transition: .4s; -o-transition: .4s;}
  .category-switch .slider:before { position: absolute; content: ""; height: 13px; width: 13px; left: 3px; bottom: 3px; background-color: white; transition: .4s; -webkit-transition: .4s; -moz-transition: .4s; -ms-transition: .4s; -o-transition: .4s; }
  .category-switch input:checked + .slider { background-color: green;}
  .category-switch input:focus + .slider { box-shadow: 0 0 1px #2196F3;}
  .category-switch input:checked + .slider:before {transform: translateX(26px); -moz-transform: translateX(26px); -o-transform: translateX(26px); -webkit-transform: translateX(26px); -ms-transform: translateX(26px); }
  .category-switch .slider.round { border-radius: 34px;}
  .category-switch .slider.round:before { border-radius: 50%;}
  .category-view .product-listing-wrap p a { font-size: 16px;}
  
  
  .category-sorting .sorts-bx{ width: 33%; text-align:right; }
  .category-sorting .sorts-bx .form-group{ margin-bottom:0px;}
  .category-sorting .sorts-bx .form-group select.form-control { max-width: 180px; width: 100%; font-size: 14px; border: solid 1px #BDBDBD; font-family: "Poppins-Regular"; padding: 5px 10px; display: inline-block; appearance: auto;}
  .category-sorting .sorts-bx .form-group label { font-weight: unset; margin: 0px; padding-right: 15px; font-family: "Poppins-Regular";}
  .multiple-cate { width: 40%; text-align: center;}
  .multiple-cate label{ margin: 0px 10px; font-weight: unset; font-size: 16px; color: #828282; border: solid 1px #C0C0C0; border-radius: 4px; padding: 4px 8px;}
  .multiple-cate label input[type="checkbox"]{ margin: 0px 5px 0px 0px;}
  
  .product-listing-Three .product-listing-col{ width:32%;}
  .product-listing-Three .product-listing-col:nth-child(3n+2){ margin:0px 2% 20px;}
  
  .product-listing-Two .product-listing-col{ width:49%;}
  .product-listing-Two .product-listing-col:nth-child(odd){ margin-right:1%;}
  .product-listing-Two .product-listing-col:nth-child(even){ margin-left:1%;}
  
  .product-listing-One .product-listing-col{ width:100%;}
  
  .obl-pro-list-row{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding-top:30px;}
  .obl-pro-list-row .product-listing-col{ margin-bottom:20px; border: 1px solid #eee; padding:10px 10px 40px; position:relative;}
  .product-listing-col .cate-pro-figure{ position:relative; }
  .product-listing-col .cate-pro-figure a{ position:relative; overflow:hidden; text-align:center; height: 350px; display: flex; flex-flow: wrap; align-items: center; justify-content: center;}
  .product-listing-col .cate-pro-figure img{ transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s; width: auto; height: auto; max-height: 100%; aspect-ratio: unset;    overflow-clip-margin: unset;    overflow: unset;}
  .product-listing-col .cate-pro-figure img:hover{ transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); opacity:.7}
  .product-listing-col .cate-pro-figure .cam-bx { position: absolute; top: 10px; left: 10px; width: 40px; height: 40px; text-align: center; line-height: 34px; border: 1px solid #bbb;
   border-radius: 50%;}
  .product-listing-col .cate-pro-figure .cam-bx img{ height:25px;}
  .product-listing-col .cate-pro-figure .wishlist-bx { position: absolute; top: 10px; right: 10px; width: 40px; height: 40px; text-align: center; line-height: 40px; border: 1px solid #bbb; border-radius: 50%;}
  .product-listing-col .cate-pro-figure .wishlist-bx a{ font-size:20px; color: #8c8c8c;}
  .product-listing-col .produ-view{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding:15px 0px; align-items:center; justify-content: space-between;}
  .product-listing-col .produ-view .syte-discovery{ font-family: Poppins-Regular; font-size: 12px; height: 32px; line-height: 30px; display: inline-block; padding: 0 6px; border-radius: 4px; background-color: #fff; color: #828282; border: 1px solid #777;}
  .product-listing-col .produ-view a.try-room{ font-family: Poppins-Regular; font-size: 12px; height: 32px; line-height: 30px; display: inline-block; padding: 0 6px; border-radius: 4px; background-color: #0b9444; color: #fff; border: 1px solid #0b9444; text-decoration: none;}
  .product-listing-col .simi-name a { font-size: 16px; color: #828282; font-family: "Poppins-SemiBold";}
  .product-listing-col .produ-price ul{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding:10px 0px; align-items:center; justify-content: space-between; list-style:none; margin:0px; padding:10px 0px; font-size:14px;}
  .product-listing-col .produ-price ul .mrp { width: calc(100% - 90px);}
  .product-listing-col .produ-price ul .best-pri { width: 90px; text-align: right;}

  .product-listing-col .best-pri a{ border: 1px solid #777; padding: 5px 8px; color: #333;}
  .product-listing-col .add-button{ position:absolute; bottom:0px; width:100%; left:0px;}
  .roomvo-stimr.some-class { background: #a5a5a5; border: 1px solid #a5a5a5; font-size: 12px; border-radius: 5px; color: #fff; height: 32px; padding: 0 6px; }
  .product-listing-col .add-button a.add-pro-btn { background-color: #0b9444; color: #fff; display: block; text-align: center; text-decoration: none; font-weight: bold; padding: 3px 10px;}
  .load-more-product { text-align: center; padding: 30px 0; }
  .load-more-product button{ border: solid 1px #008000; color: #008000; font-size: 16px; padding: 0px 30px; height: 45px; display: inline-block; line-height: 42px; border-radius: 5px; background-color: transparent;}
  .category_accordion_data h2{margin: 20px 0;}
  .category_accordion_data h2 span, .category_accordion_data h3 span{font-size: 24px; font-weight: bold; line-height: normal;}
  .category_accordion_data .price-table{margin-bottom: 15px;}
  .category_accordion_data .accordion { margin-bottom: 0px; padding: 0; list-style-type: none; }
  .category_accordion_data .accordion>li { margin-bottom: 7px;}
  .category_accordion_data .accordion li a { font-size: 14px; color: #333; background: #ccc; padding: 10px; display: block; border-radius: 0.15em; margin-bottom: 0px; font-family: "Poppins-Medium"; cursor: pointer;}
  .category_accordion_data .accordion li a:before { content: '>'; float: right; font-size: 20px; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); }
  .category_accordion_data .inner p { font-size: 15px; line-height: 24px; color: #333;}
  .category_accordion_data .inner p:last-child{ margin-bottom: 0px;}
  .category_accordion_data .inner { padding: 0; list-style-type: none; display: none; padding: 10px; border: solid 1px #ccc; border-bottom-right-radius: 0.15em; border-bottom-left-radius: 0.15em; margin-top: -1px;}
  .category_accordion_data .inner.show{display: block;}
  /* .category_accordion_data .inner li{ color: #747474;} */
  .category_accordion_data h2 strong, .category_accordion_data h2 strong span { font-size: 28px; color: #333 !important;}
  .load-more-product button:hover{ border: solid 1px #008000; background-color: #008000; color:#fff;}
  .product-listing-col .produ-view .syte-discovery:before { content: ''; width: 14px; height: 13px; display: inline-block; vertical-align: middle; margin-right: 5px; margin-top: -3px; background: url(../public/find-similer.png) no-repeat center; background-size: contain;}
  .roomvo-stimr.some-class:before{content: ''; background: url(../public/try.png) no-repeat center; width: 19px; height: 19px; background-size: contain; display: inline-block; vertical-align: middle; margin-right: 5px; margin-top: -3px;}
  .tile-content-row { padding-top: 30px; }
  .category-section a, .category-section a span {color: #0d6efd;}
  .tile-content-row h2{ font-size: 28px; margin: 30px 0px 15px; color: #000; font-family: "Poppins-Regular";}
  .tile-content-row p{ font-size: 1.1em; margin: 0px 0px 15px; color: #000; line-height: 24px;}
  .tile-content-row ol, .tile-content-row ul{ margin:0px 0px 15px 18px;}
  .tile-content-row ol li, .tile-content-row ul li{ font-size: 1.1em;  color: #000; line-height: 28px;}
  .tile-content-row ol li a, .tile-content-row ul li a{ font-size: 1.1em; margin: 0px 0px 15px; color: #3366ff;}
  .tile-date-table { max-width: 1150px; width: 100%; } 
  .tile-date-table thead tr th { font-family: "Poppins-SemiBold"; text-align: center; padding: 8px 10px; background-color:#f1f1f1; }
  .tile-date-table tbody tr td { padding: 8px 10px; text-align: center;  font-size: 14px; vertical-align:top;}
  .tile-date-table tbody tr td a { color: #3366ff;}
  .tile-date-table tbody tr td p { margin:0px;}
  .three-col-table tr th, .three-col-table tr td{ width:33%;}
  .two-col-table tr th, .two-col-table tr td{ width:50%;}
  
  .tile-related-blog{ padding-bottom:50px;}
  .tile-related-blog .title { text-align: center; margin-bottom: 30px;}
  .listing-Wrap { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; }
  .listing-Wrap .listing-col{ width:49%; margin-bottom:20px; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; border:solid 1px #ccc;}
  .listing-Wrap .listing-col:nth-child(odd){ margin-right:1%;}
  .listing-Wrap .listing-col:nth-child(even){ margin-left:1%;}
  .listing-Wrap .listing-col .listingImg{ width:160px;background-color: #ddd;}
  .listing-Wrap .listing-col .listingText{ width:calc(100% - 160px); padding: 30px 15px 30px 30px;}
  .listing-Wrap .listing-col .listingText .listingHead { padding: 10px 0px 15px;}
  .listing-Wrap .listing-col .listingText .listingHead a div{ font-size: 20px; color: #000; font-family: "Poppins-Medium";}
  .listing-Wrap .listing-col .listingText p{ font-size: 1.1em; margin: 0px; color: #000; line-height: 22px;}
  .listing-Wrap .listing-col .listingText .listingButton a{ border: solid 1px #777; padding: 0px 20px; height: 40px; display: inline-block; line-height: 38px; border-radius: 5px; color: #828282; margin-top: 15px;}
  .listing-Wrap .listing-col .listingText .listingButton a:hover{ border: solid 1px #008000; background-color: #008000; color:#fff;}
  
  .filter-by-mobile{ position: fixed; bottom: 0px; width: 100%; justify-content: space-between; background-color: #202020; z-index: 2; align-items: center; display: none;}
  .filter-by-mobile .locaton-custom { width: 50%; text-align: center; border-right: solid 1px #fff; padding: 0px 0px;}
  .filter-by-mobile .locaton-custom select { background-color: transparent; color: #fff; border: none; appearance: none; -webkit-appearance: none; -moz-appearance: none; max-width: 100%; text-align:center; margin: 0px auto;}
  .filter-by-mobile .filter-col { width: 50%; text-align: center;  cursor: pointer; }
  .filter-by-mobile .filter-col .sort-filter-col{ max-width: 100%; margin: 0px auto; color: #fff; padding: 10px 0px;}
  .loader {    position: fixed;    width: 100%;    height: 100%;    background-color: #00000069;    top: 0px;    left: 0px; z-index: 9;}
  .loader .css-1hhqbne {   position: absolute;    top: 50%;    left: 50%;    width: 100px;    height: 100px;    transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%);}
  .category-section .category-view table p { margin-bottom: 0; }
  .category-section .category-view table tr td { border: 1px solid #cbcbcb; padding: 8px 10px; }
  .category_accordion_data table { max-width: 1200px !important; width: 100% !important;    table-layout: fixed; height: auto !important;   border: none !important;}
  .category_accordion_data table tr:nth-child(1) td { background-color: #f1f1f1;}
  .category_accordion_data .inner>li { margin-bottom: 7px;}
  .category_accordion_data .inner>li:last-child{ margin-bottom: 0px;}
  .category_accordion_data h3 span strong{font-size: 24px;}
  .category_accordion_data p span a span{color: #0d6efd !important;}



  /*********************************************************** Category Page CSS ***************************************************/
  
  
  /********************************************************** Home Page CSS ****************************************************/
  h1{ font-size:40px; font-family: "Poppins-SemiBold"; color:#848484; margin: 0; padding: 0; line-height:1.5;}
  h2{ font-size:34px; font-family: "Poppins-SemiBold"; color:#848484; margin: 0; padding: 0; line-height:1.5;}
  h3{ font-size:30px;  font-family: "Poppins-SemiBold"; color:#828282; margin: 0; padding: 0; line-height:1.5;}
  h4{ font-size:26px;  font-family: "Poppins-SemiBold"; color:#828282; margin: 0; padding: 0; line-height:1.5;}
  img{ max-width:100%;}
  p{ font-size: 18px; color: #747474;line-height:28px; margin-bottom:0px; font-family: "Poppins-Regular"; }
  a{text-decoration:none; transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s;}
  a:hover, a:focus, input:focus, select:focus, textarea:focus{text-decoration:none !important;outline:none;box-shadow:none !important;}
  img{ max-width:100%;}
  .default-btn{ background-color:#0F9243; border:solid 1px #0F9243; color:#fff; Padding :12px 17px; display:inline-block; border-radius:4px; font-family: "Poppins-SemiBold"; font-size:20px;}
  .default-btn:focus{ color:#fff;}
  .default-btn:hover{ background-color:transparent; border:solid 1px #0F9243; color:#0F9243;}
  .default-btn:hover label{ color: #000;}
  .default-btn label{ color: #fff;}
  
  .style-tiles-section .container { max-width: 100% !important; padding: 0px 20px;}
  section.home_trialbook { padding: 20px;}

  .hp-otb-pro { padding: 40px 0px; background-color: #f3f3f4;}
  .hp-spotlight-bx { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; margin: 0px !important; align-items: center;}
  section.home_trialbook.hp-otb-pro .hp-spotlight-bx{margin: 0 !important; }
  .hp-spotlight-bx .otb-pro-fig { width: 40%;}
  .hp-spotlight-bx .otb-pro-fig video { vertical-align: bottom;}
  .hp-spotlight-bx .otb-pro-content { width: 60%; padding: 15px; position: relative;  align-items: center; text-align: center; max-width: 800px; margin: 0px auto;}
  .hp-spotlight-bx .otb-pro-content span{ font-size: 55px; font-family: "Poppins-SemiBold"; color: #0f9243; margin: 0; padding: 0; line-height: 1.5;}
  .hp-spotlight-bx .otb-pro-content .pointer-text { color: #333; font-size: 40px; line-height: normal; margin: 15px 0px 30px;}
  .our-client a.indus-btn { max-width: 288px; width: 100%; display: block; margin: 30px auto 0px; text-align: center; font-family: "Poppins-SemiBold"; border: solid 1px #656565; border-radius: 4px; font-size: 18px; color: #656565; font-family: "Poppins-SemiBold"; Padding :12px 17px;}
  .our-client a.indus-btn:hover{ background-color:#0F9243; border: solid 1px #0F9243; color: #fff;}
  .hp-spotlight-bx .otb-pro-content .otp-pr-cont-figure { position: absolute; right: 20px; bottom: 0px; max-width: 30%; width: 100%; display: none;}
  .hp-spotlight-bx .otb-pro-content .tiger-mahendra-sir{ display: none;}


  .style-tiles-section { background-color: #fff; padding-top: 22px; margin-bottom: -7px}
  .style-wrap .styletiles-slider{ margin:0px !important;}
  .styletiles-slider .slick-list{ padding:0px !important;}
  .styletiles-slider .styletiles-bx { position: relative;}
  .styletiles-bx .style-content { position: absolute; top: 150px; left: 0px; width: 100%;}
  .styletiles-bx .style-content h4{ font-family: "SnellBT-Bold"; font-size: 66px; margin: 0px; padding-left: 20px; color: #000;}
  .style-tiles-section .slick-dots { width: 100%; bottom:40px; left: 0px; display: flex !important; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; justify-content: center; align-items: center;}
  .style-tiles-section .slick-dots li { display: inline-block; width: 20px; height: 20px; border: solid 1px #5D4D36; border-radius: 50%; transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s; margin:0px 15px;}
  .style-tiles-section .slick-dots li button:before{ display:none;}
  .style-tiles-section .slick-dots li.slick-active{ width:30px; height:30px; transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s;}
  .styletiles-bx-item img{ width:100%;}
  .styletiles-bx video { height: 100%;}
  
  
  
  .trending-products-section { background-color: #ededed; padding:60px 0px 30px; border-bottom: solid 2px #989898;}
  .trending-products-section h1{ text-align:center; padding-bottom:15px; font-size: 28px !important;}
  .trending-products-section h1+p { text-align:center;}
  .trending-slider, .trending-products-section .slick-slider{ padding-top:30px;}
  .treding-t{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; padding-top:30px;}
  .treding-t>div{ width:20%;}
  .trending-slider .slick-list{ padding:0px !important;}
  .trending-slider .slick-slide{ padding:0px 10px;}
  .treding-t .t-item, .trending-products-section .t-item{ width:85% !important; margin:0px auto 50px; text-align:center;}
  .treding-t .t-item img, .trending-products-section .t-item img { border-radius: 50%; margin-bottom: 20px; overflow: hidden;}
  .treding-t .t-item .txt, .trending-products-section .t-item .txt { color: #555; font-size: 18px;}
  section.same_look { background-color: #f3f3f3; border-bottom: solid 0px #989898;}
  .hp-features { background: #f5f5f6; padding: 50px 0px; border-bottom: solid 0px #989898;}
  .hp-features h1 { font-size: 28px !important; margin-bottom: 15px;}
  .hp-features p{ text-align: center; font-size: 16px;}
  .hp-features h4{ text-align:center; padding-bottom:35px;}
  .features-row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; margin: 0px auto; padding-top: 20px;}
  .features-row .features-col { width: 18%; text-align: center; padding: 15px 10px 20px; margin: 0px 1%; border: solid 1px #ccc; border-radius: 10px;}
  .features-row .features-col:hover { background-color: #fff;}
  .features-row .features-col .f-fig { background-image: url(/ficon-sprite.png); background-repeat: no-repeat; width: 70px; height: 70px; margin: 0px auto;}
  .features-row .features-col .f-text { font-size: 18px; color: #747474; font-family: "Poppins-Regular";}
  .features-row .features-col .f-fig.exp { background-position: -4px 0px;}
  .features-row .features-col .f-fig.interna { background-position: -64px 0px;}
  .features-row .features-col .f-fig.client { background-position: -88px 0px;}
  .features-row .features-col .f-fig.design { background-position: -175px 0px;}
  .features-row .features-col .f-fig.stores { background-position: -347px 0px;}
  .features-row .features-col .f-fig.visualizer { background-position: -260px 0px;}
  
  .pattern-section { padding: 60px 0px; background-color: #F3F3F4;}
  .pat-color-wrap { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
  .pat-color-wrap .pat-color-content{ width:50%; padding-right:25px;}
  .pat-color-mobile-fig{ display:none;}
  .pat-color-wrap .pat-color-content p{ margin: 50px 0px;}
  .pat-color-wrap .pat-color-figure{ width:50%; padding-left:25px; text-align:right;}
  .signature-company{ display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding-bottom: 60px;}
  .signature-company-left-section { width: 50%;}
  .signature-company-right-section { width: 50%; text-align: center; padding-left: 15px; }
  .signature-company-right-section .video-iframe-col { max-width: 270px; width: 100%; margin: 0px auto; text-align: left;}
  .signature-company-right-section img { width: auto !important; height: auto !important;}
  .signature-company-right-section .video-iframe-col strong{ color: #848484;}
  
  
  section.signature_iframe.signature-section {
    border-bottom: solid 2px #989898;
}
  .testimonials-section{ padding: 60px 0px; border-bottom: solid 2px #989898;}
  .testimonials-section h2{ text-align:center; }
  .review-row{position:relative}
  .quote-bx{width:70px;height:70px;border-radius:50%;background-color:#fff;padding:15px;position:absolute;top:-35px;left:35px;z-index:1;display:flex;flex-flow:wrap;align-items:center}
  .quote-bx img{max-width:100%}
  .testimonials-section .slick-slider .slick-list { padding: 60px 0px 0px !important;}
  .testimonials-section .slick-slide { padding: 0 15px;}
  .review-row .review-text{padding:60px 35px 35px;background-color:#f3f3f4;position:relative}
  .review-row .review-text:before{content:'';width:0;height:0;border-left:20px solid transparent;border-right:20px solid transparent;border-top:20px solid #f3f3f4;display:block;position:absolute;bottom:-20px;left:30px}
  .review-row .review-text p{font-size:20px;color:#747474;line-height:30px;margin:0}
  .review-row .author-bx{padding:30px 35px 0}
  .review-row .author-bx h6{font-size:18px;margin:0 0 12px;font-family:"Poppins-SemiBold";color:#626262}
  .review-row .author-bx p{font-size:16px;color:#848484;margin-bottom:0}
  .testimonials-section .slick-prev{width:35px;height:35px}
  .testimonials-section .slick-prev:before{content:'';display:inline-block;width:18px;height:18px;border-right:3px solid #848484;border-top:3px solid #848484;transform:rotate(-135deg);-webkit-transform:rotate(-135deg);-moz-transform:rotate(-135deg);-ms-transform:rotate(-135deg);-o-transform:rotate(-135deg)}
  .testimonials-section .slick-next{width:35px;height:35px}
  .testimonials-section .slick-next:before{content:'';display:inline-block;width:18px;height:18px;border-right:3px solid #848484;border-top:3px solid #848484; transform:rotate(45deg); -o-transform:rotate(45deg); -ms-transform:rotate(45deg); -moz-transform:rotate(45deg); -webkit-transform:rotate(45deg);}
  
  
  .client-section { padding: 60px 65px; border-top: solid 2px #989898;}
  .our-client h2 { text-align: center; padding-bottom: 70px;}
  .our-client ul { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; margin: 0px; list-style:none;     justify-content: center;     padding: 0px;}
  .our-client ul li { width: 20%; padding: 15px; text-align: center; margin-bottom: 30px;}
  .our-client ul li img{ height:70px; object-fit: contain;}
  .find-dealer-section { background-color: #343836; padding: 60px 0px;}
  .find-dealer-row h2 { text-align: center; color: #fff !important; padding-bottom: 40px;}
  .find-dealer-row form{ max-width:660px; width:100%; margin:0px auto;}
  .find-dealer-row form .form-group{ margin:0px; position:relative;}
  .find-dealer-row form .form-group input{ width: 100%; padding: 0px 230px 0px 20px; height: 80px; font-size: 30px; border: 0px; border-radius: 0px; border-radius: 4px;}
  .find-dealer-row form .form-group .locate-btn { position: absolute; top: 0px; right: 0px; max-width: 215px; width: 100%; height: 80px; border: none; background-color: #0f9243; color: #fff; font-size: 30px; border-radius: 4px; font-family: "Poppins-SemiBold";}
  .find-dealer-row form .form-group .locate-btn:hover{ background-color: #ffcb08; color: #333;}
  .obl-experience-row .obl-matterport-text-col p{ font-size: 20px; margin: 30px 0px;}
  .obl-experience-row{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;flex-flow:wrap;width:100%;padding:60px 0;align-items:center}
  .obl-experience-row .obl-matterport-text-col{width:50%;padding-right:15px;text-align:center}
  .obl-experience-row .obl-matterport-text-col h3{font-size:24px; margin:0 0 15px}
  .obl-experience-row .obl-matterport-text-col a{background-color:#0f9243;color:#fff;display:inline-block; padding:12px 17px;border-radius:4px;border:solid 1px #0f9243;font-size: 18px; font-family: "Poppins-SemiBold";}
  .obl-experience-row .obl-matterport-text-col a:hover{background-color:transparent;color:#0f9243;border:solid 1px #0f9243;text-decoration:none; }
  .obl-experience-row .obl-matterport-video-col{width:50%;padding-left:15px}
  .obl-experience-row .obl-matterport-video-col img{width:100%}
  
  
  .latest-updates-section { padding: 60px 0px; background-color: #f1f1f1; border-bottom: solid 2px #989898;}
  .latest-updates-section h2 { text-align: center; padding-bottom: 50px; }
  .latest-up-row .latest-col { width: 48%;}
  .latest-up-row .latest-col:nth-child(odd){ margin-right:2%;}
  .latest-up-row .latest-col:nth-child(even){ margin-left:2%;}
  .latest-up-row .latest-col h6 { margin: 0 0 15px;}
  .latest-up-row .latest-col h6 a{ font-size: 24px; color: #2d2d2d; font-family: Poppins-Medium;}
  .home-post { width: 50%; padding: 20px; border: 1px solid #d2d2d2; margin-left: -1px; background-color: #fff;}
  .home-post .listingText a.post-tiles { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; margin: 20px 0; color: #2d2d2d; font-family: "Poppins-SemiBold"; font-size: 20px; line-height: normal; }
  .home-post .listingText a.post-tiles div { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; color: #2d2d2d; font-family: "Poppins-SemiBold"; font-size: 20px; line-height: normal; }
  
  .home-post .listingText p{ display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; font-size:14px; line-height:24px;}
  .home-post .listingText .listingButton a { font-size: 18px; margin-bottom: 0; color: #2d2d2d; font-family: "Poppins-SemiBold";}
  .latest-up-row .latest-col .listingImg img{ object-fit: scale-down; height: 300px; width: 100%;}
  
  .home-post { width: 50%; padding: 20px; border: 1px solid #d2d2d2; margin-left: -1px; background-color: #fff;}
  .home-post .listingText a.post-tiles { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; margin: 20px 0; color: #2d2d2d; font-family: "Poppins-SemiBold"; font-size: 20px; line-height: normal; }
  .home-post .listingText p{ display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; font-size:14px; line-height:24px;}
  .home-post .listingText .listingButton a { font-size: 18px; margin-bottom: 0; color: #2d2d2d; font-family: "Poppins-SemiBold";}
  .home-post .listingText .listingButton a:hover, .home-post .listingText .listingButton a:hover i, .home-post .listingText a.post-tiles:hover{ color: #0F9243;}
  .home-post .listingImg a{ display:block; overflow:hidden;}
  .home-post .listingImg a img { transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
  .home-post .listingImg a img:hover{ transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1);}
  /* .signature-company-left-section h2, .signature-company-right-section .video-iframe-col strong, .pat-color-content h2, .our-client h2, .find-dealer-row h2, .latest-updates-section h2{ font-size: 34px; font-family: "Poppins-SemiBold";} */

  .same_look .same_look_wrap { padding: 60px 0; flex-direction: row-reverse;}
  .same_look .same_look_data { padding-right: 25px; }
  .same_look .same_look_data h2{ color: #1E7100 !important;}
  .same_look .same_look_wrap p { margin: 50px 0; font-size: 20px; line-height: 32px; color: #333;}
  .same_look .same_look_data .default-btn label {    margin: 0px;    font-family: "Poppins-SemiBold";    font-size: 20px;}
  .same_look .same_look_data .default-btn:hover label{ color: #0F9243;}
  .same_look .same_look_img { padding-left: 25px; }
  .same_look_img h2, .same_look_img span { display: none; }
  
  
  /********************************************************** Home Page CSS ****************************************************/
  
  
  
  * {
    box-sizing: border-box;
  }
  .bread-crumb.custom.custome-container {
      width: 100% !important;
  }
  
  .sidebar-x.sidebar-main-x.filters-left.newClass {
      position: fixed;
      top: 48px;
  }
  .sidebar-x.sidebar-main-x.filters-left.newClass.bottom {
      position: absolute;
      top: auto;
      left: 0;
  }
  .loading-mask {
      display: none;
  }
  .modals-overlay {
      display: none;
  }	
  .area-wrap {
      float: left;
      width: 100%;
      padding: 2em 0;
  }
  .area-col {
      width: 24%;
      display: inline-block;
      vertical-align: top;
  }
  .area-thumb {
      text-align: center;
      margin: 0px 0px 1em;
  }
  .area-thumb img {
      border: 1px solid #ddd;
      border-radius: 50%;
      max-width: 100px;
  }
  .area-txt {
      text-align: center;
      font-size: 14px;
      color: #333;
      line-height: 120%;
  }
  .area-txt a {
      display: block;
      font-size: 14px;
      color: #333;
  }
  .abt-content a, .abt-content a strong {
      color: #1979c3;
      font-weight: normal;
  }
  .select-location-wrapper {
      background-color: #ffcb08;
      padding: 15px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 1em;
      float: left;
      width: 100%;
  
  }
  .select-location-wrapper span:first-child {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    line-height: normal;
}
.select-location-wrapper .select-wraper input {
    max-width: 250px;
    width: 100%;
    padding: 0px 10px;
    height: 36px;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
  .cat-desc {
      float: left;
      width: 100%;
  }
  .select-location-wrapper span {
      display: inline-block;
  }
  .select-location-wrapper select {
      text-transform: uppercase;
      height: 28px;
      border-radius: 3px;
      box-shadow: 0 1px 5px #555;
      box-sizing: border-box;
      padding: 0 10px;
      min-width: 280px;
  }
  .page-products #panel {
      position: relative;
      float: left;
      width: 100%;
  }
  .page-products #panel2 {
      padding: 0 0 0 15%;
      width: 95%;
      margin-left: 0;
      padding-left: 300px;
      float: left;
  }
  .catalog-category-view .panel .custome-container {
      width: 90%;
  }
  .bread-crumb li {
      display: inline-block;
  }
  .bread-crumb a, .bread-crumb span {
      color: #828282;
      font-size: 13px;
      position: relative;
      padding-right: 5px;
  }
  .bread-crumb span:after{ content: ">"; color: #828282; font-size: 16px; position: absolute; top: -1px; right: 0; }
  .bread-crumb a{padding-right: 14px;}
  .bread-crumb a:after  { content: ">"; color: #828282; font-size: 16px; position: absolute; top: -1px; right: 0; }
  .bread-crumb span:nth-last-child(1):after{display:none}
  .page-products .bread-crumb span {
      display: inline-block;
      margin: 0 0.2em;
      margin-left: 0.2em;
      position: relative;
      line-height: 130%;
  }
  .page-products .bread-crumb span:after {
      content: "";
      color: #000;
      position: absolute;
      right: -1em;
      top: 0;
  }
  #panel2 .category-description {
      padding: 0;
      background: 0 0;
      margin: 0;
      border-bottom: none;
      float: left;
      width: 100%;
  }
  .category-description .g-heading h1 {
      font-size: 30px;
      color: #333;
      margin: 0;
  }
  .category-description .g-heading h1 span::before{
      display: none;
  }
  .abt-content .g-heading {
      
      font-size: 2.3em;
      margin: 1em 0;
      padding: 0;
      line-height: 100%;
      display: block;
      position: relative;
      float: left;
      width: 100%;
  }
  .category-description .g-heading {
      margin: 5px 0;
  }
  .category-description p {
      color: #2d2d2d;
      font-size: 14px;
  }
  .abt-content p {
      
      font-size: 1.1em;
      margin: 0.5em 0;
      line-height: 140%;
  }
  .tilesListingWrapper {
      display: block;
      width: 100%;
      margin: 1em 0;
      float: left;
  }
  .tilesListingWrapper .product-sorting {
      position: static;
      top: 46px;
      background-color: #fff;
      z-index: 2;
      padding: 0;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      display: block;
      width: 100%;
  }
  .tilesListingWrapper .product-sorting {
      z-index: 4;
      margin-top: 0;
  }
  .shorting-items, .tilesListingWrapper .product-sorting .product-sorts {
      width: 25%;
      padding: 0;
  }
  .productListing {
      display: table-cell;
      width: 100%;
      vertical-align: top;
      float: left;
  }
  .secondaryContent {
      float: left;
      width: 100%;
  }
  div#panel21 {
      float: left;
      width: 100%;
  }
  .switch-wrap, .tilesListingWrapper .product-sorting {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border: 0;
  }
  .shorting-items .mode {
      display: flex;
      margin-right: 10px;
      vertical-align: middle;
  }
  .mode-change {
      width: 40px;
      height: 30px;
      display: inline-block;
      padding: 0;
      box-sizing: border-box;
      background-color: #f0f0f0;
      text-align: center;
      border: 1px solid #bdbdbd;
      margin-left: -1px;
      line-height: 26px;
      cursor: pointer;
      transition: all .1s linear;
  }
  .mode-change.active, .mode-change:hover {
      background-color: #dedede;
  }
  .mode-change.three-item {
      border-radius: 3px 0 0 3px;
  }
  .mode-change img {
      max-width: 16px;
  }
  .mode-change.one-item {
      border-radius: 0 3px 3px 0;
  }
  .tilesListingWrapper .product-sorting .numberOf {
      font-family: Poppins-Regular;
      color: #828282;
  }
  .shorting-items #tilecountis, .shorting-items .mode {
      display: inline-block;
  }
  .shorting-items.hide-details, .shorting-items.type-chnage {
      text-align: center;
  }
  .shorting-items.type-chnage span {
      display: inline-block;
      color: #828282;
      vertical-align: middle;
  }
  .shorting-items.type-chnage span {
      display: inline-block;
      color: #828282;
      vertical-align: middle;
  }
  .switch {
      margin: -16px 5px 0;
  }
  .switch input[type=checkbox] {
      width: 0;
      height: 0;
      visibility: hidden;
  }
  .switch label {
      width: 46px;
      height: 23px;
      display: block;
      background-color: #7d7d7d;
      border-radius: 25px;
      position: relative;
      cursor: pointer;
      transition: .5s;
  }
  .switch label:after {
      content: "";
      width: 16px;
      height: 16px;
      background-color: #fff;
      position: absolute;
      border-radius: 70px;
      top: 3px;
      left: 5px;
      transition: .5s;
  }
  .switch input:checked + label {
      background: green;
  }
  .switch input:checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
	  -webkit-transform: translateX(-100%);
	  -moz-transform: translateX(-100%);
	  -ms-transform: translateX(-100%);
	  -o-transform: translateX(-100%);
  }
  .hidden {
      display: none !important;
      visibility: hidden;
  }
  label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
  }
  label[for=hidedetails] {
      margin: auto;
      padding: 0 20px;
      border: 1px solid silver;
      border-radius: 3px;
      height: 35px;
      line-height: 35px;
      color: #828282;
      font-weight: 400;
      cursor: pointer;
      user-select: none;
  }
  input#hidedetails {
      margin: 0 0 0 5px;
      display: inline-block;
      position: relative;
      top: 2px;
      height: 15px;
      width: 15px;
  }
  .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom {
      width: 100%;
  }
  .tilesListingWrapper .product-sorting .product-sorts label select {
      box-shadow: none !important;    
      margin: 0 0 0 auto;
      border-radius: 3px !important;
      height: 35px;
      padding: 0 30px 0 10px !important;
      border: 1px solid silver !important;
      line-height: 35px;
      color: #828282;
      font-weight: 400;
      cursor: pointer;
      background-position: 97% -18px;
      position: relative;
      display: block;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      /* background: url(../static/frontend/colora/colora_home2/en_US/img/select-arrow.png) 90% -18px no-repeat; */
  }
  .filter-close {
      display: none;
  }
  .hide-desktop {
      display: none !important;
  }
  .myproductbox {
      float: left;
      width: 100%;
  }
  .toolbar-products {
      display: none !important;
  }
  .productListing .productBox {
      width: calc(33.33% - 15px);
  }
  .productListing .productBox {
      float: left;
      text-align: center;
      overflow: hidden;
      position: relative;
      -webkit-transition: all .25s ease-in-out;
      -moz-transition: all .25s ease-in-out;
      -o-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;
      margin: 0 15px 2% 0;
      padding: 10px;
      border: 1px solid #eee;
  }
  .productListing .productBox .action.wishlist {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 3;
      min-height: unset;
      height: 34px;
      width: 34px;
      border: 2px solid #bbb;
      border-radius: 50%;
      text-align: center;
      display: block;
      padding: 3px 0;
  }
  em, i {
      font-style: italic;
  }
  .productListing .productBox .action.wishlist i:before {
      content: '';
      height: 26px;
      width: 26px;
      /* background: url(../static/frontend/colora/colora_home2/en_US/images/newheart.png) center no-repeat; */
      background-size: contain;
      display: inline-block;
  }
  .productListing .productBox .syte-discovery {
      float: left;
      clear: left;
  }
  .find-similier.similer-btn:before, .productListing .productBox .syte-discovery:before, .product-info-list .get-best a:before, .page-products .item-share span:before, .product-info-list .item-similier a:before, .productListing .productBox .roomvo-stimr:before {
      content: '';
      width: 14px;
      height: 13px;
      /* background: url(../static/frontend/colora/colora_home2/en_US/images/find-similer.png) no-repeat center; */
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-top: -3px;
  }
  .productListing .productBox .roomvo-container {
      display: inline-block;
      margin-left: 5px;
      vertical-align: top;
      margin-bottom: 20px;
      float: right;
      clear: right;
  }
  .find-similier.similer-btn, .productListing .productBox .syte-discovery, .productListing .productBox .roomvo-stimr {
      font-family: Poppins-Regular !important;
      font-size: 11px !important;
      height: 32px !important;
      line-height: 30px !important;
      width: auto;
      display: inline-block !important;
      vertical-align: middle;
      padding: 0 3px !important;
      border-radius: 4px !important;
      margin: 0.8em 0;
      background-color: #fff !important;
      color: #828282 !important;
      border: 1px solid #777 !important;
  }
  .productListing .productBox .roomvo-stimr:before {
      background-image: url(/media/img/try.png);
      width: 19px;
      height: 19px;
  }
  .mobileonly {
      display: none;
  }
  .product-info-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      clear: both;
  }
  .product-info-list>div.full-width {
      width: 100%;
      text-align: left;
      clear: both;
  }
  .product-info-list>div {
      width: 50%;
      text-align: right;
      margin-bottom: 10px;
  }
  .catalog-topnav.amasty-catalog-topnav {
      display: none;
  }
  .product-info-list>div:nth-child(2n) {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      text-align: left;
  }
  .product-info-list div.p-name strong {
      color: #646464;
      font-family: Poppins-SemiBold;
      font-size: 15px;
  }
  .bgimg{background-image: url(/thankyou.png);}
  .product-info-list>div, .product-info-list>div a {
      font-size: 13px;
      color: #828282 !important;
  }
  .catalog-category-view a.add-cart.action {
      font-size: 13px;
      font-weight: 400 !important;
      background-color: #fff !important;
      color: #828282 !important;
      height: 36px !important;
      padding: 0 10px !important;
      margin: 0;
      border: 0;
      display: contents;
  }
  .catalog-category-view .add-cart.action span {
      border: 1px solid #777 !important;
      padding: 5px 8px !important;
  }
  .productListing .get-best a {
      text-decoration: underline;
  }
  .page-products .get-best a:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/get-best-price.png); */
      width: 19px;
      height: 16px;
  }
  .filters-left .filterBy {
      width: 100% !important;
      padding: 0;
      border: 0;
      border-radius: 0;
      margin: 0;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 1px;
  }
  .filters-left .filterBy .flt-type.active, .filters-left .filterBy .flt-type:hover {
      background-color: #c6c6c6;
      display: block;
      float: left;
      width: 100%;
  }
  .filters-left .filterBy .flt-type {
      text-align: center;
      width: 100%;
  }
  .filter-options .am-ranges {
      overflow-x: hidden;
      overflow-y: auto;
  }
  .filters-left .filterBy .filters label {
      font-family: Poppins-Regular;
      padding: 5px 10px;
      display: inline-block;
      color: #373737;
      border: 1px solid #939393;
      margin: 5px 2px;
      border-radius: 20px;
      background-color: #fff;
      font-weight: lighter;
  }
  /*#filter-color .swatch-layered.colors>div.popular-sparater, .popular-sparater {
      clear: both;
      width: 100% !important;
      margin: 10px 0 !important;
      border-top: 1px solid #ccc !important;
  }*/
  .filters-left .filterBy .filters label a {
      color: #373737;
      text-decoration: none;
  }
  #filter-state-tiles-availability-, .filter-options .count {
      display: none;
  }
  .cBox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
  }
  input[type=checkbox], input[type=radio] {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;
  }
  .page-products .filters-left {
      position: absolute;
      left: 0;
      width: 76px;
      padding: 0;
      background: #ededed;
      top: 0;
  }
  .sidebar-x.sidebar-main-x.filters-left._sticky {
      margin-top: 182px !important;
  }
  .filter-subtitle {
      display: none!important;
  }
  .filters-left .filterBy .flt-type.active+.filters {
      opacity: 1;
      transform: translateX(0);
	  -webkit-transform: translateX(0);
	  -moz-transform: translateX(0);
	  -ms-transform: translateX(0);
	  -o-transform: translateX(0);
  }
  .filters-left .filterBy .filters {
      position: absolute;
      top: 0;
      left: 100%;
      width: 250px;
      background-color: #f6f6f6;
      z-index: 2;
      opacity: 0;
      height: 97%;
      overflow-y: auto;
      border-left: 2px solid #fff;
      transform: translateX(-300px);
	  -webkit-transform: translateX(-300px);
	  -moz-transform: translateX(-300px);
	  -ms-transform: translateX(-300px);
	  -o-transform: translateX(-300px);
	   transition: transform .2s linear;
	   -webkit-transition: transform .2s linear;
	   -moz-transition: transform .2s linear;
	   -ms-transition: transform .2s linear;
	   -o-transition: transform .2s linear;
  }
  .filters-left .filterBy .flt-type:before {
      content: '';
      display: block;
      height: 54px;
      width: 54px;
      margin: 0 auto;
      background-color: #a5a5a5;
      border-radius: 50%;
      background-size: contain;
      margin-bottom: 3px;
      background-repeat: no-repeat;
      background-position: center;
  }
  #filter-size .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/size.png); */
      background-size: 57%;
  }
  #filter-room .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/room.png); */
      background-size: 62%;
  }
  #filter-type .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/type.png); */
      background-size: 57%;
  }
  #filter-design .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/design.png); */
      background-size: auto 64%;
  }
  #filter-color .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/color.png); */
      background-size: calc(100% + 3px);
  }
  #filter-finish .flt-type:before {
      /* background-image: url(../static/frontend/colora/colora_home2/en_US/images/finish.png); */
      background-size: 60% auto;
  }
  .mobileFilters, .sorting, .clearAll, .flt-search, .filters-left .filterBy .flt-type span, .filters-left .actionBtns, .mfc {
      display: none;
  }
  .location-modal .modal-header .modal-title, .share-modal .modal-header .modal-title {
      color: #434343;
      font-weight: 200;
      font-family: Poppins-Regular;
  }
  .location-modal .modal-header .action-close, .share-modal .modal-header .action-close {
      padding: 0;
      background: #f1f1f1;
      border-radius: 0;
      height: 40px;
      width: 40px;
      text-align: center;
  }
  .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%;
  }
  .modal-slide .modal-inner-wrap, .modal-popup .modal-inner-wrap {
      background-color: #fff;
      box-shadow: 0 0 12px 2px rgb(0 0 0 / 35%);
      opacity: 1;
      pointer-events: auto;
  }
  .location-modal .modal-inner-wrap, .share-modal .modal-inner-wrap {
      max-width: 600px;
  }
  .modal-slide._show .modal-inner-wrap, .modal-popup._show .modal-inner-wrap {
	   transform: translate(0,0);
      -webkit-transform: translate(0,0);
	  -moz-transform: translate(0,0);
	  -ms-transform: translate(0,0);
	  -o-transform: translate(0,0);
     
  }
  .modal-popup .modal-inner-wrap {
      margin: 5rem auto;
      width: 75%;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      box-sizing: border-box;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
	  -moz-transform: translateY(-200%);
	  -ms-transform: translateY(-200%);
	  -o-transform: translateY(-200%);
	  
      -webkit-transition: -webkit-transform .2s ease;
      transition: transform .2s ease;
	  -moz-transition: transform .2s ease;
	  -ms-transition: transform .2s ease;
	  -o-transition: transform .2s ease;
  }
  .modal-popup._inner-scroll .modal-content {
      overflow-y: auto;
  }
  .location-modal select, .share-modal input {
      padding: 10px;
      height: auto;
      border-color: #999;
  }
  .modal-popup.modal-slide .modal-footer {
      padding-top: 15px;
      padding-bottom: 15px;
  }
  .abt-content .g-heading {
      
      font-size: 2.3em;
      margin: 1em 0;
      padding: 0;
      line-height: 100%;
      display: block;
      position: relative;
      float: left;
      width: 100%;
  }
  .abt-content .g-heading h2 {
      font-size: 28px;
  }
  .catalog-category-view .abt-content p { font-family: "Poppins-Regular" !important;}
  .catalog-category-view .secondaryContent h2 { font-size: 28px; padding-bottom: 10px;}
  .catalog-category-view .secondaryContent h2 strong { font-weight: unset;}
  .catalog-category-view .secondaryContent p { font-family: "Poppins-Regular" !important;}
  .abt-content .g-heading span {
      padding: 0;
      position: relative;
      display: inline-block;
  }
  .abt-content .g-heading span:before {
      width: 5px;
      height: 0.8em;
      position: absolute;
      left: -0.7em;
      content: "";
      background: #ffcb08;
      top: 0.2em;
  }
  .abt-content p {
      
      font-size: 16px;
      margin: 0.5em 0;
      line-height: 24px;
  }
  .tile-video {
      position: relative;
      width: 100%;
      float: left;
      min-height: 500px;
      margin: 2em 0;
  }
  .tile-video iframe {
      position: absolute;
      width: 100%;
      height: 100%;
  }
  table.price-wrap tr td, table.price-wrap tr th {
      border: 1px solid #ddd;
      padding: 0.5em;
  }
  .price-table table th, .price-table table td {
      font-size: 1.1em;
      vertical-align: middle;
  }
  .price-table p.pricedisclaimer-txt {
      font-size: .9em;
  }
  #category-posts {
      width: 100%;
      float: left;
      margin-top: 30px;
  }
  #category-posts .title {
      text-align: center;
  }
  .listingWrapper {
      width: 100%;
      float: left;
      z-index: 1;
      margin-bottom: 5em;
  }
  .listingWrapper.cat-list-post .listingleftWrap {
      width: 100%;
      padding: 0;
      float: left;
  }
  .listingWrapper .listingleftWrap .listingRow {
      width: 48%;
      float: left;
      border: 1px solid #ccc;
      margin: 20px 0;
      padding-bottom: 1em;
      min-height: 382px;
  }
  .listingWrapper .listingleftWrap .listingRow .listingImg {
      float: left;
      width: 30%;
  }
  .listingWrapper .listingleftWrap .listingRow .listingImg img {
      width: auto;
      max-width: 100%;
  }
  .listingWrapper .listingleftWrap .listingRow .listingText {
      float: right;
      width: 70%;
      padding: 1.8em 2em 0 1em;
      
  }
  .listingWrapper .listingleftWrap .listingRow .listingText .listingDate {
      width: 100%;
      float: left;
      font-size: 14px;
  }
  .listingWrapper .listingleftWrap .listingRow .listingText .listingHead, .listingWrapper .listingleftWrap .listingRow .listingText .listingHead a div {
      width: 100%;
      float: left;
      font-size: 25px;
      line-height: 30px;
      padding: 10px 0 0;
      color: #000;
  }
  .listingWrapper.cat-list-post .listingleftWrap .listingRow .listingText .listingHead a div{
      padding-top: 0;
      font-size: 20px;
  }
  .listingWrapper .listingleftWrap .listingRow .listingText .listingContant, .listingWrapper .listingleftWrap .listingRow .listingText .listingContant a {
      width: 100%;
      float: left;
      padding: 10px 0 0;
      font-size: 16px;
      line-height: 22px;
      color: #000;
      min-height: auto;
  }
  .listingWrapper.cat-list-post .listingleftWrap .listingRow .listingText .listingContant a {
      padding-top: 0;
      font-size: 14px;
  }
  .listingWrapper.cat-list-post .listingleftWrap .listingRow .listingText .listingButton {
      width: auto;
      float: left;
      padding: 20px 0 0;
  }
  .listingWrapper.cat-list-post .listingleftWrap .listingRow .listingText .listingButton a {
      width: auto;
      border-radius: 4px !important;
      margin-bottom: 20px;
      background-color: #fff !important;
      color: #828282 !important;
      border: 2px solid #777 !important;
  }
  .listingWrapper .listingleftWrap .listingRow .listingText .listingButton a {
      display: block;
      text-decoration: none;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      transition: .3s all ease-in-out;
  }
  #load-more-loader a, #load-more-product a {
      min-width: 150px;
      display: inline-block !important;
      width: auto !important;
      padding: 0 30px !important;
      font-family: Poppins-Regular !important;
      font-size: 16px !important;
      height: 42px !important;
      line-height: 40px !important;
      font-weight: 400;
      color: green !important;
      border: 1px solid #777 !important;
      border-radius: 4px;
  }
  div#load-more-product {
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 2em;
  }
  .modal-slide._show, .modal-popup._show {
      visibility: visible;
      opacity: 1;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease;
  }
  .page-products .modal-popup {
      z-index: 10 !important;
  }
  .modal-popup._inner-scroll {
      overflow-y: visible;
  }
  .modal-slide, .modal-popup {
      bottom: 0;
      left: 0;
      min-width: 0;
      position: fixed;
      right: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: visibility 0s .3s,opacity .3s ease;
      transition: visibility 0s .3s,opacity .3s ease;
  }
  .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%;
  }
  .modal-slide._inner-scroll .modal-inner-wrap {
      overflow-y: visible;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
  .modal-popup .modal-inner-wrap {
      margin: 5rem auto;
      width: 75%;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      box-sizing: border-box;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
	  -moz-transform: translateY(-200%);
	  -ms-transform: translateY(-200%);
	-o-transform: translateY(-200%);
      -webkit-transition: -webkit-transform .2s ease;
      transition: transform .2s ease;
	  -moz-transition: transform .2s ease;
	  -ms-transition: transform .2s ease;
	  -o-transition: transform .2s ease;
  }
  
  .modal-slide .modal-inner-wrap, .modal-popup .modal-inner-wrap {
      background-color: #fff;
      box-shadow: 0 0 12px 2px rgb(0 0 0 / 35%);
      opacity: 1;
      pointer-events: auto;
  }
  .modal-slide._show .modal-inner-wrap, .modal-popup._show .modal-inner-wrap {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
	  -moz-transform: translate(0,0);
	  -ms-transform: translate(0,0);
	  -o-transform: translate(0,0);
  }
  .modal-popup .modal-header {
      padding-bottom: 1.2rem;
      padding-top: 3rem;
  }
  .location-modal .modal-header .modal-title, .share-modal .modal-header .modal-title {
      color: #434343;
      font-weight: 200;
      font-family: Poppins-Regular;
      line-height: 110%;
  }
  span.select-wraper {
      float: left;
      width: 100%;
      display: block;
  }
  span.select-wraper select#select-location-pop {
      float: left;
      width: 100%;
      display: block;
  }
  .modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-top: 20px!important;
      padding-bottom: 1.6rem;
  }
  .location-modal .modal-header .action-close span {
      display: none;
  }
  .modal-popup.modal-slide .modal-footer {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 3rem;
      padding-right: 3rem;
  }
  button.close-location {
      border: 2px solid #eee;
      background: transparent;
      padding: 0 20px;
      height: 40px;
      border-radius: 30px;
      font-size: 16px;
  }
  .modal-popup .modal-title {
      padding-bottom: 10px;
      font-size: 26px;
      margin-bottom: 0;
      min-height: 1em;
      word-wrap: break-word;
  }
  .location-modal .modal-header .action-close, .share-modal .modal-header .action-close {
      padding: 0;
      background: #f1f1f1;
      border-radius: 0;
      height: 40px;
      width: 40px;
      text-align: center;
      outline: none;
  }
  .modal-popup .action-close:before, .modal-slide .action-close:before {
      content: "x"!important;
      font-size: 20px;
  }
  .modal-custom .action-close:before, .modal-popup .action-close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 32px;
      color: gray;
      content: '\e616';
      font-family: 'icons-blank-theme';
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: 400;
      overflow: hidden;
      speak: none;
      text-align: center;
  }
  .modal-custom .action-close, .modal-popup .action-close, .modal-slide .action-close {
      background-image: none;
      background: 0 0;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-shadow: none;
      font-weight: 400;
      display: inline-block;
      text-decoration: none;
      position: absolute;
      right: 0;
      top: 0;
  }
  .active-filter-list .amshopby-filter-name, .productListing .productBox a .roomvo-stimr {
      display: none !important;
  }
  .amshopby-filter-current .amshopby-item.item {
      display: inline-block;
      padding: 0 40px 0 10px;
      margin: 0 10px 13px 0;
      background-color: #e9e9e9;
      border: 1px dashed #929292;
      border-radius: 20px;
      line-height: 27px;
  }
  .amshopby-filter-current .amshopby-item.item {
      align-items: center;
      position: relative;
  }
  .amshopby-filter-current .amshopby-remove {
      right: 10px;
      left: auto;
      top: 6px;
      height: 15px;
      width: 15px;
  }
  .amshopby-filter-current .amshopby-remove {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
  }
  .tilesListingWrapper .product-sorting .amshopby-filter-current .amshopby-items {
      display: flex;
      flex-flow: wrap;
      align-items: center;
  }
  .amshopby-filter-current .amshopby-items {
      padding: 0;
  }
  .amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
      background-color: #434343;
  }
  .amshopby-filter-current .amshopby-remove:before {
      transform: rotate(-45deg);
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -ms-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
  }
  .amshopby-filter-current .amshopby-remove:after {
      transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
  }
  .amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #757575;
      content: '';
  }
  .active-filter-list+.active-filter-list, .filters-left .active-filter-list {
      display: none;
  }
  .am-swatch-wrapper a.am-swatch-link input {
      display: none;
  }
  .mobileFilters, .sorting, .clearAll, .flt-search, .filters-left .filterBy .flt-type span, .filters-left .actionBtns, .mfc {
      display: none;
  }
  .product-sorting .active-filter-list>.filtered {
      display: none!important;
  }
  .block-actions.filter-actions {
      text-align: right;
  }
  .block-actions.filter-actions .action {
      position: relative;
      padding-right: 23px;
      display: inline-block;
  }
  .active-filter-list a.filter-clear {
      color: #434343 !important;
  }
  .active-filter-list a.filter-clear span {
      position: relative;
      top: 6px;
  }
  .product-sorting .active-filter-list {
      width: 100%;
      margin-bottom: 20px;
      order: -200;
  }
  .active-filter-list, .active-filter-list a {
      font-size: 14px;
      color: #434343;
  }
  .block-actions.filter-actions .action:before {
      transform: rotate(-45deg);
	   -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -ms-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
  }
  .block-actions.filter-actions .action:after {
      transform: rotate(45deg);
	   -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
  }	
  .items {
      margin: 0;
      padding: 0;
      list-style: none;
  }
  .block-actions.filter-actions .action:after, .block-actions.filter-actions .action:before {
      position: absolute;
      width: 16px;
      height: 1px;
      background-color: #434343;
      content: '';
      right: 0;
      top: 16px;
  }
  .filters-left .filterBy .filters .cBox.flt, .filters-left .filterBy .filters .cBox.flt a, .filters-left .filterBy .filters label.cBox.ff:hover, .filters-left .filterBy .filters label:hover a {
      background: #898989;
      color: #fff !important;
  }
  .filters-left .filterBy .filters label a.with-icon {
      display: grid;
      grid-template-columns: .4fr 1fr;
      align-items: center;
  }
  svg:not(:root) {
      overflow: hidden;
  }
  .size-box-img {
      display: inline-block;
      max-width: 60px;
      vertical-align: middle;
      background: #c4c4c4;
      max-height: 20px;
      margin-right: 10px;
  }
  .filters-left .filterBy .checkmark {
      display: none;
  }
  #filter-color .swatch-layered.colors>div a {
      display: block;
      float: left;
      width: 100%;
      text-align: center;
  }
  #filter-color .swatch-layered.colors .swatch-option {
      margin: 0 auto !important;
      height: 46px;
      width: 46px;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      float: none;
      border-radius: 50%;
      border: 3px solid #000;
  }
  .am-swatch-wrapper.item {
      padding: 0.2em 0.2em 0;
  }
  .am-swatch-wrapper.item a span.swcolor-white.swatch-option.image {
      background-position: left top;
  }
  .am-swatch-wrapper.item a span.swcolor-white.swatch-option.image {
      background: #fff;
  }
  #filter-color .swatch-layered.colors .swatch-text {
      display: block;
      background: 0 0;
      height: auto;
      width: 100%;
      text-align: center;
      color: #383838;
      font-size: 14px;
      margin: 4px 0 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  #filter-color .swatch-option.color:not(.disabled):hover, #filter-color .swatch-option.image:not(.disabled):hover {
      border: 3px solid #000;
      outline: 0 !important;
  }
  .am-swatch-wrapper.item a span.swcolor-grey.swatch-option.image {
      background-position: left -326px top;
  }
  .am-swatch-wrapper.item a span.swcolor-grey.swatch-option.image {
      background: #b4b4b4;
  }
  .am-swatch-wrapper.item a span.swcolor-brown.swatch-option.image {
      background-position: left -99px top;
  }
  .am-swatch-wrapper.item a span.swcolor-brown.swatch-option.image {
      background: #865340;
  }
  .am-swatch-wrapper.item a span.swcolor-blue.swatch-option.image {
      background-position: left -262px top;
  }
  .am-swatch-wrapper.item a span.swcolor-blue.swatch-option.image {
      background: #2b70f1;
  }
  .am-swatch-wrapper.item a span.swcolor-beige.swatch-option.image {
      background-position: left -133px top;
  }
  .am-swatch-wrapper.item a span.swcolor-beige.swatch-option.image {
      background: #c9b194;
  }
  .swatch-layered.colors {
      padding: 0 1em;
  }
  #filter-color .swatch-layered.colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
  }
  .am-swatch-wrapper.item a span.swcolor-black.swatch-option.image {
      background: #040404;
  }
  .am-swatch-wrapper.item a span.swcolor-cream.swatch-option.image {
      background: #ede3c4;
  }
  .am-swatch-wrapper.item a span.swcolor-ivory.swatch-option.image {
      background: #eadeba;
  }
  .am-swatch-wrapper.item a span.swcolor-green.swatch-option.image {
      background: #3dc72c;
  }
  .am-swatch-wrapper.item a span.swcolor-pink.swatch-option.image {
      background: #f6a9e8;
  }
  .am-swatch-wrapper.item a span.swcolor-yellow.swatch-option.image {
      background: #feca2d;
  }
  #filter-color .swatch-layered.colors .swcolor-multi-colour.swatch-option {
      background: linear-gradient(to right bottom,#cc0076,#a02f87,#629834) !important;
  }
  .am-swatch-wrapper.item a span.swcolor-orange.swatch-option.image {
      background: orange;
  }
  .am-swatch-wrapper.item a span.swcolor-purple.swatch-option.image {
      background: #ad0af6;
  }
  .am-swatch-wrapper.item a span.swcolor-red.swatch-option.image {
      background: #fc431a;
  }
  .am-swatch-wrapper.item a span.swcolor-sandune.swatch-option.image {
      background: #d8c5b4;
  }
  .am-swatch-wrapper.item a span.swcolor-terracotta.swatch-option.image {
      background: #cf4e46;
  }
  .am-swatch-wrapper.item a span.swcolor-wenge.swatch-option.image {
      background: #645452;
  }
  .mage-error {
      color: red;
      font-size: 10px;
  }
  .shorting-items.hide-details {
      display: none;
  }
  
  .add-to-compare { position: absolute; top: 20px; left: 20px; z-index: 3; min-height: unset; height: 34px; width: 34px; border: 2px solid #bbb; border-radius: 50%; text-align: center; display: block; padding: 3px 0;}
  .add-to-compare img { height: 100%;}
  .abt-content p { margin: 0 0 7px; }
  .add-to-compare img {
      height: 22px;
  }
  #comparestickybtn { background: #ffcb08 !important; top: 45% !important;}
  #comparestickybtn a span { color: #343434 !important; font-size: 13px !important; font-family: Poppins-SemiBold;}
  .product-info-list>div.bss-bt-quickview a {
      background: #c5c5c5!important;
      color: #000 !important;
      display: block;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
  }
  
  
  
  
  /******************* Create account css ******************/
  .usercreateaccount {
      padding: 70px 0;
  }
  .usercreateaccount h2 {
      border-left: 5px solid #ffcb08;
      margin-bottom: 40px;
  }
  .usercreateaccount h2 span {
      font-size: 26px;
      padding-left: 15px;
  }
  .usercreateaccount h3 {
      margin: 20px 0;
  }
  .usercreateaccount h3 span {
      font-size: 22px;
      color: #3ca362;
  }
  .usercreateaccount .form-control, .usercreateaccount .form-select {
      height: 40px;
      border-color: #e3e3e3;
  }
  .usercreateaccount .form-control:focus, .usercreateaccount .form-select:focus{
      border-color: #cdd4da;
  }
  .usercreateaccount .form-label {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
  }
  .usercreateaccount .btn-primary{
      background-color: #0f9243;
      color: #fff;
      border: none;
      font-size: 16px;
      border-radius: 30px;
      padding: 8px 30px;
  }
  .usercreateaccount .btn-primary:hover{
      background-color: #0f9243;
  }
  .usercreateaccount .btn-secondary {
      border-radius: 30px;
      padding: 8px 30px;
      background: #ebebeb;
      color: #000;
      border-color: #ebebeb;
      font-size: 16px;
  }
  .usercreateaccount .btn-secondary:hover{
      background: #ffcb08;
      border-color: #ffcb08;
  }
  .usercreateaccount .btn{margin-top: 20px;}
  .usercreateaccount.loginaccount .btn{margin-top: 0;}
  .passwordstr {
      background-color: #f4f4f4;
      font-size: 14px;
      padding: 5px 9px;
      border-radius: 5px;
  }
  .usercreateaccount sup {
      top: -0.1em;
      color: #e02b27;
  }
  .forgotpassword.usercreateaccount h3 span {
      font-size: 14px;
      color: #333;
  }
  .forgotpassword.usercreateaccount h2{margin-bottom: 0;}
  .forgotpassword.usercreateaccount h3 {
      margin: 10px 0 30px;
  }
  .loginpagedata {
      display: flex;
      justify-content: space-between;
  }
  .logincontent {
      width: 47%;
  }
  .usercreateaccount.loginaccount h2 {
      margin-bottom: 40px;
  }
  .loginaccount h4{border-bottom: 1px solid #e8e8e8; padding-bottom: 1em;}
  
  .loginaccount h4 span{
      margin-bottom: 1em;
      padding-bottom: 1em;
      font-size: 1.2em;
      
  }
  .loginaccount form {
      margin-top: 30px;
  }
  .loginaccount p{
      padding-top: 20px;
  }
  .loginaccount small {
      font-size: 15px;
  }
  
  .error { padding: 0 !important; font-size: 14px; line-height: 20px; }
  
  .passwordstr.No-Password{background-color: #f4f4f4;}
  .passwordstr.strong{background-color: #007f1d; color: #fff}
  .passwordstr.medium{background-color: #001cf8; color: #fff}
  .passwordstr.weak{background-color: #dc3545; color: #fff;}
  
  .supportticket .btn-primary{margin-top: 0;}
  
  /******************************* Account page css *********************************/
  
  .acountdata {
      padding: 40px 0;
  }
  
  .acountdataleft .nav-tabs{display: block;}
  
  .acountdata {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .acountdataleft{
      width: 350px;
  }
  .acountdataleftcon { position: sticky; top: 100px; }
  .acountdataleftcon{background-color: #f4f4f4;}
  .acountdataright {
      width: calc(100% - 400px);
  }
  .obl_loader { width: 360px !important; position: fixed; left: 50%; top: 35%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); }
  
  .acountdataleft .nav-tabs .nav-link{
      border: none;
      border-left: 3px solid transparent;
      color: #575757;
      display: block;
      width: 100%;
      text-align: left;
      padding: 10px;
      font-size: 15px;
  }
  .acountdataright .form-control[type=file]:not(:disabled):not([readonly]) {
      border: none;
      border-radius: 0;
      width: auto;
  }
  .acountdataleft .nav-tabs .nav-link:hover,.acountdataleft .nav-tabs .nav-link:focus{
      border: none;
      border-left: 3px solid transparent;
      background-color: #eee;
  }
  .acountdataleft .nav-tabs .nav-link.active {
      color: #000;
      background-color: #eee;
      border-color: var(--bs-nav-tabs-link-active-border-color);
      width: 100%;
      text-align: left;
      border: none;
      border-left: 3px solid #ff5501;
      border-radius: 0;
  }
  .acountdataleft .nav-tabs{
      border-bottom: none;
  }
  .errordiv {
      background: #fdf0d5;
      padding: 5px 15px;
  }
  .errordiv p {
      font-size: 13px;
      margin: 0;
      color: #6f4400;
  }
  .supportticketDataleft a {
      background: #00914a;
      color: #fff;
      padding: 8px 20px;
      border-radius: 30px;
  }
  .supportticketDataleft a:hover {
      background: #fc0;
      color: #000;
      padding: 8px 20px;
      border-radius: 30px;
  }
  .supportticketDataright {
      margin-top: 30px;
  }
  .supportticketDataright a{color: #333;}
  .supportticketDataleft p {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 5px;
  }
  .supportticketbottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }
  .supportticketbottom p {
      font-size: 14px;
      margin: 0;
  }
  .supportticketbottom  select.form-select {
      width: auto;
      height: auto;
      margin: 0 7px;
  }
  .usercreateaccount.accountdashboard {
      padding: 0;
  }
  .viewticketbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
  }
  .viewticketbtn a {
      padding: 8px 30px;
      background: #00914a;
      color: #fff;
      border-radius: 30px;
  }
  .viewticketbtn a:hover {
      padding: 8px 30px;
      background: #fc0;
      color: #333;
      border-radius: 30px;
  }
  .viewticketbtn .btn-secondary{margin: 0;}
  .viewticketdetails p {
      font-size: 14px;
      border-bottom: none;
      margin-bottom: 5px;
  }
  .viewticketdetails p span {
      padding-left: 5px;
  }
  .viewticketdetails {
      margin: 30px 0;
  }
  
  .viewticketdetails p span{word-wrap: break-word;}
  
  .smalltitle {
      color: #333;
      border-bottom: 1px solid #dde2e6;
      padding-bottom: 5px;
  }
  .orderviewpage .supportticketDataright {
      margin-top: 0;
  }
  .orderinformation {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .orderinformation div {
      width: 47%;
      margin-bottom: 20px;
  }
  .orderinformation p {
      margin-bottom: 5px;
      font-size: 16px;
  }
  .viewordertopstrip { display: flex; justify-content: space-between; padding: 5px 10px; margin-bottom: 20px; box-shadow: 0 5px 10px 0px #c9c9c9; border: 1px solid #eee; }
  .viewordertopstrip p {
      font-size: 14px;
      margin: 0;
  }
  .aboutorder {
      margin: 30px 0;
  }
  .aboutorder h4 span {
      font-size: 23px;
  }
  .aboutorder p {
      margin: 0;
      font-size: 16px;
  }
  .loginBottomStrip {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .loginBottomStrip button.btn.btn-secondary {
      margin: 0;
  }
  
  .vieworderpagesingle .acountdata{justify-content: center;}
  button.button_normal.btn.btn-primary { background: transparent; color: #0D6EFD; padding: 0;     font-size: 14px;}
  .dashboard-banner_data ol li { font-size: 16px; line-height: 25px; margin-bottom: 5px; }
  .review_table .btn.btn-primary{margin-top: 0;}
  .review_table .rrr{margin-right: 0;}
  
  .reviewdetail-right a { font-size: 16px; margin-right: 10px; border-bottom: 1px solid #333; color: #333; }
  .reviewdetail-right p { font-size: 16px;  margin-bottom: 10px;}
  .reviewdetail-right h4 { font-size: 18px; margin: 5px 0; }
  p.your_review { margin: 0; margin-top: 15px; }
  p.your_review strong {
      font-size: 16px;
  }
  
  @media only screen and (max-width: 1200px) {
      
  .page-products #panel2 {
      width: 100%;
      padding-left: 320px;
  }	
  .productListing .productBox {
      width: calc(49.666% - 4px);
      margin: 0 4px 2% 0;
  }	
  .vieworderpagesingle .acountdata .acountdataright {width: 100%;}
  
  }
  
  
  @media only screen and (max-width: 980px) {
    .zsiq_floatmain {margin-bottom: 50px !important;}

      
  .page-products #panel2 {
      padding-left: 0;
  }
  .abt-content {
      width: 100%;
      margin: 0;
      padding-right: 0;
  }	
  .tilesListingWrapper .product-sorting .product-sorts label {
      width: 42%;
  }
  .productListing .productBox {
      width: calc(33.333% - 4px);
      margin: 0 4px 2% 0;
  }	
  body.page-products .filters-left {
      width: calc(100% - 40px);
  }	
  .filters-left .filterBy .flt-type.active {
      background: #fff;
      display: inline-block;
      width: 80px;
  }
  body .filters-left .filterBy .flt-type {
      width: 80px;
      text-align: center;
  }
  .filters-left .filterBy .flt-type {
      border-bottom: 1px solid #bfbfbf;
  }
  .filters-left .filterBy .flt-type.active+.filters {
      opacity: 1;
      transform: translateX(0);
	  -webkit-transform: translateX(0);
	  -moz-transform: translateX(0);
	  -ms-transform: translateX(0);
	  -o-transform: translateX(0);
  }	
  body .filters-left .filterBy .filters {
      top: 0;
      left: 80px;
      width: calc(100% - 80px);
  }		
  .filters-left .filterBy .filters label a {
      color: #000;
  }
  .filters-left .filterBy .filters label {
      border-bottom: 1px solid #ccc;
  }
  .loginpagedata {
      display: inherit;
  }
  .logincontent {
      width: 100%;
  }
  .logincontent:nth-child(2){
      margin-top: 30px;
  }
  .foot-col.foot-connect ul li a{ padding-right:10px;}

  .pro-filter-row>ul {
  
    height: calc(100vh - 0px);
padding-bottom: 150px;
}

  }
  
  
  @media only screen and (max-width: 767px) {
  
  .tilesListingWrapper .product-sorting .amshopby-filter-current .amshopby-items {
      padding-top: 1em;
  }	
  .select-location-wrapper {
      margin-top: 10px;
      height: auto;
  }
      
  .select-location-wrapper span {
      font-size: 12px;
      line-height: 14px;
  }	
  .modal-popup .modal-title {
      font-size: 19px;
  }	
  .modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
      padding-top: 10px!important;
  }
          
  
  .category-wall-tiles .productListing .productBox, .category-floor-tiles .productListing .productBox, .productListing .productBox {
      width: 100%;
      float: left;
      height: 550px;
  }
  .category-floor-tiles .productListing .productBox, .productListing .productBox {
      padding: 10px;
      border: 1px solid #eee;
  }
  .shorting-items.type-chnage {
      order: -20;
      width: 100%;
      text-align: right;
      margin-bottom: 10px;
  }
  .shorting-items, .tilesListingWrapper .product-sorting .product-sorts {
      width: 50% !important;
      text-align: center;
  }
  .tilesListingWrapper .product-sorting .shorting-items.product-sorts {
      position: fixed;
      bottom: 0;
      margin: 0;
      width: 100% !important;
      left: 0;
      background: #fff !important;
      z-index: 99;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-top: 1px solid #eee;
  }
  .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom {
      border-right: 1px solid #eee;
  }
  .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom, .product-sorts .filter-by {
      margin: 0;
      width: 50% !important;
  }
  .filter-by .filter-by.sticky {
      position: fixed;
      top: 50%;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: #fff;
      box-shadow: 0 0 5px #666;
      background-position: center !important;
      padding: 0;
      z-index: 10;
      line-height: 50px;
      text-align: center;
      border: 1px solid #777;
      border-left: 0;
      color: #036c2d;
  }	
  .page-products .abt-content .bread-crumb, .shorting-items .mode, .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom, .product-sorting .active-filter-list>.filtered {
      display: block !important;
  }	
  .abt-content .bread-crumb {
      margin-top: 0!important;
  }
  .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom, .tilesListingWrapper .product-sorting .product-sorts label.locaton-custom select {
      display: inline-block !important;
  }
  .tilesListingWrapper .product-sorting .product-sorts label select {
      margin: 0;
      border: 0 !important;
      /* background: url(../static/frontend/colora/colora_home2/en_US/images/two-arrows.png) no-repeat left center !important; */
      background-size: 15px !important;
      max-width: 100px;
      padding: 0 0 0 14px !important;
  }
  .product-sorts .filter-by .filter-by {
      position: relative;
      padding-left: 38px;
  }
  .filter-by, .shorting-items #tilecountis {
      color: #4f4f4f;
  }
  .tilesListingWrapper .secondaryContent .g-heading h2 {
      font-size: 24px;
  }
  .tile-video {
      min-height: 380px;
  }
  .listingWrapper .listingleftWrap .listingRow .listingImg {
      width: 100%;
      text-align: center;
      padding: 0;
  }
  .listingWrapper .listingleftWrap .listingRow .listingText {
      width: 100%;
      padding-bottom: 20px;
  }
  .listingWrapper .listingleftWrap .listingRow {
      width: 100%;
      float: left;
      border: 1px solid #ccc;
      margin: 20px 0;
      padding-bottom: 0;
  }	
  .abt-content .g-heading h3 {
      font-size: 1em;
  }
  .catalog-category-view .panel .custome-container {
      width: 90% !important;
  }	
  .page-products .filters-left.active {
     overflow: unset;
	  transform: translateX(0);
	  -webkit-transform: translateX(0);
	  -moz-transform: translateX(0);
	  -ms-transform: translateX(0);
	  -o-transform: translateX(0);
  }
  .page-products .filters-left {
      display: block !important;
      position: fixed;
      left: 0;
      height: 90%;
      top: 0;
      z-index: 9;
      transform: translateX(-100%);
	  -webkit-transform: translateX(-100%);
	  -moz-transform: translateX(-100%);
	  -ms-transform: translateX(-100%);
	  -o-transform: translateX(-100%);
	  transition: transform .2s linear;
	  -webkit-transition: transform .2s linear;
	  -moz-transition: transform .2s linear;
	  -ms-transition: transform .2s linear;
	  -o-transition: transform .2s linear;
  }
  .hide-desktop {
      display: block !important;
      text-align: center;
      color: #fff;
  }	
  .thumb {
      float: left;
      width: 100%;
      padding-bottom: 1em;
  }	
  .filter-by span:after {
      content: '';
      height: 19px;
      width: 23px;
      display: inline-block;
      /* background: url(../static/frontend/colora/colora_home2/en_US/images/fliter-by.png) no-repeat center; */
      background-size: contain;
      vertical-align: middle;
      margin-left: 7px;
  }
  .product-sorts .filter-by:after {
      position: absolute;
      left: 0;
      top: 3px;
      height: 10px;
  }	
  .filter-by.sticky::after {
      display: none;
  }
  .shorting-items .mode {
      display: none !important;
  }
  .Socialbtm {
      display: block !important;
      opacity: 1 !important;
      width: 50px !important;
      bottom: 36px !important;
      position: fixed;
      background-color: #fff;
  }
  
  }
  .modal-popup._inner-scroll .modal-inner-wrap .modal-header {
      height: auto !important;
  }
  
  @media only screen and (max-width: 540px) {
  .category-wall-tiles .productListing .productBox, .category-floor-tiles .productListing .productBox, .productListing .productBox {
      height: 500px;
  }
  .shorting-items, .tilesListingWrapper .product-sorting .product-sorts {
      width: 100% !important;
      text-align: center;
      padding-bottom: 0.5em;
  }
      
  }
  
  /* header css */
  
  button.action.edit-address-link{display:block!important}
  .menu-slider.slick-initialized{ max-height:200px}
  .productDetails .product-content .product-head-section .p-name span {
  font-size: 15px;line-height: 100%;
  }
  .t-item{ width: 80%!important; }
     .tile-content.resp-tab-content, .resp-accordion{ display: none; }
     .resp-accordion.resp-tab-active{ display: block!important; }
     
     .styletiles-slider .styletiles-bx .slid-button-bx { position: absolute; top: 51%; left: 0px; width: 32%; text-align: center; transform: translate(-0%, -50%); -webkit-transform: translate(-0%, -50%); -o-transform: translate(-0%, -50%); -ms-transform: translate(-0%, -50%); -moz-transform: translate(-0%, -50%); }
      .styletiles-slider .styletiles-bx .slid-button-bx a { background-color: #288840; border:solid 1px #288840; color: #fff; padding: 10px 50px; font-size: 20px; text-transform: uppercase; border-radius: 25px; font-weight: 800;} 
      .styletiles-slider .styletiles-bx .slid-button-bx a:focus{ outline:none;}
      .styletiles-slider .styletiles-bx .slid-button-bx.first-slid-row { width: 100%; top: unset; bottom: 70px;}
      .styletiles-slider .styletiles-bx .slid-button-bx.last-slid-row { width: 100%; top: 43%;}
      
      /******************************* CSS add by Mahi ****************************/
      .catalog-product-view .sample-modal-custom .modal-content button { margin: 0px 5px !important;}
      .certificate-wrap {    display: flex;        flex-flow: wrap;        justify-content: space-around;        width: 100%;        padding-bottom: 50px;    }
      .certificate-col {        width: 23%;        padding: 15px;        text-align: center;         background-color: #f1f1f1;}
      .certificate-col a {        text-align: center;        display: block;        margin-top: 1em;        color: #000;        text-transform: uppercase;    }
      .certificate-head {     padding-bottom: 10px;    }
      .other-certificates {        max-width: 650px;        padding-bottom: 50px;        width: 100%;        margin: 0px auto;    }
      .other-certificates h3{ text-align: center; margin-bottom: 15px;}
      .other-certificates ul {     list-style: none;      margin: 0px;        padding: 0px;    display: flex;        flex-flow: wrap;        justify-content: space-around;   }
      .other-certificates ul li{ width:25%; padding: 0px 15px;}
      


      @media screen and (max-width: 1600px)
      {
          .catalog-product-view .sample-modal-custom .modal-inner-wrap { max-width: 500px; width: 100%;}
          
          .catalog-product-view .sample-modal-custom .modal-content button { margin: 0px 2px !important; padding: 0px 10px;}
          .hp-spotlight-bx .otb-pro-content span br { display: none;}
          
          .hp-spotlight-bx .otb-pro-content {  max-width: 630px; margin: 0px auto;}
          .hp-spotlight-bx .otb-pro-content span{ font-size: 45px;}
          .hp-spotlight-bx .otb-pro-content .pointer-text{ font-size: 30px;}
          .features-row .features-col .f-text{ font-size: 16px;}
          
      } 
      /******************************* CSS add by Mahi ****************************/
      
      @media only screen and (max-width: 1199px)
      {
          .styletiles-slider .styletiles-bx .slid-button-bx a { padding: 6px 30px; font-size: 16px;}
          .obl-experience-row .obl-matterport-text-col p { font-size: 16px; margin: 20px 0px;}
      }

      @media only screen and (max-width: 1280px)
      {
        .product-listing-col .produ-view .syte-discovery { padding: 0 5px; font-size: 11px; max-width: 95px; width: 100%;}
        .product-listing-col .produ-view .roomvo-container { max-width: 115px; width: 100%; text-align: right;}
        .product-listing-col .produ-view .roomvo-stimr.some-class { font-size: 11px; padding: 0px 5px;}
        .product-listing-col .produ-view .roomvo-stimr.some-class:before{ width: 15px; height: 15px;}
      }
      
      @media only screen and (max-width: 991px)
      {
          .styletiles-slider .styletiles-bx .slid-button-bx{ top:53%;}
          .certificate-col{ width:49%; margin-bottom: 15px;}
          .certificate-col:nth-child(odd){margin-right: 1%;}
          .certificate-col:nth-child(even){margin-left: 1%;}

  
      }
      
      @media only screen and (max-width: 767px)
      {
          .styletiles-slider .styletiles-bx .slid-button-bx{ top:50%; width:100%;}
          .styletiles-slider .styletiles-bx .slid-button-bx.first-slid-row{ bottom:10px;}
          .other-certificates ul li{width: 50%;}
          .trending-products-section h1 { font-size: 22px !important;}

      }
     
      /******************************* CSS add by Mahi ****************************/
      @media screen and (max-width: 479px)
      {
  
      .catalog-product-view .sample-modal-custom .modal-content button { margin: 0px 0px 10px !important; padding: 0px 10px !important;max-width: 100% !important;	}
      .header-row .co-logo{max-width: 160px;}
      .custom-head-mobile{width: calc(100% - 160px);} 
      .custom-head-mobile .custom-head-col img { height: 20px; }
      .header-row .co-logo img { height: 25px; } 
      .certificate-col{ width:100%; margin-bottom: 15px;}
      .certificate-col:nth-child(odd){margin-right: 0%;}
      .certificate-col:nth-child(even){margin-left: 0%;}
      .trending-products-section h1 { font-size: 20px !important;}

      }	
      /******************************* CSS add by Mahi ****************************/
     .tilesListingWrapper .product-sorting {
      z-index: 4;
  }
  .select-location-wrapper { margin-top: 0; }
  
  /****** Listing and download catalog ******/
  
  @media screen and (max-width: 479px){
  .cms-download-catalogue .menu-slider-wrap{ position: relative!important; top: 0px!important; }
       .cms-download-catalogue .downloadButton.fixedtop{ position: fixed!important; top: 65px!important; z-index: 999;  }
  .cms-download-catalogue .downloadButton>ul>li>.tabber{ top: 80px!important; }
  .cms-download-catalogue .downloadTab ul li.all.wall{ margin-bottom: 0px!important; }
  }
  /****** Listing Social icon ******/
  .whatsapp.btm-sprite {
      -webkit-filter: brightness(0) invert(1);
	   -moz-filter: brightness(0) invert(1);
	    -ms-filter: brightness(0) invert(1);
		 -o-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
  @media only screen and (max-width: 767px){
  .Socialbtm { display: block !important; opacity: 1 !important; width: 45px !important; bottom: 56px !important; background: #0f9243 !important; border-radius: 8px 0px 0px 8px;}
  .Socialbtm .whatsapp svg { -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); -moz-filter: brightness(0) invert(1); -ms-filter: brightness(0) invert(1); -o-filter: brightness(0) invert(1);}
  
  }
  
  /****** Store locater page ******/
  .cms-store-locator header nav {
      display: block;
  }
  
  /* Internal css */
  #panel2 > div.breadcrumbs:nth-child(2){
      display:none !important;
  }
  #amasty-shopby-product-list > div.catalog-topnav.amasty-catalog-topnav{
  
    display:none;
  }
  
  /* GuestWishlist */
  
  .fme-quote-showcart{
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
  
  }
  .minicart-wrapper .action.fme-quote-showcart:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 28px;
      color: #757575;
      content: '\e600';
      font-family: 'luma-icons';
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center;
  }
  .minicart-wrapper .action.fme-quote-showcart .counter.qty {
      background: #ff5501;
      color: #fff;
      height: 24px;
      line-height: 24px;
      border-radius: 2px;
      display: inline-block;
      margin: 3px 0 0;
      min-width: 18px;
      overflow: hidden;
      padding: 0 3px;
      text-align: center;
      white-space: normal;
  }
  .minicart-wrapper .action.fme-quote-showcart .counter-label
  {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
  }
  .minicart-wrapper .action.fme-quote-showcart .counter.qty.empty {
      display: none;
  }
  .minicart-wrapper .action.fme-quote-showcart .counter-number{
      text-shadow:0 0 7px #000
  }
  .minicart-wrapper .action.action.fme-quote-showcart:hover:before{
      color:#333
  }
  .minicart-wrapper .action.fme-quote-showcart:active:before{
      color:#757575
  }
  .minicart-wrapper .action.fme-quote-showcart.active{
      display:inline-block;text-decoration:none
  }
  .minicart-wrapper .action.fme-quote-showcart.active:active:before{
      color:#757575
  }
  .minicart-wrapper .action.fme-quote-showcart{
      white-space:nowrap
  }
  .minicart-wrapper .action.fme-quote-showcart .counter.qty .loader>img{
      max-width:24px
  }
  .minicart-wrapper .action.fme-quote-showcart .counter-number{
      text-shadow:0 0 7px #000
  }
  .opc-estimated-wrapper .minicart-wrapper button.action.fme-quote-showcart{
      background-image:none;background:none;-moz-box-sizing:content-box;border:0;box-shadow:none;line-height:inherit;margin:0;padding:0;text-decoration:none;text-shadow:none;font-weight:400
  }
  .opc-estimated-wrapper .minicart-wrapper button.action.fme-quote-showcart:focus,.opc-estimated-wrapper .minicart-wrapper button.action.fme-quote-showcart:active{
      background:none;border:none
  }
  .opc-estimated-wrapper .minicart-wrapper button.action.fme-quote-showcart:hover{
      background:none;border:none
  }
  .opc-estimated-wrapper .minicart-wrapper button.action.fme-quote-showcart{
      pointer-events:none;opacity:.5
  }
  
  /* Guest wishlist design */
  .fme-quote-cart-wrapper,
  .guest-wishlist-wrapper {
      clear: both;
      width: 100%;
      float: left;
      position: static;
  }
  #guest-wishlist-mpdal {
      clear: both;
      padding: 50px 0;
      background-color: #343434;
      display: none;
  }
  #guest-wishlist-mpdal.show {
      display: block;
  }
  #guest-wishlist-mpdal .block {
      margin: 0;
  }
  #wishlist-sidebar {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
  }
  #wishlist-sidebar li.item {
      width: calc(100%/8);
      padding: 5px;
  }
  #wishlist-sidebar .product-item-photo,
  #wishlist-sidebar .product-item-photo span {
      display: block;
      width: 100% !important;
      padding: 0 !important;
      height: auto;
  }
  #wishlist-sidebar li.item .product-image-photo {
      position: static;
      display: block;
      width: 100% !important;
      margin: 0;
  }
  
  #guest-wishlist-mpdal .block-title {
      letter-spacing: 0.307042px;
      font-size: 18px;
      margin: 0;
      color: #fff;
      display: block;
  }
  .wishlist-actions {
      text-align: right;
  }
  .wishlist-actions button {
      border: 0;
      padding: 0px 20px;
      height: 34px;
      border-radius: 5px;
  }
  .wishlist-actions button.btn-share {
      background-color: transparent !important;
      color: #fff;
      padding: 0;
  }
  .wishlist-actions button.btn-share span, .wishlist-actions button.btn-share svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 3px;
  }
  .wishlist-actions button.btn-compare {
      color: #000;
      border: 1px solid #fff;
      margin: 0 10px;
  }
  .wishlist-actions button.btn-save {
      background-color: #fff;
      border: 1px solid #fff;
  }
  .wishlist-actions button.btn-compare:hover {
      background-color: #fff;
      border: 1px solid #fff;
      color: #343434;
  }
  .wishlist-actions button.btn-save:hover {
      color: #fff;
      border: 1px solid #fff;
      background-color: transparent !important;
  }
  @media only screen and (max-width: 767px) {
      .wishlist-actions {
          margin-top: 20px;
      }
      .wishlist-actions button.btn-compare {
          margin: 0.2em 0;
      }
      .wishlist-actions button {
          padding: 0 5px;
          font-size: 12px;
      }
      #wishlist-sidebar li.item {
          width: calc(100%/2);
      }
      #guest-wishlist-mpdal.show {
          padding: 30px 0;
      }
  }
  /* Layered Navigation */
  
  .filter-subtitle{
      display:none!important;
  }
  .filter-current-subtitle{
      display:none!important;
  }
  h1.page-title{
      display:none!important;
  }
  
  /* Layered Navigation */
  /* product list */
  #amasty-shopby-product-list > div.catalog-topnav.amasty-catalog-topnav{
      display:none;
  }
  .toolbar-products{
      display:none!important;
  }
  .productBox .add-to-links-secondary a.active { color: #ffcb05;}
  /* product list */
  /* Related blog desing issue in category page*/
  .catalog-category-view .listingleftWrap { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; flex-flow: wrap; padding-top: 30px !important;}
  .catalog-category-view .listingleftWrap .listingRow { margin: 0px 0px 30px; width: 49%; min-height: unset !important; padding: 0px;}
  .catalog-category-view .listingleftWrap .listingRow:nth-child(odd) { margin-right: 1%;}
  .catalog-category-view .listingleftWrap .listingRow:nth-child(even) { margin-left: 1%;}
  .catalog-category-view .listingleftWrap .listingRow .listingImg { max-width: 180px; width: 100%;}
  .catalog-category-view .listingleftWrap .listingRow .listingText { width: calc(100% - 180px); padding: 30px 15px;}
  .catalog-category-view .listingleftWrap .listingRow .listingText .listingButton a { margin: 0px !important;}
  
  .dontshow-location {
      border: 2px solid #eee;
      background: transparent;
      padding: 0 20px;
      height: 40px;
      border-radius: 30px;
      position: absolute;
      width: auto;
      bottom: 15px;
      right: 5%;
  }
  
   @media(max-width: 480px){
          .dontshow-location{
               right: 5px;
               font-size: 12px;
          }
      }
  
  @media screen and (max-width: 1199px)
  {
      .catalog-category-view .listingleftWrap .listingRow .listingImg { max-width: 120px;}
      .catalog-category-view .listingleftWrap .listingRow .listingText { width: calc(100% - 120px); padding: 15px;}
  }
  
  @media screen and (max-width: 767px)
  {
      .catalog-category-view .listingleftWrap .listingRow { margin: 0px 0px 20px; width: 100%;}
      .catalog-category-view .listingleftWrap .listingRow:nth-child(odd) { margin-right: 0%;}
      .catalog-category-view .listingleftWrap .listingRow:nth-child(even) { margin-left: 0%;}
      .catalog-category-view .listingleftWrap .listingRow .listingImg { max-width: 100%;}
      .catalog-category-view .listingleftWrap .listingRow .listingText { width: calc(100% - 0px); padding: 15px;}
      
  }
  /* Related blog desing issue in category page*/
  
  
  .inline-filter{
    padding-right: 40px;
      border-bottom: 1px solid #d4d5d9;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      display: block;
      background-color: #e1eaff;
      font-size: 14px;
      color: #424554;
      }
    .inline-filter .if-title{
    vertical-align: top;
      display: inline-block;
     /* margin-right: 30px;*/
      color: #3e4152;
      }
    .inline-filter .if-title .heading{
    padding-left: 16px;
      /*padding-top: 30px;*/
      font-size: 18;
      }
    .inline-filter .if-title .filterId{
    padding-left: 16px;
      padding-bottom: 30px;
      /*font-size: 21.5px;*/
      font-weight: 500;
      line-height: 1.33;
      }
    .inline-filter .if-values{
    display: inline-block;
      }
    .inline-filter .inline-filter-option-row1 {
    margin-top: 10px;
    /*margin-right: 50px;*/
      display: inline-block;
      /*min-width: 4%;*/
      vertical-align: top;
      }
    .inline-filter .top-value{
    padding: 5px 5px;
      font-weight: 500;
      text-align: center;
      width: auto;
      border-radius: 4px;
      background-color: #b8acac;
      /*font-size: 18px;*/
      }
      .mobilet9l    { display:none; }
  
  @media screen and (max-width: 500px) {
  .mobilet9l   { display:block; }
  }
  .inline-filter{ display:none;}
  .inline-filter { padding: 15px !important; margin: 15px 0px 20px; border: 0px !important; background-color: #f6f6f6 !important;}
  .inline-filter::-webkit-scrollbar { width: 5px;}
  .inline-filter::-webkit-scrollbar-track { background: #f1f1f1;}
  .inline-filter::-webkit-scrollbar-thumb { background: #888; border-radius: 10px;}
  .inline-filter::-webkit-scrollbar-thumb:hover { background: #555;}
  .inline-filter .if-title {
      width: 100%;
      display: block !important;
      position: sticky;
      left: 0px;
      top: 0px;
  }
  .inline-filter .if-title .heading, .inline-filter .if-title .filterId { padding: 0px !important; font-size: 16px; color: #646464; font-family: Poppins-SemiBold; display: inline-block !important;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value { border-bottom: 1px solid #ccc; font-family: Poppins-Regular; padding: 6px 10px; display: inline-block; color: #373737; border: 1px solid #939393; margin: 0px 3px; border-radius: 20px; background-color: #fff;}
  
  @media screen and (max-width: 767px)
  {
      .inline-filter{ display:block;}
      
  }
  
  .inline-filter .if-values .inline-filter-option-row1 .top-value.White { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #fff;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Grey { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #b4b4b4;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Brown { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #865340;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Blue { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #2b70f1;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Beige { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #c9b194;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Black { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #040404;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Cream { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #ede3c4;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Ivory { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #eadeba;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Green { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #3dc72c;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Pink { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #f6a9e8;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Yellow { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #feca2d;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Chequered { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #000;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Multi-colour { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background: linear-gradient(to right bottom, #cc0076, #a02f87, #629834);}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Orange { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #FFA500;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Purple { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #ba5ddb;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Red { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #fc431a;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Sandune { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #d8c5b4;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Terracotta { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #cf4e46;}
  .inline-filter .if-values .inline-filter-option-row1 .top-value.Wenge { width: 40px; height: 40px; font-size: 0px; border-radius: 50%; background-color: #645452;}
   
  /*Add wishlist item design and remove button design for category page*/
  .guest-wishlist-wrapper #guest-wishlist-mpdal { padding: 50px 0; background-color: #343434;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .block-title { font-family: "Poppins-Regular"; margin-bottom: 15px; font-size: 20px;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items { display: flex; flex-wrap: wrap; width: 100%; list-style: none;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li { width: calc(100%/8); padding: 5px;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-photo { height: 200px; overflow: hidden; background-color: #fff; display: flex; align-items: center; flex-flow: wrap;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .product-item-name { margin: 10px 0px 0px;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .product-item-name a, .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .product-item-name a span { color: #fff;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .price-box { margin: 0px 0px 10px;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .product-item-actions button { font-weight: 400; background: #ffcc00; border-radius: 25px; color: #fff; font-size: 1em; padding: 5px 10px; display: inline-block; margin: 0px 0px 10px; border: 1px solid #ffcc00;}
  .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-details .product-item-actions button:hover { background: #c39c00; border: 1px solid #c39c00;}
  
  .container_new .pro_about p { word-wrap: break-word;}
  .category_accordion_data p, .category_accordion_data p span, .category_accordion_data p strong { font-size: 14px !important; color: #000000 !important; line-height: 24px !important;}
  .category_accordion_data p a { font-size: 14px !important;}
/* export page get in touch */
.export-page-getInTouchTariq {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}
.main-location-popup .modal-header { align-items: flex-start;}
  .main-location-popup .location-pop-section { display: flex; flex-flow: wrap;}
  .main-location-popup .location-icon-bx { width: 50px; padding-top: 5px;}
  .main-location-popup .location-text-bx { width: calc(100% - 50px); padding-left: 15px;}
  .main-location-popup .modal-body { display: flex; flex-flow: wrap; align-items: center;}
  .main-location-popup .modal-body .btn-success { width: 30%; height: 36px; font-size: 13px;}
  .main-location-popup .location-or-bx { width: 15%; text-align: center; position: relative;}
  .main-location-popup .location-or-bx:before { content: ''; background-color: #ccc; width: 50px; height: 1px; display: block; position: absolute; left: 50%; top: 50%; z-index: 0; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); }
  .main-location-popup .location-or-bx p { font-size: 12px; width: 36px; height: 36px; border: solid 1px #ccc; background-color: #f9f9f9; border-radius: 50%; display: flex; justify-content: center; align-items: center; margin: 0px auto; position: relative;}

  .main-location-popup .css-b62m3t-container { width: 55%;}
  .main-location-popup .css-b62m3t-container .css-1jqq78o-placeholder { font-size: 13px;}
  .main-location-popup input { width: 100% !important; grid-area: 1 / 3 !important; border: none !important; padding: 0px !important; min-width: 100% !important; font-size: 13px;}
  .main-location-popup .css-1fdsijx-ValueContainer div:after{ display: none !important;}
  .main-location-popup .css-1fdsijx-ValueContainer div{ display: unset !important;}
  .main-location-popup .css-1hb7zxy-IndicatorsContainer { display: none !important;}
  .main-location-popup .css-1u9des2-indicatorSeparator { display: none !important;}
  .main-location-popup .css-1wy0on6 { display: none;}
  .main-location-popup .css-hlgwow div{ display: unset !important;}
  .main-location-popup .css-hlgwow div:after{ display: none !important;}

  .introjs-tooltip{ max-width: 350px !important; min-width: 350px !important;}
  .introjs-tooltiptext { font-size: 20px !important; font-family: Poppins-SemiBold !important; color: #474747 !important; text-align: center !important;} 
  .introjs-tooltip .introjs-bullets ul li a.active { background-color: #ffcb08; width: 10px !important;}
  .introjs-nextbutton { background-color: #0f9243 !important; border: 1px solid #0f9243 !important; color: #fff !important; padding: 6px 25px !important; display: inline-block !important; border-radius: 6px !important; font-size: 16px !important;}
  .introjs-nextbutton:hover { background-color: transparent !important; border: 1px solid #0f9243 !important; color: #0f9243 !important;}
  .introjs-prevbutton { background-color: transparent !important; border: 1px solid #0f9243 !important; color: #0f9243 !important; padding: 6px 25px !important; display: inline-block !important; border-radius: 6px !important; font-size: 16px !important;}
  .introjs-prevbutton:hover { background-color: #0f9243 !important; border: 1px solid #0f9243 !important; color: #fff !important; }
  .introjs-bullets { padding-top: 0px !important; padding-bottom: 0px Impo !important;}
  .introjs-tooltip-title { min-height: auto !important;}

.export-page-getInTouchTariq .form-control {
    width: calc(100% - 150px) !important;
}
.export-page-getInTouchTariq .dropdown button {
    height: 45px;
    background-color: transparent;
    color: #333;
    border: 1px solid #d9d9d9;
    width: 100%;
    border-radius: 0px;
    position: relative;
}
.export-page-getInTouchTariq .dropdown button:active{ background-color: transparent;}
.export-page-getInTouchTariq .dropdown button>div{ overflow: hidden; font-size: 14px;}
.export-page-getInTouchTariq .dropdown button:after {
    position: absolute;
    top: 20px;
    right: 7px;
}
.export-page-getInTouchTariq .dropdown button:focus-visible{ box-shadow: none;}
.export-page-getInTouchTariq .dropdown .dropdown-menu img {
    margin-right: 5px;
}
.export-page-getInTouchTariq {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}
.export-page-getInTouch .dropdown {
    width: 100px;
	padding-right:5px;
}
.export-page-getInTouch .form-control {
    width: calc(100% - 100px) !important;
}
.export-page-getInTouch .dropdown button {
    width: 100%;
    background-color: #fff;
    border: solid 1px #dee2e6;
    color: #333;
    font-size: 10px;
    height: 34px;
    position: relative;
}
.export-page-getInTouch .dropdown button>div{ overflow: hidden; font-size: 12px;}
.export-page-getInTouch .dropdown button:after {
    position: absolute;
    top: 15px;
    right: 5px;
}
.export-page-getInTouch .dropdown .dropdown-menu img {
    margin-right: 5px;
}
.export-page-getInTouch .dropdown button:active{ background-color: transparent;}
.export-page-getInTouch {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.export-page-getInTouchTariq .dropdown button img {
    height: auto !important;
    width: 20px !important;
}
.export-page-getInTouchTariq .dropdown {
    width: 150px;
    padding-right: 5px;
}

.ft-fromUs-row .us-form-col form .form-group{ position: relative;}
.ft-fromUs-row .us-form-col form .form-group .dropdown { position: absolute; top: 0px; left: 0px;}
.ft-fromUs-row .us-form-col form .form-group .dropdown button { background-color: #dfdfdf; color: #333; border-radius: 25px; height: 40px; font-size: 12px; border: solid 1px #fff; width: 75px; padding: 0px 5px;}
.ft-fromUs-row .us-form-col form .form-group .dropdown ~ .input-bx { padding-left: 80px; box-sizing: border-box; width: 100%;}
.ft-fromUs-row .us-form-col form .form-group .dropdown button:after { position: absolute; top: 18px; right: 4px;}
.ft-fromUs-row .us-form-col form .form-group .dropdown button>div { font-size: 12px; overflow: hidden;}
.ft-fromUs-row .us-form-col form .form-group .dropdown button>div img { height: auto !important; width: 16px !important;}


  @media only screen and (max-width: 1199px)
  {
      .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li { width: calc(100%/5); margin-bottom: 15px;}
      .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-photo { height: 170px;}
      
  }
  
  @media only screen and (max-width: 767px)
  {
      .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li { width: calc(100%/3); margin-bottom: 15px;}
      
  }
  
  @media only screen and (max-width: 500px)
  {
      .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li { width: calc(100%/2);}
      .guest-wishlist-wrapper #guest-wishlist-mpdal .product-items li .product-item-info .product-item-photo { height: 140px;}
      
  }
  /*Add wishlist item design and remove button design*/
  
  /*View mode css in List/Grid*/
  .view-mode-two .productListing .productBox { width: calc(50% - 15px);}
  .view-mode-one .productListing .productBox { width: calc(100% - 15px); margin-right: 0px;}
  
  /*filter design in ipad*/
  @media screen and (max-width: 980px)
  {
      .catalog-category-view.page-products .filters-left { display: none;}
      .catalog-category-view .hide-desktop.filter-by.sticky-filter{ display:none !important;}
      .hide-desktop.filter-by.sticky-filter span.filter-by.sticky{ position: fixed; top: 50%; right: 0; width: 50px; height: 50px; background-color: #fff; box-shadow: 0 0 5px #666; background-position: center !important; padding: 0; z-index: 10; line-height: 50px; text-align: center; border: 1px solid #777; border-right: 0; color: #036c2d;}
      .catalog-category-view.page-products .filters-left.active { transform: translateX(0); -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0);  overflow: unset; display: block !important; position: fixed !important; left: 0 !important; height: 90%;   top: 0 !important; z-index: 9; margin-top: 0px !important; transition: transform .2s linear; -webkit-transition: transform .2s linear; -moz-transition: transform .2s linear; -ms-transition: transform .2s linear; -o-transition: transform .2s linear;}
      .ft-fromUs-row .us-form-col form .form-group .dropdown button{ width:120px;}
      .ft-fromUs-row .us-form-col form .form-group .dropdown ~ .input-bx { padding-left: 125px;}
      .ft-fromUs-row .us-form-col form .form-group .dropdown button>div img { width: 20px !important;}
  }
  
  @media screen and (max-width: 980px)
  {
  header.nav-container .second-band .searchbox .hide-desktop {
      display: block !important;
  }
  }
  /*Category page findsimilar button color change*/
  .productListing .productBox .roomvo-stimr {
      visibility: visible;
      display: inline-block;
      -webkit-border-radius: 5px !important;
      border-radius: 5px !important;
      background-color: #0b9444 !important;
      color: #ffffff !important;
      border: 1px solid #0b9444 !important;
      font-family: Poppins-Regular !important;
  }
  
  /*Add desing for sortby and filter by*/
  @media only screen and (max-width: 767px)
  {
      .tilesListingWrapper .product-sorting .shorting-items.product-sorts{ background: #202020 !important;}
      .tilesListingWrapper .product-sorting .product-sorts label select option { color: #202020;}
      .tilesListingWrapper .product-sorting .product-sorts label select{ color:#fff !important;}
      .product-sorts .filter-by .filter-by{ color:#fff !important;}
      .select-location-wrapper span:first-child{ font-size: 16px;}
      .select-location-wrapper.stick-top {     position: sticky;        top: 60px;        z-index: 2;  padding: 6px 15px;  }
      .select-location-wrapper .select-wraper input{ margin-top:0px; height: 30px;         font-size: 14px;}
    }
    @media screen and (max-width: 479px)
    {
        .select-location-wrapper.stick-top { top: 54px;}
        .select-location-wrapper span:first-child {  font-size: 12px;  line-height: 14px;    }
        .select-location-wrapper .select-wraper input{  font-size: 12px;}
    
    }
  .productListing .productBox .roomvo-stimr{ font-family: "Poppins-Bold" !important;}
  #comparestickybtn { background: #ffffff !important; border: solid 1px #0b9444;}
  #comparestickybtn a span { color: #0b9444 !important;}
  
  /*Code add by rp*/
  .product-info-list>div.bss-bt-quickview a{ background: #c5c5c5!important; color: #000 !important; display: block; text-align: center; text-decoration: none; font-weight: bold;}
  .productListing .productBox .roomvo-stimr { visibility: visible; display: inline-block; -webkit-border-radius: 5px !important; border-radius: 5px !important; background-color: #0b9444 !important; color: #ffffff !important; border: 1px solid #0b9444 !important; font-family: Poppins-Regular !important;}
  .modal-popup .modal-inner-wrap .close-location { background-color: #0b9444; color: #fff; border-color: #0b9444;}
  .location-modal { background-color: #00000082;}
  
  @media screen and (max-width: 980px)
  {
      .hide-desktop.filter-by.sticky-filter span.filter-by.sticky{ bottom: 138px; top: unset;}
      #comparestickybtn{ display:none !important;}
  
  }
  
  /*Category page faq section issue*/
  .secondaryContent ul, .secondaryContent ol, .t9l_2by2 { padding-left: 18px; list-style: disc;}
  .t9l_2by2 { display: flex; flex-flow: wrap; width:100%;}
  .t9l_2by2 li { width: 33.333%; padding-right: 20px;}
  
  @media screen and (max-width: 980px)
  {
      .t9l_2by2 li { width: 50%;}
  }
  
  @media screen and (max-width: 639px)
  {
      .t9l_2by2 li { width: 100%;}
.main-location-popup .location-icon-bx { width: 40px;}
      .main-location-popup .location-text-bx { width: calc(100% - 40px); padding-left: 10px;}
      .main-location-popup .modal-dialog .modal-content .modal-body{ padding-bottom: 20px !important;}
      .main-location-popup .modal-body .btn-success { width: 100%; font-size: 14px;}
      .main-location-popup .location-or-bx { width: 100%; margin: 10px 0px;}
      .main-location-popup .location-or-bx:before { width: 1px; height: 24px;}
      .main-location-popup .css-b62m3t-container { width: 100%;}
      .main-location-popup .location-or-bx p { font-size: 9px !important; width: 20px; height: 20px;}
  }
  
  .tilesListingWrapper .secondaryContent .g-heading h3 {
      font-size: 1.2em !important;
  }
  .abt-content .g-heading {
      font-size: 1.2em !important;
  }
  /* .abt-content .g-heading h2{
      font-size: 1.2em !important;
  } */
  /*Code add by rp*/
  
  /* search css */
  .auto-search-bar { position: absolute; width: 100%; left: 0px; z-index: 1; padding: 0px 50px 0px 28%; top: 80px;}
  .auto-search-bar .container { max-width: 100% !important; }
  .auto-search-row { border: 1px solid #bbb; flex-flow: wrap; width: 100%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; background-color: #fff;}
  .auto-search-row .search-col-left{ width:30%; padding:15px;}
  .search-col-left .search-title { color: #a6a6a6; text-align: left; font-size: .8em; text-transform: uppercase; font-weight: 700;}
  .search-col-left .aa-dataset { padding-bottom: 20px; }
  .search-col-left ul { list-style:none; padding:0px; margin:5px 0px 0px;}
  .search-col-left ul li a{ color: #000; font-size:14px; padding: 5px 10px; display:block; text-decoration: none;}
  .search-col-left ul li a:hover{ background-color: #f2f2f2;}
  .search-col-left ul li a em{ font-weight: 700; font-style: normal;}
  .search-col-left ul li a small{ font-size:12px;}
  .auto-search-row .search-col-right{ width:70%; border-left: solid 1px #eee;}
  
  .aa-dataset-products{ flex-flow: wrap; width: 100%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; background-color: #fff;}
  .aa-dataset-products .search-products-col{ width:50%;}
  .aa-dataset-products .search-products-col a{ flex-flow: wrap; width: 100%; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; padding: 15px 10px; color: #000; font-size:14px; text-decoration:none;}
  .aa-dataset-products .search-products-col a em{ font-weight: 700; font-style: normal;}
  .aa-dataset-products .search-products-col a small{ font-size:12px;}
  .aa-dataset-products .search-products-col a:hover{ background-color: #f2f2f2;}
  .aa-dataset-products .search-products-col a .thumb { width: 75px; height:75px; overflow: hidden; text-align: center;}
  .aa-dataset-products .search-products-col a .thumb img{ width: auto;}
  .aa-dataset-products .search-products-col a .info { width:calc(100% - 75px); padding-left:10px; line-height: 18px;}
  .aa-dataset-products .search-products-col a .info p{ margin:0px;}
  .aa-dataset-products .search-products-col a .info br{ display:none;}
  .aa-dataset-products .search-products-col .text { font-size: .8em; color: #666; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;}
  .autocomplete-products-footer { width: 100%; text-align: center; background-color: #f9f9f9; padding: 10px;}
  .autocomplete-products-footer a{ color: #337ab7; font-weight:bold;}
  
  /* search css end */
  
  .our-client{color: 'white';background-Color: 'red';}
  
  .pincode-popup-bx .modal-dialog { margin: 0px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) !important; -o-transform: translate(-50%, -50%) !important; -mox-transform: translate(-50%, -50%) !important; -moz-transform: translate(-50%, -50%) !important; -ms-transform: translate(-50%, -50%) !important; max-width: 500px; width: 100%;}
  .pincode-popup-bx .modal-dialog .modal-content { padding: 25px; border: none;}
  .modal-dialog .modal-content .modal-header { padding: 0px; justify-content: center;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-header .modal-title { font-size: 22px; font-family: Poppins-SemiBold; color: #828282; border: none; padding-bottom: 15px;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-body { padding: 30px 0px;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-body form input { background-color: #ededed; border: none; width: 100%; height: 45px; border-radius: 30px; padding: 0px 15px; font-size: 16px;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-footer { justify-content: space-between; padding: 0px; border: none;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-primary { margin: 0px; background-color: #0b9444; color: #fff; border: 1px solid #0b9444; border-radius: 30px; max-width: 170px; width: 100%; font-size: 16px; height: 45px; }
  .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-primary:hover { background-color: transparent; color: #0b9444; }
  .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-secondary { background-color: transparent; color: #0b9444; border: 1px solid #0b9444; border-radius: 30px; max-width: 170px; width: 100%; font-size: 16px; height: 45px; margin: 0px;}
  .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-secondary:hover{ background-color: #0b9444; color: #fff;}
  .pincode-popup-bx { z-index: 2147483649 !important;}
  
   .tbody_th span { font-weight: bold; display: none; }

   .cool_tiles_pages { padding: 60px 0; } 
   .cool_tiles_pages p, .cool_tiles_pages li { font-size: 16px; color: #747474; line-height: 28px;}  
   .cool_tiles_pages strong { font-size: 18px; padding-bottom: 10px; display: block; }
   .cool_tiles_pages h1{ text-align: center; margin-bottom: 50px; }  
   .cool_tiles_pages h4 { position: relative; padding-bottom: 15px; margin-bottom: 20px; font-size: 22px !important;     margin-top: 40px;}
   .cool_tiles_pages h4:before{ content: ''; width: 20%; height: 5px; background-color: #ffcb08;position: absolute; bottom: 0;}
   .cool_tiles_pages img { width: 500px; margin: 50px auto; display: block; }
   .cool_tiles_pages .btn.btn-primary { background: #ffcb08; color: #000; padding: 10px 30px; border-radius: 25px; font-size: 1.1em; border-color: #ffcb08;}
   .cool_tiles_pages .btn.btn-primary:hover{background-color: transparent; border-color: #ffcb08;}

   .forever_tiles_data { text-align: center; padding: 0 250px; }
   .forever_tiles_page ul{display: flex; justify-content: space-between; flex-wrap: wrap;}
   .forever_tiles_page ul li{width: 45%;}





     /******************************** Carpet Look Page *********************************/
     .withGroutBorder{border: 1px solid #515151;}
     .carpet_looks { max-width: 1200px; margin: 0px auto; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; justify-content: center; padding: 70px 0px; }
     .carpet_looks .carpet-left-sec { width: 30%;}
     .carpet_looks .dynamic-section { padding: 0px 5%; width: 40%;}
     .dynamic-section .carpet-logo { text-align: center;}
     .dynamic-section .carpet-logo img{ height: 100px;}
     .tilecalculation .grout{ text-align: center;}
     .tilecalculation .grout label { font-size: 14px; font-weight: unset; padding-left: 10px;}
     .tilecalculation .img-section{ padding-bottom: 10px;}
     .tilecalculation .img-section .row { margin: 0px; justify-content: center; display: flex; display: -webkit-flex; display: -moz-flex;  display: -ms-flex; flex-flow: wrap; width: 100%;}
     .tilecalculation .img-section .row .col { width: 16.666%; box-sizing: border-box; position: relative; padding: 0px; background-color: #f1f1f1; line-height: 0;    flex: unset; overflow: hidden;}
     .tilecalculation .img-section .row .col img { max-width: 100%; height: 48px; width: 100%; cursor: pointer;}
     .tilecalculation .img-section .row .col input { position: absolute; top: 0px; right: 0px;}
     .tilecalculation .replace-tile { text-align: center;}
     .tilecalculation .replace-tile label { margin: 0px; font-size: 14px; font-weight: unset; padding-left: 10px;}
     .tilecalculation .replace-tile button.btn-primary { max-width: 200px; width: 100%; background-color: #f1f1f1;color: #000;     border: 2px solid #eee;}
     .tilecalculation .replace-tile button.btn-primary:hover{ background-color: #ffcb08; border-color: #ffcb08;}
  
     .carpet_looks .carpet-sec-right { width: 30%; position: relative;}
     .carpet-sec-button-bx { width: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%);}
     .carpet-sec-button-bx ul { list-style: none; margin: 0px; padding: 0px; display: flex; flex-flow: wrap; justify-content: space-between;}
     .carpet-sec-button-bx ul li { width: 48%; margin-bottom: 10px;}
     .carpet-sec-button-bx ul li div { background-color: #ebebeb; color: #222; border-radius: 50px; width: 100%; display: inline-block; text-align: center; height: 42px; line-height: 42px; cursor: pointer; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
     .carpet-sec-button-bx ul li div img{ height: 20px; margin-right: 5px;}
     .carpet-sec-button-bx ul li div:hover{ background-color: #ffcb08; border-color: #ffcb08;}
  
     .carpet_looks h1{ padding-left: 25px; font-family: "Poppins-Regular"; color: #262626 !important; font-size: 30px !important;    position: relative;}
     .carpet_looks h1:before { content: ''; border-left: 5px solid #ffcb08; position: absolute; left: 0; /* top: 0; */ /* bottom: 0; */ top: 7px; height: 30px; }
     .carpet_looks span{ font-size: 26px; color: #ffcb08; margin-top: 10%; display: block; line-height: 1.5;}
     .tile-used-section { padding-top: 30px;}
     .tile-used-section h5{font-weight: 400; font-size: 14px; color: #000; display: block; padding: 20px 0 10px;}
     .tile-used-section .tiles_added { margin: 0px; padding-left: 20px;}
     .tile-used-section .tiles_added li{ font-weight: 400; font-size: 16px; color: #000; margin-bottom: 10px; font-family: "Poppins-Regular";}
  
     .carpet_looks .dynamic-section .measurement label{display: block;}
     .carpet_looks .dynamic-section button{width: 100%; clear: both; margin-bottom: 15px; padding: 9px 25px !important; min-height: unset; line-height: unset; border-radius: 24px; font-weight: 400; border: 1px solid transparent; max-width: 150px; margin-top: 20px;}
     .carpet_looks .dynamic-section button:hover{ background-color: #ffcb08; border-color: #ffcb08;}
     .carpet_looks .measurement { display: flex; justify-content: space-between; padding-top: 20px;}
     .carpet_looks .measurement div{width:48%;}
     .carpet_looks .measurement div p.error.text-danger { font-size: 11px; padding-top: 5px;}
     .carpet_looks .measurement div:nth-child(odd) {margin-right: 2%;} 
     .carpet_looks .measurement div:nth-child(even) {margin-left: 2%;}
     .carpet_looks .measurement div label{ margin: 0px 0px 5px; font-size: 14px; font-weight: unset;}
     .carpet_looks .measurement div input { height: 36px; padding: 5px 10px; min-height: 36px; border: 1px solid #c2c2c2; background-color: transparent; width: 100%;}
     .measurement-button { text-align: center;}
     .carpet_looks .note { text-align: center;}
     .carpet_looks .note h6 { font-size: 14px; line-height: normal;}
  
  
     .carpetlook-product-popup { padding: 0px !important;}
     .carpetlook-product-popup .modal-dialog { max-width: 75%; width: 100%;}
     .carpetlook-product-popup .modal-dialog .modal-header{ padding: 15px !important;}
     .carpetlook-product-popup .modal-dialog .modal-header .modal-title { font-size: 24px !important;}
     .carpetlook-product-popup .modal-body .ais-SearchBox .ais-SearchBox-form .ais-SearchBox-input { width: 100%; height: 40px; padding: 3px 15px; border: solid 1px #ccc; border-radius: 5px;}
     .carpetlook-product-popup .modal-body .ais-Stats .ais-Stats-text { font-size: 16px; padding: 15px 0px; display: block;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list { padding-bottom: 15px; border-bottom: 1px solid #c1c1c1; margin-bottom: 15px;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item { width: 19%; margin-bottom: 15px;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item .search-result-product img {  width: auto; height: 120px;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item .search-result-product .ais-Highlight-nonHighlighted { font-size: 13px;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits button.ais-InfiniteHits-loadMore { border: 2px solid #eee; color: #434343; border-radius: 30px; padding: 10px 30px; float: right;}
     .carpetlook-product-popup .modal-body .ais-InfiniteHits button.ais-InfiniteHits-loadMore:hover{ background-color: #ffcb08; border-color: #ffcb08;}
     .carpetlook-product-popup #preview-modal { max-width: 600px; width: 100%; margin: 0px auto; padding: 20px 15px;}
     .carpetlook-product-popup #preview-modal .modal-body { margin: 0px auto;}
     .carpetlook-product-popup #preview-modal .modal-body .row { max-width: 300px; width: 100%; margin: 0px;}
     .carpetlook-product-popup #preview-modal .modal-body .row .col { padding: 0px; position: relative; overflow: hidden;}
     .carpetlook-product-popup #preview-modal .modal-body .row .col input { position: absolute; top: 0px; right: 0px;}
     .carpetlook-product-popup #preview-modal .modal-body .row .col img { height: 36px; width: 100%; object-fit: cover;}
     

     .areaCalc-cnvrtrSec-section { background-color: #ffcc001a; padding: 70px 0px;}
     .areacalculator-content h1, .areacalculator-content h2 { font-weight: bold; font-size: 30px; margin-bottom: 15px;}
     .areaCalc-cnvrtrSec-row { max-width: 600px; width: 100%; margin: 0px auto; padding: 30px 0px; }
     .areaCalc-cnvrtrSec-row h1{ font-size: 36px; font-weight: 700; margin: 0px 0px 20px; font-family: "Poppins-Bold";}
     .areaCalc-data { background-color: #fff; padding: 25px; border-radius: 8px; box-shadow: 1px 4px 6px 0 rgba(48,48,48,.1); margin-bottom: 10px;}
     .areaCalc-data label { font-size: 12px; color: #606060; line-height: 18px; font-weight: 300; display: block;    }
     .areaCalc-data .areaCalc { width: 100%; margin-bottom: 20px;}
     .areaCalc input { font-size: 14px; font-weight: 500; color: #303030; padding: 0px 0px; height: 30px; box-sizing: border-box; cursor: pointer; border: 0; outline: none!important; width: 100%; border-bottom: 1px solid #d7d7d7;}
     .areaCalc__cnvrtrSec__inputWrapper { display: flex; flex-flow: wrap; justify-content: space-between; align-items: center;}
     .areaCalc__cnvrtrSec__inputWrapper .areaCalc { margin: 0px; width: 180px;  }
     .areaCalc .stateSearchInputId { font-size: 14px; font-weight: 500; color: #303030; height: 30px; box-sizing: border-box; cursor: pointer; border: 0; outline: none!important; width: 100%; border-bottom: 1px solid #d7d7d7; padding: 0px 0px;}
     .areaCalc__cnvrtrSec__inputWrapper .unitsuffel-btn { width: 44px; height: 44px; line-height: 44px; margin: 0 16px; border-radius: 50%; text-align: center; box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.2); background-color: #fff; cursor: pointer; }
     .areaCalc-cnvrtrSec-row .result { background-color: #fff; padding: 25px; border-radius: 8px; box-shadow: 1px 4px 6px 0 rgba(48,48,48,.1);font-size: 16px;}
     .areacalculator-content ol { margin-left: 18px; padding: 0px;}
     .areacalculator-content ol li { font-size: 18px; color: #262626; line-height: 28px;}
     .areacalculator-content ol li h5 { color: #262626; font-weight: bold;}
     .areacalculator-content ol li ol {   margin-left: 40px; margin-bottom: 20px;}
    .areacalculator-content ol li ol li { color: #747474;}
    .areacalculator-content ol li ol li p{ margin-bottom: 5px;}
    .areacalculator-faq { padding-top: 30px; }
    .areacalculator-faq h2{ margin-bottom: 15px;}
    .areacalculator-faq .newacd ul { padding: 0px; margin: 0px; list-style: none; }
    .areacalculator-faq .newacd ul li { margin-bottom: 10px; width: 100%; display: block; background-color: #ccc; color: #333; cursor: pointer; border-radius: 0.15em; transition: background 0.3s ease; }
    .areacalculator-faq .newacd ul li a { width: 100%; display: block; padding: 0.75em; color: #333; position: relative; padding-right: 30px;}
    .areacalculator-faq .newacd ul li a:before { content: '+'; width: 20px; height: 20px; line-height: 20px; font-size: 22px; text-align: center; border-radius: 3px; display: block; position: absolute; top: 12px; right: 10px; }
    .areacalculator-faq .newacd ul li a.active:before{ display: none;}
    .areacalculator-faq .newacd ul li a.active:after { content: '-'; width: 20px; height: 20px; line-height: 20px; font-size: 22px; text-align: center; border-radius: 3px; display: block; position: absolute; top: 12px; right: 10px; }
    .areacalculator-faq .newacd ul li ul.inner.show { padding: 0.75em; background-color: #f1f1f1;}
    .areacalculator-faq .newacd ul li ul.inner.show li{ background-color: #f1f1f1;}
    .areacalculator-faq .newacd ul li ul.inner.show li:last-child { margin-bottom: 0px;}

    .costestimator{ background-color: #e4f5fd;}
    .portlet-body-row { background-color: #fff; padding: 40px 30px; border-radius: 8px; box-shadow: 1px 4px 6px 0 rgba(48,48,48,.1); margin-bottom: 10px; }
    .portlet-body-row .portlet-body-cost { margin-bottom: 25px;}
    .portlet-body-row .portlet-body-cost label { display: block; font-weight: 500;}
    .portlet-body-row .portlet-body-cost .portlet-group { display: flex; flex-flow: wrap; align-items: center; }
    .portlet-body-row .portlet-body-cost .portlet-group .form-control { background-color: #fff; border: 1px solid #e5e5e5; box-shadow: none; border-radius: 0px; height: 40px; padding: 5px 15px; font-size: 14px; width: calc(100% - 50px); }
    .portlet-body-row .portlet-body-cost .portlet-group .portlet-group-addon { background-color: #e5e5e5; width: 50px; height: 40px; text-align: center; line-height: 40px; font-weight: bold; color: #333; }
    .portlet-body-row .portlet-body-cost:last-child { margin-bottom: 0px;}
    .portlet-body-row .portlet-body-cost.form-actions { text-align: right;}
    .portlet-body-row .portlet-body-cost.form-actions .calculate-button { background-color: #0f9243; border: solid 1px #0f9243; color: #fff; font-weight: bold; padding: 0px 15px; height: 40px; font-size: 14px; border-radius: 30px;}
    .portlet-body-row .portlet-body-cost.form-actions .calculate-button:hover { background-color: transparent; border: solid 1px #0f9243; color: #0f9243; }
    .portlet-body-row .portlet-body-cost.form-actions .reset-button { background-color: #ffcb08; border: solid 1px #ffcb08; color: #333; font-weight: bold; padding: 0px 15px; height: 40px; font-size: 14px; border-radius: 30px;}
    .portlet-body-row .portlet-body-cost.form-actions .reset-button:hover { background-color: transparent; border: solid 1px #ffcb08; color: #ffcb08;}
    .portlet-body-row .portlet-body-cost.form-actions button { margin-left: 10px;}
    .areaCalc-cnvrtrSec-row-result { background-color: #fff; padding: 25px 30px; border-radius: 8px; box-shadow: 1px 4px 6px 0 rgba(48,48,48,.1); }
    .areaCalc-cnvrtrSec-row-result div { font-size: 24px; font-weight: bold; color: #000;}
    .costesti_sub { padding-top: 30px;}
    .costestimator-prod-row { display: flex; flex-flow: wrap; align-items: center; margin-bottom: 25px; background-color: #fff; }
    .costestimator-prod-img { width: 50%; padding: 15px;}
    .costestimator-prod-text { width: 50%; padding: 15px; }
    .costestimator-prod-text h3 { margin-bottom: 15px;}
    .costestimator-prod-text p:last-child{ margin-bottom: 0px;}
    .costestimator-prod-row:nth-child(odd) .costestimator-prod-img { order: 1;}
    .costestimator-prod-row:nth-child(odd) .costestimator-prod-text { order: 2;}
    .costestimator-prod-row:nth-child(even) .costestimator-prod-img { order: 2;}
    .costestimator-prod-row:nth-child(even) .costestimator-prod-text { order: 1;}




    .latestNewsWrap { max-width: 1200px; width: 100%; margin: 70px auto; display: flex; flex-flow: wrap; align-items: center; background-color: #f1f1f1; position: relative;}
    .latestNewsWrap .newsImg { width: 50%;}
    .latestNewsWrap .newsImg img{ width:100%;}
    .latestNewsWrap .newsWrap { width: 50%; padding: 25px;}
    .latestNewsWrap .newsWrap .newsHead { font-family: "Poppins-SemiBold"; font-size: 24px;}
    .latestNewsWrap .newsWrap .newsDate { font-size: 16px; padding: 15px 0px;}
    .latestNewsWrap .newsWrap .newsContant { font-size: 16px; line-height: 1.8;}
    .latestNewsWrap .backButton { position: absolute;  bottom: 25px; right: 25px;}
    .latestNewsWrap .backButton .button a { text-decoration: none !important; background-color: #707070; color: #fff; text-align: center; padding: 8px 25px; font-size: 16px; border-radius: 10px; transition: .3s all ease-in-out; display: inline-block; }
    .latestNewsWrap .backButton .button a:hover{ background-color: #048d3b;}


    .shipping_policy_page { width: 100%; display: inline-block;}
    .obl-policy-section { width: 100%; display: inline-block;}
    .obl-policy-section .abt-content { padding: 30px 0px 60px;}
    .obl-policy-section .abt-content .corAdd h1 { margin: 0px 0px 15px; font-family: Poppins-Bold !important; display: block; font-size: 28px !important;}
    .obl-policy-section .abt-content .corAdd h2, .obl-policy-section .abt-content .corAdd h3 { display: block !important; font-size: 18px !important; color: #333 !important; font-weight: bold;text-transform: capitalize; font-family: "Poppins-Regular" !important; margin: 0px 0px 10px !important; line-height: 24px !important;}
    .obl-policy-section .abt-content .corAdd p { margin: 0px 0px 15px !important; font-family: "Poppins-Regular" !important; color: #333 !important; font-size: 14px !important; line-height: 24px !important;}
    .obl-policy-section .abt-content .corAdd p:last-child { margin-bottom: 0px;}
    .obl-policy-section .abt-content .corAdd { padding-bottom: 15px !important;}

  
  /************************************************************** Responsive CSS *********************************************************/
  .filter-close-bx{ display: none;}
  
  .product_store_section { background-color: #f1f1f1; margin-top: 25px; padding: 15px;}
  .product_store_section h4 { font-size: 18px !important;}
  .product_store_row { display: flex; flex-flow: wrap; align-items: center; padding-top: 15px;}
  .product_store_figure { width: 100px;     max-height: 100px; overflow: hidden;}
  .product_store_figure img{ width: 100%; margin: 0px;}
  .product_store_text { width: calc(100% - 100px); padding-left: 15px;}
  .product_store_text p{ margin: 0px;}
  .product-rating-row { display: flex; flex-flow: wrap; align-items: center;}
  .product-rating-row div span { font-size: 25px !important;}

  .style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover;}
  .error-text-bx a svg { transform: rotate(90deg); -webkit-transform: rotate(90deg); -o-transform: rotate(90deg); -ms-transform: rotate(90deg); -moz-transform: rotate(90deg); vertical-align: sub;}







  @media screen and (max-width: 1600px)
  {
      .footer-top .foot-col .Social-Icons li { padding: 0px 5px 0px 0px;}
      .features-row{ width:100%;}
      .product-listing-col .cate-pro-figure a{ height: 260px;}

	.menu-slider { height: 190px;}
      
  }

  @media screen and (max-width: 1440px)
  {
    .carpet_looks .dynamic-section { padding: 0px 3%;}
    .carpet_looks h1{ font-size: 28px !important;}
    .carpet_looks span { font-size: 24px;}
    .tilecalculation .grout label, .tilecalculation .replace-tile label, .carpet_looks .dynamic-section .measurement label, .carpet_looks .note h6 { font-size: 14px;}

	.menu-slider { height: 170px;}

  }
  
  @media screen and (max-width: 1366px)
  {
      h1{ font-size:34px;}
      h2{ font-size:30px;}
      h3{ font-size:26px;}
      h4{ font-size:24px;}
      
      .review-row .review-text p{ font-size:18px;}
      .signature-company-left-section h2, .signature-company-right-section .video-iframe-col strong, .pat-color-content h2, .our-client h2, .find-dealer-row h2, .latest-updates-section h2{ font-size: 30px;}
      .productdetail .slick-prev { left: 0 !important; }
      .productdetail .slick-next {right: 0 !important;}
      .pro_similartiles #syte-similar-items-container .syte-slider-arrow.syte-slider-arrow-right, .pro_youmayalso #syte-personalization-container .syte-slider-arrow.syte-slider-arrow-right{right: 0;}
      .pro_similartiles #syte-similar-items-container .syte-slider-arrow.syte-slider-arrow-left, .pro_youmayalso #syte-personalization-container .syte-slider-arrow.syte-slider-arrow-left { left: 0 !important; }

	.menu-slider { height: 160px;}

  }
  
    @media screen and (max-width: 1280px)
    {
        .menu-slider { height: 150px;}

    }


  @media screen and (max-width: 1199px)
  {
      .container{max-width:100% !important; padding: 0px 15px !important;}
      .nav-row ul.product-menu>li>a{ font-size:12px;}
      .menu-slider .slick-prev { width: 45px; height: 45px; left: -15px; z-index: 0;}
      .menu-slider .slick-prev:before { width: 15px; height: 15px;}
      .menu-slider .slick-next { width: 45px; height: 24px; right: -15px; z-index: 0;}
      .menu-slider .slick-next:before { width: 15px; height: 15px;}
      p { font-size: 16px; line-height: 26px;}
      h1{ font-size:30px;}
      h2{ font-size:28px;}
      h3{ font-size:24px;}
      h4{ font-size:22px;}
      .signature-company-left-section h2, .signature-company-right-section .video-iframe-col strong, .pat-color-content h2, .our-client h2, .find-dealer-row h2, .latest-updates-section h2{ font-size: 28px;}
      .hp-spotlight-bx .otb-pro-content {  max-width: 500px;}
      .hp-spotlight-bx .otb-pro-content span { font-size: 30px;}
      .hp-spotlight-bx .otb-pro-content .pointer-text{ font-size: 24px;}
      .trending-slider .t-item .trending-figure{ margin:10px;}
      .trending-slider .t-item .t-title{ font-size:16px;}
      .hp-features h4{ padding-bottom:15px;}
      .features-row .features-col{ padding: 10px;}
      .features-row .features-col .f-text{ font-size:16px;}
      .pat-color-wrap .pat-color-content p { margin: 25px 0px;}
      .testimonials-section .slick-prev { width: 20px; left: -10px;}
      .testimonials-section .slick-prev:before{ width:16px; height:16px;}
      .testimonials-section .slick-next { width: 20px; right: -10px;}
      .testimonials-section .slick-next:before{ width:16px; height:16px;}
      .our-client h2 { padding-bottom: 30px;}
      .our-client ul li img { height: 55px;}
      .find-dealer-row h2{ padding-bottom:20px;}
      .find-dealer-row form .form-group input { padding: 0px 160px 0px 15px; height: 60px; font-size: 16px;}
      .find-dealer-row form .form-group .locate-btn { max-width: 150px; height: 60px; font-size: 20px;}
      .category-view .product-listing-wrap {padding: 0px 15px;}
      .product-listing-Three .product-listing-col{ width:49%;}
      .product-listing-Three .product-listing-col:nth-child(3n+2) { margin: 0px 0% 20px;}
      .product-listing-Three .product-listing-col:nth-child(odd) { margin-right: 1%;}
      .product-listing-Three .product-listing-col:nth-child(even) { margin-left: 1%;}
      .site_map_flex{grid-template-columns: repeat(5, 1fr);}
      .dr_download_data{padding-right: 15px;}
      .download_content_brifdata{grid-template-columns: repeat(3, 1fr);}
      .catalog_data .flex-css{margin-bottom: 25px;}
      .PincodeBasedQuotation form{padding-right: 0;}
      .product_compare_page tr td .prod_imageItem .prod_imageItem_icon{top: 0;}
      .product-listing-col .cate-pro-figure a{ height: 240px;}


      .carpetlook-product-popup .modal-dialog { max-width: 95%;}
     
      .carpet_looks h1 { font-size: 22px !important;}
      .carpet_looks span { font-size: 18px;}
      .carpet_looks .dynamic-section { padding: 0px 2%;}
      .tilecalculation .img-section .row .col img { height: 42px;}
      .latestNewsWrap .backButton { position: unset; padding: 0px 20px 20px; width: 100%; text-align: right;}
      .latestNewsWrap .newsWrap .newsHead { font-size: 20px;}
      .latestNewsWrap .newsWrap .newsDate { font-size: 14px; padding: 15px 0px;}
      .latestNewsWrap .newsWrap .newsContant { font-size: 14px;}

      .menu-slider { height: 170px;}
      .header-right-col .h-main-menu ul li { padding-left: 15px;}

  }
  
    @media screen and (max-width: 1100px)
    {
        .menu-slider { height: 155px;}
    }
  
  @media screen and (max-width: 991px)
  {
      h1{ font-size:26px;}
      h2{ font-size:24px;}
      h3{ font-size:24px;}
      h4{ font-size:20px;}
      .signature-company-left-section h2, .signature-company-right-section .video-iframe-col strong, .pat-color-content h2, .our-client h2, .find-dealer-row h2, .latest-updates-section h2{ font-size: 24px;}
      .hp-spotlight-bx .otb-pro-content span { font-size: 25px;}
      .hp-spotlight-bx .otb-pro-content .pointer-text{ font-size: 20px;}
      .hp-spotlight-bx .otb-pro-content p { margin: 10px 0px 15px;}
      .trending-slider .t-item { width: 100%; margin: 0px auto 30px;}
      .default-btn { Padding: 10px 15px; font-size: 16px;}
      .review-row .review-text p { font-size: 16px; line-height: 26px;}
      .review-row .review-text { padding: 45px 15px 15px;}
      .review-row .author-bx h6 { font-size: 16px; margin: 0 0 7px;}
      .our-client ul li { width: 25%; padding: 10px; margin-bottom: 15px;}
      .our-client ul li img { height: 45px;}
      .our-client a.indus-btn{ margin: 15px auto 0px;}
      .latest-up-row .latest-col { width: 100%;}
      .latest-up-row .latest-col:nth-child(odd) { margin: 0px 0px 50px;}
      .latest-up-row .latest-col:nth-child(even) { margin-left: 0%;}
      .latest-updates-section h2 { padding-bottom: 30px;}
      .home-post .listingText a.post-tiles { margin: 15px 0; font-size: 18px;}
      .home-post .listingText .listingButton a{ font-size:16px;}
      .latest-up-row .latest-col h6 a{ font-size:20px;}
      .styletiles-slider .slick-dots{ bottom: 20px;}
      .styletiles-slider .slick-dots li.slick-active{ width:22px; height:22px;}
      .styletiles-slider .slick-dots li{ margin: 0px 5px;}
      .acountdataleft { width: 250px; }
      .acountdataright { width: calc(100% - 280px); }
      .site_map_flex{grid-template-columns: repeat(4, 1fr);}
      .productpara{padding-left: 0;}
      .thankyou_page_data{padding: 50px;}

    .carpet_looks .carpet-left-sec { width: 100%;}
    .carpet_looks span { margin-top: 30px;}
    .tile-used-section { padding-top: 10px;}
    .carpet_looks .dynamic-section { padding: 15px 0% 0px; width: 100%; max-width: 500px;}
    .carpet_looks .carpet-sec-right { width: 100%; padding-top: 30px;}
    .carpet-sec-button-bx { position: unset; transform: unset; -webkit-transform: unset; -o-transform: unset; -ms-transform: unset; -moz-transform: unset;}
    .tilecalculation .img-section .row .col img {  height: 50px;}

    .menu-slider { height: 180px;}
        
  }
  
  
  @media screen and (max-width: 980px)
  {
      .header-section { position: fixed; width: 100%; top: 0px; z-index: 9;}
      .menu-slider { padding-top: 65px;}
      .page-wrapper.header-head-bx { top: 0px;}
      .ft-fromUs-row .fr-Title { width: 100%; text-align: center; padding-bottom: 15px;}
      .ft-fromUs-row .us-form-col { width: calc(100% - 0px);}
      .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn{ width:100%;}
      .ft-fromUs-row .us-form-col form .form-group { margin: 0px 0px 10px; width: 100%; padding-right: 0px;}
      .ft-fromUs-row .us-form-col form .form-group input { width: 100%;}
      .ft-fromUs-row .us-form-col form .form-group.button { width: 100%; text-align: center;}
      .ft-fromUs-row .us-form-col form .form-group.button .ft-submit-btn { width: auto; padding: 0px 50px;}
      .footer-top .foot-col { width: 100%; padding: 15px 0px 0px;}
      .footer-top .foot-col .select-lang { padding-top: 15px;}
      .footer-top .foot-col .f-heading h6 { font-size: 18px; color: #fff; margin: 0px 0px 10px;}
      .footer-top.ft-bottom .foot-col:nth-child(5) { width: 100%;}
      .footer-top.ft-bottom .foot-col:nth-child(6) { width: 100%;}
      .footer-top.ft-bottom .foot-col .maplocate img { height: 40px;}
      .footer-top .foot-col .select-lang select{ width:100%;}
      .footer-top.ft-bottom { border-top: none; padding: 0px; margin-top: 0px;}
      .footer-top .foot-col ul{ display:none; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .footer-top .foot-col .foot-box { padding-top: 15px;}
      .footer-top .foot-col .f-heading{ position:relative;}
      .footer-top .foot-col .f-heading:before{ content: ""; position: absolute; right: 0px; top: 50%;  height: 10px; width: 10px; border-left: 1px solid #d5d5d5; border-bottom: 1px solid #d5d5d5; transform: translateY(-50%) rotate(-45deg); -webkit-transform: translateY(-50%) rotate(-45deg); -moz-transform: translateY(-50%) rotate(-45deg); -ms-transform: translateY(-50%) rotate(-45deg); -o-transform: translateY(-50%) rotate(-45deg);}
      .footer-top .foot-col .f-heading.active-link:before{ content: ""; position: absolute; right: 0px; top: 12px;  border-left: 1px solid #fc0; border-bottom: 1px solid #fc0; transform: translateY(-50%) rotate(135deg); -webkit-transform: translateY(-50%) rotate(135deg); -moz-transform: translateY(-50%) rotate(135deg); -ms-transform: translateY(-50%) rotate(135deg); -o-transform: translateY(-50%) rotate(135deg);}
      .footer-top .foot-col .f-heading.active-link+ul { display: block; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .footer-top .foot-col .f-heading.active-link h6 { color: #fc0;}
      .nav-row ul.product-menu>li>a{ font-size:14px;}
      .footer-top .foot-col ul.Social-Icons { display: flex !important;}
      
      .custom-head-mobile{ display:block;}
      .custom-head-mobile { display: flex; width: calc(100% - 180px); align-items: center; justify-content: right;}
      .custom-head-mobile .custom-head-col { padding-left: 20px;}
      .custom-head-mobile .custom-head-col img{ height:22px;}
      
      .category-view .main-sidebar{ position: fixed; top: 0px; left: -85%; max-width: 85%; width: 100%; z-index: 9; height: 100%; background-color: #fff; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s;}
      .category-view .product-listing-wrap { width: calc(100% - 0px);}
      .listing-Wrap .listing-col{ width:100%;}
      .listing-Wrap .listing-col:nth-child(odd) { margin-right: 0%;}
      .listing-Wrap .listing-col:nth-child(even) { margin-left: 0%;}
      .listing-Wrap .listing-col .listingText{ padding:15px;}
      .main-sidebar.open-filter { left: 0px;}
      .filter-by-mobile{ display:flex;}
      .pro-filter-row{position:unset;}
      .pro-filter-row ul li .filters-by.open-filter{ width: calc(100% - 75px);}
      .filter-close-bx {  text-align: right;  padding: 10px 10px 0px; display: block;}
      .filter-close-bx img{ height: 20px;}

      header { padding: 0px;}
      .header-row { overflow: hidden; position: relative; padding:15px 0px;}
      #mobile_search_div { padding: 5px 0px; display: flex; flex-flow: wrap; width: 100%; align-items: center; background-color: #202020; position: absolute; top: 5px; right: -100%; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s; -o-transition: all 0.5s; z-index: 99;}
      #mobile_search_div.m_searchbar_a { right: 0px;}
      #mobile_search_div form { width: 100%; position: relative;}
      #mobile_search_div form .search-input { width: 100%; border: 1px solid #bdbdbd; background-color: #4e4e4e; padding: 0px 40px 0px 35px; height: 40px; border-radius: 3px; color: #fff;}
      #mobile_search_div form .searchibtn { position: absolute; top: 0px; left: 0px; width: 30px; height: 40px; background-color: transparent; border: none; border-radius: 5px;}
      #mobile_search_div form .form-group:before { content:''; background-repeat:no-repeat;position: absolute; top: 0px; left: 0px; width: 30px; height: 40px; background-color: transparent; border: none; border-radius: 5px; background-position: center;}
      #mobile_search_div form .searchibtn i { color: #bdbdbd; font-size: 18px;}
      #mobile_search_div #m_s_close_btn { width: 40px; height: 40px; background-color: transparent; border: none; border-radius: 5px; position: absolute; top: 0px; right: 0px;}
      #mobile_search_div #m_s_close_btn i { font-size: 20px; color: #bdbdbd;}
      #mobile_search_div #m_s_close_btn:after { content: ''; height: 2px; border-radius: 2px; background-color: #878787; display: block;   width: 24px; margin: 0px auto; transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transition: all .25s ease-in-out; -webkit-transition: all .25s ease-in-out; -moz-transition: all .25s ease-in-out; -ms-transition: all .25s ease-in-out; -o-transition: all .25s ease-in-out;}
      #mobile_search_div #m_s_close_btn:before { content: ''; height: 2px; margin-bottom: 6px; border-radius: 2px; background-color: #878787; display: block;  width: 24px; margin:0px auto -2px;  transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg); transition: all .25s ease-in-out; -webkit-transition: all .25s ease-in-out; -moz-transition: all .25s ease-in-out; -ms-transition: all .25s ease-in-out; -o-transition: all .25s ease-in-out;}	
      .treding-t .t-item .txt, .trending-products-section .t-item .txt{ font-size:16px;}
      
      .auto-search-bar { padding: 0px; top: 60px;}
      .filter-by-mobile{ display:flex;}
      .shopping_cart { display: inherit}
      .shopping_cart_data_right,.shopping_cart_data_left{width: 100%;}
      .store_locator_flex_distributor .distributor_card{width: 49%;margin-bottom: 15px;}
      .aboutus_page_data{display: inherit; background: transparent;}
      .aboutus_page_data div{width: 100%;}
      .aboutus_page_data h4 { padding-right: 0; padding-top: 20px; }
      .aboutus_page_data h4 span { color: #ffcb08; font-size: 35px !important; line-height: 45px !important; }
      .aboutus_page .productpage_title { margin: 30px 0 15px; }
      .Pressrelease_card { width: 48%;}
      .career_image .career_image_col { width: 49%; margin-bottom: 10px; }
      .custom-head-col.cart-bx span { background: #ffcb37; width: 18px; height: 18px; position: absolute; text-align: center; line-height: 19px; font-size: 10px; font-weight: bold; border-radius: 50%; top: 12px; }
      .same_look .same_look_wrap { padding: 30px 0;}
      .same_look .same_look_wrap p{font-size: 18px; line-height: normal; margin: 25px 0;}
      .product_compare_page table {overflow: auto; display: block; width: 100%;}
      .product_compare_page .table tbody>tr>td, .product_compare_page .table tbody>tr>th { width: 230px; }

      .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item { width: 24%;}

      .latestNewsWrap .newsImg { width: 100%;}
      .latestNewsWrap .newsWrap { width: 100%; padding: 20px;}
      .latestNewsWrap .newsWrap .newsContant { font-size: 14px;}
      .mobile-icon.mobile-icon-open ~ .product-menu:before { content: '';  width: 100%; height: 100%; background-color: #000000ad; display: block; position: fixed; z-index: -1;}

      .menu-slider { height: 230px;}
      .pro-filter-row ul li .color-row>li { color: #373737 !important;}
  }
  
@media screen and (max-width: 800px)
{
	.menu-slider { height: 210px;}

}
  
  
  
  @media screen and (max-width: 767px)
  {
      .hp-features h1 { font-size: 22px !important; margin-bottom: 10px;}
      .footer-top.ft-bottom .foot-col .maplocate img { height: 30px;}
      .obl-blog-row .blog-article { width: calc(100% - 0px); padding:0px 0px 30px 0px;}
      .obl-blog-row .blog-aside { width: 100%;}
      .hp-otb-pro { padding: 40px 0px; background-color: #fff;}
      .hp-otb-pro .container{ padding:0px;}
      .trending-products-section{ padding: 40px 0px 30px;}
      .trending-tiles-row h2 { padding-bottom: 10px;}
      .trending-slider { padding-top: 15px;}
      .hp-spotlight-bx .otb-pro-fig { width: 50%; display: none;}
      .hp-spotlight-bx .otb-pro-content { padding: 25px 15px;  width:100%; text-align: center;}
      .hp-spotlight-bx .otb-pro-content p.ptag{ margin-bottom: 0px;}
      .hp-spotlight-bx .otb-pro-content .tiger-mahendra-sir { display: block; margin-bottom: 15px;}
      .hp-spotlight-bx .otb-pro-content p.ptag a { max-width: 176px; width: 100%;}
      .hp-spotlight-bx .otb-pro-content .otp-pr-cont-figure { max-width: 100px;}
      .hp-spotlight-bx .otb-pro-content{ max-width: 100%;}
      .hp-spotlight-bx .otb-pro-content .pointer-text { font-size: 20px !important; margin: 10px 0px 15px;}
      .hp-features { padding: 35px 0px;}
      .home-color-tile-section { padding: 35px 0px;}
      .features-row { justify-content: center;}
      .features-row .features-col { width: 32%; margin: 0px 0% 15px;}
      .features-row .features-col:nth-child(3n+2){ margin-left: 2%; margin-right: 2%;}
      .pattern-section { padding: 40px 0px;}
      .pat-color-mobile-fig{ display:block;}
      .pat-color-wrap .pat-color-content { width: 100%; padding-right: 0px;}
      .pat-color-wrap .pat-color-figure{ display:none;}
      .testimonials-section { padding: 40px 0px;}
      .client-section { padding: 20px 0px 40px;}
      .our-client ul li { width: 33%;}
      .find-dealer-section{ padding:40px 0px;}
      .obl-experience-row{ padding:40px 0px;}
      .obl-experience-row .obl-matterport-text-col { width: 100%; padding: 0px 0px 25px;}
      .obl-experience-row .obl-matterport-video-col { width: 100%; padding-left: 0px;}
      .latest-updates-section{ padding:40px 0px;}
      .home-post{ padding:15px;}
      .category-sorting .tilecountis-bx, .sorts-bx{ display:none;}
      .category-sorting{justify-content: center;}
      .category-switch{width: 100%;}
      .product-listing-Three .product-listing-col{ width:100%;}
      .product-listing-Three .product-listing-col:nth-child(3n+2) { margin: 0px 0% 20px;}
      .product-listing-Three .product-listing-col:nth-child(odd) { margin-right: 0%;}
      .product-listing-Three .product-listing-col:nth-child(even) { margin-left: 0%;} 
      .product-listing-Two .product-listing-col{ width:100%;}
      .product-listing-Two .product-listing-col:nth-child(odd){ margin-right:0%;}
      .product-listing-Two .product-listing-col:nth-child(even){ margin-left:0%;}
      .tile-content-row p iframe{ width:100%;}
      .tile-content-row h2 { font-size: 20px; margin: 20px 0px 10px;}
      .amshopby-filter button.clear-all-btn {  position: unset; text-align: left; margin-bottom: 10px; width: 100%;}
      .amshopby-filter ul{ padding:0px;}
      .amshopby-filter ul li { padding: 0 30px 0 10px; margin: 0 5px 6px 0;}
      .signature-company-left-section { width: 100%;}
      .signature-company-right-section { width: 100%; padding-left: 0px;}
      .find-dealer-row h2 { padding-bottom: 0px;}
      .latest-up-row .latest-media { width: 100%; margin: 0% 0px 30px;}
      .latest-up-row .latest-blog { width: 100%; margin-left: 0;}
      .treding-t .t-item, .trending-products-section .t-item { width: 90% !important; margin: 0px auto 30px;}
      .treding-t .t-item .txt, .trending-products-section .t-item .txt { font-size: 14px;}
      .style-tiles-section .slick-dots li { width: 12px; height: 12px; margin: 0px 3px;}
      .style-tiles-section .slick-dots li.slick-active { width: 15px; height: 15px;}
      .style-tiles-section .slick-dots{bottom:15px;}
      
      .auto-search-row .search-col-left { width: 100%; padding: 10px; max-height: 220px; height: 100%; overflow: auto;}
      .auto-search-row .search-col-right { width: 100%; border-top: solid 1px #eee; border-left: none; max-height: 240px; height: 100%; overflow: auto;}
      .aa-dataset-products .search-products-col { width: 100%;}
      .aa-dataset-products .search-products-col a{ padding:10px;}
      .search-col-left ul li a{ padding: 2px 10px;}
      .search-col-left .aa-dataset { padding-bottom: 10px;}
      .area-wrap { padding: 1em 0 0px;}
      .area-thumb img { max-width: 80px;}
      .acountdata {display: inherit;}
      .acountdataleft{width: 100%;margin-bottom: 40px;}
      .acountdataright { width: 100%; }
      .acountdataright .form-control[type=file]:not(:disabled):not([readonly]){width: 100%;}
      .thead_th {display: none;}
      .tbody_th span { display: inline-block; }
      thead, tbody, tfoot, tr, td, th { display: block; border: none !important; padding: 1px 0 !important; }
      tr{margin-bottom: 15px;}
      .orderviewpage .text_inherit td {text-align: left !important;}
      p, a{font-size: 14px !important; line-height: 22px;}
      .dashboard-banner_data ol li { font-size: 14px; line-height: 22px;}
      .checkout_cart_page{padding: 30px 0;}
      .estimate_mobile_view{display: block;}
      .shipping_page .shopping_cart_data_right{display: none;}
      .offcanvas-body .shipping_page .shopping_cart_data_right{display: block;}
      .react_formicon svg{margin: 0;}
      .Pressrelease_page h5{margin-top: 30px;}
      .investor_page {padding: 20px 0 40px;}
      .investor_page .productpage_title { margin: 0 0 20px; }
      .sub_accordion_investor .accordion-button{padding-left: 15px; padding-right: 15px;}
      .sub_accordion_investor thead, .sub_accordion_investor tbody, .sub_accordion_investor tfoot, .sub_accordion_investor tr, .sub_accordion_investor td, .sub_accordion_investor th {display: revert !important;border: 1px solid #e6e6e6 !important;padding: inherit !important;}
      .investor_accordion .accordion-item {margin-bottom: 2px;}
      .career_page .productpage_title { margin: 30px 0 20px; }
      .career_image { margin-top: 30px;}
      .career_page { padding: 0 0 30px; }
      .contactus_page .contactus_data{display: inherit;}
      .contactus_page .contactus_data_con{width: 100%;}
      .contactus_page .contactus_data_con h3{margin-top: 30px;}
      .contactus_page { padding: 30px 0; }
      .contactus_page .contactus_data{margin-top: 30px;}
      .site_map_flex{grid-template-columns: repeat(3, 1fr);}
      .tbody_th td{width: auto !important;}
      .shopping_cart_data_left .tbody_th_item {display: inline-grid;margin-left: 20px;margin-top: 15px;}
      .download_content_brifdata{grid-template-columns: repeat(2, 1fr);}
      .productslider{padding-right: 0;}
      .same_look_data h2, .same_look_data span {display: none;}
      .same_look .same_look_img{padding-left: 0;}
      .same_look .same_look_img .default-btn label{ font-family: "Poppins-SemiBold";}
      .same_look .same_look_img .default-btn:hover label{ color:#0F9243;}
      .same_look .same_look_data{padding-right: 0;}
      .same_look .same_look_wrap{display: inherit; text-align: center;}
      .same_look_img h2, .same_look_img span{display: block;}
      .same_look_data h2, .same_look_data span { display: none; }
      .same_look_img img{padding: 25px 0;}
      .same_look_img span { width: max-content; margin: 0 auto; }
      .same_look .same_look_wrap p{margin: 0; margin-top: 15px;}
      .right_sidebar_form{display: none;}
      .mobile_view_footericon{display: block;}
      .PincodeBasedQuotation { padding: 30px 0; }
      .productdetail .productpage_title span, .productdetail .prd-heading.addIcon span, .productdetail .prd-heading.addIcon{font-size: 25px;}
      .product_compare_page .table tbody:nth-child(2) tr th,.product_compare_page .table tbody:nth-child(2) tr td,.product_compare_page .table tbody:nth-child(1) tr td { padding: 5px !important;}
      .product_compare_page .table tbody:nth-child(1) tr td{border-bottom: 1px solid #d1d1d1 !important}
      .product_compare_page .table tbody tr{margin-bottom: 0 !important; padding: 0 !important;}
      .product-listing-col .cate-pro-figure a{ height: 200px;}
      .signature-company-left-section h2, .signature-company-right-section .video-iframe-col strong, .pat-color-content h2, .our-client h2, .find-dealer-row h2, .latest-updates-section h2 {
        font-size: 22px;
    }

    .areaCalc-cnvrtrSec-section { padding: 50px 0px;}
    .obl-experience-row .obl-matterport-text-col h3{font-size: 22px !important;}
    .carpet_looks { padding: 40px 0px;}
    .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item { width: 32%;}
    .areaCalc__cnvrtrSec__inputWrapper .areaCalc { width: 40%;}

    .costestimator-prod-row:nth-child(even) .costestimator-prod-img { order: 1;}
    .costestimator-prod-row:nth-child(even) .costestimator-prod-text { order: 2;}
    .costestimator-prod-img { width: 100%; padding: 15px 15px 0px;}
    .costestimator-prod-text { width: 100%;}
    .costestimator-prod-text h3 { margin-bottom: 10px;}
    .obl-policy-section .abt-content .corAdd h1 { font-size: 22px !important;}
    .category-menu .slick-arrow{ display: none !important;}
    .home_testimonials .slick-slider { width: 90%;  margin: 0px auto;}
    .home_testimonials .slick-slider .slick-prev{ left: -25px;}
    .home_testimonials .slick-slider .slick-next{ right: -25px;}
    .custom-head-mobile .custom-head-col { padding-left: 15px;}

  }
  
  @media screen and (max-width: 639px)
  {
    .custom-head-mobile .custom-head-col { padding-left: 12px;}
      .hp-spotlight-bx .otb-pro-content h1{ font-size:22px;}
      .custom-head-mobile .custom-head-col { padding-left: 15px;}
      .our-client ul li img { height: 40px;}
      .pat-color-wrap .pat-color-content p { margin: 15px 0px;}
      .review-row .author-bx { padding: 30px 15px 0;}
      .home-post { margin-left: 0px; width: 100%; margin-top: 0px; margin-bottom: 15px;}
      .find-dealer-row form .form-group input{ height:50px; padding: 0px 110px 0px 15px;}
      .find-dealer-row form .form-group .locate-btn { max-width: 100px; height: 50px; font-size: 18px;}
      .testimonials-section .slick-slide { padding: 0;}
      .testimonials-section .slick-list { padding: 40px 0px 0px !important;}
      .tile-content-row p iframe{ height:180px;}
      .listing-Wrap .listing-col .listingImg { width: 100%; text-align: center;}
      .listing-Wrap .listing-col .listingText { width: calc(100% - 0px);}
      .listing-Wrap .listing-col .listingText .listingHead a div{ font-size:18px;}
      .hp-spotlight-bx .otb-pro-content span { font-size: 20px;}
      .latest-up-row .latest-col .listingImg img { height: auto;}
      .pincode-popup-bx .modal-dialog{ max-width:95%;}
      .pincode-popup-bx .modal-dialog .modal-content{ padding:0px;}
      .modal-dialog .modal-content .modal-header{ padding:15px;}
      .product_defaultModal .modal-header{ padding-left:0 !important;padding-right:0 !important;}  
      .product_defaultModal .modal-content { border-radius: 0; padding: 10px; }                  
      .pincode-popup-bx .modal-dialog .modal-content .modal-header .modal-title { font-size: 16px; padding-bottom: 0px;}
      .pincode-popup-bx .modal-dialog .modal-content .modal-body { padding: 20px 15px;}
      .pincode-popup-bx .modal-dialog .modal-content .modal-footer { padding: 0px 15px 15px;}
      .orderinformation {display: inherit;}
      .orderinformation div {width: 100%;}
      .viewordertopstrip{flex-wrap: wrap;}
      .viewordertopstrip p{width: 48%;}
      /* thead, tbody, tfoot, tr, td, th { display: block; border: none !important;} */
      /* tr{margin-bottom: 20px;} */
      .productdetail .breadcrumb .breadcrumb-item {font-size: 12px;padding-left: 2px;}
      .prolocation .input-group{background-color: #818181;}
      .productpara .accordion-button, .productpara .accordion-body {padding: 10px;}
      .pr_loc_total storng{font-size: 16px; padding-left: 5px;}
      .pro_about.Pro_Other p{width: 100%;padding-left: 0;}
      .pro_about.Pro_Other p span { position: initial; left: 270px; color: #424242; }
      .review_images img { width: 140px; height: 100px; object-fit: cover; margin-right: 5px; }
      .pro_writereview_content {margin: 10px 0;}
      .pro_writereview .productpage_title, .pro_youmayalso .productpage_title { margin-bottom: 20px; }
      .productpage_title span { font-size: 20px; }
      .pro_writereview { padding-top: 10px; }
      .pro_youmayalso .slick-slide { padding: 0; }
      .productpage_title1 strong,.prd-heading.addIcon, .prd-heading.addIcon span { font-size: 18px;}
      .productpage_title1 { margin: 25px 0 15px; border-bottom: 1px solid #82828238; padding-bottom: 10px; }
      .prosimilartiles_item { padding: 0; }
      .pro_similartiles .productpage_title { margin-bottom: 20px; }
      .testimonials-section { padding: 20px 0px; }
      .testimonials-section .slick-slider .slick-list { padding: 20px 0px 0px !important; }
      .pro_similartiles{margin-bottom: 40px;}
      .pro_youmayalso{margin-top: 40px;}
      .shopping_cart_data_right, .shopping_cart_data_left{padding: 0;}
      .shopping_cart{margin-bottom: 20px;}
      .item_data_cart img { height: 80px; margin-right: 0; display: block; margin-bottom: 10px; }
      .item_data_cart{display: inherit;}
      .shipping_page .nav-tabs .nav-item .nav-link.active, .shipping_page .nav-tabs .nav-item .nav-link{font-size: 16px;}
      .shipping_page .nav-tabs .nav-item .nav-link.active:before, .shipping_page .nav-tabs .nav-item .nav-link:before{
        height: 4px;
      }
      .shipping_page .nav-tabs .nav-item .nav-link:after, .shipping_page .nav-tabs .nav-item .nav-link:after{height: 20px; width: 20px; top: -8px;}
      .shipping_page .nav-tabs{margin-bottom: 30px !important;}
      .shipping_page .row {display: inherit;}
      .store_locator_page { padding: 10px 0 30px; }
      .store_locator_flex {display: inherit;margin: 30px 0;}
      .store_locator_flex .store_locator_data {width: 100%;}
      .store_locator_flex .store_locator_data:nth-child(1){margin-bottom: 30px;}
      .store_locator_data form { margin-top: 20px; }
      .store_locator_data form .btn.btn-primary{margin: 0;}
      .store_locator_cont { margin: 20px 0 0; }
      .store_locator_flex_distributor .distributor_card{width: 100%;}
      .distributor_card p strong{font-size: 16px;}
      .aboutus_page_data h4 span { color: #ffcb08; font-size: 22px !important; line-height: 29px !important; }
      .aboutus_page { padding: 30px 0; }
      .Pressrelease_page { padding: 30px 0; }
      .category-view iframe {width: 430px;}
      .thankyou_page_data {padding: 30px;}
      .error-figure{width:100%;padding-right: 0;}
      .error-content{width: 100%;}
      .hp-spotlight-bx .otb-pro-content span, .hp-spotlight-bx .otb-pro-content .pointer-text {  font-size: 20px !important;    }
      .hp-spotlight-bx .otb-pro-content .otp-pr-cont-figure{ right: 0px; max-width: 100px;}
      .hp-spotlight-bx .otb-pro-content p {  padding-right: 0px;}
      .hp-spotlight-bx .otb-pro-content .pointer-text {  font-size: 14px !important; margin: 10px 0px 15px;}
      .hp-spotlight-bx .otb-pro-content span.none-mobile{ display: none;}
      .hp-spotlight-bx .otb-pro-content .ptag{ margin-bottom: 0px;     text-align: center;}
      .hp-spotlight-bx .otb-pro-content a{padding: 7px 10px;}

  }

  @media screen and (max-width: 575px)
{
	.menu-slider { height: 180px;}
}
  
  
  @media screen and (max-width: 479px)
  {
      .features-row .features-col{ width:49%; padding: 10px 5px; margin: 0px 0% 10px;}
      .features-row .features-col:nth-child(3n+2) { margin-left: 0%; margin-right: 0%;}
      .features-row .features-col:nth-child(odd){ margin-right: 1%;}
      .features-row .features-col:nth-child(even){ margin-left: 1%;}
      .features-row .features-col .f-fig { height: 60px;}
      .features-row .features-col .f-text { font-size: 14px;}
      .features-row .features-col:last-child{ width:100%;}
      .our-client ul li { width: 50%;}
      .menu-slider .slick-list .slick-slide .menu-item p { margin: 5px 0px; font-size: 11px !important;     line-height: 14px;}
      .category-menu .slick-list { margin: 0px 0px;}
      .menu-slider .slick-list .slick-slide { padding: 0px 5px;}
      .latest-up-row .latest-col .listingImg img { height: auto;}
      .area-col { width: 49%; margin-bottom: 15px;}
      .area-col:nth-child(3), .area-col:nth-child(4) { margin-bottom: 0px;}
      .area-thumb img { max-width: 60px;}
      .pincode-popup-bx .modal-dialog .modal-content .modal-body form input{ height:40px;}
      .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-primary, .pincode-popup-bx .modal-dialog .modal-content .modal-footer .btn-secondary{ max-width: 140px; font-size: 14px; height: 40px; padding: 0px 3px;}
      .loginBottomStrip a{padding-left: 5px !important;}
      .usercreateaccount {padding: 40px 0;}
      .usercreateaccount h2 span {font-size: 20px;padding-left: 5px;}
      .usercreateaccount .btn-primary,.usercreateaccount .btn-primary:hover,.usercreateaccount .btn-secondary,.usercreateaccount .btn-secondary:hover{padding: 8px 20px;}
      .aboutorder h4 span {font-size: 21px;}
      .review_rat{display: block;}
      .review_rat small{padding: 0;}
      .header-row .co-logo{max-width: 130px; padding-left: 35px;}
      .custom-head-mobile{width: calc(100% - 130px);} 
      .custom-head-mobile .custom-head-col img { height: 20px; }
      .header-row .co-logo img { height: auto; } 
      .cont_shopping_btn { display: inherit;}
      .cont_shopping_btn .btn-outline-primary{margin: 5px 0;}
      .aboutus_page_data h4 span { color: #ffcb08; font-size: 20px; line-height: 29px; }
      .aboutus_page .productpage_title { margin: 20px 0 15px; }
      .Pressrelease_card {width: 100%;}
      .Pressrelease_page h4{font-size: 28px; margin-bottom: 10px;}
      .career_image .career_image_col{width: 100%;margin-bottom: 5px;}
      .site_map_flex{grid-template-columns: repeat(2, 1fr);gap: 15px;}
      td.tbody_th_item_img img{width: 100%; height: auto;}
      .your_rating_css_div{display: initial;}
      .your_rating_css_div>div{width: 100%;}
      .review_pro_custom_file>div, .pro_writereview .btn.btn-primary{width: 100%; margin-top: 15px;}
      .review_pro_custom_file{margin-top: 20px; display: initial;}
      .category-view iframe {width: auto;}
      .custom-head-col.cart-bx span{width: 15px; height: 15px; line-height: 16px;}
      .download_catalog_page{padding: 30px 0;}
      .download_content_brifdata { grid-template-columns: repeat(1, 1fr); padding: 0 0px 0px 10px;}
      .catalog_data .flex-css{margin-bottom: 0;}
      .download_catalog_page .nav-tabs button { margin: 0px !important; width: 100%; padding: 5px;}
      .download_catalog_page nav { width: 70px; top: 46px;}
      .catalog_download_btn .img{ width:40px; height: 40px; line-height: 38px;}
      .catalog_download_btn img { width: 24px;}
      .dr_download_data .tab-content { padding: 10px; top: 55px;}
      .dr_download_data{ width: calc(100% - 70px); padding-right: 0;}
      .catalog_download_btn p { font-size: 12px !important; line-height: 14px;}
      .download_content_data .btn.btn-outline-secondary { border-radius: 10px; margin: 0 2px;padding: 5px 8px; font-size: 12px;}
      .catalog_download_btn{ padding: 0px;}
      .download_catalog_page .nav { width: 100%; display: flex; margin-bottom: 20px; flex-wrap: wrap; justify-content: space-between; margin: 0px; }
      .download_catalog_page .nav-tabs .nav-link, .download_catalog_page .nav-tabs .nav-link.active { margin: 0; width: 100%; padding: 5px;}
      .shopping_cart_data_left td.tbody_th_item_img img {height: 125px;}
      .shopping_cart_data_left .tbody_th_item { display: inline-grid; margin-left: 0; margin-top: 15px; width: 25% !important; }
      .thankyou_page_data{padding: 15px;}
      .thankyou_page_data img {max-width: 80px;}
      .thankyou_page h2 span { font-size: 18px; margin: 0; line-height: 24px; }
      .thankyou_page h2 {padding-left: 10px;}
      .productdetail .productpage_title span, .productdetail .prd-heading.addIcon span, .productdetail .prd-heading.addIcon{font-size: 22px; line-height: normal;}
      .productdetail .prd-heading.addIcon{margin: 20px 0;}
      .pro_about.Pro_Other { margin-top: 30px; }
      .pro_youmayalso { margin-top: 20px; }
      .productdetail .productpage_title{margin-bottom: 15px;}
      .product_compare_page .table tbody>tr>td, .product_compare_page .table tbody>tr>th { width: 150px; }
      .product_compare_page .prod_imageItem h4{font-size: 16px;}

      .category_accordion_data table { display: block;  overflow: auto;}
      .category_accordion_data table tr td p {  width: 110px; }

      .carpetlook-product-popup .modal-body .ais-InfiniteHits .ais-InfiniteHits-list .ais-InfiniteHits-item { width: 48%;}
  .tilecalculation .img-section .row .col img { height: 40px;}
  .tilecalculation .grout label, .tilecalculation .replace-tile label, .carpet_looks .dynamic-section .measurement label, .carpet_looks .note h6 { font-size: 12px;}
.obl-experience-row .obl-matterport-text-col h3{font-size: 20px !important;}

	 .menu-slider { height: 170px;}
     .hp-features h1 { font-size: 20px !important;}
  }

  
  
  @media screen and (max-width: 400px){
      .logincontent .loginBottomStrip {display: inherit;}
      .logincontent .loginBottomStrip .btn{width: 100%;}
      .logincontent .loginBottomStrip .btn:nth-child(2){margin-top: 10px;}
      .reviewdetail-right p, .rrr { font-size: 14px;}
  }
  
  
  @media screen and (max-width: 399px)
{
	.menu-slider { height: 150px;}
}
  /************************************************************** Reaponsive CSS *********************************************************/
  
  

.obl-calculator-section h2 { margin: 0px 0px 15px; color: #2E363F; font-size: 28px;}
.obl-tile-calcul-row { max-width: 700px; width: 100%; margin: 70px auto; background-color: #fff; padding: 50px;}
.obl-tile-calcul-row .nav-tabs { border: none; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;    justify-content: space-between; margin-bottom: 30px; border-bottom: solid 1px #D9DBE9 !important; padding-bottom: 25px;}
.obl-tile-calcul-row .nav-tabs:before, .obl-tile-calcul-row .nav-tabs:after{ display:none;}
.obl-tile-calcul-row .nav-tabs li { float: none; margin: 0px; width: auto;position: relative;}
.obl-tile-calcul-row .nav-tabs li:before { content: ''; background-color: #EFF0F6; width: 140px; height: 8px; border-radius: 10px; display: block; position: absolute; top: 50%; left: 50%; transform: translate(50%, -50%); -o-transform: translate(50%, -50%); -ms-transform: translate(50%, -50%); -moz-transform: translate(50%, -50%);}
.obl-tile-calcul-row .nav-tabs li.active:after { content: ''; background-color: #00953B; width: 60px; height: 8px; border-radius: 10px; display: block; position: absolute; top: 50%; left: 100%; transform: translate(50%, -50%); -o-transform: translate(50%, -50%); -ms-transform: translate(50%, -50%); -moz-transform: translate(50%, -50%);}
.obl-tile-calcul-row .nav-tabs li:last-child:before, .obl-tile-calcul-row .nav-tabs li:last-child.active:after{ display:none;}
.obl-tile-calcul-row .nav-tabs li a { border: none !important; margin: 0px; padding: 0px; width: 50px; height: 50px; text-align: center; line-height: 50px; background-color: #EFF0F6 !important; color: #6F6C90 !important; font-size: 16px; border-radius: 30px; cursor: pointer !important;}
.obl-tile-calcul-row .nav-tabs li.active a { background-color: #00953B !important; color: #fff !important; box-shadow: 3px 2px 8px 0px inset #08642d; width: 50px; display: inherit; height: 50px; line-height: 50px; }
.obl-tile-calcul-row .nav-tabs li:before { content: ''; background-color: #EFF0F6; width: 120px; height: 8px; border-radius: 10px; display: block; position: absolute; top: 50%; left: 50%; transform: translate(50%, -50%); -o-transform: translate(50%, -50%); -ms-transform: translate(50%, -50%); -moz-transform: translate(50%, -50%); } *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.unit-area-row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; padding-bottom:30px; align-items: center;}
.unit-area-row .unit-area-left { width: calc(100% - 92px); padding-right: 15px;}
.unit-area-row .unit-area-left h5{ margin: 0px 0px 10px; font-family: "Poppins-SemiBold"; font-size: 22px; color: #191D23;}
.unit-area-row .unit-area-left p{ margin:0px;}
.unit-area-row .unit-area-right { width: 92px; text-align: right;}
.unit-area-row .unit-area-right select{ border: 1px solid #A8A8A8; border-radius: 8px; padding: 0px 15px; height: 50px; font-size: 18px; color: #626A6E;}	
.total-area-col h5{ color: #191D23; font-size: 20px; margin: 0px 0px 15px;}
.total-area-row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; align-items: center; padding: 30px 0px; justify-content: space-between; position: relative; margin-bottom: 30px;}
.total-area-row:before { content: ''; background-color: #EAEAEA; width: 2px; height: 100%; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); }
.total-area-row:after { content: 'Or'; width:30px; height:30px; line-height:30px; text-align:center; border-radius:30px; background-color:#fff; font-size:16px; color:#000; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); }
.total-area-row .total-area-left{ width:45%;}
.total-area-row .total-area-right{ width:45%; text-align:right;}
.total-area-row .total-area-right span{ background: #FFFFFF; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); border-radius: 8px; padding: 15px 10px; color: #191D23; font-size: 16px; text-decoration:none; width:100%; display: block; text-align: center; cursor: pointer;}
.area-feet-input { position: relative;}
.area-feet-input input { width: 100%; border: solid 1px #E0E0E0; height: 60px; border-radius: 8px; padding: 0px 110px 0px 15px; font-size: 16px;}
.area-feet-input .label-bx { margin: 0px; background-color: #fff; color: #202020; font-size: 14px; position: absolute; top: -15px; left: 15px; padding: 5px 10px; font-weight: unset;}
.area-feet-input .sw-ft-bx { background-color: #E0E0E0; color: #202020; width: 100px; height: 60px; text-align: center; line-height: 60px; font-size: 16px; position: absolute; top: 0px; right: 0px; border-radius: 8px;}
.calculate-total-area { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; align-items: center; padding: 30px 0px; justify-content: space-between; margin-bottom: 30px;}
.calculate-total-area .alculate-total-left { width: 45%;}
.calculate-total-area .alculate-total-left span{ background: #FFFFFF; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25); border-radius: 8px; padding: 15px 10px; color: #191D23; font-size: 16px; text-decoration: none; width:100%; display: block; text-align: center; cursor: pointer;}
.calculate-total-area .alculate-total-center { width: 10%; text-align:center; position: relative;}
.calculate-total-area .alculate-total-center:before { content: ''; background-color: #EAEAEA; width: 2px; height:200px; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); }
.calculate-total-area .alculate-total-center p{ margin:0px; position:relative; z-index:1; text-align:center; border-radius:30px; background-color:#fff;}
.calculate-total-area .alculate-total-right { width: 45%;}
.calculate-total-area .alculate-total-right .area-feet-input { margin-top: 25px;}
.alculate-total-right .total-feet-area-bx{ margin-bottom:35px;}
.alculate-total-right .total-feet-area-bx:last-child{ margin-bottom:0px;}
.another-tile-section ul{ list-style: none; margin: 0px; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center; justify-content: space-between;}
.another-tile-section ul li, .another-tile-section ul li a { color: #202020; font-size: 16px; font-family: "Poppins-SemiBold"; text-decoration:none;}
.another-tile-section ul li a.another-btn { color: #202020; font-size: 16px; font-family: "Poppins-SemiBold"; background-color: #F3F3F4; border: 1px solid #848484; border-radius: 4px; padding: 0px 15px; height: 45px; display: inline-block; line-height: 45px; text-decoration: none;}
.next-step-row { text-align: right; padding-top: 80px;}
.next-step-row .step-btn{ background-color: #00953B; box-shadow: 0px 3px 10px rgba(74, 58, 255, 0.18); border-radius: 4px; border: solid 1px #00953B; color: #fff; padding: 0px 30px; height: 60px; line-height: 58px; display: inline-block; font-size: 20px; font-family: "Poppins-SemiBold"; text-decoration: none;}
.next-step-row .step-btn:hover{ background-color: transparent; border: solid 1px #00953B; color: #00953B;}
.obl-tile-calcu-select { padding-bottom: 25px;}
.obl-tile-calcu-select h5{ margin: 0px 0px 15px; font-size: 16px; color: #191D23; font-family: "Poppins-SemiBold";}
.obl-tile-calcu-select select{ box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 4px; width: 100%; padding: 8px 15px; border: none;}
.obl-tile-tags-bx h6{ margin: 0px 0px 15px; font-size: 18px; color: #202020;}
.obl-tile-tags-bx h6 strong{  font-family: "Poppins-Bold"; padding-left:10px; font-size: 28px;}
.obl-tile-tags-bx .tile-tags h6{ margin: 0px 0px 20px; font-size: 18px; color: #202020; font-weight: bold;}
.obl-tile-tags-bx .tile-tags{ background-color: #FFFFFF; border: 1px solid #E7EAEE; border-radius: 4px; padding:20px;}
.obl-tile-tags-bx .tile-tags ul { list-style: none; margin: 0px; display: flex; flex-wrap: wrap; width: 100%;}
.obl-tile-tags-bx .tile-tags ul li { background-color: #e0e3f9; border-radius: 4px; padding: 6px 8px; font-size: 16px; margin: 0px 10px 10px 0px;}
.obl-tile-tags-bx .tile-tags ul li:hover{ background-color: #ECFDF5; color: #064E3B;}
.obl-tile-tags-bx .tile-tags ul li.active { background-color: #ECFDF5 !important; color: #064E3B; font-family: "Poppins-SemiBold";}
.tile-calculator-detail { padding-top: 30px;}
.tile-calculator-detail h6{ margin: 0px 0px 15px; font-size: 18px; color: #202020; font-weight: bold;}
.tile-calculator-detail h6 strong{  font-family: "Poppins-Bold"; padding-left:10px; font-size: 25px;}
.tile-detail-field-bx { padding-top: 20px; display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;justify-content: space-between;}
.tile-detail-field-bx .detail-field-col { width:31%;}
.tile-detail-field-bx .detail-field-col input { padding: 0px 70px 0px 15px;}
.tile-detail-field-bx .detail-field-col .sw-ft-bx { width: 60px;}
.obl-tile-calcul-row .tab-content > .active{opacity: 1;}
.tilecalculator-text p strong {    color: #000;    font-size: 16px;    font-weight: 700;}
.obl-tile-calcul-row .nav-tabs li a { border: none !important; margin: 0px; padding: 0px; width: 50px; height: 50px; text-align: center; line-height: 50px; background-color: #EFF0F6 !important; color: #6F6C90 !important; font-size: 16px; border-radius: 30px; cursor: pointer !important; display: block;}


#obl-one .total-area-section.new_class { display: flex; justify-content: space-between; align-items: center; flex-flow: wrap; margin-top: 30px; }
#obl-one .calculate-total-area{display: none;}
#obl-one .total-area-section.new_class .calculate-total-area { display: block; width: 45%; order: 1; }
#obl-one .total-area-section.new_class .calculate-total-area .alculate-total-left{width: 100%;}
#obl-one .total-area-section.new_class .total-area-row { width: 45%; order: 2; }
#obl-one .total-area-section.new_class .next-step-row { text-align: right; padding-top: 80px; order: 3; float: right; width: 100%; }
#obl-one .total-area-section.new_class .total-area-row:before { left: -30px; transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }
#obl-one .total-area-section.new_class .total-area-row:after { left: -45px; transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); }
#obl-one .total-area-section.new_class .area-feet-input { margin: 30px 0; }
#obl-one .total-area-section.new_class .next-step-row { text-align: right; padding-top: 80px; order: 3; float: right; width: 100%; }
#obl-one .total-area-section.new_class .total-area-row .total-area-left{width: 100%;}
#obl-one .total-area-section.new_class .total-area-row {padding: 0;margin: 0;}


.premium-sale-section { padding: 50px 0px; background-color: #f6f6f6;}
.premium-head-row .premium-title { width: calc(100% - 250px); padding-right: 15px;}
.premium-head-row .premium-title h1{ font-size: 30px !important; color: #1b1b1b !important; margin: 0px; font-family: "Poppins-Bold";}
.premium-head-row .premium-sort-bx { width: 250px;}
.location-row { padding: 25px 0px; justify-content: space-between;}
.location-col{ width:49%;}
.location-col .location{ width: 100%; background-color: #fff; border: solid 1px #cccccc; border-radius: 5px; height: 44px; padding: 0px 10px; font-size: 14px; color: #11151F; font-family: "Poppins-SemiBold";}
.premium-product-row .pre-product-row { background-color: #fff; border: solid 1px #f3f3f3; width: 49%; margin-bottom: 15px; padding: 15px;     display: flex; display: -moz-flex;  display: -ms-flex;  flex-flow: wrap; }
.premium-product-row .pre-product-row:nth-child(odd) { margin-right: 1%;}
.premium-product-row .pre-product-row:nth-child(even) { margin-left: 1%;}
.premium-product-row .pre-product-row .pre-pro-figure { width: 180px; height: 180px; overflow: hidden; position: relative; text-align: center;}
.premium-product-row .pre-product-row .pre-pro-text { width: calc(100% - 180px); padding-left: 20px;}
.premium-product-row .pre-product-row .pre-pro-text h3 { margin: 0px !important; font-size: 24px !important; color: #515151 !important; font-family: "Poppins-Regular";}
.pre-pro-text .sales-offer-row{ padding-top: 15px; align-items: end;     display: flex;  display: -moz-flex;  display: -ms-flex;  flex-flow: wrap;}
.pre-pro-text .sales-offer-row .offer-text { width: calc(100% - 25px);}
.pre-pro-text .sales-offer-row .pro-cart-col { width: 25px;}
.pre-pro-text .sales-offer-row .offer-text p, .pre-pro-text .sales-offer-row .offer-text p strong { font-size: 18px; line-height: normal; margin-bottom: 5px; color: #909096;}
.pre-pro-text .sales-offer-row .offer-text p:last-child{ margin-bottom: 0px;}
.loadmore { text-align: center; padding-top: 25px;}
.loadmore a{ background-color: #499749; border: solid 1px #499749; padding: 8px 20px; color: #fff !important; border-radius: 5px; font-size: 18px;     display: inline-block;}
.loadmore a:hover { background-color: transparent; border: solid 1px #499749; color: #499749 !important;}

.tile_selector_data{ align-items: center;}


li.top-language{ padding-left: 10px !important;}
li.top-language .language-bx { border: solid 1px transparent; padding: 2px 8px; border-radius: 30px; cursor: pointer; transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s;}
li.top-language .language-bx:hover{ border: solid 1px #ffcb08; background-color: #ffcb08;transition: all 0.5s; -webkit-transition: all 0.5s; -ms-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s;}
li.top-language .language-bx:hover img{ filter: brightness(0.2); -webkit-filter: brightness(0.2);}
li.top-language .language-bx img { height: 20px;}

.quicklook-video-mobile{ display: none;}
.pro_about.mob{ display: none;}

.Our_stores.flex-css {
    padding-top: 30px;
}

/************************************************** Mobile Filter css **************************************************/
.filter-heading-head { display: none;}
.foot-icon-bx { display: none;}
.right_sidebar_form .close-form{ display: none;}
.nav-row ul.product-menu>li.catalog-mobile-view-menu{ display: none;}
@media only screen and (max-width: 980px)
{ 
    .right_sidebar_form .close-form{ display: block;}
    .foot-icon-bx { display: block;}
    .filter-heading-head { display: flex; flex-flow: wrap; align-items: center; justify-content: space-between; padding: 0px 10px; height: 45px; background-color: #fff; border-bottom: 1px solid #eaeaec; }
    .filter-heading-head h6 { margin: 0px; font-family: "Poppins-SemiBold"; font-size: 13px;}
    .pro-filter-row>ul { background-color: #f5f5f6; width: 30%;}
    .pro-filter-row>ul>li { background-color: #f5f5f6; width: 100%; border-bottom: 1px solid #eaeaec; display: block; padding: 15px 10px; text-align: left;}
    .pro-filter-row>ul>li.filter-active-cls { background-color: #fff;}
    .pro-filter-row>ul>li h6 { font-size: 13px; margin: 0px 0px 0px;}
    .pro-filter-row ul li .filters-by.open-filter { background-color: #fff; width: calc(100% - 30%); left: 30%; top: 45px; padding-bottom: 70px;}
    .filter-close-bx { padding: 0px;}
    .filter-close-bx img { height: 16px;}
    .pro-filter-row ul li .filters-by ul { padding: 0px;}
    .pro-filter-row ul li .filters-by ul li { padding: 15px 10px; display: block; border: 0px solid #939393; border-radius: 0px; border-bottom: .5px solid #eaeaec;  width: 100%; margin: 0px; font-size: 13px; line-height: 1.2;}
    .pro-filter-row ul li .popular-sparater { display: none;}
    .pro-filter-row ul li .color-row>li { margin: 0px 0px 0px !important; border-bottom: .5px solid #eaeaec !important; padding: 8px 10px !important; display: flex !important; flex-flow: wrap; align-items: center;}
    .pro-filter-row ul li .color-row>li .color-option { margin: 0px 10px 0px 0px !important; height: 30px; width: 30px; border: 1px solid #0000001c;}
    .pro-filter-row>ul>li.filter-active-cls h6 { font-family: "Poppins-SemiBold";}

    .pro-filter-row>ul>li .flt-type-img { display: none;}
    .category_listing_page .filter-by-mobile { width: 40%; background-color: #f9f9f9; border-top: solid 2px #e9e9e9; padding: 6px 0px; height: 56px; align-items: end;}
    .filter-by-mobile .locaton-custom { border: none;}
    .category_listing_page .filter-by-mobile .foot-icon-bx img { height:16px}
    .filter-by-mobile .filter-col .sort-filter-col { padding: 0px;}
    .filter-by-mobile .locaton-custom select, .filter-by-mobile .filter-col .sort-filter-col { color: #333; font-size: 12px; padding: 0px; line-height: normal;text-align-last: center;}
    .mobile_view_footericon { display: flex; flex-flow: wrap; width: 40%; bottom: 0px; padding: 0px; background-color: #f9f9f9; border-top: solid 2px #e9e9e9; padding: 6px 0px; height: 56px; align-items: flex-end;}
    .mobile_view_footericon .foot-info-col { width: 50%; text-align: center; }
    .mobile_view_footericon .foot-info-col p { margin: 0px; color: #333; font-size: 12px !important; padding: 0px; line-height: normal;}
    .mobile_view_footericon a svg { margin: 0px auto 5px !important; height:16px;}
    
    .right_sidebar_form { display: block;}
    .getintouch_siadebar .foot-icon-bx { display: block !important;}
    .getintouch_siadebar { position: fixed; top: unset; bottom: 0px; left: 50%; transform: translate(-50%, -0%); -o-transform: translate(-50%, -0%); -ms-transform: translate(-50%, -0%); -moz-transform: translate(-50%, -0%); width: 20%; border-radius: 0px; background-color: #f9f9f9; border-top: solid 2px #e9e9e9; padding: 6px 0px; height: 56px; display: flex; flex-flow: wrap; align-items: end; justify-content: center;}
    .getintouch_siadebar .foot-icon-bx { width: 45px; height: 45px; line-height: 45px; position: fixed; left: 50%; transform: translate(-50%, -0%); -o-transform: translate(-50%, -0%); -ms-transform: translate(-50%, -0%); -moz-transform: translate(-50%, -0%); bottom: 30px; background-color: #202020; border-radius: 30px; box-shadow: 0px 0px 12px #0e0e0e;}
    .getintouch_siadebar .foot-icon-bx img { height:24px;}
    .right_sidebar_form.active .getintouch_siadebar { left: 50%;}
    .getintouch_siadebar svg { display: none;}
    .getintouch_siadebar p { margin: 0px; color: #333; font-size: 12px !important; padding: 0px; line-height: normal;}
    .right_sidebar_form { width: 100%; padding: 30px 15px 40px 15px; right: unset; top:unset; bottom: -300px; z-index: 1;}
    .right_sidebar_form.active { right: unset; bottom: 55px; z-index: 9999;}
    .right_sidebar_form .close-form { border-radius: 30px; width: 45px; height: 45px; line-height: 43px; flex-flow: wrap; align-items: center; justify-content: center; position: absolute; top: -25px; left: 50%; transform: translate(-50%, -0%); -o-transform: translate(-50%, -0%); -ms-transform: translate(-50%, -0%); -moz-transform: translate(-50%, -0%); text-align: center; background-color: #ffffff; border: solid 1px #ededed;}
    .right_sidebar_form .close-form svg { transform: rotate(90deg); height: 25px;}
    .copy-right { padding-bottom: 80px;}
    .mobile-foot-category-row { display: flex !important; flex-flow: wrap; width: 40.2%; bottom: 0px; background-color: #f9f9f9; border-top: solid 2px #e9e9e9 !important; padding: 6px 0px; height: 56px; align-items: flex-end; position: fixed; left: 0px; border: 0px; z-index: 1;}
    .mobile-foot-category-row .foot-info-col { width: 50%; text-align: center;}
    .mobile-foot-category-row .foot-info-col span { margin: 0px; color: #333; font-size: 12px !important; padding: 0px; line-height: normal; display: block;}
    .mobile-foot-category-row .foot-info-col svg { margin: 0px auto 5px !important; height: 16px;}
    .mobile-foot-category-row .foot-info-col svg path { fill: #0f9243;}
    .nav-row ul.product-menu>li.catalog-mobile-view-menu{ display: block; background-color: #ffca3447;}
    .nav-row ul.product-menu>li.catalog-mobile-view-menu a{ width: calc(100% - 0px);}

}

@media only screen and (max-width: 639px)
{ 
    .filter-by-mobile .locaton-custom select, .filter-by-mobile .filter-col .sort-filter-col, .getintouch_siadebar p, .mobile_view_footericon .foot-info-col p, .mobile-foot-category-row .foot-info-col span { font-size: 11px !important;}
}

@media only screen and (max-width: 379px)
{ 
    .filter-by-mobile .locaton-custom select, .filter-by-mobile .filter-col .sort-filter-col, .getintouch_siadebar p, .mobile_view_footericon .foot-info-col p, .mobile-foot-category-row .foot-info-col span { font-size: 10px !important;}
}

/************************************************** Mobile Filter css **************************************************/




@media screen and (max-width: 1280px){

    .error-text-bx { max-width: 400px}

}

@media screen and (max-width: 1199px){

    .board_directors_data{width: 49%;}
    .projects_at_orientbell_page .overviewHead p { padding: 15px 200px 0; }
    .projects_at_orientbell_page .large-whyUs p, .projects_at_orientbell_page .large-testi p{padding: 15px 200px 30px;}
    .projects_at_orientbell_page.our_capabilities_page .overviewHead p{padding: 15px 50px 0;}
    .our_capabilities_page .sevenTag ul{padding: 0 50px;}
    .our_capabilities_page .typeRow ul li{width: 29%;}
    .CSR_slider .slick-next {right: 0;}
    .CSR_slider .slick-prev{left: 0;}
    .exports_project_page .rowSection .row .col.text .desContant{padding: 0 30px;}
    .exports_project_page .rowSection .row .col.text .desHead{padding-left: 30px;}
    .exports_project_page .rowSection .row .col.text .desBorder{margin-left: 30px;}
    .forever_tiles_data{padding: 0;}

    .premium-product-row .pre-product-row .pre-pro-figure { width: 140px; height: 140px;}
    .premium-product-row .pre-product-row .pre-pro-text { width: calc(100% - 140px); padding-left: 15px;}
    .premium-product-row .pre-product-row .pre-pro-text h3 {font-size: 18px !important;}
    .pre-pro-text .sales-offer-row .offer-text p, .pre-pro-text .sales-offer-row .offer-text p strong{ font-size: 14px;}
    .header-right-col .top-bar-menu ul li { padding-left: 7px;}
    li.top-language { padding-left: 4px !important;}
}

@media screen and (max-width: 991px)
{
    .faq_page .faqWrap{margin: 0;}
    .tile_buying_page .resp-tabs-list{margin: 40px 0 0;}
    .tile_buying_page .resp-tabs-list li{width: 32%; margin: 25px 0;}
    .tile_buying_page .main-head, .tile_buying_page .sp-head{font-size: 25px; line-height: normal; display: inline;}
    .site_map_page h1{font-size: 22px;}
    .tile_buying_page h1{margin: 30px 0;}
    .tile_buying_page .spotlight-image img{margin-bottom: 20px;}
    .tile_buying_page .tile-buying-guide-content { margin: 30px 0; padding: 30px; }
    .tile_buying_page .tile-buying-sub{display: initial;}
    .tile_buying_page .tile-buying-sub:before { left: -20px; top: -20px; bottom: -20px; right: -20px; position: absolute; z-index: -1; }
    .tile_buying_page .tile-buying-left{width: 100%;}
    .tile_buying_page .tile-buying-right{width: 100%; padding: 10px;}
    .sitemap-wrapper{grid-template-columns: repeat(4, 1fr);}
    .our_milestones_page .mileWay{width: 65%;}
    .residential_project_page .projectHead p { padding: 10px 0; }
    .projects_at_orientbell_page .overviewHead p { padding: 15px 0 0; }
    .projects_at_orientbell_page .large-whyUs p, .projects_at_orientbell_page .large-testi p{padding: 15px 0 30px;}
    .projects_at_orientbell_page .whyMain ul{padding: 0;}
    .projects_at_orientbell_page .whyMain ul{display: initial;}
    .projects_at_orientbell_page .whyMain ul li{width: 100%;}
    .our_capabilities_page .sevenTag ul { padding: 0; }
    .our_capabilities_page .designRow{display: initial;}
    .our_capabilities_page .designLeft, .our_capabilities_page .designRight{width: 100%;}
    .our_capabilities_page .desContant{margin-bottom: 15px;}
    .our_capabilities_page .designRow{margin-top: 30px;}
    .our_capabilities_page .typeRow ul{justify-content: space-between;}
    .our_capabilities_page .sevenTag ul li{margin: 0 0 10px;}
    .core_value_page .thumbIcon{padding: 10px;}
    .government_project_page .clientLogoSlides { width: 32%;}
    .government_project_page .clientLogoSlides img{width: auto;}
    .error-text-bx { max-width: 100%; float: unset;}
    .error-content{position: unset; left: unset; top: unset; transform: unset;margin-top: 30px;}
    .error-text-bx h1{font-size: 24px;}
    .cataloguesearch_page_desktop_view{display: none;}
    .cataloguesearch_page_mobile_view{display: block;}
    .cataloguesearch_page_mobile_view .cataloguesearch_page_tile_filter{display: inherit;}
    .cataloguesearch_page_mobile_view button.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled, .cataloguesearch_page_mobile_view .ais-ClearRefinements-button { font-size: 1.2rem; background-color: #ebebeb; border: 2px solid #ebebeb; color: #333; padding: 6px; width: 100%; border-radius: 30px; margin-bottom: 20px; }
    .cataloguesearch_page_mobile_view button.ais-ClearRefinements-button.ais-ClearRefinements-button--disabled:hover,.cataloguesearch_page_mobile_view .ais-ClearRefinements-button:hover{
        background-color: #007f1d; border-color: #007f1d; color: #fff;cursor: pointer;}
    .cataloguesearch_page_mobile_view .cataloguesearch_page_tile_filter .sidebar { width: 100%; } 
    .cataloguesearch_page_mobile_view .cataloguesearch_page_main_tile_data{width: 100%;}  
    .cataloguesearch_page_mobile_view button.ais-InfiniteHits-loadMore { display: flex; margin: 30px auto 0; background: #ddd; border: 1px solid #ddd; padding: 10px 30px; border-radius: 30px; box-shadow: none; outline: none; } 
    .cataloguesearch_page_mobile_view button.ais-InfiniteHits-loadMore:hover {background-color: #007f1d;border-color: #007f1d; color: #fff;}
    .cataloguesearch_page_mobile_view input.ais-SearchBox-input { width: 100%; border: 1px solid #ddd; padding: 0 5px; height: 35px;}
    .mobile_view_searchingResult { display: flex; justify-content: space-between; align-items: center; margin: 15px 0 20px;}
    .cataloguesearch_page_mobile_view .ais-SortBy { margin-left: 5px; }
    .cataloguesearch_page_mobile_view select.ais-SortBy-select { border: 1px solid #ddd; border-radius: 0; }
    .mobile_view_searchingResult p { margin: 0; }
    .cataloguesearch_page_mobile_view .ais-InfiniteHits-item img{ width: 100%; }
    .cataloguesearch_page_mobile_view .ais-InfiniteHits-item { width: 48%; text-align: center; margin-bottom: 15px; background: #e1e1e1dd; }
    .cataloguesearch_page_mobile_view .ais-InfiniteHits-item img { width: 100%; }
    .cataloguesearch_page_mobile_view .ais-InfiniteHits-item div{padding: 15px 0;}
    .core_value_page h1:before{height: 30px;}
    .manufacturing_capabilities_page .abt-main .spotlight-image, .manufacturing_capabilities_page .abt-main .spotlight-content { width: 100%; }
    .manufacturing_capabilities_page .abt-main .spotlight-image{ order: 2; }
    .manufacturing_capabilities_page .abt-main .spotlight-content .main-head{font-size: 40px; line-height: 50px; padding-bottom: 15px;}

    .premium-product-row .pre-product-row .pre-pro-figure { width: 100px; height: 100px;}
    .premium-product-row .pre-product-row .pre-pro-text { width: calc(100% - 100px);}
    .premium-head-row .premium-title h1{ font-size: 24px !important;}

}


@media screen and (max-width: 767px)
{    
    .cataloguesearch_page{padding: 30px 0;}
	.obl-calculator-section { padding: 0;}
	.obl-calculator-section h2 { font-size: 20px; margin: 0px; line-height: 28px; margin-bottom: 15px; }
	.obl-tile-calcul-row { margin: 30px auto 0px; padding: 25px;}
	.obl-tile-calcul-row .nav-tabs li:before { width: 110px; left: 100%;}
    .career_page .masonry{grid-template-columns: repeat(2, 1fr);}
    .career_page h1, .career_page h2, .career_page h4 { font-size: 20px !important;}
    .faq_page .faqWrapper { padding: 15px 0; }
    .faq_page .faqWrapper h1{font-size: 24px;}
    .faq_page .faqWrapper .faqPara { font-size: 18px; padding: 10px 0; line-height: 26px; }
	.faq_page .faqHead{margin: 15px 0;}
    .faq_page audio{margin: 0;}
    .site_map_page{padding: 30px 0;}
    .tile_buying_page .tile-buying-highlight{padding: 15px;}
    .aboutus_page .abt-content .g-heading h2 { font-size: 22px; }
    .productpage_title{font-size: 22px; line-height: normal;}
    .investor_important_area h4{line-height: 22px;font-size: 16px;}
    .sub_accordion_investor.accordion{padding: 0 15px;}
    .investor_important_area h4{margin-bottom: 5px;}
    .investor_accordion .accordion-body { padding: 10px 0; }
    .investor_accordion .table{margin-bottom: 0;}
    .press_release_detail_page img{height: auto;}
    .press_release_detail_page {padding: 0 0 30px;}
    .sitemap-wrapper{grid-template-columns: repeat(3, 1fr);}
    .tile_buying_page .tile-buying-highlight ul li{font-size: 14px;}
    .privacy_policy_page .paraItalic strong { font-size: 16px; }
    .privacy_policy_page ul{margin: 10px 0;}
    .privacy_policy_page ul li { padding: 3px 0; }
    .privacy_policy_page .bread-crumb{font-size: 14px;}
    .category_detail_page thead, .category_detail_page tbody, .category_detail_page tfoot, .category_detail_page tr, .category_detail_page td, .category_detail_page th { display: revert; border: 1px solid #b5b5b5 !important; padding: 3px !important; }
    .product-img-view{height: 400px;}
    .our_milestones_page .mileWay { width: 100%; }
    .about_awards_accolades_years { padding: 15px 0; background: #e3e3e3; color: #818181; font-size: 16px; display: inline-block; margin: 15px 1px 0; width: 12%; text-align: center; }
    .residential_project_page .row{display: initial;}
    .residential_project_page .col{max-width: 100%; padding: 0;}
    .residential_project_page .text { padding: 30px 0; }
    .residential_project_page .row:nth-child(2n) .text{padding-left: 0;}
    .residential_project_page .projectHead{padding-top: 30px;}
    /* .residential_project_page h1, .government_project_page .clientHead { font-size: 22px !important; } */
    .residential_project_page .rowSection { padding-bottom: 0; }
    .residential_project_page .desHead { margin-bottom: 15px; font-size: 18px; font-weight: bold; text-transform: uppercase; color: #000; border-bottom: 3px solid #ffcb08; width: -moz-fit-content; width: fit-content; padding-bottom: 8px; }
    .privacy_policy_page .site_map_page{padding: 0 0 30px;}
    .privacy_policy_page .site_map_page h1 { margin: 0; font-size: 22px !important; margin: 0 !important; }
    .projects_at_orientbell_page{padding: 30px 0;}
    .projects_at_orientbell_page .overviewHead h1 { font-size: 22px; }
    .projects_at_orientbell_page .overviewHead{margin: 0;}
    .projects_at_orientbell_page .projectRow{justify-content: space-between; flex-wrap: wrap;}
    .projects_at_orientbell_page .slick-slide{width: 100%;}
    .projects_at_orientbell_page .slick-slide:nth-child(2n){margin: 0;}
    .projects_at_orientbell_page .p-content{margin-top: 15px;}
    .projects_at_orientbell_page .contactMain, .projects_at_orientbell_page .abt-content{display: initial;}
    .projects_at_orientbell_page .contact-right{padding-left: 0; width: 100%;}
    .projects_at_orientbell_page .abt-content .g-heading { font-size: 20px !important; }
    .projects_at_orientbell_page .contact-left{width: 100%; padding-right: 0;}
    .projects_at_orientbell_page .salesOffice { margin-top: 30px; padding-top: 0; width: 100%; }
    .projects_at_orientbell_page .regionDetails li{font-size: 14px; padding: 10px 0;}
    .projects_at_orientbell_page .regionDetails li span{font-size: 14px;}
    .category_listing_page .category-section .category-view table tr td{padding: 5px !important;}
    .category_listing_page .category_accordion_data h2 strong, .category_accordion_data h2 strong span{font-size: 22px; line-height: normal;}
    .category_accordion_data h3 span strong{font-size: 20px;}
    .category_listing_page thead, .category_listing_page tbody, .category_listing_page tfoot, .category_listing_page tr, .category_listing_page td, .category_listing_page th{padding: 5px !important; border: 1px solid #cbcbcb !important; display: revert !important;}
    .projects_at_orientbell_page .thumb-image { width: 100%; }
    .our_capabilities_page .productionMain h3{font-size: 20px;}
    .our_capabilities_page .productionMain h3 span{font-size: 16px;}
    .our_capabilities_page .designRow { display: inline-flex; }
    .our_capabilities_page .designLeft .desHead{font-size: 20px;}
    .our_capabilities_page .designLeft .desBorder{height: 3px;}
    .projects_at_orientbell_page.our_capabilities_page .overviewHead p { padding: 0 0 20px; }
    .CSR_page .csr-initiate{padding-top: 30px; padding-bottom: 30px;}
    .CSR_page .gallerySec {padding-top: 30px;}
    .CSR_page { padding-bottom: 30px; }
    .CSR_page .gallerySec h3{font-size: 20px;}
    .core_value_page .g-heading{margin-top: 30px !important;}
    .core_value_page .g-heading+p { margin-bottom: 30px !important; }
    .product_quality_page .row:nth-child(odd) .mobile span { right: unset; bottom: unset; font-size: 56px; left: 30px; top: 65px; z-index: 1;}
    .product_quality_page .col.mobile span{right: unset; bottom: unset; font-size: 56px; left: 20px; top: 15px; z-index: 1;}
    .government_project_page .ourClientWrap{padding: 30px 0;}
    .government_project_page .clientLogoSlides {width: 48%;}
    .projects_at_orientbell_page .contactus_data{display: inherit; margin-top: 30px; padding-bottom: 30px;}
    .projects_at_orientbell_page .contactus_data_con.custom_div_in_projectatobl{width: 100%;}
    .projects_at_orientbell_page .contactus_data_con.custom_div_in_projectatobl:nth-child(2){margin-top: 30px;}
    .projects_at_orientbell_page .custom_div_in_projectatobl .g-heading { font-size: 20px; margin-bottom: 10px; line-height: normal; }
    .projects_at_orientbell_page .custom_div_in_projectatobl p strong { font-size: 18px; }
    .error-section { padding: 20px 0px px 30px;}
    .error-text-bx h1 { font-size: 20px; }
    .error-text-bx p { margin: 20px 0; }
    .category_accordion_data h2 span, .category_accordion_data h3 span{font-size: 20px; font-weight: bold; line-height: normal;}
    .category_accordion_data .inner a:before{display:none}
    .tile_selector_page{padding: 30px 0;}
    .tile_selector_page .tile_selector_content h2 { font-size: 25px;}
    .tile_selector_page .tile_selector_content p{margin: 20px 0 0;}
    .tile_selector_page .tile_selector_content img{margin: 20px auto;}
    .tile_selector_page .tile_selector_content .btn-primary, .tile_selector_page .tile_selector_content .btn-primary:hover{font-size: 16px;}
    .tile_selector_page .slick-dots li button:before{font-size: 13px !important;}
    .manufacturing_capabilities_page h2 strong{font-size: 22px;}
    .Our_stores{margin-bottom: 40px;}
    .signature-company-showrooms h3 {margin: 20px 0;}
    .residential_project_page .projectHead h1, .residential_project_page h1{font-size: 22px !important;}
    h1{font-size: 24px !important;}
    h2{font-size: 22px !important;}
    h3{font-size: 20px !important;}
    h4{font-size: 18px !important;}
    .mobile_view_footericon a:nth-child(1) svg { font-size: 24px; margin-bottom: 20px; }
    .mobile_view_footericon a svg { font-size: 30px; display: block; }
    .obl-policy-section .abt-content .corAdd ul li{font-size: 14px !important;}
    .cool_tiles_pages h1{margin-bottom: 20px;}
    .cool_tiles_pages{padding: 30px 0;}
    .cool_tiles_pages img{width: auto; margin: 30px auto;}
    .forever_tiles_page ul li{width: 100%;}
    .manufacturing_capabilities_page .abt-main .spotlight-content .main-head {font-size: 24px;line-height: 28px;}
    .manufacturing_capabilities_page{padding: 20px 0 30px;}
    .manufacturing_capabilities_page .bread-crumb { margin: 10px 0; }
    .manufacturing_capabilities_page .inno-main{display: initial; margin-top: 20px;}
    .manufacturing_capabilities_page .thumbIcon{margin-bottom: 15px;width: 100px; height: 100px; line-height: 100px;}
    .manufacturing_capabilities_page .thumbIcon img{width: 60px;}
    .manufacturing_capabilities_page .thumbContent{width: 100%;}

    .premium-product-row .pre-product-row{ width:100%;}
    .premium-product-row .pre-product-row:nth-child(odd) { margin-right: 0%;}
    .premium-product-row .pre-product-row:nth-child(even) { margin-left: 0%;}

    .premium-head-row .premium-title { width: calc(100% - 0px); padding: 15px 0px 0px; order: 2;}
    .premium-head-row .premium-sort-bx { width: 100%; text-align: center; order: 1;}

    .quicklook-video-mobile{ display: block; padding-top: 25px;}
    .pro_about.mob{ display: block;}
    .pro_about.mob .prd-heading {  padding-bottom: 10px; margin-bottom: 10px;}
    .desktop-about-section{ display: none;}

}

@media screen and (max-width: 639px)
{
	.obl-tile-calcul-row{ padding:15px;}
	.obl-tile-calcul-row .nav-tabs li a { width: 40px; height: 40px; line-height: 40px;}
	.obl-tile-calcul-row .nav-tabs li:before { width: 75px;}
	.unit-area-row .unit-area-left h5{ font-size:16px;}
	.total-area-col h5{ font-size:16px;}
	.area-feet-input .label-bx { font-size: 12px; top: -10px; left: 10px; padding: 2px 5px;}
	.area-feet-input input { height: 50px; padding: 0px 60px 0px 10px; font-size: 14px;}
	.area-feet-input .sw-ft-bx { width: 60px; height: 50px; line-height: 50px; font-size: 14px;}
	.another-tile-section ul li, .another-tile-section ul li a, .another-tile-section ul li a.another-btn { font-size: 14px;}
	.next-step-row { padding-top: 40px;}
	.next-step-row .step-btn { height: 50px; line-height: 46px;font-size: 16px;}
    .home_trialbook.hp-otb-pro .container{padding: 0 !important;}
    #obl-one .total-area-section.new_class .next-step-row{padding-top: 0; margin-bottom: 30px;}
    #obl-one .total-area-section.new_class{display: initial;}
    #obl-one .total-area-section.new_class .total-area-row { width: 100%; order: 2; display: initial; }
    #obl-one .total-area-section.new_class .calculate-total-area{width: 100%;}
    #obl-one .total-area-section.new_class .total-area-row:after, #obl-one .total-area-section.new_class .total-area-row:before{display: none}
    .obl-tile-calcul-row{padding: 0; padding-bottom: 30px;}
    .calculate-total-area{padding: 0;}
    .area-feet-input .sw-ft-bx{width: auto; padding: 0 10px;}
    .obl-tile-tags-bx .tile-tags ul{padding: 0;}
    .tile-detail-field-bx .detail-field-col{width: 100%;}
    .total-area-row{display: initial;}
    .total-area-row:before, .total-area-row:after{display: none;}
    .total-area-row .total-area-right{margin: 15px 0;width: 100%;}
    .site_map_page.shipping_policy_page .obl-policy-section .abt-content .corAdd h1, .site_map_page.return_refund_policy_page .obl-policy-section .abt-content .corAdd h1 { font-size: 20px; line-height: initial;}
    .obl-policy-section .abt-content .corAdd h2 { font-size: 20px !important;  line-height: initial;}
    .board_directors_data{width: 100%; padding: 15px; margin: 10px 0;}
    .our_milestones_page .mileWay{padding: 30px 0;}
    .our_milestones_page .mile-stone::before, .our_milestones_page .Milebox::after{display: none;}
    .our_milestones_page .Milebox{width: 100%;}
    .our_milestones_page .mile-stone, .our_milestones_page .mile-stone:nth-child(even) .Milebox{margin-bottom: 15px;}
    .our_milestones_page .Milebox h4{font-size: 16px;}
    .about_awards_accolades_con{padding-bottom: 30px;}
    .projects_at_orientbell_page .project-name { font-size: 16px; margin-bottom: 10px; }
    .projects_at_orientbell_page .l-head{font-size: 20px;}
    .projects_at_orientbell_page .large-testimonial .t-profile{margin: 0px 0 30px;}
    .projects_at_orientbell_page .large-testimonial .tRight{margin-left: 10px;}
    .projects_at_orientbell_page .whyList span{width: 70px; height: 70px; line-height: 70px;}
    .projects_at_orientbell_page .whyTag { font-size: 16px; width: calc(100% - 90px); }
    .projects_at_orientbell_page .whyMain ul li{margin-bottom: 15px;}
    .CSR_page .csr-initiate iframe { width: 100%; height: 250px; margin-top: 15px; }
    .CSR_slider iframe{height: 250px;}
    .core_value_page h1:before{top: 2px;}
    .exports_project_page .obl-makes-section .container{padding: 0 !important;}
    .exports_project_page .rowSection .row .col.text .desHead{font-size: 18px;}
    .exports_project_page .awards-row .awards-col{padding: 10px;}
    .exports_project_page .client-slider div{width: 100%; margin-bottom: 20px;}
    .packaging-section .awards-row .awards-col { width: 100%; margin-bottom: 15px; padding: 0;}
    .board_directors_page .abt-content .g-heading span:before{height: 25px;}
    .unit-area-row .unit-area-right{width: auto; padding-top: 10px;}
    .premium-product-row .pre-product-row .pre-pro-text h3 { font-size: 16px !important;    }
    .quicklook-video-mobile video { height: 240px;}
}


@media screen and (max-width: 575px)
{
    
    .core_value_page .thumbIcon img { width: 60px; }
    .core_value_page .thumbContent { width: 100%; float: left; padding-left: 0; margin-top: 20px; }
    .core_value_page .inno-main { margin-bottom: 20px; }
    .government_project_page .clientLogo { margin-top: 20px; }
    .government_project_page .clientLogoSlides{margin: 5px auto;}
    .government_project_page .clientLogoSlides img { width: 95px; }
    .cool_tiles_pages h4{font-size: 20px !important;}
    .forever_tiles_data,.cool_tiles_pages h1{text-align: left;}
}

@media screen and (max-width: 479px)
{
	.obl-tile-calcul-row .nav-tabs li:before { width: 60px; left: 50%;}
	.obl-tile-calcul-row .nav-tabs li.active:after {  width: 34px; left: 90%;}
	.tile-detail-field-bx .detail-field-col .sw-ft-bx { width: 35px;}
	.tile-detail-field-bx .detail-field-col input { padding: 0px 35px 0px 10px;}
	.tile-detail-field-bx .detail-field-col .label-bx { font-size: 10px; top: -13px; left: 3px; padding: 2px 5px;}
    .career_page h1, .career_page h2, .career_page h4 { font-size: 18px !important; margin: 0; }
	.faq_page .faqWrapper h1{font-size: 20px;}
    .tile_buying_page .resp-tabs-list li{width: 48%;}
    .tile_buying_page .main-head, .tile_buying_page .sp-head{font-size: 20px;}
    .tile_buying_page h1{font-size: 18px; line-height: 20px;}
    .tile_buying_page .tile-head, .tile_buying_page .wall-tile-heading{font-size: 18px;}
    .tile_buying_page .tile-buying-guide-content{padding: 15px;}
    .sitemap-wrapper{grid-template-columns: repeat(1, 1fr);}
	.sitemap-wrapper .f-heading{ margin-bottom: 10px;}
    .sitemap-wrapper .sitemap-box ul{ display: flex; flex-flow: wrap;}
    .sitemap-wrapper .sitemap-box ul li { width: 50%; padding-right: 10px;}
    .sitemap-wrapper .sitemap-box ul li:nth-child(even) { padding-right: 0px;}
    .product-img-view { height: 300px; }
    .board_director_profile{width: 90%;}
    .about_awards_accolades_years{padding: 12px 0; width: 100%; margin: 0 0 2px;}
    .about_awards_accolades_data { width: 46%; order: 100; text-align: center; margin: 30px 0 0; }
    .about_awards_accolades_data{width: 100%;}
    .projects_at_orientbell_page .regionDetails li{display: inherit;}
    .projects_at_orientbell_page .regionDetails li span{display: block;}
    .our_capabilities_page .typeRow ul li { width: 46%; }
    .our_capabilities_page .sevenTag ul li{display: block; border: none;}
    .our_capabilities_page .sevenTag ul li a{font-size: 16px !important;}
    .download_content_brifdata .catalog_data {margin-bottom: 10px;}
    .download_content_brifdata .catalog_data:hover .flex-css {color: #009444;}
    .tile-related-blog-section .listing-col{display: initial !important;}
    .tile-related-blog-section .listing-Wrap .listing-col .listingImg { width: 100%; order: 1; height: 250px; margin-bottom: 20px; }
    .tile-related-blog-section .listing_blog_data { order: 2; width: 100%; padding-left: 0; }
    .tile-related-blog-section .listing-Wrap .listing-col .listingImg img { height: 250px; width: 100%; object-fit: cover; }
    .cataloguesearch_page_mobile_view .ais-InfiniteHits-item{width: 100%;}
    .mobile_view_searchingResult{display: inherit;}
    .mobile_view_searchingResult div{width: 100%;}
    .mobile_view_searchingResult p { margin: 0; width: 80px; }
    .mobile_view_searchingResult div{margin-bottom: 5px;}
    .cataloguesearch_page_mobile_view button.ais-InfiniteHits-loadMore{margin-top: 10px;}
    .core_value_page h1:before { height: 25px; }
    .h1,h1,h1 strong span, h1 strong, h1 span{font-size: 22px !important;}
    .h2,h2,h2 strong span, h2 strong, h2 span{font-size: 20px !important;}
    .h3,h3,h3 strong span, h3 strong, h3 span{font-size: 18px !important;}
    .h4,h4,h4 strong span, h4 strong, h4 span{font-size: 16px !important;}
    .obl-policy-section .abt-content .corAdd { padding-bottom: 15px; }

    .premium-product-row .pre-product-row .pre-pro-figure { width: 60px; height: 60px;}
    .premium-product-row .pre-product-row .pre-pro-text { width: calc(100% - 60px);}

    .areaCalc__cnvrtrSec__inputWrapper .areaCalc { width: 100%; margin-bottom: 15px;}
    .areaCalc__cnvrtrSec__inputWrapper .areaCalc:last-child{ margin-bottom: 0px;}
    .areaCalc__cnvrtrSec__inputWrapper { position: relative;}
    .areaCalc__cnvrtrSec__inputWrapper .unitsuffel-btn { position: absolute; top: -70px; right: 0; margin: 0px;}
    .nav-row ul.product-menu{ top:55px;}
    #mobile_search_div { padding: 3px 0px;}


    
}
button.mic-box {
    position: absolute;
    top: 0px;
    right: 40px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
}
button.mic-box img {
    height: 22px;
}

#newicon { position: absolute; right: -7px; z-index: 1; top: 0px; width: 70px;}
.obl-loader{ text-align: center; position: fixed; width: 100%; height: 100%; background-color: #000000b0; top: 0; left: 0; display: flex; flex-flow: wrap; align-items: center; justify-content: space-around; z-index: 1;}
/* .obl-loader img{ height:100px; width: 100px;} */
.popular-sparater { width: 100%; height: 2px; background-color: #c6c6c6; margin: 3px 0px; }
#mobile_search_div .mic-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 30px;
    height: 40px;
}
 
#mobile_search_div .mic-box img{ -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);}

/* a:hover,.treding-t .t-item, .trending-products-section .t-item:hover a span, .features-col:hover .f-text, .home-post .listingText .listingButton a:hover, .home-post .listingText .listingButton a:hover i, .home-post .listingText a.post-tiles:hover{color: #0d6efd !important;}
.footer-top .foot-col ul li a:hover:after, .obl-submenu-row .obl-submenu-nav .titleBox ul li a:hover:after{background-color: #0d6efd !important;}
.product-listing-col .best-pri a:hover,.mini_cart .btn.btn-primary:hover,.default-btn:hover{border-color: #0d6efd !important}
.product-listing-col .add-button a.add-pro-btn:hover,.listing-Wrap .listing-col .listingText .listingButton a:hover,.checkout_cart_page .btn-outline-primary:hover, .our-client a.indus-btn:hover{color: #fff !important;} */

.categoryBanner { max-height: 395px; width: 100%; object-fit: contain; cursor: pointer; }
.tiles-store-section h2 { padding-bottom: 15px;}
	.tiles-store-section .store_locator_flex_distributor .distributor_card .distributor_card_flex a { color: #686868; text-transform: uppercase;}


    .get-in-touchpopup { z-index: 1500; position: fixed; left: 0px; bottom: 0px; width: 213px; transition: ease all .5s; -webkit-transition: ease all .5s; -moz-transition: ease all .5s; -ms-transition: ease all .5s; -o-transition: ease all .5s;}
    .get-in-touchpopup a { display: block;}
    .get-in-touchpopup .icon-close-black-icon { width: 36px; height: 36px; line-height: 36px; border-radius: 50%; position: absolute; top: 0; right: 0; background-color: #000; text-align: center; cursor: pointer;}
    .icon-close-black-icon img { height: 16px;}
    @keyframes bounce {
        0% {
          transform: rotate(0deg);
		  -webkit-transform: rotate(0deg);
		  -moz-transform: rotate(0deg);
		  -ms-transform: rotate(0deg);
		  -o-transform: rotate(0deg);
        }
        50% {
          transform: rotate(45deg);
		  -webkit-transform: rotate(45deg);
		  -moz-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  -o-transform: rotate(45deg);
        }

         100% {
          transform: rotate(0deg);
		  -webkit-transform: rotate(0deg);
		  -moz-transform: rotate(0deg);
		  -ms-transform: rotate(0deg);
		  -o-transform: rotate(0deg);
        }
      }

      .get-in-touchpopup  {
        animation:
          bounce 250ms
          alternate 
          cubic-bezier(.2, .65, .6, 1);
          animation-delay: 3s;
      }


.view-count{     background-color: #febc36;
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 17px;
    display: flex;
    display:-webkit-box; display:-webktit-flex; display:-moz-flex; display:-ms-flex;
     align-items: center; transition: all 0.5s; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -ms-transition: all 0.5s;}

    .view-count img{height:35px; margin-right:5px; animation:uparrow 2s infinite;; }

    @keyframes uparrow{
        0% {opacity:1;}
        50%{opacity:0.2;}
        100%{opacity:1;}

    }

    .view-count strong{
        font-size: 18px;
        color: #000;
    }

        .view-count:hover{background-color: #008758; color:#fff;} 
        .view-count strong{color: #008758; }  
        .view-count:hover strong{color:#febc36;}

        .shipping_modal_box .popup-foot-btn{ display: flex; flex-flow: wrap; align-items: center; justify-content: space-between; border-bottom: solid 1px #ccc; padding-bottom: 15px; margin-bottom: 15px;}
        .shipping_modal_box .popup-foot-btn button { width: 40%; padding: 8px 5px !important;}
        .shipping_modal_box .popup-foot-btn button:hover{ background-color: #0b9444 !important; color: #fff !important;}
        .shipping_modal_box .popup-foot-bottom { text-align: center;}
        .shipping_modal_box .popup-foot-bottom button { max-width: 200px; width: 100%;}
        .shipping_modal_box .popup-foot-btn button { width: 40%; padding: 8px 10px !important; background-color: transparent !important; border: solid 1px #0b9444 !important; border-radius: 30px !important; color: #0b9444 !important; }
        .shipping_modal_box .popup-foot-bottom button { max-width: 200px; width: 100%; background-color: #0b9444 !important; border: none; color: #fff !important;}
        .shipping_modal_box .popup-foot-bottom button:hover { background-color: #ffcb08 !important; color: #000 !important;}

        .country-number-row button {
            width: 100%;
            text-align: left;
            background-color: transparent;
            border: solid 1px #dee2e6;
            border-radius: 0px;
            height: 40px;
        }
        .country-number-row button:hover, .country-number-row button:active{ background-color: transparent;  border: solid 1px #dee2e6;}
        .country-number-row .dropdown-toggle:after{ position: absolute; top:20px; right:20px;}

	@media screen and (max-width: 1366px)
	{
		.tiles-store-section .store_locator_flex_distributor .distributor_card .mb-4 strong { font-size: 18px;}
	}

	@media screen and (max-width: 1280px)
	{
		.tiles-store-section .store_locator_flex_distributor .distributor_card { width: 49%;}
	}

    @media screen and (max-width: 980px)
	{
		.get-in-touchpopup{ bottom: 55px;}
	}

	@media screen and (max-width: 767px)
	{
		.tiles-store-section .store_locator_flex_distributor .distributor_card { width: 49%;}
		.tiles-store-section .store_locator_flex_distributor .distributor_card .mb-4 { margin-bottom: 10px !important;}
		.tiles-store-section .store_locator_flex_distributor .distributor_card .mb-4 strong { font-size: 16px;}
		.tiles-store-section .store_locator_flex_distributor .distributor_card_flex{ margin-top:10px;}
        .get-in-touchpopup { width:130px;}
	}

	@media screen and (max-width: 479px)
	{
		.tiles-store-section h2 { font-size: 20px!important;}
        .shipping_modal_box .popup-foot-btn button { width: 49%; font-size: 12px;}
	}

    /*********************Investor page css ************************/
    .investor_information { margin-bottom: 30px; background-color: #f1f1f1; padding: 30px 20px;}
.investor_information h2 { text-align: center; margin-bottom: 10px;}
.investor_information p, .investor_information p a { font-size: 20px; line-height: 34px; text-align: justify;}
.investor_information p:last-child{ margin-bottom: 0px;}
.investor_information p a{ color: #00953b; font-family: "Poppins-SemiBold";}



@media screen and (max-width: 1440px)
{
	.investor_information p, .investor_information p a { font-size: 16px; line-height: 28px;}
	.investor_information h2 {font-size: 20px !important;}
}

@media screen and (max-width: 767px)
{
	.investor_information p, .investor_information p a { line-height: 24px;}
}
.ask-question-popup .modal-dialog .modal-dialog .modal-content { padding: 0px;}
.ask-question-popup .modal-content .modal-header { position: absolute; top: 10px; right: 10px; width: 30px; height: 30px; border: none; z-index: 9;}
.ask-question-popup .modal-content .modal-header .btn-close { margin: 0px; border-radius: 4px;}
.ask-question-popup .modal-content .modal-header .modal-title{ display: none;}
.ask-question-popup .modal-dialog { max-width: 850px;}
.ask-question-popup .modal-content .ask-question-section { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; align-items: center;}
.ask-question-popup .modal-content .ask-question-section .expert-fig-bx{ width: 50%;}
.ask-question-popup .modal-content .ask-question-section .modal-body{ width:50%; padding:30px !important;}
.ask-question-popup .modal-content .ask-question-section .modal-body h2{ text-align: center !important; font-family: "Poppins-Regular"; font-size: 30px !important;}
.ask-question-popup .modal-content .ask-question-section .modal-body h2+p{ text-align: center !important; }
.ask-question-popup .modal-content .ask-question-section .modal-body .btn.btn-primary { margin: 10px 0px 0px; border-radius: 30px !important;}
.ask-question-popup .modal-content .ask-question-section .modal-body form input{ background-color: #f1f1f1 !important; border: none !important; border-radius: 5px !important;}
@media screen and (max-width: 1199px)
{
    .ask-question-popup .modal-dialog { max-width: 750px;}
    .ask-question-popup .modal-content .ask-question-section .modal-body { padding: 20px !important;}
    .ask-question-popup .modal-content .ask-question-section .modal-body form input { height: 40px;}
    .ask-question-popup .modal-content .ask-question-section .modal-body .btn.btn-primary { padding: 7px 0;}
    .ask-question-popup .modal-content .ask-question-section .modal-body label { line-height: normal; margin: 0px 0px 2px; display: block;}
}

@media screen and (max-width: 767px)
{
    .ask-question-popup .modal-content .ask-question-section .expert-fig-bx{ width: 100%; display: none;}
    .ask-question-popup .modal-content .ask-question-section .modal-body{ width:100%;}
    .ask-question-popup .modal-dialog { max-width: 320px;}
    .ask-question-popup .modal-content .ask-question-section .modal-body h2 { font-size: 24px !important;}


}
  footer{z-index: 2147483649 !important;}
  .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu  { height: 550px; overflow: hidden;}
.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 530px !important;}

@media screen and (max-width: 1850px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 520px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 500px !important;}
}

@media screen and (max-width: 1750px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 500px; }
      .style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 480px !important;}
}

@media screen and (max-width: 1700px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 470px; }
      .style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 450px !important;}
}

@media screen and (max-width: 1600px)
{
	.desktopMainSlider_home_page{ margin-top: 20px;}
	.style-tiles-section { padding-top: 0px; margin-bottom: 0px;}
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 410px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 410px !important;}
}

@media screen and (max-width: 1440px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 400px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 400px !important;}
}

@media screen and (max-width: 1366px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 370px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 370px !important;}
}

@media screen and (max-width: 1280px)
{
	
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 330px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 330px !important;}
}

@media screen and (max-width: 1199px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 300px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 300px !important;}
}

@media screen and (max-width: 1100px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 280px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 280px !important;}
}

@media screen and (max-width: 1000px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 250px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 250px !important;}
}

@media screen and (max-width: 900px)
{
	.desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 210px; }
	.style-tiles-section .category-menu .styletiles-bx-item img { object-fit: cover; height: 210px !important;}
}

@media screen and (max-width: 767px)
{
    .page-wrapper.header-head-bx { height: 210px; overflow: hidden;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:700px; overflow: hidden;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height: 700px !important; object-fit: cover; width: 100%;}
}

@media screen and (max-width: 700px)
{
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:650px; overflow: hidden;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height: 650px !important;}
}

@media screen and (max-width: 650px)
{
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:600px; overflow: hidden;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height: 600px !important;}
}

@media screen and (max-width: 639px)
{
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:575px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height:555px !important;}
}

@media screen and (max-width: 575px)
{
    .page-wrapper.header-head-bx { height: 190px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:480px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height:460px !important;}
}

@media screen and (max-width: 479px)
{
    .page-wrapper.header-head-bx { height: 175px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:480px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height:460px !important;}
}

@media screen and (max-width: 399px)
{
    .page-wrapper.header-head-bx { height: 165px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item { height:350px;}
	.MobileMainSlider_home_page .home_style, .MobileMainSlider_home_page .home_style .slick-list, .MobileMainSlider_home_page, .MobileMainSlider_home_page .category-menu .styletiles-bx-item img { height:350px !important;}
}
.for-cls {
    min-height: 350px !important;
}
.swal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .4);
    z-index: 10000000000000000 !important;
    pointer-events: none;
    transition: opacity .3s;
}
/***************************************** Search Result Page CSS ************************************/
.obl-searchresult-section { padding: 5px 0px;}
.obl-searchresult-section .select-location-wrapper {margin-bottom: 10px;}
.obl-searchresult-heading { padding-bottom: 25px; padding-right: 150px; position: relative;}
.searchresult-sort-bx { position: absolute; top: 0px; right: 0px; width:168px;}
.searchresult-sort-bx select { width: 100%; background-color: #fff; padding: 0px 10px; height: 35px; border-radius: 5px; border: solid 1px #ddd; }
.obl-searchresult-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
.obl-searchresult-col { border: 1px solid #f1f1f1; border-radius: 4px; display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 32%; margin-bottom: 25px; align-items: center;}
.obl-searchresult-col:hover{ background-color: #fff;}
.obl-searchresult-col:nth-child(3n+2){ margin-right: 2%; margin-left: 2%;}
.obl-searchresult-col .cate-pro-figure { background-color: #fff; width: 100%;}
.cate-pro-figure a { position: relative; overflow: hidden; text-align: center; height: 350px; display: flex; flex-flow: wrap; align-items: center; justify-content: center;}
.cate-pro-figure a img { transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s; width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset;}
.obl-searchresult-col .cate-pro-text{ width: calc(100% - 0px); padding: 15px;}
.obl-searchresult-col .cate-pro-text .simi-name a { color: #333; font-size: 20px; font-family: "Poppins-SemiBold"; margin-bottom: 10px !important; display: block;}
.obl-searchresult-col .cate-pro-text .produ-price .mrp, .obl-searchresult-col .cate-pro-text .produ-price .mrp strong { font-size: 14px; margin: 10px 0px 0px; line-height: normal;}
.obl-searchresult-col .cate-pro-text .produ-size { font-size: 14px; margin: 5px 0px 0px; line-height: normal;}
.obl-searchresult-col .cate-pro-text .add-button ul { margin: 0px; padding: 15px 0px 0px; list-style: none; display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center;}
.obl-searchresult-col .cate-pro-text .add-button ul li{ padding-right: 10px;}
.obl-searchresult-col .cate-pro-text .add-button ul li:last-child{ padding-right: 0px;}
.obl-searchresult-col .cate-pro-text .add-button ul li a { min-width: 120px; width: 100%; display: block; text-align: center; height: 36px; line-height: 32px; border-radius: 30px; border: solid 1px #009239; font-size: 14px; color: #009239; background-color: #fff;}
.obl-searchresult-col .cate-pro-text .add-button ul li.add-pro-btn a:hover{ background-color: #009239; color: #fff;}
.obl-searchresult-col .cate-pro-text .add-button ul li.buy-now-btn a{ background-color: #009239; color: #fff;}
.obl-searchresult-col .cate-pro-text .add-button ul li.buy-now-btn a:hover{ background-color: #ffcb08; border-color: #ffcb08; color: #333;}

.obl-searchresult-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap;}
.obl-searchresult-left {   width: 300px;}
.obl-searchresult-left .mobile_view_filter { display: none;}
.obl-searchresult-right { width: calc(100% - 300px); padding-left: 20px;}
.obl-searchresult-left .ClearRefinements .all-clear-btn { font-size: 1.2rem; background-color: #ebebeb; border: 2px solid #ebebeb; color: #333; padding: 6px; width: 300px; border-radius: 30px; margin-bottom: 15px;}
.obl-searchresult-left .ClearRefinements .all-clear-btn:hover{ background-color: #007f1d; border-color: #007f1d; color: #fff; cursor: pointer;}
.searchresult-filter { border: 1px solid #efefef; margin-bottom: 15px;}
.searchresult-filter .title-bx { background-color: #efefef; padding: 7px 10px; font-size: 16px; color: #333; margin: 0;}
.searchresult-filter .ais-HierarchicalMenu { padding: 10px;}
.searchresult-filter input.search-input-bx { width: 100%; border: 1px solid #cbcbcb; padding: 5px;}
.searchresult-filter .RangeInput-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; align-items: center;}
.searchresult-filter .ais-RangeInput-label { width: 45%;}
.searchresult-filter .ais-RangeInput-separator { padding: 0 5px; width: 10%; text-align: center;}
.searchresult-filter ul.ais-RefinementList-list { list-style: none; margin: 0px 0px 0px; padding: 0px;}
.searchresult-filter ul.ais-RefinementList-list li .ais-RefinementList-label { display: flex; flex-flow: wrap; align-items: center;}
.searchresult-filter ul.ais-RefinementList-list li input[type="checkbox"] { margin-right: 5px;}
.obl-searchresult-col .cate-pro-text .add-button a { width: 100%;    display: block;    text-align: center;    height: 30px;    line-height: 30px;    border-radius: 5px;    color: #fff;    margin-top: 10px;}

.category-store-section-row h2 { margin-bottom: 20px;}
.category-store-section-row .store_locator_row .obl_store_locator_col .store_add_icon {    width: 100%;    text-align: left;    position: relative;    padding-left: 25px;}
.category-store-section-row .store_locator_row .obl_store_locator_col .store_add_icon .store_phone_icon {    position: absolute;    left: 0;    width: 20px;    height: 20px;}
.category-store-section-row .store_locator_row .obl_store_locator_col { width: 49%; padding:15px;}
.category-store-section-row .store_locator_row .obl_store_locator_col:nth-child(3n+2){ margin-left: 0px; margin-right: 0px;}
.category-store-section-row .store_locator_row .obl_store_locator_col:nth-child(odd){ margin-right: 1%;}
.category-store-section-row .store_locator_row .obl_store_locator_col:nth-child(even){ margin-left: 1%;}
.category-store-section-row .store_locator_row .obl_store_locator_col h3{ font-size:14px !important;}
.category-store-section-row .store_locator_row .obl_store_locator_col p{ font-size:12px !important;}


@media screen and (max-width: 1600px)
{
    .cate-pro-figure a{ height: 260px;}
}

@media screen and (max-width: 1440px)
{
    .obl-searchresult-col .cate-pro-text .produ-price .mrp, .obl-searchresult-col .cate-pro-text .produ-price .mrp strong { font-size: 16px;}
    .obl-searchresult-col .cate-pro-text .add-button ul { padding: 15px 0px 0px;}
    .obl-searchresult-col .cate-pro-text .add-button ul li { padding-right: 5px;}
    .obl-searchresult-col .cate-pro-text .add-button ul li a { min-width: 110px; font-size: 14px;}

}

@media screen and (max-width: 1280px)
{
    .obl-searchresult-col { width: 49%; margin-bottom: 15px;}
    .obl-searchresult-col:nth-child(3n+2){ margin-right: 0%; margin-left: 0%;}
    .obl-searchresult-col:nth-child(odd){ margin-right: 1%;}
    .obl-searchresult-col:nth-child(even){ margin-left: 1%;}

}

@media screen and (max-width: 1199px)
{
    .obl-searchresult-col .cate-pro-text .simi-name a { font-size: 18px !important; margin-bottom: 10px !important; }
    .obl-searchresult-col .cate-pro-text .produ-size { font-size: 14px; margin: 0px; }
    .obl-searchresult-col .cate-pro-text .add-button ul { padding: 10px 0px 0px; }
    .obl-searchresult-col .cate-pro-text .add-button ul li a { height: 32px; line-height: 30px; }
    .cate-pro-figure a{ height: 240px;}

}

@media screen and (max-width: 980px)
{
    .obl-searchresult-section { padding: 30px 0px;}
    .obl-searchresult-left { width: 100%;}
    .obl-searchresult-right { width: calc(100% - 0px); padding: 20px 0px 0px;}
    .obl-searchresult-left .mobile_view_filter { display: block;}
    .obl-searchresult-div{ display: none;}
    .obl-searchresult-div.active-filter { display: block;}
    .obl-searchresult-heading { padding: 50px 0px 10px;}
    .searchresult-sort-bx{width:100%;}
    .obl-searchresult-div.filter-active{ display:block;}
    .obl-searchresult-left .ClearRefinements .all-clear-btn{width:100%;}

}

@media screen and (max-width: 767px)
{
    .cate-pro-figure a{ height: 200px;}
    .category-store-section-row .tru_btn_new{ padding-top:25px;}
    .category-store-section-row .store_locator_row .obl_store_locator_col { width: 100%;}
    .category-store-section-row .store_locator_row .obl_store_locator_col:nth-child(odd){ margin-right: 0%;}
    .category-store-section-row .store_locator_row .obl_store_locator_col:nth-child(even){ margin-left: 0%;}

}

@media screen and (max-width: 639px)
{
    .obl-searchresult-col { width: 100%;}
    .obl-searchresult-col:nth-child(odd){ margin-right: 0%;}
    .obl-searchresult-col:nth-child(even){ margin-left: 0%;}
    .category-store-section-row h2{ font-size: 20px !important;}
}
/***************************************** Search Result Page CSS ************************************/


/***************** Mobile Screen Homepage       ******/
.new-design-mobileview{ display: none;}
.oblhome-category-section { padding: 30px 0px 10px;}
.oblhome-category-row { display: flex; display: -webkit-box; display: -webktit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap;}
.oblhome-category-row .oblhome-category-col{ width:48%; margin-bottom: 25px;}
.oblhome-category-row .oblhome-category-col.remove-tiles{ display: none;}
.oblhome-category-row .oblhome-category-col:nth-child(odd){ margin-right: 2%;}
.oblhome-category-row .oblhome-category-col:nth-child(even){ margin-left: 2%;}
.oblhome-category-col .oblhome-category-figure { border-radius: 5px; overflow: hidden;}
.oblhome-category-col .oblhome-category-figure img{ width:100%;}
.oblhome-category-col .oblhome-category-figcation { display: flex; display: -webkit-box; display: -webktit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; padding-top: 5px; align-items: center;}
.oblhome-category-col .oblhome-category-figcation h6 { margin: 0px;  width: calc(100% - 0px); padding-right: 0px; text-align: center;}
.oblhome-category-col .oblhome-category-figcation h6 a { color: #0f9243;}
.oblhome-category-col .oblhome-category-figcation p { margin: 0px; width: 60px; text-align: right; display: none;}
.view-all-category-btn a { border: solid 1px #DDDDDD; color: #202020; height: 50px; width: 100%; display: block; text-align: center; line-height: 50px; font-size: 16px !important; border-radius: 30px; font-family: "Poppins-SemiBold";}
.view-all-category-btn a:hover { border: solid 1px #0F9243; color: #fff; background-color: #0F9243;}

.largest-collection-section { padding: 30px 0px 10px 15px;}
.largest-collection-section h2, .largest-collection-section h2 strong{ font-size: 20px !important;}
.largest-collection-section h2 { font-family: "Poppins-Medium"; margin-bottom: 15px;}
.mobile-latest-sction .slick-list{ padding-left: 0px !important;}
.mobile-latest-sction .slick-list .slick-slide{ padding-right: 15px;}
.new-collection-bx { position: relative; border-radius: 5px; overflow: hidden;}
.new-collection-bx h6 { position: absolute; bottom: 0px; left: 0px; width: 100%; margin: 0px; padding: 25px; background-color: #ffffff85;  font-size: 16px !important; font-family: "Poppins-SemiBold";}
.new-collection-bx a, .new-collection-bx h6 a{ color: #313131; font-size: 16px !important; font-family: "Poppins-SemiBold";}

.obl-LatestTrending-section { padding: 30px 0px 10px 15px;}
.obl-LatestTrending-section h2{ font-family: "Poppins-Medium"; margin-bottom: 0px;}
.obl-LatestTrending-section .new-collection-bx h6 a { color: #313131; font-size: 16px !important; font-family: "Poppins-SemiBold";}
.new-collection-bx a, .new-collection-bx h6 a { color: #313131; font-size: 16px !important; font-family: "Poppins-SemiBold";}

button.read-btn-cat-page-grey {
    background-color: transparent;
    border: none;
    color: #009444;
    font-family: "Poppins-Medium";
    }
    .category_accordion_data.mobile{ display: none;}

@media screen and (max-width: 767px)
{
	.new-design-mobileview{ display: block;}
    .mobile-latest-sction .slick-list .slick-slide{ padding-right: 10px;}
    .new-collection-bx h6 { padding: 15px;}
    .style-tiles-section .slick-dots li { width: 8px; height: 8px; background-color: #D9D9D9; border-color: #D9D9D9;}
    .style-tiles-section .slick-dots li button { width: 8px; height: 8px; padding: 0px;}
    .style-tiles-section .slick-dots li.slick-active { width: 8px; height: 8px; background-color: #605B5B; border-color: #605B5B; }
    .style-tiles-section .category-menu .slick-arrow{ display: none !important;}
    .menu-slider { display: none;}
    .style-tiles-section { padding-top: 0px; margin-bottom: 0;}
	
    .desktopMainSlider_home_page { margin-top: 0px;}
    .desktopMainSlider_home_page .style-tiles-section .container{ padding: 0px !important;}
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 190px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 190px !important;}
    .page-wrapper.header-head-bx{height:60px;}
    .category_accordion_data.mobile { display: block;}
    .category_accordion_data.desktop{ display: none;}
    
}

@media screen and (max-width: 700px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 170px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 170px !important;}
}

@media screen and (max-width: 599px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 150px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 150px !important;}
    .style-tiles-section .slick-dots { bottom: 5px;}
}

@media screen and (max-width: 520px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 135px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 135px !important;}
}

@media screen and (max-width: 479px)
{
    .desktopMainSlider_home_page { margin-top: 0px;}
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 125px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 125px !important;}
    .page-wrapper.header-head-bx{height:54px;}
}

@media screen and (max-width: 420px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 115px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 115px !important;}
}

@media screen and (max-width: 400px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 105px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 105px !important;}
}

@media screen and (max-width: 380px)
{
    .desktopMainSlider_home_page, .desktopMainSlider_home_page .styletiles-bx-item, .desktopMainSlider_home_page .category-menu { height: 95px; }
	.style-tiles-section .category-menu .styletiles-bx-item img {  height: 95px !important;}
}

/******** ****/

.recording{ padding-bottom: 10px;}
.recording button {
    background-color: transparent;
    border: solid 1px #d9d9d9;
    height: 45px;
    padding-left: 45px;
    position: relative;
    width: 100%;
    text-align: left;
}
.recording button img {
    position: absolute;
    top: 50%;
    left: 10px;
    height: 25px;
    transform: translate(-0%, -50%);
}

.pdp-other-detail-row{ margin: 35px 0px !important; display: inline-block; width: 100%; max-width: 767px;}
.pdp-other-detail-row h2 { margin-bottom: 0px; padding-bottom: 10px !important;}
.pdp-other-detail-row .pdp-other-bx p { margin: 0px 0px 5px; display: flex; flex-flow: wrap; align-items: center; border: solid 2px #f1f1f1; padding: 0px; width: 100%;}
.pdp-other-detail-row .pdp-other-bx p .pdp-label { width: 40%; padding: 10px; background-color: #f1f1f1; font-size: 16px !important; line-height: 18px; font-family: "Poppins-Medium"; position: unset; color: #424242;}
.pdp-other-detail-row .pdp-other-bx p .pdp-text-detail { font-size: 16px !important; padding: 0px 10px; line-height: normal; width: calc(100% - 40%); position: unset; color: #424242;}
.pdp-other-detail-row.mobile{ display: none;}


@media screen and (max-width: 767px)
{
	.pdp-other-detail-row .pdp-other-bx p .pdp-label { font-size: 14px !important;}
.pdp-other-detail-row .pdp-other-bx p .pdp-text-detail { font-size: 14px !important;}

.pdp-other-detail-row.mobile{ display: block;}
.pdp-other-detail-row.desktop{ display: none;}

}


.product-listing-col .produ-size {    display: flex;    flex-flow: wrap;    align-items: center;    justify-content: space-between;}
.obl-searchresult-col .produ-size {    display: flex;    flex-flow: wrap;    align-items: center;    justify-content: space-between;}



/**************************************  Design page CSS ************************************/
.designpage-section{ padding: 35px 0px 0px;}
.designpage-section .padding-spacing{ padding-bottom: 20px;}
.designpage-section .padding-spacing:last-child{ padding-bottom: 0px;}
.designpage-section p{ font-size: 14px !important; color: #000 !important; line-height: 24px !important;}
.designpage-section .talk-expert-btn { background-color: transparent; border: solid 1px #0f9243; border-radius: 30px; color: #0f9243; padding: 8px 20px; display: inline-block;}
.designpage-section .talk-expert-btn:hover { background-color: #0f9243; border: solid 1px #0f9243; color: #fff;}
.designpage-heading-bx h1 { margin-bottom: 10px; font-size: 32px !important;}
.designpage-section h2 { margin-bottom: 10px; font-size: 20px !important;}
.designpage-category-wrap { padding-top: 35px;}
.designpage-category-wrap h4{ margin-bottom: 15px;}
.designpage-category-row { display: flex; display: -webkit-box; display: -webkit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
.designpage-category-col{ width:23.5%; margin:0px 1% 25px; padding: 15px 15px 65px; border-radius: 5px; overflow: hidden; box-shadow: 0px 2px 10px #e9e9e9; position: relative;}
.designpage-category-col:nth-child(4n+1){ margin-left: 0%;}
.designpage-category-col:nth-child(4n+4){ margin-right: 0%;}
.designpage-category-col .designpage-figure{ text-align: center;}
.designpage-category-col .designpage-figure a { position: relative; overflow: hidden; text-align: center; height: 270px; display: flex; flex-flow: wrap; align-items: center; justify-content: center; background-color: #f1f1f1;}
.designpage-category-col .designpage-figure img{ width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset;}
.designpage-category-col .talk-expert-btn{ position: absolute; left: 50%; bottom: 20px; transform: translate(-50%, -0%); -o-transform: translate(-50%, -0%); -ms-transform: translate(-50%, -0%); -moz-transform: translate(-50%, -0%); -webkit-transform: translate(-50%, -0%); max-width: 150px; width: 100%;}
.designpage-category-col .designpage-value { position: absolute; top: 20px; right: 15px;}
.designpage-category-col .designpage-value span { background-color: #ffffffe8; padding: 5px 10px; border-radius: 15px;}
.designpage-category-col .designpage-text{ padding: 20px 0px 0px;    text-align: center;}
.designpage-category-col .designpage-text h3{ font-size: 16px !important; margin-bottom: 15px; line-height: normal;}
.designpage-latest-design { text-align: center; background-color: #f1f1f1; padding: 35px !important; border-radius: 5px; margin:0px auto 35px; max-width: 900px; width:100%;}
.designpage-relatedpost-section { padding-bottom: 50px;}
.designpage-relatedpost-row .slick-list { padding: 0px !important; margin: 0px -10px;}
.designpage-relatedpost-row .slick-list .slick-slide { padding: 0px 10px;}
.designpage-relatedpost-row .related-blog-col { background-color: #f1f1f1; border: solid 1px #f1f1f1;}
.designpage-relatedpost-row .related-blog-col .blog-figure a { overflow: hidden; text-align: center; height: 215px; display: flex; flex-flow: wrap; align-items: center; justify-content: center; background-color: #fff;}
.designpage-relatedpost-row .related-blog-col .blog-figure img { width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset; }.designpage-relatedpost-row .related-blog-col .blog-text { padding: 15px;}
.designpage-relatedpost-row .related-blog-col .blog-text .listingHead a, .designpage-relatedpost-row .related-blog-col .blog-text .listingHead div { line-height: normal; color: #292929; font-size: 16px; font-family: Poppins-Bold; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; height: 50px; }
.designpage-relatedpost-row .related-blog-col .blog-text .listingHead a{ margin: 10px 0px;}
.designpage-relatedpost-row .related-blog-col .blog-text .listingText p { display: -webkit-box; -webkit-line-clamp: 7; -webkit-box-orient: vertical; overflow: hidden; height: 190px;}
.designpage-relatedpost-row .related-blog-col .blog-text a { font-size: 16px; color: #0f9243; font-family: "Poppins-Bold"; line-height: normal; }
.designpage-relatedpost-row .slick-arrow { width: 30px; height: 30px; background-color: #ffcc00c9 !important; border-radius: 5px; z-index: 1; display: block !important;}
.designpage-relatedpost-row .slick-prev { left: -15px;}
.designpage-relatedpost-row .slick-prev:before { content: ''; display: inline-block; width: 12px; height: 12px; border-right: 3px solid #000; border-top: 3px solid #000; transform: rotate(-135deg); margin-left: 5px; }
.designpage-relatedpost-row .slick-next { right: -15px;}
.designpage-relatedpost-row .slick-next:before { content: ''; display: inline-block; width: 12px; height: 12px; border-right: 3px solid #000; border-top: 3px solid #000; transform: rotate(45deg); margin-right: 5px; }
.designpage-faq-section { padding-bottom: 35px !important;}
.designpage-faq-section .newacd .accordion{ list-style: none; margin: 0px; padding: 0px;}
.designpage-faq-section .newacd ul li { margin: 0px 0px 10px; color: #333; font-size: 14px; line-height: 24px; font-family: Poppins-Regular;}
.designpage-faq-section .newacd ul li a.toggle { background: #ccc; color: #333; font-family: Poppins-Medium; width: 100%; display: block; padding: 0.75em; border-radius: 0.15em; transition: 0.3s ease; cursor: pointer;}
.designpage-faq-section .newacd ul li a.toggle:before{ content: ">"; float: right; font-size: 20px; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg);}
.designpage-faq-section .newacd ul li a.toggle:hover { background: #ccc;}
.designpage-faq-section .newacd ul li .inner{ list-style: none;}
.designpage-faq-section .newacd ul .inner{ padding: 10px; border: 1px solid #ccc; border-bottom-right-radius: .15em; border-bottom-left-radius: .15em; margin-top: -1px;}
.designpage-faq-section .newacd ul li { margin: 0px 0px 10px; color: #333; font-size: 14px; line-height: 24px; font-family: Poppins-Regular;}
.designpage-faq-section .newacd ul li:last-child{ margin-bottom: 0px;}
.design-category-col-right { padding-bottom: 25px;}
.design-category-head-row { background-color: #fff; border: solid 1px #f1f1f1; padding: 12px 30px; border-radius: 10px; box-shadow: 0px 2px 10px #f1f1f1; position: sticky; top: 50px; z-index: 1; width: 100%;}
.design-category-head-row .slick-slide a { padding: 10px 15px; display: block; color: #6a6a6a; background-color: #f1f1f1; margin: 0px 5px; border-radius: 5px; text-align: center;}
.design-category-head-row .slick-slide a:hover, .design-category-head-row .slick-slide a:active, .design-category-head-row .slick-slide a:focus{ background-color: #dfdfdf; color:#000;}
.design-category-head-row .slick-arrow { z-index: 1; width: 30px; height: 30px;}
.design-category-head-row .slick-prev:before{ content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #333; border-top: 2px solid #333; transform: rotate(-135deg);}
.design-category-head-row .slick-next:before { content: ''; display: inline-block; width: 10px; height: 10px; border-right: 2px solid #333; border-top: 2px solid #333; transform: rotate(45deg); }

.image-zoom-view-modal{ padding: 0px !important;}
.image-zoom-view-modal .modal-dialog { max-width: 750px; width: 100%; margin: 0px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) !important; -o-transform: translate(-50%, -50%) !important; -mox-transform: translate(-50%, -50%) !important; -moz-transform: translate(-50%, -50%) !important; -ms-transform: translate(-50%, -50%) !important;}
.image-zoom-view-modal .modal-dialog .modal-content .modal-dialog .modal-content { padding: 10px;}
.image-zoom-view-modal .modal-dialog .modal-content .modal-dialog .modal-content .modal-header { position: absolute; top: 20px; right: 20px; border: none; padding: 0px;}
.image-zoom-view-modal .modal-dialog .modal-content .modal-dialog .modal-content .modal-header .btn-close { background-color: #ffffffc4; opacity: 1;}
.image-zoom-view-modal .modal-dialog .modal-content .modal-dialog .modal-content img { height: auto; width: 100%; border-radius: 5px; object-fit: cover;}

.two-column-designpage .designpage-category-wrap{ padding-top: 0px;}
.two-column-designpage .designpage-category-col { width: 49%; margin-left: 0px; margin-right: 0px;}
.two-column-designpage .designpage-category-col:nth-child(odd) { margin-right: 1%;}
.two-column-designpage .designpage-category-col:nth-child(even) { margin-left: 1%;}
.two-column-designpage .designpage-category-col .designpage-figure img{ width:100%;}

.designpage-heading-row { display: flex; flex-flow: wrap; align-items: center; padding-bottom: 15px;}
.designpage-heading-row .designpage-heading-left { width:calc(100% - 100px); padding-right: 10px;}
.designpage-heading-row .designpage-heading-left h4{ margin-bottom: 0px;}
.designpage-heading-row .designpage-heading-right { width: 100px; text-align: right;}
.designpage-heading-row .designpage-heading-right a { background-color: #0f9243; border: solid 1px #0f9243; border-radius: 30px; color: #fff; padding: 8px 20px; display: inline-block;}
.designpage-heading-row .designpage-heading-right a:hover { background-color: transparent; border: solid 1px #0f9243; color: #0f9243;}
.common-text-content-section { padding-bottom: 25px;}

@media screen and (max-width: 1600px)
{
    .designpage-category-col .designpage-figure a{ height:230px;}
    .designpage-relatedpost-row .related-blog-col .blog-figure a { height: 150px;}
}

@media screen and (max-width: 1280px)
{
    .designpage-category-col .designpage-figure a{ height:170px;}
}

@media screen and (max-width: 1199px)
{
    .designpage-heading-bx h1 { font-size: 28px !important;}
	.designpage-category-col{ width:32%; margin: 0px 0% 15px;}
    .designpage-category-col .talk-expert-btn { bottom: 15px;}
	.designpage-category-col:nth-child(3n+2){ margin-right: 1% !important; margin-left: 1% !important;}
	.designpage-category-col:nth-child(4n+1) { margin-left: 0%;}
    .designpage-category-col:nth-child(4n+4) { margin-right: 0%;}
	.designpage-category-col .designpage-figure a { height: 210px;}
    .designpage-category-col .designpage-value { top: 15px; right: 15px;}
    .designpage-relatedpost-row .related-blog-col .blog-figure a { height: 140px;}
    .designpage-relatedpost-row .related-blog-col .blog-text .listingText p {  -webkit-line-clamp: 8;}

    
}

@media screen and (max-width: 981px)
{ 
    .designpage-relatedpost-row .related-blog-col .blog-figure a { height: 160px;}
    .designpage-category-col{ width:49%; margin: 0px 0% 15px;}
	.designpage-category-col:nth-child(3n+2){ margin-right: 0% !important; margin-left: 0% !important;}
    .designpage-category-col:nth-child(odd){ margin-right: 1% !important;}
    .designpage-category-col:nth-child(even){ margin-left: 1% !important;}
    .designpage-category-col .designpage-figure a { height: 260px;}
    .design-category-head-row ul li a { padding: 6px 10px; font-size: 12px;}
    .design-category-head-row{ top:60px;}

    .image-zoom-view-modal .modal-dialog { max-width: 600px;}
}

@media screen and (max-width: 767px)
{
    .designpage-heading-bx h1 { margin-bottom: 10px; font-size: 24px !important;}
    .designpage-section h2 { margin-bottom: 15px;}
    .designpage-category-col .talk-expert-btn { position: unset;transform: unset; -o-transform: unset; -ms-transform: unset; -moz-transform: unset; -webkit-transform: unset;}
    .designpage-latest-design { padding: 20px !important;}
    .designpage-relatedpost-row .slick-prev { left: -10px;}
    .designpage-relatedpost-row .slick-next { right: -10px;}
    .designpage-category-col .designpage-figure a { height: 250px; }
    .designpage-category-col .designpage-text { padding: 10px 0px 0px;}
    .designpage-section .talk-expert-btn { padding: 6px 15px; display: block; max-width: 150px; text-align: center; margin: 0px auto; }
    .designpage-relatedpost-row .related-blog-col .blog-figure a { height: 170px;}
    .designpage-category-col .designpage-figure a { height: 290px;}
    .design-category-head-row ul li a { padding: 6px 10px;}

    .designpage-category-col{ width:100%; padding: 10px;}
	.designpage-category-col:nth-child(odd){ margin-right: 0%;}
	.designpage-category-col:nth-child(even){ margin-left: 0%;}
    .designpage-relatedpost-row .related-blog-col .blog-text .listingText p{ height: auto;}
    .designpage-relatedpost-row .related-blog-col .blog-text .listingHead a, .designpage-relatedpost-row .related-blog-col .blog-text .listingHead div{ height:auto;}
    .designpage-category-wrap { padding-top: 25px;}
    .desi-page-category-col-bx { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
    .design-category-head-row { width: 100%;}
    .design-category-col-right { width: calc(100% - 0%); }
    .designpage-category-col .designpage-text h3 { font-size: 14px !important; margin-bottom: 10px;}

    .two-column-designpage .designpage-category-col { width: 100%;}
    .two-column-designpage .designpage-category-col:nth-child(odd) { margin-right: 0%;}
    .two-column-designpage .designpage-category-col:nth-child(even) { margin-left: 0%;}

}

@media screen and (max-width: 639px)
{
    .designpage-category-col .designpage-figure a { height: 210px;}
    .image-zoom-view-modal .modal-dialog { max-width: 95%;}
}

@media screen and (max-width: 479px)
{
    .designpage-category-col .designpage-figure a { height: 220px; }
    .designpage-relatedpost-row .related-blog-col .blog-figure a { height: 145px;}
    .design-category-head-row ul li{ width:100%;}
    .design-category-head-row { top: 55px;}

}
/**************************************  Design page CSS ************************************/  
/***************    Record Audio btn      ********************************/

.pincode-popup-bx .modal-dialog .modal-content .modal-body .recording button { position: relative; color: #999;	text-decoration: none; overflow: hidden;}
.pincode-popup-bx .modal-dialog .modal-content .modal-body .recording button span:nth-child(1) { position: absolute; top: 0; right: 0; width: 100%; height: 1.5px; background: linear-gradient(to right, #0f9243, #0f9243); animation: animate1 2s linear infinite;}
@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.pincode-popup-bx .modal-dialog .modal-content .modal-body .recording button span:nth-child(2) { position: absolute; top: 0; right: 0; height: 100%; width: 1.5px; background: linear-gradient(to bottom, #0f9243, #0f9243); animation: animate2 2s linear infinite; animation-delay: 1s;}
@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(100%);
	}
}

.pincode-popup-bx .modal-dialog .modal-content .modal-body .recording button span:nth-child(3) { position: absolute; bottom: 0; right: 0; width: 100%; height: 1.5px; background: linear-gradient(to left, #ffcb37, #ffcb37); animation: animate3 2s linear infinite;}
@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(-100%);
	}
}

.pincode-popup-bx .modal-dialog .modal-content .modal-body .recording button span:nth-child(4) { position: absolute; top: 0; left: 0; height: 100%; width: 1.5px; background: linear-gradient(to top, #ffcb37, #ffcb37); animation: animate4 2s linear infinite; animation-delay: 1s;}
@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-100%);
	}
}

/***************    Record Audio btn      ********************************/

/***************************** new-visual-search-modal Popup *****************************/
.visual-modal{ padding: 0px !important;}
.visual-modal .modal-content{ border: none;}
.visual-modal .modal-content .modal-content { height: 100%; overflow: unset;}
.visual-modal .loading-content { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -o-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); -moz-transform: translate(-50%, -50%); width: 100%; text-align: center; padding: 15px; }
.visual-modal .loading-content img { height: 120px; width: auto; }
.visual-modal .loading-content h6 { margin: 15px 0px 0px; font-family: "Poppins-SemiBold"; font-size: 20px; }
.visual-modal .modal-dialog .modal-dialog{ margin:0px;}
.visual-modal .modal-dialog { max-width: 1440px; width: 100%; height: 768px;}
.visual-modal .modal-dialog .modal-header{ border: none;}
.visual-modal .modal-dialog .modal-header .btn-close { position: absolute; top: 10px; right: 15px; color: #000; opacity: 1; font-size: 20px; z-index: 9;}
.search-similar-product-sectio { display: flex; display: -webkit-box; display: -webktit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap;}
.search-similar-product-sectio .search-similar-product-left { width: 300px;}
.search-similar-product-sectio .search-similar-product-right { width: calc(100% - 300px);}
.search-similarproduct-head { padding: 15px 25px; border-bottom: 1px solid #ccc; height: 55px;}
.search-similarproduct-head h6 { margin: 0px; color: #000; font-size: 16px; font-family: Poppins-SemiBold;}
.similarproduct-left-row-data{ padding: 25px;}
.similarproduct-filter-row { margin-top: 25px; max-height: 275px; height: 100%; overflow: auto;}
.similarproduct-filter-row::-webkit-scrollbar { width: 3px;}
.similarproduct-filter-row::-webkit-scrollbar-track { background: #fff;}
.similarproduct-filter-row::-webkit-scrollbar-thumb { background: #ccc;}
.similarproduct-filter-row::-webkit-scrollbar-thumb:hover { background: #555;}
.similarproduct-filter-col { border-bottom: 1px solid #ccc; padding: 15px 0px;}
.similarproduct-filter-col .flt-type-heading { font-size: 16px; color: #000; position: relative; cursor: pointer; padding-right: 30px;}
.similarproduct-filter-col .flt-type-heading:before { content: ''; display: inline-block; width: 12px; height: 12px; border-right: 2px solid #8f8f8f; border-bottom: 2px solid #8f8f8f; transform: rotate(45deg); position: absolute; top: 3px; right: 10px;}
.similarproduct-filter-col .flt-type-heading.open:before { transform: rotate(-135deg); top: 10px;}
.similarproduct-filter-col .flt-type-listing ul{ list-style: none; margin: 15px 0px 0px; padding: 0px;}
.similarproduct-filter-col .flt-type-listing ul li { padding-bottom: 10px; padding-left: 30px; font-size: 16px; position: relative; }
.similarproduct-filter-col .flt-type-listing ul li label { font-weight: unset; margin: 0px;}
.similarproduct-filter-col .flt-type-listing ul li input { width: 20px; height: 20px; position: absolute; top: 2px; left: 0px; }
.similarproduct-filter-col .flt-type-listing ul p.load-more-btn { font-family: "Poppins-SemiBold"; color: #0f9243 !important; margin: 0px; padding-left: 30px; font-size: 14px;}
.search-similar-product-sectio .search-similar-product-right .search-similarproduct-head { display: flex; flex-flow: wrap; justify-content: space-between; align-items: center;}
.similarproduct-item p { margin: 0px; font-size: 14px; color: #000; padding-right: 25px; line-height: normal; font-family: Poppins-SemiBold;}
.similarproduct-select .select-bx { color: #000; padding: 1px 5px; border: solid 1px #838383; border-radius: 3px;}
.searchsimilar-prodect-section{ max-height: 675px; overflow: auto; margin-top: 25px;}
.searchsimilar-prodect-row { display: flex; display: -webkit-box; display: -webktit-flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; }
.searchsimilar-prodect-section::-webkit-scrollbar { width: 6px;}
.searchsimilar-prodect-section::-webkit-scrollbar-track { background: #fff;}
.searchsimilar-prodect-section::-webkit-scrollbar-thumb { background: #ccc;}
.searchsimilar-prodect-section::-webkit-scrollbar-thumb:hover { background: #555;}
.searchsimilar-prodect-row .searchsimilar-prodect-col { margin: 0px 1% 25px; width: 23%; border: 1px solid #eee; }
.searchsimilar-prodect-row .searchsimilar-prodect-col a { display: block;}
.searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img { position: relative; overflow: hidden; text-align: center; height: 250px; display: flex; flex-flow: wrap; align-items: center; justify-content: center;}
.searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img img{ width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset;}
.searchsimilar-prodect-col .searchsimilar-prodect-text { padding: 10px;}
.searchsimilar-prodect-col .searchsimilar-prodect-text h6 { font-size: 16px; font-family: Poppins-SemiBold;}
.searchsimilar-prodect-col .searchsimilar-prodect-text p { font-size: 14px; margin-bottom: 0px; line-height: normal;}
.searchsimilar-loar-more-btn { text-align: center; padding: 15px 0px 30px;}
.searchsimilar-loar-more-btn a { padding: 10px 30px; background-color: #0f9243; border: solid 1px #0f9243; color: #fff; border-radius: 6px; font-size: 18px; display: inline-block; }
.searchsimilar-loar-more-btn a:hover{ background-color: #fc0; border: solid 1px #fc0; color: #404040;}
.similarproduct-img { position: relative; overflow: hidden; text-align: center; height: 350px; display: flex; flex-flow: wrap; align-items: center; justify-content: center; }
.similarproduct-img img { width: auto; height: auto; max-height: 100%; aspect-ratio: unset; overflow-clip-margin: unset; overflow: unset; position: relative;}
.crop-img-filter { cursor: all-scroll;}
.crop-img-filter:before { content: ""; position: absolute; width: 100%; height: 100%; display: block; border: 50px solid rgb(0 0 0 / 30%); box-shadow: inset 0px 0px 0px 2px #fff; box-sizing: border-box; transition: all 0.5s ease; -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -ms-transition: all 0.5s ease; -o-transition: all 0.5s ease; left: 0%; top: 0px; z-index: 1;}
.crop-icon-section { position: absolute; left: 10px; bottom: 10px; width: 40px; height: 40px; line-height: 38px; background-color: #000000b8; border-radius: 30px; z-index: 2; cursor: pointer;}
.crop-icon-section img { height:20px; -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);}
.similarproduct-mobile-filter{ display: none;}
.similarproduct-filter-mobile-heading{ display: none;}
.confirm-crop-btn { position: absolute; bottom: 10px; left: 0px; width: 100%; padding: 0px 10px;  z-index: 9;}
.confirm-crop-btn button{ padding: 6px 10px !important; background-color: #0f9243; border: solid 1px #0f9243; color: #fff; border-radius: 6px; font-size: 16px; display: inline-block;}



@media screen and (max-width: 1440px)
{
    .visual-modal{ padding: 0px !important;}
    .visual-modal .modal-dialog{ margin: 0px auto;}
    .similarproduct-left-scroll-bx { height: calc(100vh - 100px); overflow: auto; padding-bottom: 50px;}
    .searchsimilar-prodect-section {  max-height: calc(100vh - 100px);}
    .visual-modal .modal-content .modal-content { overflow: auto;}
}

@media screen and (max-width: 1199px)
{
    .searchsimilar-prodect-row .searchsimilar-prodect-col{ width:31%;}
    .searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img{ height: 200px;}
}

@media screen and (max-width: 1024px)
{
    .searchsimilar-prodect-row .searchsimilar-prodect-col{ width:48%;}
}

@media screen and (max-width: 767px)
{
    .search-similarproduct-head{ padding: 15px; height: auto;}
    .similarproduct-left-row-data { padding: 15px;}
    .search-similar-product-sectio .search-similar-product-left { width: 100%;}
    .search-similarproduct-head h6 { text-align: center; font-size: 14px;}
    .similarproduct-img, .similarproduct-img div{ height: auto;}
    .search-similar-product-sectio .search-similar-product-right { width: calc(100% - 0%);}
    .similarproduct-left-scroll-bx { padding-bottom: 0px; height: auto;}
    .similarproduct-filter-row{ max-height: unset; margin-top: 0px; padding: 0px 15px 70px;}
    .searchsimilar-prodect-col .searchsimilar-prodect-text h6 { -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; display: -webkit-box; font-size: 14px;}
    .similarproduct-filter-col { padding: 10px 0px;}
    .similarproduct-filter-col .flt-type-heading{ font-size: 14px; padding-right: 20px; font-family: "Poppins-Medium"; color: #333;}
    .similarproduct-filter-col .flt-type-listing ul li input { width: 16px; height: 16px; top: 5px;}
    .similarproduct-filter-col .flt-type-listing ul li{ font-size: 14px; padding-left: 25px; padding-bottom: 5px;}
    .visual-modal .modal-dialog .modal-header { padding: 0px;}
    .similarproduct-filter-col .flt-type-heading:before { width: 8px; height: 8px; top: 5px; right: 4px; }
    .similarproduct-select .select-bx{ padding: 0px; border: none;}
    .visual-modal .modal-dialog .modal-header .btn-close { top: 18px; right: 10px; font-size: 14px;}
    .searchsimilar-prodect-col .searchsimilar-prodect-text {  padding-top: 10px;}
    .searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img { height: 200px;}
    .crop-img-filter:before { border: 25px solid rgb(0 0 0 / 30%);    }
    .similarproduct-mobile-filter{ display: inline-block;}
    .similarproduct-mobile-filter p{ margin: 0px; font-family: "Poppins-Medium"; color: #000; font-size: 15px !important;}
    .similarproduct-mobile-filter p svg { width: 33px; height: 28px; padding-right: 5px;}
    .similarproduct-img { max-width: 250px; height: 300px; width: 100%; margin: 0px auto;}
    .similarproduct-filter-mobile-heading { display: flex; flex-flow: wrap; align-items: center; justify-content: space-between; padding: 20px 15px; border-bottom: 1px solid #ccc;}
    .similarproduct-filter-mobile-heading p{ font-family: Poppins-SemiBold; color: #000; font-size: 16px !important; margin-bottom: 0px;}
    .similarproduct-filter-mobile-heading .btn-close { width: 25px; height: 25px; background-color: #f1f1f1; opacity: 1;}
    .searchsimilar-prodect-section { max-height: unset;}
    .similarproduct-item{display: none;}
    .search-similar-product-sectio .search-similar-product-right .search-similarproduct-head { border-bottom: 1px solid #a1a1a1; border-top: 1px solid #a1a1a1; padding: 15px 0px;}
    .similarproduct-filter { position: fixed; top: 0px; right: -100%; width: 100%; height: 100%; background-color: #fff; z-index: 9; transition: ease all .5s;}
    .expanded-sec .similarproduct-filter { right: 0px;}
    .searchsimilar-prodect-row .searchsimilar-prodect-col { width: 49%; margin: 0px 0% 15px; }
    .searchsimilar-prodect-row .searchsimilar-prodect-col:nth-child(odd){ margin-right: 1%;}
    .searchsimilar-prodect-row .searchsimilar-prodect-col:nth-child(even){ margin-left: 1%;}
    .similarproduct-filter-col .flt-type-listing ul p.load-more-btn { padding-left: 25px;}
    .visual-modal .loading-content img { height: 80px; }
    .visual-modal .loading-content h6 { margin: 10px 0px 0px; font-size: 16px; }
    .confirm-crop-btn button{ font-size: 14px;}
    .visual-modal .modal-content .modal-content {  overflow: auto;}
}

@media screen and (max-width: 639px)
{
    .searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img { height: 150px;}
    .similarproduct-select { width: calc(100% - 80px);}
    .similarproduct-item { width: 80px; text-align: right;}
    .similarproduct-item p{ padding-right: 0px;}
    .crop-img-filter:before {        border: 10px solid rgb(0 0 0 / 30%);    }
    .crop-icon-section {    left: 5px;        bottom: 5px;        width: 30px;        height: 30px;        line-height: 28px;    }
    .crop-icon-section img{ height: 15px;}
    .searchsimilar-loar-more-btn{ padding: 0px;}
}

@media screen and (max-width: 479px)
{
    .searchsimilar-prodect-row .searchsimilar-prodect-col .searchsimilar-prodect-img { height: 110px;}
}
/***************************** new-visual-search-modal Popup *****************************/

/**************** shop-by-color page start  ***************/
.ShopByColor-intro-section { padding: 50px 0px;}
.ShopByColor-intro-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%; align-items: center;}
.ShopByColor-intro-left { width: 65%; height: 320px;}
.ShopByColor-intro-left img { object-fit: cover; width: 100%; height: 100%;}
.ShopByColor-intro-right { width: 35%; padding-left: 50px;}
.ShopByColor-intro-right p{ font-size: 16px; margin: 20px 0px; line-height: 26px;}
.ShopByColor-intro-right .shop-all-btn{ font-size: 16px; text-transform: uppercase;}
.find-color-section { background-color: #F5F5F6; padding: 50px 0px;}
.find-color-heading-row { text-align: center; padding-bottom: 15px;}
.find-color-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
.find-color-row .find-color-col { width: 18.4%; background-color: #fff; padding: 7px 7px 10px; border-radius: 5px; margin: 0px 1% 20px;}
.find-color-row .find-color-col:nth-child(5n+1) { margin-left: 0px;}
.find-color-row .find-color-col:nth-child(5n+5) { margin-right: 0px;}
.find-color-row .find-color-col .find-color-text { padding-top: 10px;}
.find-color-row .find-color-col .find-color-text h4 { font-family: "Poppins-Regular"; margin: 0px; text-align: center;}

@media screen and (max-width: 1199px)
{
    .ShopByColor-intro-right h2 { font-size: 24px !important;}
    .ShopByColor-intro-right p { font-size: 14px; margin: 10px 0px; line-height: 24px;}
    .ShopByColor-intro-right .shop-all-btn { font-size: 14px; Padding: 6px 17px;}
}

@media screen and (max-width: 991px)
{
    .ShopByColor-intro-left { width: 60%;}
    .ShopByColor-intro-right { width: 40%; padding-left: 30px;}
    .find-color-row .find-color-col { width: 24%; margin: 0px 0.5% 20px;}
    .find-color-row .find-color-col:nth-child(5n+1) { margin-left: 0.5%;}
    .find-color-row .find-color-col:nth-child(5n+5) { margin-right: 0.5%;}
    .find-color-row .find-color-col .find-color-text h4 { font-size: 16px !important;}

}

@media screen and (max-width: 767px)
{
    .ShopByColor-intro-section { padding: 30px 0px;}
    .ShopByColor-intro-left { width: 100%; height: auto;}
    .ShopByColor-intro-right { width: 100%; padding: 20px 0px 0px;}
    .ShopByColor-intro-right p { line-height: 22px;}
    .find-color-section { padding: 30px 0px;}
    .find-color-heading-row { padding-bottom: 0px;}
    .find-color-row .find-color-col { width: 49%; margin: 0px 0% 15px;}
    .find-color-row .find-color-col:nth-child(5n+1) { margin-left: 0%;}
    .find-color-row .find-color-col:nth-child(5n+5) { margin-right: 0%;}
    .find-color-row .find-color-col:nth-child(odd) { margin-right: 1%;}
    .find-color-row .find-color-col:nth-child(even) { margin-left: 1%;}
    .find-color-row .find-color-col .find-color-text h4 { font-size: 14px !important;}

}
/**************** shop-by-color page Ends  ***************/

/*************** Home page shop-by-color page start  **************/
.home-color-tile-section { background-color: #fff; padding: 50px 0px;}
.home-color-tile{ text-align: center; padding-bottom: 15px;}
.home-color-tile p{ font-size: 16px;}
.find-color-heading-row { text-align: center; padding-bottom: 15px;}
.home-color-tile-row { display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
.home-color-tile-row .home-color-tile-col { width: 18.4%; background-color: #f5f5f6; padding: 7px 7px 10px; border-radius: 5px; margin: 0px 1% 20px;}
.home-color-tile-row .home-color-tile-col:nth-child(5n+1) { margin-left: 0px;}
.home-color-tile-row .home-color-tile-col:nth-child(5n+5) { margin-right: 0px;}
.home-color-tile-row .home-color-tile-col .find-color-img img{ width:100%;}
.home-color-tile-row .home-color-tile-col .find-color-text { padding-top: 10px;}
.home-color-tile-row .home-color-tile-col .find-color-text h4 { font-family: "Poppins-Regular"; margin: 0px;text-align: center; font-size: 18px !important;}
.shop-all-solor-btn { text-align: center; padding-top: 20px;}


@media screen and (max-width: 991px)
{
   
    .home-color-tile-row .home-color-tile-col .find-color-text h4 { font-size: 16px !important;}

}

@media screen and (max-width: 767px)
{
    .home-color-tile-row .home-color-tile-col { width: 48%; margin: 0px 0% 15px;}
    .home-color-tile-row .home-color-tile-col:nth-child(3n+2){ margin-right: 0%; margin-left: 0%;}
    .home-color-tile-row .home-color-tile-col:nth-child(odd){ margin-right: 2%;}
    .home-color-tile-row .home-color-tile-col:nth-child(even){ margin-left: 2%;}
    .home-color-tile-row .home-color-tile-col .find-color-text h4 { font-size: 14px !important;}
    .home-color-tile-row .home-color-tile-col.lastchild-color-bx{ display: none;}
    .shop-all-solor-btn{ padding-top: 0px;}
}
/*************** Home page shop-by-color page end  **************/

/***************** Store Locator page   ****************/
.store_locator_row .obl_store_locator_col .store_add_row .store_add_icon { position: absolute; right: 20px; bottom: 20px;}
.store_add_icon .store-whatsapp-icon { display: block; text-align: center;}
.store_add_icon .store-duration-icon { margin-top: 10px;}
.store_add_icon .store-whatsapp-icon span { width: 30px; height: 30px; line-height: 28px; display: block; background-color: #5fd448; border-radius: 50%; margin: 0px auto; cursor: pointer;}
.store_add_icon .store-whatsapp-icon span svg { fill: #fff; height: 22px;}


/***************** Store Locator page Ends  ****************/
.pdp-form{ width: 100% !important;  padding: 10px !important; }
.pdp-form > button{ width: 100%}
#titlediv{display: none !important;}




/***************** Sustainability page css  ****************/
.sustainability-wrap { padding: 50px 0px;}
.sustainability-row h1 { font-family: Poppins-Bold; font-size: 28px !important; margin-bottom:25px;}
.sus-paraWrap{ padding-bottom: 25px;}
.sus-paraWrap:last-child{ padding-bottom: 0px;}
.sus-paraWrap h2 { font-size: 18px !important; color: #333; font-weight: 700; margin: 0px 0 30px; text-transform: capitalize;}
.sus-paraWrap h3 { font-size: 18px !important; color: #333; font-weight: 700; margin: 0px 0 15px; text-transform: capitalize;}
.sus-paraWrap p{ padding: 0; color: #333; font-size: 14px; line-height: 24px;}
.sus-paraWrap ul{ margin: 0px 0px 15px 18px; padding: 0px;}
.sus-paraWrap p:last-child{ margin-bottom: 0px;}

.obl-sustainability-section { padding-top: 50px;}
.obl-sustainability-section h2{ font-family: Poppins-Bold; font-size: 28px !important; margin-bottom: 15px;}
.obl-sustainability-section h2+p{ color: #333;}
.obl-sustainability-row {display: flex; display: -moz-flex; display: -ms-flex; flex-flow: wrap; width: 100%;}
.obl-sustainability-row .obl-sustainability-col { width: 23.5%; border: 1px solid #e5e5e5; margin-top: 20px; padding: 20px; margin: 0px 1% 25px; }
.obl-sustainability-row .obl-sustainability-col:nth-child(4n+1) { margin-left: 0px;}
.obl-sustainability-row .obl-sustainability-col:nth-child(4n+4) { margin-right: 0px;}
.obl-sustainability-row .obl-sustainability-col .susta-image { text-align: center;}
.obl-sustainability-row .obl-sustainability-col .susta-image img { height: 200px;}
.obl-sustainability-row .obl-sustainability-col small { color: #747474;}
.obl-sustainability-row .obl-sustainability-col h4 { font-size: 18px !important; margin: 15px 0px 0px; font-family: Poppins-Regular; line-height: 30px;}
.obl-sustainability-row .obl-sustainability-col a:hover h4{ color: #0b9444 !important;}

@media screen and (max-width: 1600px)
{
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 150px;}
    .obl-sustainability-row .obl-sustainability-col h4 { font-size: 16px !important; line-height: 26px;}

}

@media screen and (max-width: 1280px)
{
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 120px;}

}

@media screen and (max-width: 1199px)
{
    .obl-sustainability-row .obl-sustainability-col { width: 32%; margin: 0px 0% 25px; }
    .obl-sustainability-row .obl-sustainability-col:nth-child(3n+2){ margin-left: 2%; margin-right: 2%;}
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 160px;}

}

@media screen and (max-width: 991px)
{
    .obl-sustainability-row .obl-sustainability-col { width: 49%; }
    .obl-sustainability-row .obl-sustainability-col:nth-child(3n+2){ margin-left: 0%; margin-right: 0%;}
    .obl-sustainability-row .obl-sustainability-col:nth-child(odd){ margin-right: 1%; }
    .obl-sustainability-row .obl-sustainability-col:nth-child(even){ margin-left: 1%; }
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 190px;}

}

@media screen and (max-width: 767px)
{
    .obl-sustainability-row .obl-sustainability-col { width: 100%; margin: 0px 0% 15px; }
    .obl-sustainability-row .obl-sustainability-col:nth-child(odd){ margin-right: 0%; }
    .obl-sustainability-row .obl-sustainability-col:nth-child(even){ margin-left: 0%; }
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 250px;}
    .obl-sustainability-section h2 { font-size: 20px !important;}
    
}

@media screen and (max-width: 479px)
{
    .obl-sustainability-row .obl-sustainability-col{ padding: 15px;}
    .obl-sustainability-row .obl-sustainability-col .susta-image img { height: 180px;}


}
/***************** Sustainability page css  ****************/


/****************************** Bathroom Design Inner CSS ******************************/
.obl-master-design-section{ padding-bottom: 50px;}
.designpage-category-col .designpage-figure img { overflow: unset; transition: all .5s ease; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -ms-transition: all .5s ease;}
.designpage-category-col .designpage-figure{ overflow: hidden;}
.designpage-category-col:hover .designpage-figure img{ transform: scale(1.1); -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1);}
.obl-master-design-section .designpage-category-row{ padding-bottom: 30px;}

.designpage-category-row.inner-design-gallery .designpage-category-col { width: 32%; margin: 0px 0% 25px;}
.designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(3n+2) { margin-left: 2%; margin-right: 2%;}

.designpage-section .description-text button { background-color: transparent; border: none; color: #00953b; font-family: "Poppins-SemiBold";}
.designpage-section .description-text span{ color: #000;}
.designpage-section .designpage-heading-bx h4 { margin: 25px 0px 15px; font-size: 16px !important;}

@media screen and (max-width: 991px)
{
    .designpage-category-row.inner-design-gallery .designpage-category-col { width: 49%; margin: 0px 0% 15px;}
    .designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(3n+2) { margin-left: 0% !important; margin-right: 0% !important;}
    .designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(odd) { margin-right: 1% !important;}
    .designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(even) { margin-left: 1% !important;}
}

@media screen and (max-width: 767px)
{
    .designpage-category-row.inner-design-gallery .designpage-category-col { width: 100%;}
    .designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(odd) { margin-right: 0% !important;}
    .designpage-category-row.inner-design-gallery .designpage-category-col:nth-child(even) { margin-left: 0% !important;}
}

/****************************** Bathroom Design Inner CSS ******************************/



/**************** Review page css  ***************/
.review-section { padding: 50px 0px;}
.review-heading-row { max-width: 500px; width: 100%; padding-bottom: 50px; margin: 0px auto; background-image: url(/quote-bg.png); background-repeat: no-repeat; background-size: cover; padding: 30px; border-radius: 30px; height: 337px;}
.review-heading-row h1 { margin: 0px; color: #fff !important; text-align: center; font-family: "Poppins-Bold";}
.review-row { max-width: 700px; width: 100%; margin: 50px auto 0px;}
.review-button-tab { display: flex; flex-flow: wrap; align-items: center;}
.review-button-tab .open-rev-section { width: 50%; text-align: center; border: solid 1px #ccc; background-color: #fff; color: #333; font-size: 16px; padding: 15px; margin-right: -1px;}
.review-tab-data { padding: 30px; border: solid 1px #ccc; border-top: 0px; margin-right: 1px;}
.review-button-tab .open-rev-section.rev-active { border: solid 1px #fed94b; background-color: #fed94b; color: #333; font-family: "Poppins-Medium";}
.review-page-rev-section label.form-label { font-family: "Poppins-Regular"; font-weight: unset; display: block; margin: 0px 0px 2px;}
.review-page-rev-section .review_pro_custom_file { flex-flow: wrap; align-items: start;}
.review-page-rev-section .review_pro_custom_file .img-name-sec-div p {    margin: 0px;    font-size: 14px;}
.review-page-rev-section .review_pro_custom_file .btn-primary { width: 49%; padding: 10px; border-radius: 2px; line-height: 24px; background-color: #0f9243; border: solid 1px #0f9243;}
.review-page-rev-section .your_rating_css { width: 100%; display: flex; flex-flow: wrap; align-items: center;}
.review-page-rev-section .your_rating_css .form-label { padding-right: 15px;}
.review-page-rev-section textarea { height: 100px !important;}
.review-page-video-section .review-upload-form { display: flex; flex-flow: wrap; justify-content: space-between;}
.review-page-video-section .review-upload-form .review-upload-formgroup { width: 48%; margin-bottom: 15px;}
.review-page-video-section .review-upload-form .review-upload-formgroup label { font-family: "Poppins-Regular"; font-weight: unset; display: block; margin: 0px 0px 2px;}
.review-page-video-section .review-upload-form .review-upload-formgroup.full-width{ width:100%; margin-right: 0%; margin-left: 0%;}
.review-tab-data .form-control { padding: 10px;}
.review-page-video-section .review-upload-form .review-upload-formgroup.rating-row { display: flex; flex-flow: wrap; align-items: center;}
.review-page-video-section .review-upload-form .review-upload-formgroup.rating-row label { padding-right: 15px;}
.review-page-video-section .review-upload-form .review-upload-formgroup.rating-row div span { font-size: 28px !important;}
.review-page-video-section .review-upload-form .review-upload-formgroup.submit-btn { margin: 10px 0px 0px;}
.review-page-video-section .review-upload-form .review-upload-formgroup.submit-btn button { padding: 10px; border-radius: 2px; line-height: 24px; background-color: #0f9243; border: solid 1px #0f9243; width: 100%; color: #fff; font-size: 16px; font-family: "Poppins-Bold";}

@media screen and (max-width: 639px)
{
    .review-button-tab .open-rev-section { font-size: 14px; padding: 15px 10px;}
    .review-heading-row { max-width: 331px; height: 223px;}
    .review-heading-row img { height: 30px;}
    .review-tab-data { padding: 15px;}
    .review-page-video-section .review-upload-form .review-upload-formgroup { width: 100%;}
    .review-page-rev-section .your_rating_css_div>div { width: 100%;}

}

@media screen and (max-width: 479px)
{
    .review-button-tab .open-rev-section { padding: 5px 10px; line-height: 18px; height: 60px;}
    .review-page-rev-section .review_pro_custom_file .btn-primary { width: 100%; margin-top: 10px;}
    
}

/**************** Review page css  ***************/


/***************** Design Ideas page css  ****************/
.design-ideas-page{ background-color: transparent;}
.design-ideas-page .find-color-row .find-color-col { width: 32%; background-color: #f1f1f1; margin: 0px 0px 30px;}
.design-ideas-page .find-color-row .find-color-col:nth-child(3n+2) { margin-left: 2%; margin-right: 2%;}
.tru_btn_new.design-page {
    padding-top: 25px;
}
@media screen and (max-width: 980px)
{
    .design-ideas-page .find-color-row .find-color-col{ width: 49%; margin: 0px 0px 15px;}
    .design-ideas-page .find-color-row .find-color-col:nth-child(3n+2) { margin-left: 0%; margin-right: 0%;}
    .design-ideas-page .find-color-row .find-color-col:nth-child(odd){ margin-right: 1%;}
    .design-ideas-page .find-color-row .find-color-col:nth-child(even){ margin-left: 1%;}
}


/***************** Design Ideas page css  ****************/
.designpage-heading-row .designpage-heading-left h3 { font-size: 20px !important;}
.designpage-category-row .designpage-category-col h4 { font-size: 16px !important; margin-bottom: 15px; line-height: normal;}

@media screen and (max-width: 767px)
{
    .designpage-heading-row .designpage-heading-left h3 { font-size: 18px !important;}   
}